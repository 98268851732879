import React, { useEffect, useState } from "react";
import useCustomer from "Hooks/CustomerHooks/useCustomer";
import HeadingTitle from "Components/Headings/HeadingTitle";
import { addPageQueryParam, getPageParam } from "Helpers/PageHelper";

import CustomersTable from "./partials/CustomersTable";
import CustomerFilter from "./partials/CustomerFilter";

const CustomersContainer = () => {
    const { getCustomers } = useCustomer();
    const [customers, setCustomers] = useState(null);
    const [currentPage, setCurrentPage] = useState(getPageParam());
    const [filtersActive, setFiltersActive] = useState<boolean>(false);

    const fetchCustomers = async (page: number) => {
        const data = await getCustomers(page);
        setCustomers(data);
    };

    useEffect(() => {
        if (!filtersActive) {
            fetchCustomers(currentPage);
        }
        addPageQueryParam(currentPage);
    }, [currentPage]);

    return (
        <div className={"customers-container"}>
            <CustomerFilter
                filtersActive={filtersActive}
                setFiltersActive={setFiltersActive}
                setCustomerData={setCustomers}
                currentPage={currentPage}
            />
            <HeadingTitle title={"Customers List"} />
            <CustomersTable
                // @ts-ignore
                customers={customers?.data}
                setPage={setCurrentPage}
                // @ts-ignore
                totalPages={customers?.total}
            />
        </div>
    );
};

export default CustomersContainer;
