import React from "react";
import { CustomerRentalStatus } from "Types/CustomerTypes";
import { getCurrentDateDifference } from "Utils/DateUtils";
import CustomerRentingStatus from "./CustomerRentingStatus";
import CustomerFinishedStatus from "./CustomerFinishedStatus";
import CustomerPickUpStatus from "./CustomerPickUpStatus";

interface CustomerStatusHandlerProps {
    status: CustomerRentalStatus;
    date: string;
}
const CustomerStatusHandler = ({
    status,
    date,
}: CustomerStatusHandlerProps) => {
    const handleStatus = () => {
        switch (status) {
            case "RENTING":
                return <CustomerRentingStatus />;
            case "FINISHED":
                return <CustomerFinishedStatus />;
            case "PICK_UP":
                const untilPickupDays = getCurrentDateDifference(date);
                return <CustomerPickUpStatus days={untilPickupDays} />;
            default:
                break;
        }
    };
    return <>{handleStatus()}</>;
};

export default CustomerStatusHandler;
