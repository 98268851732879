import React, { useEffect, useState } from "react";
import ContentWrapper from "Components/ContentWrapper/ContentWrapper";
import LoadingPlaceholder from "Components/Placeholders/LoadingPlaceholder/LoadingPlaceholder";
import AntSidebar from "Components/Sidebar/AntSidebar";
import Logo from "Components/Logo/Logo";
import MobileMenu from "Components/MobileMenu/MobileMenu";

const DashboardLayout = ({ children }) => {
    const [isLoaded, setLoading] = useState(true);

    useEffect(() => {
        setLoading(false);
    }, []);

    return (
        <div className="rac-dashboard-layout flex mdh:flex-wrap w-full">
            <MobileMenu />
            <div
                className={
                    "mdh:hidden flex flex-col bg-white m-3  mt-3 rounded-md"
                }
            >
                <div className={" bg-white mt-3 pt-3 mb-3 w-36 mx-auto"}>
                    <Logo />
                </div>
                <AntSidebar />
            </div>
            <div className="flex flex-col w-full p-3">
                <ContentWrapper>
                    {" "}
                    {isLoaded ? <LoadingPlaceholder /> : children}{" "}
                </ContentWrapper>
            </div>
        </div>
    );
};

export default DashboardLayout;
