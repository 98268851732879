import React from "react";
import ManageIntegrationsContainer from "Containers/Integrations/ManageIntegrationsContainer";
import withRole from "../../../Providers/AuthProvider/WithRole";

const IntegrationPage = () => {
    return (
        <div className="integration-page">
            <ManageIntegrationsContainer />
        </div>
    );
};

export default withRole(IntegrationPage, ["admin"], []);
