import ReactEcharts from "echarts-for-react";
import DashboardContext from "../../../Context/DashboardContext";
import { useContext } from "react";

const BarChart = () => {
    const { analytics } = useContext(DashboardContext);
    const weeklyStatistic = analytics?.weeklyStatistic;

    const option = {
        tooltip: {
            trigger: "axis",
            axisPointer: {
                type: "shadow",
            },
        },
        grid: {
            left: "3%",
            right: "4%",
            bottom: "3%",
            containLabel: true,
        },
        xAxis: [
            {
                type: "category",
                data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
                axisTick: {
                    alignWithLabel: true,
                },
            },
        ],
        yAxis: [
            {
                type: "value",
            },
        ],
        series: [
            {
                name: "Revenue",
                type: "bar",
                barWidth: "20%",
                data: [
                    weeklyStatistic?.Monday?.totalPrice,
                    weeklyStatistic?.Tuesday?.totalPrice,
                    weeklyStatistic?.Wednesday?.totalPrice,
                    weeklyStatistic?.Thursday?.totalPrice,
                    weeklyStatistic?.Friday?.totalPrice,
                    weeklyStatistic?.Saturday?.totalPrice,
                    weeklyStatistic?.Sunday?.totalPrice,
                ],
            },
            {
                name: "Reservations",
                type: "bar",
                barWidth: "20%",
                data: [
                    weeklyStatistic?.Monday?.reservations,
                    weeklyStatistic?.Tuesday?.reservations,
                    weeklyStatistic?.Wednesday?.reservations,
                    weeklyStatistic?.Thursday?.reservations,
                    weeklyStatistic?.Friday?.reservations,
                    weeklyStatistic?.Saturday?.reservations,
                    weeklyStatistic?.Sunday?.reservations,
                ],
            },
        ],
    };
    return <ReactEcharts style={{ height: "400px" }} option={option} />;
};

export default BarChart;
