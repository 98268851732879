import React, { useContext } from "react";
import IntegrationImage from "../../Integrations/IntegrationImage";
import IntegrationStatus from "../../Integrations/IntegrationStatus";
import { Divider } from "antd";
import IntegrationUtils from "Utils/IntegrationUtils";
import HeadingTitle from "../../Headings/HeadingTitle";
import IntegrationConfig from "../../Integrations/IntegrationConfig";
import { SingleIntegrationContext } from "Context/Integrations/SingleIntegrationContext";
import CreateConfigModal from "../../Integrations/RentelonApiConfigurations/Modal/CreateConfigModal";
import DeleteIntegrationModal from "Containers/Integrations/partials/DeleteIntegrationModal";
import UpdateIntegrationModal from "Containers/Integrations/partials/UpdateIntegrationModal";

const IntegrationWrapper = () => {
    const { integration } = useContext(SingleIntegrationContext);

    const {
        integration_type,
        integration_name,
        integration_service,
        status,
        uuid,
    } = integration;

    return (
        <div>
            <div className={"flex sm:flex-wrap gap-5"}>
                <div
                    className={
                        "w-[250px] h-[250px] bg-blue-100 p-3 rounded-md flex items-center justify-center"
                    }
                >
                    <IntegrationImage
                        integrationType={integration_type}
                        size={220}
                    />
                </div>
                <div className={"w-full"}>
                    <div
                        className={
                            "flex sm:flex-wrap sm:mb-4 gap-3 justify-between items-center"
                        }
                    >
                        <div className={" flex gap-3 items-center"}>
                            <h1 className={"font-bold text-2xl"}>
                                {integration_type?.replace("_", " ")}
                            </h1>
                            <p className={"font-bold"}>
                                {" "}
                                ({integration_name}){" "}
                            </p>
                            <IntegrationStatus
                                className={"pt-1 pb-1 "}
                                integrationStatus={status}
                            />
                        </div>
                        <div className={"flex gap-2"}>
                            <UpdateIntegrationModal
                                data={{
                                    integration_name,
                                    status,
                                    integrationId: uuid,
                                }}
                            />
                            {integration_type === "RENTELON_API" && (
                                <CreateConfigModal integrationId={uuid} />
                            )}
                            <DeleteIntegrationModal integrationId={uuid} />
                        </div>
                    </div>
                    <p className={"mt-2 "}>
                        SERVICE:{" "}
                        <strong className={"!text-blue-500"}>
                            {integration_service}
                        </strong>
                    </p>
                    <p className={"mt-2"}>
                        {IntegrationUtils.getIntegrationShortDescrption(
                            integration_type,
                        )}
                    </p>
                    <Divider />
                    <p className={"mt-2"}>
                        {IntegrationUtils.getIntegrationLongDescription(
                            integration_type,
                        )}
                    </p>
                    <Divider />
                    <p className={"mt-2 sm:mb-4"}>
                        <a
                            className={"uppercase text-[12px] font-medium "}
                            download
                        >
                            Get Documentation
                        </a>
                    </p>
                </div>
            </div>
            <HeadingTitle title={"Configuration"} />
            <IntegrationConfig />
        </div>
    );
};

export default IntegrationWrapper;
