import React from "react";
import withRole from "../../../Providers/AuthProvider/WithRole";
import EditEmployee from "../../../Containers/Employee/EditEmployee";

const EditEmployeePage = () => {
    return (
        <div className="add-employee-page">
            <EditEmployee />
        </div>
    );
};

export default withRole(EditEmployeePage, ["admin"]);
