import Axios from "axios";

export const useAnalytics = () => {
    const getDashboardAnalytics = async (locationUuid: string = "") => {
        const response = await Axios.get(
            `/analytics?location=${locationUuid}`,
        ).catch((err) => console.log(err));
        return response?.data;
    };

    return {
        getDashboardAnalytics,
    };
};
