import React from "react";
import { Tag } from "antd";

interface ReservationConfirmedInterface {
    confirmed: "CONFIRMED";
}
const ReservationConfirmed = ({ confirmed }: ReservationConfirmedInterface) => {
    return (
        <Tag className={"px-2 py-1 font-semibold border-[2px]"} color={"green"}>
            {confirmed}
        </Tag>
    );
};

export default ReservationConfirmed;
