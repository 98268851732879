import { Context, createContext, useEffect, useState } from "react";
import { useAnalytics } from "Hooks/AnalyticsHooks/useAnalytics";
import useLocations from "Hooks/LocationsHooks/useLocations";
import { LocationTypes } from "Types/LocationTypes";
import { DefaultAnalyticsDashboardContextObject } from "Constants/Dashboard/Analytics/analyticsConstants";
import { useAuth } from "Hooks/AuthHooks/useAuth";
import {
    DashboardProviderProps,
    AnalyticsTypes,
    DashboardContextType,
} from "Types/AnalyticsTypes";

const DashboardContext: Context<DashboardContextType> =
    createContext<DashboardContextType>({
        analytics: DefaultAnalyticsDashboardContextObject,
        setAnalytics: () => {},
        locations: [],
    });

export function DashboardProvider({ children }: DashboardProviderProps) {
    const { isUserAdmin } = useAuth();
    const { getLocations } = useLocations();
    const { getDashboardAnalytics } = useAnalytics();

    const [analytics, setAnalytics] = useState<AnalyticsTypes | null>(null);
    const [locations, setLocations] = useState<LocationTypes[] | null>(null);

    const fetchAnalyticsData = async () => {
        const data = await getDashboardAnalytics();
        setAnalytics(data[0]);
    };

    const fetchLocationsData = async () => {
        const data = await getLocations();
        setLocations(data);
    };

    useEffect(() => {
        if (isUserAdmin()) {
            fetchAnalyticsData();
            fetchLocationsData();
        }
    }, []);

    return (
        <DashboardContext.Provider
            value={{
                analytics: analytics,
                setAnalytics: setAnalytics,
                locations: locations,
            }}
        >
            {children}
        </DashboardContext.Provider>
    );
}

export default DashboardContext;
