import React from "react";
import { Skeleton } from "antd";
import { Spin } from "antd/lib";

const LoadingPlaceholder = () => {
    return (
        <div className="flex items-center justify-center h-96">
            <Spin size="large" />
        </div>
    );
};

export default LoadingPlaceholder;
