import React from "react";
import { Alert, Pagination, Result, Space, Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import LoadingPlaceholder from "Components/Placeholders/LoadingPlaceholder/LoadingPlaceholder";
import { Link } from "react-router-dom";
import { formatDate } from "Utils/DateUtils";
import { getPageParam } from "Helpers/PageHelper";
import {
    CustomerTypes,
    TypeReservation,
} from "../../../../Types/CustomerTypes";
import TypeReservationHandler from "../../../../Components/TypeReservation/TypeReservationHandler";

interface DataType {
    key: string;
    name: string;
    country: string;
    passport: string;
    drivingLicence: string;
    reservationType: TypeReservation;
    carName: string;
    from: string;
    to: string;
}

interface Customer {
    customer: CustomerTypes[];
    car_name: string;
    from: string;
    to: string;
}

interface CustomersTableProps {
    customers: Customer[] | null;
    setPage: any;
    totalPages: number;
}

const columns: ColumnsType<DataType> = [
    {
        title: "Name",
        dataIndex: "name",
        key: "name",
    },
    {
        title: "Country",
        dataIndex: "country",
        key: "country",
    },
    {
        title: "Drivers Licence",
        dataIndex: "drivingLicence",
        key: "drivingLicence",
    },
    {
        title: "Passport",
        dataIndex: "passport",
        key: "passport",
    },
    {
        title: "From",
        key: "from",
        dataIndex: "from",
    },
    {
        title: "To",
        key: "to",
        dataIndex: "to",
    },
    {
        title: "Rented Vehicle",
        key: "carName",
        dataIndex: "carName",
    },
    {
        title: "Reservation Type",
        dataIndex: "reservationType",
        key: "reservationType",
        render: (_, { reservationType }) => {
            return <TypeReservationHandler type={reservationType} size={100} />;
        },
    },
    {
        title: "Action",
        key: "action",
        render: (_, record) => (
            <Space size="middle">
                <Link to={`/customer/${record.key}`}>Open Customer</Link>
            </Space>
        ),
    },
];

const CustomersTable: React.FC<CustomersTableProps> = ({
    customers,
    setPage,
    totalPages,
}) => {
    const handlePagination = (pageNo: number) => {
        setPage(pageNo);
    };

    if (!customers) return <LoadingPlaceholder />;
    if (customers.length <= 0)
        return <Result title={"There is no records found for customers."} />;

    const data: DataType[] = customers.map((item) => {
        const { customer, car_name, from, to } = item;
        const {
            uuid,
            customer_name,
            customer_country,
            customer_passport,
            customer_driving_licence,
            reservationType,
        } = customer[0];

        return {
            key: uuid,
            name: customer_name,
            country: customer_country,
            passport: customer_passport,
            drivingLicence: customer_driving_licence,
            carName: car_name,
            reservationType: reservationType,
            from: formatDate(from, "/"),
            to: formatDate(to, "/"),
        };
    });

    return (
        <>
            <Table
                className={"overflow-auto"}
                columns={columns}
                dataSource={data}
                pagination={false}
            />
            <div className={"mt-5 text-end"}>
                <Pagination
                    onChange={handlePagination}
                    defaultPageSize={15}
                    defaultCurrent={getPageParam()}
                    total={totalPages}
                    showSizeChanger={false}
                />
            </div>
        </>
    );
};

export default CustomersTable;
