import React from "react";
import { Tag, Tooltip } from "antd";

const AvailableStatus = () => {
    return (
        <Tag className={"pl-3 pr-3 pt-1 pb-1 font-bold"} color={"green"}>
            <Tooltip title={"Rental Status"}>AVAILABLE</Tooltip>
        </Tag>
    );
};
export default AvailableStatus;
