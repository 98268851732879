import { useContext } from "react";
import ReactEcharts from "echarts-for-react";
import DashboardContext from "../../../Context/DashboardContext";

const LineChart = () => {
    const { analytics } = useContext(DashboardContext);
    const yearlyStatistic = analytics?.yearlyStatistic;

    const reservations = yearlyStatistic?.map((item) => {
        return item.reservations;
    });

    const totalAmount = yearlyStatistic?.map((item) => {
        return item.totalSum;
    });

    const option = {
        tooltip: {
            trigger: "axis",
        },
        legend: {
            data: ["Total Amount", "Total Reservations"],
        },
        grid: {
            left: "3%",
            right: "4%",
            bottom: "3%",
            containLabel: true,
        },
        toolbox: {
            feature: {
                saveAsImage: {},
            },
        },
        xAxis: {
            type: "category",
            boundaryGap: false,
            data: [
                "January",
                "February",
                "March",
                "April",
                "May",
                "June",
                "July",
                "August",
                "September",
                "October",
                "November",
                "December",
            ],
        },
        yAxis: {
            type: "value",
        },
        series: [
            {
                name: "Total Amount",
                type: "line",
                stack: "Total",
                data: totalAmount,
            },
            {
                name: "Total Reservations",
                type: "line",
                stack: "Total",
                data: reservations,
            },
        ],
    };

    return (
        <ReactEcharts
            style={{
                height: "500px",
                background: "#fafafa",
                borderRadius: "10px",
            }}
            option={option}
        />
    );
};

export default LineChart;
