interface QueryBuilderConstructorTypes {
    /**
     * It creates a query by give object properties
     * @param queryParams {object}
     */
    createQuery: (queryParams: object) => string;
}

const QueryBuilder: QueryBuilderConstructorTypes = {
    createQuery: (queryParams: object) => {
        const query = new URLSearchParams();
        Object.entries(queryParams).forEach(([key, value]) => {
            if (value) {
                query.append(key, String(value));
            }
        });
        return query.toString();
    },
};

export default QueryBuilder;
