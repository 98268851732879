import React from "react";
import CoomingSoon from "Components/CoomingSoon";
import withRole from "Providers/AuthProvider/WithRole";

const DashboardEmployeeContainer = () => {
    // @ts-ignore
    return <CoomingSoon customText={"Cooming Soon Employee Analytics"} />;
};

export default withRole(DashboardEmployeeContainer, ["employee"]);
