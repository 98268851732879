import React from "react";
import {
    IntegrationConfigs,
    IntegrationConfigStatus,
    IntegrationLocation,
    IntegrationPeriod,
} from "Types/IntegrationTypes";
import { Avatar, Table, TableProps, Tag, Tooltip } from "antd";
import ReactCountryFlag from "react-country-flag";
import DeleteConfigModal from "../Modal/DeleteConfigModal";
import PeriodListingModal from "../Modal/PeriodModals/PeriodListingModal";
import UpdateConfigModal from "../Modal/UpdateConfigModal";

interface ConfigListPropTypes {
    configs: IntegrationConfigs[];
    integrationId: string;
}

interface DataType {
    key: string;
    row: number;
    category: string;
    categoryId: string;
    location: IntegrationLocation;
    description: string;
    defaultPrice: string;
    insurancePrice: string;
    deposit: string;
    availability: string | number;
    status: IntegrationConfigStatus;
    periods: IntegrationPeriod[];
}

const columns: TableProps<DataType>["columns"] = [
    {
        title: "#",
        dataIndex: "row",
        key: "row",
        width: 120,
    },
    {
        title: <small className={"uppercase"}>Category</small>,
        dataIndex: "category",
        key: "category",
        render: (_, record) => {
            return (
                <Tag className={"font-bold"} color={"blue-inverse"}>
                    {record.category}
                </Tag>
            );
        },
        width: 120,
    },
    {
        title: <small className={"uppercase"}>Location</small>,
        dataIndex: "location",
        key: "location",
        width: 120,
        render: (_, record) => {
            return (
                <Tooltip
                    className={"cursor-pointer "}
                    title={
                        <>
                            <p className={"!text-white text-center"}>
                                {record.location.name}
                            </p>
                            <p className={"!text-white text-center"}>
                                {record.location.city}
                            </p>
                            <p className={"!text-white text-center"}>
                                {record.location.address}
                            </p>
                        </>
                    }
                >
                    <Avatar
                        size={35}
                        icon={
                            <ReactCountryFlag
                                countryCode={record.location.country_code}
                                svg
                                style={{ width: "35px", height: "35px" }}
                            />
                        }
                    />
                </Tooltip>
            );
        },
    },
    {
        title: <small className={"uppercase"}>Description</small>,
        dataIndex: "description",
        key: "description",
        width: 120,
    },
    {
        title: <small className={"uppercase"}>Price Per Day</small>,
        dataIndex: "defaultPrice",
        key: "defaultPrice",
        width: 120,
    },
    {
        title: <small className={"uppercase"}>Insurance Per Day</small>,
        dataIndex: "insurancePrice",
        key: "insurancePrice",
        width: 120,
    },
    {
        title: <small className={"uppercase"}>Deposit</small>,
        dataIndex: "deposit",
        key: "deposit",
        width: 120,
    },
    {
        title: <small className={"uppercase"}>Availability</small>,
        dataIndex: "availability",
        key: "availability",
        width: 120,
    },
    {
        title: <small className={"uppercase"}>Status</small>,
        dataIndex: "deposit",
        key: "deposit",
        width: 120,
        align: "center",
        className: "text-center",
        render: (_, record) => {
            let statusColor = "";
            switch (record.status) {
                case "DISABLED":
                    statusColor = "red-inverse";
                    break;
                case "ENABLED":
                    statusColor = "green-inverse";
                    break;
                default:
                    break;
            }
            return (
                <Tag className={"font-bold"} color={statusColor}>
                    {record.status}
                </Tag>
            );
        },
    },
    {
        title: <small className={"uppercase"}>Actions</small>,
        dataIndex: "action",
        key: "action",
        width: 120,
        align: "center",
        render: (_, record) => {
            return (
                <div className={"flex justify-center gap-2"}>
                    <UpdateConfigModal
                        data={{
                            location_id: record.location.uuid,
                            car_sipp_code_id: record.categoryId,
                            status: record.status,
                            deposit: parseInt(record.deposit),
                            default_price: parseInt(record.defaultPrice),
                            insurance_price: parseInt(record.insurancePrice),
                            description: record.description,
                            availability: record.availability,
                            config_id: record.key,
                        }}
                    />
                    <PeriodListingModal
                        currency={record.location.currency}
                        periods={record.periods}
                        configurationId={record.key}
                    />
                    <DeleteConfigModal configId={record.key} />
                </div>
            );
        },
    },
];

const ConfigList = ({ configs, integrationId }: ConfigListPropTypes) => {
    return (
        <>
            <Table
                className={"text-center 2xl:overflow-auto"}
                size={"middle"}
                pagination={{ pageSize: 8 }}
                columns={columns}
                dataSource={configs?.map((config, index) => {
                    index = index + 1;
                    return {
                        key: config.uuid,
                        row: index,
                        category: config.category.code,
                        categoryId: config.category.uuid,
                        location: config.location,
                        description: config.description,
                        defaultPrice: `${config.default_price} ${config.location.currency}`,
                        insurancePrice: config.insurance_price || "NOT SET",
                        deposit: `${config.deposit} ${config.location.currency}`,
                        status: config.status,
                        periods: config.periods,
                        availability: config.availability,
                    };
                })}
            />
        </>
    );
};

export default ConfigList;
