import React, { useState } from "react";
import HeadingTitle from "Components/Headings/HeadingTitle";
import { Button, Input, Modal } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import Editor from "Components/TextEditor/TextEditor";
import Label from "Components/Label";
import useCompany from "Hooks/CompanyHooks/useCompany";

const CompanyLegalDocuments = () => {
    const { createLegalDocument } = useCompany();
    const [open, setOpen] = useState(false);
    const [termsConditions, setTermsConditions] = useState<string>();
    const [legalInformations, setLegalInformations] = useState<string>();
    const [documentName, setDocumentName] = useState<string>();
    const [loading, setLoading] = useState(false);

    const createDocument = async () => {
        setLoading(true);
        const data = {
            name: documentName,
            terms_conditions: termsConditions,
            legal_information: legalInformations,
        };
        await createLegalDocument(data);
        setLoading(false);
        setOpen(false);
        window.location.reload();
    };

    return (
        <>
            <div className={"flex items-center gap-3"}>
                <HeadingTitle title={"Company Legal Documents"} />
                <Button
                    type={"primary"}
                    className={"flex items-center"}
                    onClick={() => setOpen(true)}
                >
                    {" "}
                    <PlusOutlined /> Add Legal Document
                </Button>
            </div>
            <Modal
                title="Craete Legal Document"
                okText={"Create"}
                open={open}
                confirmLoading={loading}
                onOk={() => createDocument()}
                onCancel={() => setOpen(false)}
                width={1000}
            >
                <div className={"flex flex-col gap-3"}>
                    <div>
                        <Label title={"Document Name"} />
                        <Input
                            onChange={(e) => setDocumentName(e.target.value)}
                            type={"text"}
                            placeholder={"Enter document name..."}
                        />
                    </div>
                    <div>
                        <Label title={"Legal Informations"} />
                        <Editor
                            actualValue={legalInformations}
                            returnValues={setLegalInformations}
                        />
                    </div>
                    <div>
                        <Label title={"Terms & Conditions"} />
                        <Editor
                            actualValue={termsConditions}
                            returnValues={setTermsConditions}
                        />
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default CompanyLegalDocuments;
