import { UserPermissions } from "../Types/AuthTypes";

/**
 * Checks for users permissions
 * @param permissions
 * @param permissionName
 */
export function hasPermission(
    permissions: [],
    permissionName: UserPermissions,
) {
    return permissions?.some(
        // @ts-ignore
        (permission) => permission.name === permissionName,
    );
}

/**
 * Checks for all users permissions
 * @param permissions
 * @param requiredPermissions
 */
export function hasAllPermissions(
    permissions: [],
    requiredPermissions: UserPermissions[],
) {
    if (requiredPermissions) {
        return requiredPermissions?.every(
            (requiredPermission) =>
                permissions?.some(
                    // @ts-ignore
                    (permission: any) => permission.name === requiredPermission,
                ),
        );
    }
}
