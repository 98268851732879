import React from "react";
import IntegrationWrapper from "Components/ContentWrapper/Integrations/IntegrationWrapper";
import IntegrationTokenModal from "./partials/IntegrationTokenModal";

const SingleIntegrationContainer = () => {
    return (
        <>
            <IntegrationWrapper />
            <IntegrationTokenModal />
        </>
    );
};

export default SingleIntegrationContainer;
