import { useContext } from "react";
import ReactEcharts from "echarts-for-react";
import DashboardContext from "../../../Context/DashboardContext";
import { CarByPriceType } from "../../../Types/AnalyticsTypes";
import { isMobile } from "../../../Utils/DeviceUtils";
const PieChart = () => {
    const { analytics } = useContext(DashboardContext);
    const rentCarsByPrice = analytics?.rentedCarsByPrice;

    const chartData = rentCarsByPrice?.map(
        (item: CarByPriceType, index: number) => {
            return {
                value: item.totalPrice,
                name: `${index + 1}. ${item.carName} - (${item.carLicence}) - ${
                    item.reservations
                } Res.`,
            };
        },
    );

    const option = {
        tooltip: {
            trigger: "item",
        },
        legend: {
            show: false,
            orient: "vertical",
            right: 10,
            top: 50,
            bottom: 20,
        },
        series: [
            {
                name:
                    chartData && chartData.length && chartData.length > 0
                        ? "Cars By Price"
                        : "Not Data Available",
                type: "pie",
                center: [isMobile() ? "50%" : "50%", "50%"],
                radius: ["25%", isMobile() ? "35%" : "70%"],
                avoidLabelOverlap: true,
                itemStyle: {
                    borderRadius: 5,
                    borderColor: "#fff",
                    borderWidth: 2,
                },
                label: {
                    show: true, // show label
                    position: "top",
                },
                emphasis: {
                    label: {
                        show: true,
                        fontSize: 14,
                    },
                },
                labelLine: {
                    show: true,
                },
                data: chartData,
            },
        ],
    };
    return (
        <ReactEcharts
            style={{ height: "430px", margin: "0 auto 0 0" }}
            option={option}
        />
    );
};

export default PieChart;
