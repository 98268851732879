import React, { ReactNode } from "react";
import { Avatar, Button, Result, Space, Table, Tag, Tooltip } from "antd";
import type { TableProps } from "antd";
import {
    IntegrationDataTypes,
    IntegrationStatuses,
    IntegrationTypes,
} from "Types/IntegrationTypes";
import moment from "moment";
import IntegrationImage from "Components/Integrations/IntegrationImage";
import { getCountryByCode } from "../../../Utils/CountryUtils";
import ReactCountryFlag from "react-country-flag";
import IntegrationStatus from "../../../Components/Integrations/IntegrationStatus";
import { Link } from "react-router-dom";

interface DataType {
    key: string;
    integrationName: string;
    integrationType: IntegrationTypes;
    integrationImage: ReactNode;
    status: IntegrationStatuses;
    locationName: string;
    countryCode: string;
}

const columns: TableProps<DataType>["columns"] = [
    {
        title: "Name",
        dataIndex: "integrationName",
        key: "integrationName",
    },
    {
        title: "Location",
        dataIndex: "locationName",
        key: "locationName",
        render: (_, record) => (
            <div>
                <Tooltip title={getCountryByCode(record.countryCode)}>
                    <Avatar
                        size={30}
                        icon={
                            <ReactCountryFlag
                                countryCode={record.countryCode}
                                svg
                                style={{ width: "35px", height: "35px" }}
                            />
                        }
                    />
                </Tooltip>
                <strong className={"ml-2 text-[12px]"}>
                    {" "}
                    {record.locationName}{" "}
                </strong>
            </div>
        ),
    },
    {
        title: "Type",
        dataIndex: "integrationType",
        key: "integrationType",
        render: (_, record) => (
            <Tag color={"purple"} className={"font-bold"}>
                {record.integrationType}
            </Tag>
        ),
    },
    {
        title: "Integration",
        dataIndex: "integrationImage",
        key: "integrationImage",
    },

    {
        title: "Created",
        dataIndex: "created",
        key: "created",
    },
    {
        title: "Status",
        dataIndex: "status",
        key: "status",
        render: (_, record) => (
            <IntegrationStatus integrationStatus={record.status} />
        ),
    },
    {
        title: "Action",
        key: "action",
        className: "text-center",
        render: (_, record) => (
            <Button type={"primary"}>
                <Link to={`/integration/${record.key}`}>Open</Link>
            </Button>
        ),
    },
];

interface IntegrationsListPropTypes {
    integrations: IntegrationDataTypes[] | undefined;
}
const IntegrationsList = ({ integrations }: IntegrationsListPropTypes) => {
    const data: DataType[] | undefined = integrations?.map((integration) => {
        const {
            uuid,
            integration_name,
            integration_type,
            status,
            created_at,
            locationName,
            countryCode,
        } = integration;
        return {
            key: uuid,
            integrationName: integration_name,
            integrationType: integration_type,
            integrationImage: (
                <IntegrationImage
                    integrationType={integration_type}
                    size={55}
                />
            ),
            created: moment(created_at).format("DD/M/YYYY HH:MM"),
            status: status,
            locationName: locationName,
            countryCode: countryCode,
        };
    });
    return (
        <div>
            {data && data?.length > 0 ? (
                <Table
                    showHeader={false}
                    className={"text-sm 2xl:overflow-y-scroll"}
                    pagination={false}
                    columns={columns}
                    dataSource={data}
                />
            ) : (
                <Result
                    title="There is no integrations"
                    extra={
                        <Link to={"/add/integrations"}>
                            <Button type="primary" key="console">
                                Create Here
                            </Button>
                        </Link>
                    }
                />
            )}
        </div>
    );
};

export default IntegrationsList;
