import React from "react";
import { Link, useNavigate } from "react-router-dom";
import HeadingTitle from "Components/Headings/HeadingTitle";
import type { ColumnsType } from "antd/es/table";
import { Alert, Space, Table } from "antd";
import { handleCustomerRentalStatus } from "../../../../Helpers/CustomerHelper";
import { formatDate } from "../../../../Utils/DateUtils";
import CustomerStatusHandler from "../../../../Components/Status/CustomerStatus/CustomerStatusHandler";
import { TypeReservation } from "../../../../Types/CustomerTypes";
import TypeReservationHandler from "../../../../Components/TypeReservation/TypeReservationHandler";

interface DataType {
    key: string;
    name: string;
    country: string;
    rentedFrom: string;
    rentedTo: string;
    reservationType: TypeReservation;
    time: string;
    rentedDays: string;
    price: string;
}

const columns: ColumnsType<DataType> = [
    {
        title: "Name",
        dataIndex: "name",
        key: "name",
        render: (text) => <a>{text}</a>,
    },
    {
        title: "Country",
        dataIndex: "country",
        key: "country",
    },
    {
        title: "From",
        dataIndex: "rentedFrom",
        key: "rentedFrom",
        render: (_, { rentedFrom }) => {
            return formatDate(rentedFrom, "/");
        },
    },
    {
        title: "To",
        dataIndex: "rentedTo",
        key: "rentedTo",
        render: (_, { rentedTo }) => {
            return formatDate(rentedTo, "/");
        },
    },
    {
        title: "Return Time",
        dataIndex: "returnTime",
        key: "returnTime",
    },
    {
        title: "Days",
        dataIndex: "rentedDays",
        key: "rentedDays",
    },
    {
        title: "Price",
        dataIndex: "totalSum",
        key: "totalSum",
    },
    {
        title: "Reservation Type",
        dataIndex: "reservationType",
        key: "reservationType",
        render: (_, { reservationType }) => {
            return <TypeReservationHandler type={reservationType} size={100} />;
        },
    },
    {
        title: "Status",
        key: "tags",
        dataIndex: "tags",
        render: (_, { rentedFrom, rentedTo }) => {
            const customerStatus = handleCustomerRentalStatus(
                rentedFrom,
                rentedTo,
            );
            return (
                <>
                    <CustomerStatusHandler
                        status={customerStatus}
                        date={rentedFrom}
                    />
                </>
            );
        },
    },
    {
        title: "Action",
        key: "action",
        render: (_, { key }) => (
            <Space size="middle">
                <Link to={`/customer/${key}`}>Details</Link>
            </Space>
        ),
    },
];

// @ts-ignore
const SingleCarRentList = ({ rentList, currency }) => {
    const navigate = useNavigate();
    let counter = 0;
    return (
        <div className="single-car-rental-list">
            <HeadingTitle title={"Rental List"} />
            {rentList.length > 0 ? (
                <Table
                    className={"md:overflow-scroll"}
                    pagination={false}
                    columns={columns}
                    // @ts-ignore
                    dataSource={rentList?.map((rentedCar) => {
                        const {
                            uuid,
                            customer_name,
                            customer_country,
                            reservationType,
                        } = rentedCar.customer[0];
                        return {
                            key: uuid,
                            name: customer_name,
                            country: customer_country,
                            rentedFrom: rentedCar.from,
                            rentedTo: rentedCar.to,
                            returnTime: rentedCar.check_out,
                            rentedDays: rentedCar.total_days ?? 0,
                            reservationType: reservationType,
                            totalSum: rentedCar.total_price + ` ${currency}`,
                        };
                    })}
                />
            ) : (
                <Alert
                    message="There is no rents for this vehicle"
                    description="When you rent a vehicle, the most recent list of customer will show here."
                    type="info"
                    showIcon
                />
            )}
        </div>
    );
};

export default SingleCarRentList;
