import React from "react";
import dayjs from "dayjs";
import type { TimeRangePickerProps } from "antd";
import { DatePicker } from "antd";

const { RangePicker } = DatePicker;

const rangePresets: TimeRangePickerProps["presets"] = [
    {
        label: "This Week",
        value: [dayjs().startOf("week").add(1, "day"), dayjs().endOf("week")],
    },
    {
        label: "Last Week",
        value: [
            dayjs().subtract(1, "week").startOf("week"),
            dayjs().subtract(1, "week").endOf("week"),
        ],
    },
    {
        label: "Last 7 Days",
        value: [dayjs().subtract(7, "d"), dayjs()],
    },
    {
        label: "Last 14 Days",
        value: [dayjs().subtract(14, "d"), dayjs()],
    },
    {
        label: "Last 30 Days",
        value: [dayjs().subtract(30, "d"), dayjs()],
    },
];

interface DateFilterProps {
    onChange?: (date: any, formated: any) => void;
}
const DateFilter = ({ onChange }: DateFilterProps) => (
    <RangePicker
        className={"md:w-full"}
        presets={[
            {
                label: (
                    <span aria-label="Current Time to End of Day">Today</span>
                ),
                value: () => [dayjs(), dayjs().endOf("day")], // 5.8.0+ support function
            },
            ...rangePresets,
        ]}
        format="YYYY-MM-DD"
        onChange={onChange}
    />
);

export default DateFilter;
