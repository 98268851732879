import React, { createContext, ReactNode, useEffect, useState } from "react";
import useIntegrations from "../../Hooks/IntegrationHooks/useIntegrations";
import { useParams } from "react-router-dom";
import { IntegrationDataTypes } from "../../Types/IntegrationTypes";
import LoadingPlaceholder from "../../Components/Placeholders/LoadingPlaceholder";
import HeadingTitle from "../../Components/Headings/HeadingTitle";
import useIntegrationConfig from "../../Hooks/IntegrationHooks/IntegrationConfig/useIntegrationConfig";
interface SingleIntegrationProviderTypes {
    children: ReactNode;
}
interface SingleIntegrationContextTypes {
    integration: IntegrationDataTypes;
    loading: boolean;
    refresh: () => void;
    deleteConfig: (configId: string) => Promise<void>;
}
//@ts-ignore
const SingleIntegrationContext = createContext<SingleIntegrationContextTypes>();

const SingleIntegrationProvider = ({
    children,
}: SingleIntegrationProviderTypes) => {
    const { getIntegrationByUuid } = useIntegrations();
    const { deleteConfig } = useIntegrationConfig();
    const { uuid } = useParams();

    const [integration, setIntegration] = useState<IntegrationDataTypes | null>(
        null,
    );
    const [loading, setLoading] = useState(true);
    const [changeDetected, setChangeDetected] = useState(false);

    const fetchIntegration = async () => {
        const integration = await getIntegrationByUuid(uuid).finally(() => {
            setLoading(false);
        });
        setIntegration(integration);
    };

    const refresh = async () => {
        await fetchIntegration();
    };

    useEffect(() => {
        fetchIntegration();
    }, []);

    return (
        <SingleIntegrationContext.Provider
            value={{
                //@ts-ignore
                integration,
                loading,
                refresh,
                deleteConfig,
            }}
        >
            <>
                <HeadingTitle title={"Integration"} />
                {/*@ts-ignore*/}
                {loading ? <LoadingPlaceholder /> : children}
            </>
        </SingleIntegrationContext.Provider>
    );
};

export { SingleIntegrationProvider, SingleIntegrationContext };
