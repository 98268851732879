interface ObjectUtilsContructorTypes {
    /**
     * It receive object and removes all properties that are empty
     * @param object
     */
    removeEmptyProperty: (object: object) => object;

    /**
     * It checks if given object is empty
     * @param object {object}
     */
    isEmptyObject: (object: object) => boolean;
}

const ObjectUtils: ObjectUtilsContructorTypes = {
    removeEmptyProperty: (object: object) => {
        return Object.fromEntries(
            Object.entries(object).filter(([_, value]) => value !== ""),
        );
    },
    isEmptyObject: (object: object) => {
        return Object.keys(object).length === 0;
    },
};

export default ObjectUtils;
