import countries from "./partials/countries_list.json";

/**
 * @returns {{}}
 */
export function getCountries() {
    return countries;
}

/**
 * @param code cca2 code
 * @returns {*|null} country name
 */
export function getCountryByCode(code: string | undefined): any {
    const countryCodes = {};
    for (let i = 0; i < countries.length; i++) {
        const country = countries[i];
        // @ts-ignore
        countryCodes[country.cca2] = country.name;
    }
    // @ts-ignore
    return countryCodes[code] || null;
}
