import React from "react";
import { Tag, Tooltip } from "antd";

interface CustomerPickUpStatusProps {
    days: number;
}
const CustomerPickUpStatus = ({ days }: CustomerPickUpStatusProps) => {
    const formatDateText: "DAY" | "DAYS" = days === 1 ? "DAY" : "DAYS";
    return (
        <Tag className={"pl-3 pr-3 pt-1 pb-1 font-bold"} color={"geekblue"}>
            <Tooltip title={"Rental Status"}>
                PICK UP IN {days} {formatDateText}
            </Tooltip>
        </Tag>
    );
};
export default CustomerPickUpStatus;
