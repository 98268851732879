import { ReservationStatus } from "Types/ReservationTypes";

/**
 * @param status
 */
export const isReservationValidForAction = (status: ReservationStatus) => {
    return (
        status !== "CONFIRMED" &&
        status !== "FINISHED" &&
        status !== "REJECTED" &&
        status !== "CANCELED"
    );
};
