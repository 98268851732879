import React from "react";
import { Button, Dropdown, MenuProps } from "antd";
import { UnorderedListOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { isCarRented } from "Helpers/CarHelper";
import { RentCarCardProps } from "Types/CarTypes";
import { useAuth } from "../../../Hooks/AuthHooks/useAuth";

const CarOptions = ({ carData, triggerModal }: RentCarCardProps) => {
    const { isUserAdmin } = useAuth();
    const { uuid } = carData;

    const items: MenuProps["items"] = [
        isUserAdmin()
            ? {
                  key: "1",
                  label: (
                      <Link to={`/edit/car`} state={{ ...carData }}>
                          Show Vehicle
                      </Link>
                  ),
              }
            : null,
        {
            key: "2",
            label: <Link to={`/car/${uuid}`}>Create Reservation</Link>,
        },
        isCarRented(carData.rented_car)
            ? {
                  key: "3",
                  label: (
                      <Link
                          to={`/customer/${carData.rented_car[0].customer[0].uuid}`}
                      >
                          Current Reservation
                      </Link>
                  ),
              }
            : null,
        isCarRented(carData.rented_car)
            ? {
                  key: "4",
                  label: (
                      <>
                          <Button
                              className={"pl-0"}
                              type={"link"}
                              onClick={() =>
                                  triggerModal(carData.rented_car[0].uuid)
                              }
                          >
                              Finish Reservation
                          </Button>
                      </>
                  ),
              }
            : null,
    ];

    return (
        <Dropdown menu={{ items }} trigger={["click"]} placement="bottomLeft">
            <UnorderedListOutlined className={"cursor-pointer text-xl"} />
        </Dropdown>
    );
};

export default CarOptions;
