import Axios from "axios";
import { App } from "antd";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const useCustomer = () => {
    const { message, notification } = App.useApp();
    const navigate = useNavigate();
    /**
     * @returns {Promise<axios.AxiosResponse<any>|number|string>}
     */
    const getCustomers = async (page) => {
        const { data } = await Axios.get(`/get/customers?page=${page}`).catch(
            (error) =>
                message.error(
                    "'Something went wrong, please try again later.'",
                ),
        );
        return data;
    };
    /**
     * @param uuid
     * @returns {Promise<axios.AxiosResponse<any>>}
     */
    const getCustomerByUuid = async (uuid) => {
        try {
            const customer = await Axios.get(`/get/customer/${uuid}`);
            return customer;
        } catch (error) {
            notification.error({
                message:
                    error.response.data.error ||
                    "Something went wrong, try again later",
                description: "Failed to get customer!",
                duration: 5,
            });
            navigate("/customers?page=1");
        }
    };

    /**
     *
     * @param reservation
     * @param loading
     * @param closeModal
     * @param resetExchangeCar
     * @returns {Promise<void>}
     */
    const updateReservation = async (
        reservation,
        { loading, closeModal, resetExchangeCar, isUpdated },
    ) => {
        try {
            const response = await Axios.post(
                "/reservation/update",
                reservation,
            );
            message.success(response.data.message);
            resetExchangeCar(null);
            closeModal(false);
        } catch (error) {
            console.log(error);
            message.error(error.response.data.errors);
        } finally {
            loading(false);
            isUpdated(true);
        }
    };

    /**
     * @param uuid
     * @returns {Promise<void>}
     */
    const finishReservation = async (uuid, loader) => {
        await Axios.post("/rent/remove", { uuid: uuid })
            .then((response) => {
                message.success("Successfully finished reservation");
                loader(false);
            })
            .catch((error) => {
                console.error(error);
                message.error(error.message);
                loader(false);
            });
    };

    /**
     * @param query
     * @returns {Promise<axios.AxiosResponse<any>|void>}
     */
    const searchCustomer = async (query, page) => {
        const { data } = await Axios.get(
            `/search/customers?page=${page}&${query}`,
        ).catch((error) => {
            console.log(error);
            message.error(error.message);
        });
        return data;
    };

    /**
     *
     * @param secretHash
     * @returns {Promise<any>}
     */
    const getQrCodeReservation = async (secretHash) => {
        try {
            const reservation = await axios.get(`/reservation/${secretHash}`);
            return reservation.data;
        } catch (error) {
            console.log(error);
            if (error.response.status === 404) {
                navigate("/reservation/qr/status", {
                    state: { status: "NOT_FOUND" },
                });
            } else {
                message.error(error.response.data.error);
            }
        }
    };

    /**
     *
     * @param data
     * @param loading
     * @returns {Promise<void>}
     */
    const finishQrCodeReservation = async (data, loading) => {
        try {
            await axios.post("/reservation/close", data);
            navigate("/reservation/qr/status", {
                state: { status: "SUCCESS" },
            });
        } catch (error) {
            message.error(error.response.data.error);
        } finally {
            loading(false);
        }
    };

    return {
        getCustomers,
        updateReservation,
        getCustomerByUuid,
        getQrCodeReservation,
        searchCustomer,
        finishQrCodeReservation,
        finishReservation,
    };
};

export default useCustomer;
