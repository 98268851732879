import React from "react";
import { Routes, Route, Navigate, BrowserRouter } from "react-router-dom";
import { useSession } from "./Hooks/SessionHooks/useSession";
import RequireAuth from "./Providers/AuthProvider/RequireAuth";
import RegisterPage from "./Pages/AuthPages/RegisterPage";
import Dashboard from "./Pages/DashboardPages/Dashboard";
import LoginPage from "./Pages/AuthPages/LoginPage";
import DashboardLayout from "./Layouts/DashboardLayouts/DashboardLayout";
import AddCarPage from "./Pages/DashboardPages/CarPages/AddCarPage";
import ManageCarsPage from "./Pages/DashboardPages/CarPages/ManageCarsPage";
import AddEmployeePage from "./Pages/DashboardPages/EmployeePages/AddEmployeePage";
import ManageEmployeesPage from "./Pages/DashboardPages/EmployeePages/ManageEmployeesPage";
import AddLocationPage from "./Pages/DashboardPages/LocationPages/AddLocationPage";
import ManageLocationPage from "./Pages/DashboardPages/LocationPages/ManageLocationPage";
import SingleCarPage from "./Pages/DashboardPages/CarPages/SingleCarPage";
import EditLocationPage from "./Pages/DashboardPages/LocationPages/EditLocationPage";
import EditEmployeePage from "./Pages/DashboardPages/EmployeePages/EditEmployeePage";
import EditCarPage from "./Pages/DashboardPages/CarPages/EditCarPage";
import SingleCustomerPage from "./Pages/DashboardPages/CustomerPages/SingleCustomerPage";
import NotFoundPage from "./Pages/ErrorPages/NotFoundPage";
import DevelopersPage from "./Pages/DashboardPages/IntegrationPages/DevelopersPage";
import CheckPage from "./Pages/DashboardPages/CarPages/CheckPage";
import AgreementPage from "./Pages/PDFDocumentPages/AgreementPage";
import CompanySettings from "./Pages/SettingsPages/CompanySettings";
import SettingsLayout from "./Layouts/Settings/SettingsLayout";
import CustomersPage from "./Pages/DashboardPages/CustomerPages/CustomersPage";
import QrCodeReservationPage from "./Pages/PublicPages/QrCodeReservationPage";
import EmployeeAnalyticsPage from "./Pages/DashboardPages/EmployeePages/EmployeeAnalyticsPage";
import QrCodeReservationStatusPage from "./Pages/PublicPages/QrCodeReservationStatusPage";
import CarHistoryPage from "./Pages/DashboardPages/History/CarHistoryPage";
import ForgotPassword from "Pages/PublicPages/ForgotPassword";
import AddIntegrationPage from "./Pages/DashboardPages/IntegrationPages/AddIntegrationPage";
import ManageIntegrationsPage from "./Pages/DashboardPages/IntegrationPages/ManageIntegrationsPage";
import { App as AntApp } from "antd";
import { ADMIN_ROLE } from "./Constants/rentelon";
import SingleIntegrationPage from "./Pages/DashboardPages/IntegrationPages/SingleIntegrationPage";
import ManageReservationsPage from "./Pages/DashboardPages/ReservationPages/ManageReservationsPage";

function App() {
    const session = useSession();
    return (
        <BrowserRouter>
            <Routes>
                {session.getToken() ? (
                    <Route
                        path="/register/staging"
                        element={<Navigate to="/dashboard" />}
                    />
                ) : (
                    <Route
                        path="/register/staging"
                        element={<RegisterPage />}
                    />
                )}
                {session.getToken() ? (
                    <Route
                        path="/login"
                        element={
                            session.getUserRole() === ADMIN_ROLE ? (
                                <Navigate to="/dashboard" />
                            ) : (
                                <Navigate to="/manage/cars" />
                            )
                        }
                    />
                ) : (
                    <Route path="/login" element={<LoginPage />} />
                )}

                <Route
                    path="/"
                    element={
                        <RequireAuth>
                            <DashboardLayout>
                                <Dashboard />
                            </DashboardLayout>
                        </RequireAuth>
                    }
                />

                <Route path="/forgot-password" element={<ForgotPassword />} />

                <Route
                    path="/pdf/agreement"
                    element={
                        <RequireAuth>
                            <AgreementPage />
                        </RequireAuth>
                    }
                />

                <Route
                    path={"/manage/reservations"}
                    element={
                        <RequireAuth>
                            <DashboardLayout>
                                <ManageReservationsPage />
                            </DashboardLayout>
                        </RequireAuth>
                    }
                />

                {/*CARS PAGES*/}
                <Route
                    path="/dashboard"
                    element={
                        <RequireAuth>
                            <DashboardLayout>
                                <Dashboard />
                            </DashboardLayout>
                        </RequireAuth>
                    }
                />
                <Route
                    path="/add/car"
                    element={
                        <RequireAuth>
                            <DashboardLayout>
                                <AddCarPage />
                            </DashboardLayout>
                        </RequireAuth>
                    }
                />
                <Route
                    path="/edit/car"
                    element={
                        <RequireAuth>
                            <DashboardLayout>
                                <EditCarPage />
                            </DashboardLayout>
                        </RequireAuth>
                    }
                />
                <Route
                    path="/manage/cars"
                    element={
                        <RequireAuth>
                            <DashboardLayout>
                                <ManageCarsPage />
                            </DashboardLayout>
                        </RequireAuth>
                    }
                />
                <Route
                    path="/check/cars"
                    element={
                        <RequireAuth>
                            <DashboardLayout>
                                <CheckPage />
                            </DashboardLayout>
                        </RequireAuth>
                    }
                />
                <Route
                    path="/car/:uuid"
                    element={
                        <RequireAuth>
                            <DashboardLayout>
                                <SingleCarPage />
                            </DashboardLayout>
                        </RequireAuth>
                    }
                />

                {/*LOCATIONS PAGES*/}

                <Route
                    path="/add/location"
                    element={
                        <RequireAuth>
                            <DashboardLayout>
                                <AddLocationPage />
                            </DashboardLayout>
                        </RequireAuth>
                    }
                />
                <Route
                    path="/edit/location"
                    element={
                        <RequireAuth>
                            <DashboardLayout>
                                <EditLocationPage />
                            </DashboardLayout>
                        </RequireAuth>
                    }
                />
                <Route
                    path="/locations"
                    element={
                        <RequireAuth>
                            <DashboardLayout>
                                <ManageLocationPage />
                            </DashboardLayout>
                        </RequireAuth>
                    }
                />

                {/*EMPLOYEE PAGES*/}

                <Route
                    path="/add/employee"
                    element={
                        <RequireAuth>
                            <DashboardLayout>
                                <AddEmployeePage />
                            </DashboardLayout>
                        </RequireAuth>
                    }
                />
                <Route
                    path="/employees/analytics"
                    element={
                        <RequireAuth>
                            <DashboardLayout>
                                <EmployeeAnalyticsPage />
                            </DashboardLayout>
                        </RequireAuth>
                    }
                />
                <Route
                    path="/employees"
                    element={
                        <RequireAuth>
                            <DashboardLayout>
                                <ManageEmployeesPage />
                            </DashboardLayout>
                        </RequireAuth>
                    }
                />
                <Route
                    path="/edit/employee/:uuid"
                    element={
                        <RequireAuth>
                            <DashboardLayout>
                                <EditEmployeePage />
                            </DashboardLayout>
                        </RequireAuth>
                    }
                />

                {/* Histories */}

                <Route
                    path="/history/cars"
                    element={
                        <RequireAuth>
                            <DashboardLayout>
                                <CarHistoryPage />
                            </DashboardLayout>
                        </RequireAuth>
                    }
                />

                {/* CUSTOMER PAGES */}
                <Route
                    path="/customer/:uuid"
                    element={
                        <RequireAuth>
                            <DashboardLayout>
                                <SingleCustomerPage />
                            </DashboardLayout>
                        </RequireAuth>
                    }
                />

                <Route
                    path="/customers"
                    element={
                        <RequireAuth>
                            <DashboardLayout>
                                <CustomersPage />
                            </DashboardLayout>
                        </RequireAuth>
                    }
                />

                {/* INTEGRATION PAGES */}
                <Route
                    path="/integrations"
                    element={
                        <RequireAuth>
                            <DashboardLayout>
                                <ManageIntegrationsPage />
                            </DashboardLayout>
                        </RequireAuth>
                    }
                />

                <Route
                    path="/add/integrations"
                    element={
                        <RequireAuth>
                            <DashboardLayout>
                                <AddIntegrationPage />
                            </DashboardLayout>
                        </RequireAuth>
                    }
                />

                <Route
                    path="/integration/:uuid"
                    element={
                        <RequireAuth>
                            <DashboardLayout>
                                <SingleIntegrationPage />
                            </DashboardLayout>
                        </RequireAuth>
                    }
                />

                <Route
                    path="/developer"
                    element={
                        <RequireAuth>
                            <DashboardLayout>
                                <DevelopersPage />
                            </DashboardLayout>
                        </RequireAuth>
                    }
                />

                <Route
                    path="/settings"
                    element={
                        <RequireAuth>
                            <DashboardLayout>
                                <SettingsLayout title={"Company Settings"}>
                                    <CompanySettings />
                                </SettingsLayout>
                            </DashboardLayout>
                        </RequireAuth>
                    }
                />

                <Route
                    path="/reservation/:hash"
                    element={<QrCodeReservationPage />}
                />

                <Route
                    path="/reservation/qr/status"
                    element={<QrCodeReservationStatusPage />}
                />

                {/*HANDLE NO PAGE SCENARION*/}
                <Route path="*" element={<NotFoundPage />} />
            </Routes>
        </BrowserRouter>
    );
}

// Entry component
export default () => (
    <AntApp>
        <App />
    </AntApp>
);
