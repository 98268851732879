import React from "react";
import { Badge, Card, Popover, Tag } from "antd";
import { EllipsisOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { RentCarCardProps } from "Types/CarTypes";
import RentalStatusHandler from "../Status/CarStatus/RentalStatusHandler";
import {
    getCarStatusText,
    handleCurrentRentalStatus,
    isCarActive,
    isCarRented,
} from "Helpers/CarHelper";
import CarOptions from "./partials/CarOptions";
import { formatDate } from "Utils/DateUtils";
import { FaGasPump } from "react-icons/fa";
import { TbEngine } from "react-icons/tb";
import { GiCarDoor, GiGearStickPattern } from "react-icons/gi";
import { Link } from "react-router-dom";
import TypeReservationHandler from "../TypeReservation/TypeReservationHandler";
import { getCustomerReservationType } from "../../Helpers/CustomerHelper";
const { Meta } = Card;
const MobileRentCarCard = ({
    carData,
    checkType,
    onCheckSection,
    triggerModal,
    showReservationType = false,
}: RentCarCardProps) => {
    const {
        uuid,
        car_name,
        car_image_path,
        rented_car,
        is_active,
        car_fuel_type,
        car_transmission_type,
        car_engine_type,
        car_doors,
        car_licence_number,
        category,
    } = carData;

    const { category_name } = category[0];

    return (
        <>
            <Badge.Ribbon
                text={getCarStatusText(is_active)}
                color={isCarActive(is_active) ? "#4c9f23bd" : "#ff5f67"}
            >
                <Card
                    style={{ width: 325 }}
                    className={"mt-3"}
                    cover={
                        <Link to={`/car/${uuid}`}>
                            <img
                                className={"border"}
                                alt="example"
                                src={
                                    car_image_path
                                        ? `${process.env.REACT_APP_DOMAIN}${car_image_path}`
                                        : process.env.REACT_APP_IMAGE_FALLBACK
                                }
                            />
                        </Link>
                    }
                    actions={[
                        <CarOptions
                            triggerModal={triggerModal}
                            carData={carData}
                        />,
                        <Popover
                            title={
                                <div>
                                    <div className="flex items-center">
                                        <FaGasPump />
                                        <span className="ml-2 capitalize">
                                            {car_fuel_type}
                                        </span>
                                    </div>
                                    <div className="flex items-center">
                                        <GiGearStickPattern />
                                        <span className="ml-2 uppercase">
                                            {car_transmission_type}
                                        </span>
                                    </div>
                                    <div className="flex items-center">
                                        <TbEngine />
                                        <span className="ml-2 uppercasee">
                                            {car_engine_type}
                                        </span>
                                    </div>
                                    <div className="flex items-center">
                                        <GiCarDoor className={"text-[15px]"} />
                                        <span className="ml-2 uppercase">
                                            {car_doors}
                                        </span>
                                    </div>
                                </div>
                            }
                            placement="top"
                            trigger={"click"}
                        >
                            <QuestionCircleOutlined />
                        </Popover>,
                        <Popover
                            title={
                                <div className={"flex flex-col gap-3"}>
                                    <div className={"flex justify-between"}>
                                        <p>Category: </p>
                                        <Tag
                                            className={"uppercase"}
                                            color={"blue-inverse"}
                                        >
                                            {category_name}
                                        </Tag>
                                    </div>
                                    <div className={"flex justify-between"}>
                                        <p>Licence No: </p>
                                        <p>
                                            <Tag color={"blue-inverse"}>
                                                {car_licence_number}
                                            </Tag>
                                        </p>
                                    </div>
                                </div>
                            }
                            placement="top"
                            trigger={"click"}
                        >
                            <EllipsisOutlined key="ellipsis" />
                        </Popover>,
                    ]}
                >
                    <Meta
                        title={
                            <div className={"flex justify-between"}>
                                <div>{car_name}</div>
                                <RentalStatusHandler
                                    rentalStatus={handleCurrentRentalStatus(
                                        rented_car,
                                        checkType,
                                        onCheckSection,
                                    )}
                                    date={
                                        isCarRented(rented_car)
                                            ? rented_car[0].from
                                            : undefined
                                    }
                                />
                            </div>
                        }
                        description={
                            isCarRented(rented_car) && (
                                <div>
                                    <div className={"flex justify-between"}>
                                        <p>
                                            PICK UP:{" "}
                                            {formatDate(
                                                rented_car[0]?.from,
                                                "/",
                                            )}{" "}
                                        </p>
                                        <p>{rented_car[0]?.check_in}</p>
                                    </div>
                                    <div className={"flex justify-between"}>
                                        <p>
                                            RETURN:{" "}
                                            {formatDate(rented_car[0]?.to, "/")}{" "}
                                        </p>
                                        <p>{rented_car[0]?.check_out}</p>
                                    </div>
                                    {showReservationType && (
                                        <div className={"mt-3"}>
                                            <TypeReservationHandler
                                                type={getCustomerReservationType(
                                                    rented_car[0].customer,
                                                )}
                                                size={80}
                                            />
                                        </div>
                                    )}
                                </div>
                            )
                        }
                    />
                </Card>
            </Badge.Ribbon>
        </>
    );
};

export default MobileRentCarCard;
