import React, { Dispatch, SetStateAction } from "react";
import HeadingTitle from "Components/Headings/HeadingTitle";
import { LocationTypes } from "Types/LocationTypes";
import { Select } from "antd";

interface LocationsFilterProps {
    locations?: LocationTypes[] | null;
    onChange?: (location: string) => void;
    defaultValue?: string;
}
const LocationsFilter = ({
    locations,
    onChange,
    defaultValue,
}: LocationsFilterProps) => {
    return (
        <>
            <div className={"flex gap-3 items-center"}>
                <HeadingTitle title={"Location"} />
                <Select
                    // @ts-ignore
                    onChange={onChange}
                    defaultValue={defaultValue}
                    style={{ width: 220 }}
                    options={locations?.map(({ name, uuid }) => {
                        return {
                            value: uuid,
                            label: name,
                        };
                    })}
                />
            </div>
        </>
    );
};

export default LocationsFilter;
