import React, { useContext, useState } from "react";
import { Button, Modal } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import useIntegrationConfig from "Hooks/IntegrationHooks/IntegrationConfig/useIntegrationConfig";

interface DeleteConfigPropTypes {
    ruleId: string;
    update: () => void;
}
const DeleteRuleModal = ({ ruleId, update }: DeleteConfigPropTypes) => {
    const { deleteConfigPeriodRule } = useIntegrationConfig();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = async () => {
        setLoading(true);
        await deleteConfigPeriodRule(ruleId)
            .then(() => {
                setIsModalOpen(false);
                update();
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    return (
        <>
            <Button
                onClick={() => showModal()}
                className={"h-[30px] w-[30px] flex items-center justify-center"}
                title={"Delete"}
                danger={true}
                type={"primary"}
            >
                <DeleteOutlined />
            </Button>
            <Modal
                title="Are you sure that you want to delete rule?"
                open={isModalOpen}
                onOk={handleOk}
                okText={"Confirm"}
                okButtonProps={{ danger: true }}
                confirmLoading={loading}
                onCancel={handleCancel}
            >
                <p>Deleting configuration rule, This action can't be undone.</p>
            </Modal>
        </>
    );
};

export default DeleteRuleModal;
