import React from "react";
import { ArrowRightOutlined, RightOutlined } from "@ant-design/icons";
import { IconContext } from "react-icons";
import HorizontalDivider from "../../HorizontalDivider/HorizontalDivider";
import { Link } from "react-router-dom";
import { StatisticBoxTypes } from "Types/AnalyticsTypes";
import { Button } from "antd";

const StatisticBox: React.FC<StatisticBoxTypes> = ({
    title,
    colorAccent,
    color,
    iconComponent,
    data,
    linkTitle,
    linkPath,
}) => {
    return (
        <div
            style={{ backgroundColor: colorAccent }}
            className={
                "md:w-[22rem] w-[23rem] sm:w-full h-30 border-[2px] border-gray-300 p-3 rounded"
            }
        >
            <div className="justify-between flex">
                <div className={"w-[50%] flex text-5xl"}>{iconComponent}</div>
                <div className={"w-[50%] flex justify-end"}>
                    <div>
                        <div className={"text-right"}>
                            <h2
                                style={{ fontSize: "18px" }}
                                className={"uppercase font-medium"}
                            >
                                {" "}
                                <small>{title}</small>{" "}
                            </h2>
                        </div>
                        <div>
                            <h4
                                className={"font-medium text-end"}
                                style={{ fontSize: "18px" }}
                            >
                                {" "}
                                {data}{" "}
                            </h4>
                        </div>
                    </div>
                </div>
            </div>
            <HorizontalDivider classes={"bg-secondary mt-2 mb-2"} />
            <Button type={"dashed"} size={"small"}>
                <Link
                    className={"flex items-center gap-2 uppercase text-[12px]"}
                    to={linkPath}
                >
                    {linkTitle}
                    <RightOutlined />
                </Link>
            </Button>
        </div>
    );
};

export default StatisticBox;
