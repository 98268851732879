import Axios from "axios";
import { useSession } from "../SessionHooks/useSession";
import { useNavigate } from "react-router-dom";
import { App } from "antd";

export const useAuth = () => {
    const session = useSession();
    const navigate = useNavigate();
    const { message } = App.useApp();

    const register = async (data, loader) => {
        await Axios.post("/register", data)
            .then((res) => {
                session.setToken(res.data.token);
                session.setUserCache(res.data.user);
                navigate("/dashboard", { replace: true });
            })
            .catch((err) => {
                const error =
                    err.response.data.errors ||
                    "Something went wrong, try again later.";
                message.error(error);
                loader(false);
            });
    };

    const login = async (data, loader) => {
        await Axios.post("/login", data)
            .then((res) => {
                session.setToken(res.data.token);
                session.setUserCache(res.data.user);
                window.location.href = "/dashboard";
            })
            .catch((err) => {
                const error =
                    err.response.data.errors ||
                    "Something went wrong, try again later.";
                message.error(error);
                loader(false);
            });
    };

    const logout = async () => {
        Axios.get("/logout")
            .then((res) => {
                session.clearUserCache();
                session.clearToken();
                window.location.href = "/login";
            })
            .catch((err) => {
                const error =
                    err.response.data.errors ||
                    "Something went wrong, try again later.";
                message.error(error);
            });
    };

    const isUserAdmin = () => {
        return session.getUserRole() === "admin";
    };

    return {
        register,
        login,
        logout,
        isUserAdmin,
    };
};
