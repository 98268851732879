import React, { useEffect, useState } from "react";
import HeadingTitle from "Components/Headings/HeadingTitle";
import useLocations from "Hooks/LocationsHooks/useLocations";
import LoadingPlaceholder from "Components/Placeholders/LoadingPlaceholder/LoadingPlaceholder";
import useEmployee from "Hooks/EmployeeHooks/useEmployee";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { App, Checkbox, Input, Select } from "antd";
import Button from "Components/Button/Button";
import { Modal } from "antd";
import Label from "Components/Label";
import { hasPermission } from "Helpers/UserHelper";
import UploaderImage from "Components/UploaderImage";
import UrlBuilderUtils from "../../../../Utils/UrlBuilderUtils";

const EditEmployeForm = () => {
    const { notification } = App.useApp();
    const { updateEmployee, removeEmployee, getEmployeeByUuid } = useEmployee();
    const { getLocations } = useLocations();
    const { uuid } = useParams();
    const navigate = useNavigate();

    const { state } = useLocation();

    const [locations, setLocations] = useState(null);
    const [loading, setLoading] = useState(false);
    const [imagePath, setImagePath] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [employee, setEmployee] = useState(null);

    const [formData, setFormData] = useState({
        password: null,
        confirmPassword: null,
        uuid: null,
        firstname: null,
        lastname: null,
        location_uuid: null,
        email: null,
        permissions: [],
    });

    async function fetchLocationData() {
        const data = await getLocations();
        setLocations(data);
    }

    const fetchEmployee = async () => {
        const data = await getEmployeeByUuid(uuid);
        setFormData({
            uuid: data?.uuid,
            firstname: data?.firstname,
            lastname: data?.lastname,
            location_uuid: data?.location?.uuid,
            email: data?.email,
            permissions: data?.permissions?.map((permission) => {
                return permission.name;
            }),
        });
        setEmployee(data);
    };

    const editEmployee = (e) => {
        e.preventDefault();
        setLoading(true);
        if (imagePath) {
            Object.assign(formData, { image: imagePath });
        }
        if (formData.password) {
            if (formData.password !== formData.confirmPassword) {
                notification.error({
                    message: "Error",
                    description:
                        "Passwords do not match, please set correct password.",
                });
                setLoading(false);
                return;
            }
        }
        updateEmployee(formData, setLoading);
    };

    const deleteEmployee = () => {
        removeEmployee(employee?.uuid);
        navigate("/employees");
        setIsModalOpen(false);
    };

    const handlePermissions = (event) => {
        const permission = event.target.value;
        const checked = event.target.checked;

        if (!formData.permissions.includes(permission) && checked) {
            setFormData({
                ...formData,
                permissions: [...formData.permissions, permission],
            });
            const indexToRemove = formData.permissions.indexOf(permission);
            const updatePermissions = formData.permissions;
            if (indexToRemove !== -1) {
                updatePermissions.splice(indexToRemove, 1);
                setFormData({
                    ...formData,
                    permissions: updatePermissions,
                });
            }
        } else {
            const indexToRemove = formData.permissions.indexOf(permission);
            const updatePermissions = formData.permissions;
            if (indexToRemove !== -1) {
                updatePermissions.splice(indexToRemove, 1);
                setFormData({
                    ...formData,
                    permissions: updatePermissions,
                });
            }
        }
    };

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    useEffect(() => {
        fetchLocationData();
        fetchEmployee();
    }, []);

    return (
        <>
            <HeadingTitle title="Edit Employee" />
            {!locations && !employee ? (
                <LoadingPlaceholder />
            ) : (
                <>
                    <form>
                        <div>
                            <Label className={"mb-3"} title={"Profile photo"} />
                            <UploaderImage
                                image={{
                                    uid: "1",
                                    name: `Photo`,
                                    status: "done",
                                    url: employee?.profile_image
                                        ? UrlBuilderUtils.buildMediaUrl(
                                              employee?.profile_image,
                                          )
                                        : state?.profileImage
                                          ? UrlBuilderUtils.buildMediaUrl(
                                                state?.profileImage,
                                            )
                                          : "",
                                }}
                                setImage={setImagePath}
                                config={{ entity: "employee", entityId: uuid }}
                            />
                        </div>
                        <div className="grid grid-cols-2 sm:grid-cols-1 gap-4">
                            <div>
                                <Label title={"Firstname"} />
                                <Input
                                    onChange={(e) =>
                                        setFormData({
                                            ...formData,
                                            firstname: e.target.value,
                                        })
                                    }
                                    type="text"
                                    placeholder="Firstname..."
                                    value={formData?.firstname}
                                />
                            </div>
                            <div>
                                <Label title={"Lastname"} />
                                <Input
                                    onChange={(e) =>
                                        setFormData({
                                            ...formData,
                                            lastname: e.target.value,
                                        })
                                    }
                                    type="text"
                                    placeholder="Lastname..."
                                    value={formData?.lastname}
                                />
                            </div>
                            <div>
                                <Label title={"Email"} />
                                <Input
                                    onChange={(e) =>
                                        setFormData({
                                            ...formData,
                                            email: e.target.value,
                                        })
                                    }
                                    type="text"
                                    placeholder="Email..."
                                    value={formData?.email}
                                />
                            </div>
                            <div>
                                <Label title={"Select Location"} />
                                <Select
                                    value={formData?.location_uuid}
                                    onChange={(uuid) =>
                                        setFormData({
                                            ...formData,
                                            location_uuid: uuid,
                                        })
                                    }
                                    style={{ width: "100%" }}
                                    disabled={!locations?.length > 0}
                                    options={locations?.map((location) => {
                                        const { name, address, uuid } =
                                            location;
                                        return {
                                            value: uuid,
                                            label: `${name} - ${address}`,
                                        };
                                    })}
                                />
                            </div>
                            <div className="sm:w-full">
                                <Label title={"Password"} />
                                <Input
                                    autoComplete={""}
                                    onChange={(e) =>
                                        setFormData({
                                            ...formData,
                                            password: e.target.value,
                                        })
                                    }
                                    className="w-full sm:w-full"
                                    type="password"
                                    placeholder="Password.."
                                />
                            </div>
                            <div className="sm:w-full">
                                <Label title={"Confirm Password"} />
                                <Input
                                    onChange={(e) =>
                                        setFormData({
                                            ...formData,
                                            confirmPassword: e.target.value,
                                        })
                                    }
                                    className="w-full sm:w-full"
                                    type="password"
                                    placeholder="Confirm Password..."
                                />
                            </div>
                        </div>
                        <HeadingTitle title={"Permissions"} />
                        <div className={"flex flex-wrap gap-3"}>
                            <div className={"flex gap-3"}>
                                <div>
                                    <Label
                                        className={"!text-black"}
                                        title={"Manage Reservation"}
                                    />
                                </div>
                                <Checkbox
                                    value={"manage_reservation"}
                                    defaultChecked={hasPermission(
                                        employee?.permissions ||
                                            state?.permissions,
                                        "manage_reservation",
                                    )}
                                    onClick={handlePermissions}
                                />
                            </div>
                            <div className={"flex gap-3"}>
                                <div>
                                    <Label
                                        className={"!text-black"}
                                        title={"Delete Reservation"}
                                    />
                                </div>
                                <Checkbox
                                    value={"delete_reservation"}
                                    defaultChecked={hasPermission(
                                        employee?.permissions ||
                                            state?.permissions,
                                        "delete_reservation",
                                    )}
                                    onClick={handlePermissions}
                                />
                            </div>
                            <div className={"flex gap-3"}>
                                <div>
                                    <Label
                                        className={"!text-black"}
                                        title={"Manage Imported Reservations"}
                                    />
                                </div>
                                <Checkbox
                                    value={"manage_imported_reservations"}
                                    defaultChecked={hasPermission(
                                        employee?.permissions ||
                                            state?.permissions,
                                        "manage_imported_reservations",
                                    )}
                                    onClick={handlePermissions}
                                />
                            </div>
                        </div>
                        <HeadingTitle title="ACTIONS" />
                        <div className="w-full flex justify-end mt-9">
                            <Button
                                onClick={showModal}
                                className={"w-44 mr-3"}
                                danger={true}
                            >
                                Delete Employee
                            </Button>
                            <Button
                                className={"w-44"}
                                buttonType={"primary"}
                                isLoading={loading}
                                onClick={editEmployee}
                            >
                                Update Employee
                            </Button>
                        </div>
                    </form>
                    <Modal
                        title="Delete Employee!"
                        open={isModalOpen}
                        onOk={deleteEmployee}
                        onCancel={handleCancel}
                    >
                        <p>Are you sure you want to delete employee?</p>
                    </Modal>
                </>
            )}
        </>
    );
};

export default EditEmployeForm;
