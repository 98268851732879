import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import HeadingTitle from "Components/Headings/HeadingTitle";
import useEmployee from "Hooks/EmployeeHooks/useEmployee";
import LoadingPlaceholder from "Components/Placeholders/LoadingPlaceholder/LoadingPlaceholder";
import {
    Button,
    Input,
    Result,
    Table,
    Select,
    Tag,
    Avatar,
    Pagination,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import UserAvatar from "Assets/Images/user.png";
import UrlBuilderUtils from "Utils/UrlBuilderUtils";
import { addPageQueryParam, getPageParam } from "Helpers/PageHelper";
import useLocations from "Hooks/LocationsHooks/useLocations";
import QueryBuilder from "Utils/QueryBuilderUtil";

const ManageEmployee = () => {
    const { getEmployees } = useEmployee();
    const navigate = useNavigate();
    const { getLocations } = useLocations();

    const [employees, setEmployees] = useState(null);
    const [totalPageSize, setTotalPageSize] = useState(0);
    const [currPage, setCurPage] = useState(getPageParam());
    const [locations, setLocations] = useState(null);
    const [query, setQuery] = useState("");

    const initialFilters = {
        active: false,
        locationId: null,
        employeeName: null,
    };
    const [filters, setFilters] = useState({
        active: false,
        locationId: null,
        employeeName: null,
    });

    const fetchEmployees = async (page, query = "") => {
        const employees = await getEmployees(page, query);
        setEmployees(employees?.data);
        setTotalPageSize(employees?.total);
    };

    const fetchLocations = async () => {
        const locations = await getLocations();
        setLocations(locations);
    };

    const handlePagination = (pageNo) => {
        setCurPage(pageNo);
    };

    const filterEmployees = () => {
        const query = QueryBuilder.createQuery(filters);
        setFilters({ ...filters, active: true });
        if (query) {
            setQuery(query);
            fetchEmployees(getPageParam(), query);
        }
    };

    const handleLocationChange = (locationId) => {
        setFilters({ ...filters, locationId: locationId, active: true });
        const query = QueryBuilder.createQuery({
            ...filters,
            locationId: locationId,
        });
        if (query) {
            setQuery(query);
            fetchEmployees(getPageParam(), query);
        }
    };

    const clearFilters = () => {
        setFilters(initialFilters);
        setQuery("");
        fetchEmployees(1);
    };

    /**
     * Some data is not loaded as it should so we need this
     * @param data
     */
    const handleDetails = (data) => {
        navigate("/edit/employee/" + data?.uuid, {
            state: {
                profileImage: data?.profile_image,
                permissions: data?.permissions,
            },
        });
    };

    useEffect(() => {
        fetchEmployees(currPage, query);
        fetchLocations();
        addPageQueryParam(currPage);
    }, [currPage]);

    const columnsTable = [
        {
            title: "",
            dataIndex: "profileImage",
            key: "profileImage",
            render: (_, record) => {
                return (
                    <>
                        <Avatar
                            size={50}
                            src={
                                UrlBuilderUtils.buildMediaUrl(
                                    record.profileImage,
                                ) || UserAvatar
                            }
                        />
                    </>
                );
            },
        },
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "Role",
            dataIndex: "role",
            key: "role",
            render: (_, record) => (
                <p className={"capitalize"}>{record.role}</p>
            ),
        },
        {
            title: "Location",
            dataIndex: "location",
            key: "location",
        },
        {
            title: "Active",
            dataIndex: "active",
            key: "active",
        },
        {
            title: "Email",
            dataIndex: "email",
            key: "email",
        },
        {
            title: "Permissions",
            dataIndex: "permission",
            key: "permission",
            render: (permissions) => (
                <ul>
                    {permissions.length > 0 ? (
                        permissions?.map((permission) => (
                            <li className="mb-2 capitalize">
                                <Tag
                                    className={
                                        "text-[10px] font-semibold uppercase"
                                    }
                                    color={"blue"}
                                >
                                    {permission.name.replace(/_/g, " ")}
                                </Tag>
                            </li>
                        ))
                    ) : (
                        <Tag
                            className={"text-[10px] font-semibold uppercase"}
                            color={"gray"}
                        >
                            No Permissions
                        </Tag>
                    )}
                </ul>
            ),
        },
        {
            title: "Action",
            dataIndex: "action",
            key: "action",
            render: (_, { action }) => {
                return (
                    <Button onClick={() => handleDetails(action)}>
                        Details
                    </Button>
                );
            },
        },
    ];

    const data = employees?.map((employee) => ({
        key: employee.uuid,
        name: `${employee.firstname} ${employee.lastname}`,
        role: employee.roles[0].name,
        location: `${employee.companyLocation[0].city}, ${employee.companyLocation[0].address}`,
        active: "Active",
        action: employee,
        email: employee.email,
        permission: employee.permissions,
        profileImage: employee.profile_image,
    }));

    return (
        <>
            <div className={"flex gap-2 items-center"}>
                <HeadingTitle title={"LOCATION"} />
                <Select
                    value={filters.locationId}
                    onChange={handleLocationChange}
                    placeholder={"Select location..."}
                    className={"sm:w-full w-[250px]"}
                    disabled={!locations?.length > 0}
                    options={locations?.map((location) => {
                        const { name, address, uuid } = location;
                        return {
                            value: uuid,
                            label: `${name} - ${address}`,
                        };
                    })}
                />
            </div>
            <HeadingTitle title={"Filter Employees"} />
            <div className=" mb-5 pr-3 rounded-md flex justify-start">
                <div className="mr-3">
                    <Input
                        value={filters.employeeName}
                        onChange={(e) =>
                            setFilters({
                                ...filters,
                                employeeName: e.target.value,
                            })
                        }
                        name="name"
                        className="sm:w-full w-36"
                        type="text"
                        placeholder="Search by name..."
                    />
                </div>
                <Button
                    onClick={filterEmployees}
                    type={"primary"}
                    className="w-28 lg:w-full sm:w-full"
                >
                    Search <SearchOutlined />
                </Button>
                {filters.active && (
                    <Button
                        onClick={clearFilters}
                        className="w-28 lg:w-full sm:w-full"
                    >
                        Clear Filters
                    </Button>
                )}
            </div>
            <HeadingTitle title={"List Employees"} />
            {!employees ? (
                <LoadingPlaceholder />
            ) : data.length > 0 ? (
                <>
                    <Table
                        className={"md:overflow-auto"}
                        dataSource={data}
                        columns={columnsTable}
                        pagination={false}
                    />
                    <Pagination
                        className={"mt-5 text-end"}
                        onChange={handlePagination}
                        defaultPageSize={10}
                        defaultCurrent={getPageParam()}
                        total={totalPageSize}
                        showSizeChanger={false}
                    />
                </>
            ) : (
                <Result
                    className={""}
                    title="There is no employees."
                    extra={
                        <Button
                            onClick={() => navigate("/add/employee")}
                            type="primary"
                            key="console"
                        >
                            Create employee
                        </Button>
                    }
                />
            )}
        </>
    );
};

export default ManageEmployee;
