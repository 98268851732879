import React from "react";
import HeadingTitle from "Components/Headings/HeadingTitle";
import SingleIntegrationContainer from "Containers/Integrations/SingleIntegrationContainer";
import { SingleIntegrationProvider } from "../../../Context/Integrations/SingleIntegrationContext";

const SingleIntegrationPage = () => {
    return (
        <SingleIntegrationProvider>
            <SingleIntegrationContainer />
        </SingleIntegrationProvider>
    );
};

export default SingleIntegrationPage;
