import React, { useEffect, useState } from "react";
import HeadingTitle from "Components/Headings/HeadingTitle";
import useLocations from "Hooks/LocationsHooks/useLocations";
import LoadingPlaceholder from "Components/Placeholders/LoadingPlaceholder/LoadingPlaceholder";
import LocationCard from "./partials/LocationCard";
import { Result, Button } from "antd";
import { useNavigate } from "react-router-dom";

const ManageLocations = () => {
    const { getLocations } = useLocations();
    const [locations, setLocations] = useState(null);
    const navigate = useNavigate();

    async function fetchLocationData() {
        const data = await getLocations();
        setLocations(data);
    }
    function renderLocations() {
        // @ts-ignore
        return locations.map((location) => {
            return (
                <LocationCard
                    key={location.uuid}
                    locationData={location}
                    action={setLocations}
                />
            );
        });
    }

    useEffect(() => {
        fetchLocationData();
    }, []);

    if (!locations) return <LoadingPlaceholder />;

    return (
        <>
            <HeadingTitle title="Location List" />
            <div className="cards-container flex sm:flex-col g-2 flex-wrap mb-6 gap-3">
                {
                    // @ts-ignore
                    locations?.length > 0 ? (
                        renderLocations()
                    ) : (
                        <Result
                            title={"You do not have any location."}
                            subTitle={"Add your first location."}
                            extra={
                                <Button
                                    onClick={() => navigate("/add/car")}
                                    type="primary"
                                    key="console"
                                >
                                    Add Location
                                </Button>
                            }
                        />
                    )
                }
            </div>
        </>
    );
};
export default ManageLocations;
