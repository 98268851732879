import React, { useState } from "react";
import type { MenuProps } from "antd";
import { Menu } from "antd";
import { Link } from "react-router-dom";
import AddCarLink from "./Menu/MenuLinks/MainMenuLinks/AddCarLink";
import ManageCarsLink from "./Menu/MenuLinks/MainMenuLinks/ManageCarsLink";
import CheckInOutLink from "./Menu/MenuLinks/MainMenuLinks/CheckInOutLink";
import IntegrationLink from "./Menu/MenuLinks/IntegrationLinks/IntegrationLink";
import { hasPermissionTo, isUserAdmin } from "Utils/UserUtils";
import { useAuth } from "Hooks/AuthHooks/useAuth";
import {
    SettingOutlined,
    DashboardOutlined,
    CarOutlined,
    AppstoreAddOutlined,
    HistoryOutlined,
    QuestionCircleOutlined,
    LogoutOutlined,
    HomeOutlined,
    UsergroupAddOutlined,
    UserSwitchOutlined,
    SyncOutlined,
} from "@ant-design/icons";

type MenuItem = Required<MenuProps>["items"][number];

function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: "group",
): MenuItem {
    return {
        key,
        icon,
        children,
        label,
        type,
    } as MenuItem;
}
const items: MenuProps["items"] = [
    { type: "divider" },
    // main menu
    getItem(
        "Main Menu",
        "grp",
        null,
        [
            // Dashboard
            getItem(
                <Link to={"/dashboard"}>Dashboard</Link>,
                "dashboard",
                <DashboardOutlined />,
            ),
            isUserAdmin() || hasPermissionTo("manage_imported_reservations")
                ? getItem(
                      <Link to={"/manage/reservations"}>
                          Imported Reservations
                      </Link>,
                      "manageReservations",
                      <SyncOutlined />,
                  )
                : null,
            // Reservations
            getItem("Vehicles", "cars", <CarOutlined />, [
                isUserAdmin() ? getItem(<AddCarLink />, "addCar") : null,
                getItem(<ManageCarsLink />, "manageCars"),
                getItem(<CheckInOutLink />, "checkInOut"),
            ]),
            // Locations
            isUserAdmin()
                ? getItem("Locations", "locations", <HomeOutlined />, [
                      getItem(
                          <Link to={"/add/location"}>Add Location</Link>,
                          "addLocation",
                      ),
                      getItem(
                          <Link to={"/locations"}>Manage Location</Link>,
                          "manageLocation",
                      ),
                  ])
                : null,
            // Employees
            isUserAdmin()
                ? getItem("Employees", "employees", <UsergroupAddOutlined />, [
                      getItem(
                          <Link to={"/add/employee"}>Add Employee</Link>,
                          "addEmployee",
                      ),
                      getItem(
                          <Link to={"/employees"}> Manage Employees </Link>,
                          "manageEmployees",
                      ),
                      getItem(
                          <Link to={"/employees/analytics"}>
                              {" "}
                              Employees Analytics{" "}
                          </Link>,
                          "employeeAnalytics",
                      ),
                  ])
                : null,
            // Histories
            isUserAdmin()
                ? getItem("Histories", "histories", <HistoryOutlined />, [
                      getItem(
                          <Link to={"/history/cars"}>Car History</Link>,
                          "carHistory",
                      ),
                  ])
                : null,
            getItem(
                <Link to={"/customers?page=1"}>Customers</Link>,
                "customers",
                <UserSwitchOutlined />,
            ),
        ],
        "group",
    ),
    isUserAdmin() // integrations
        ? getItem("Integration", "integrations", <AppstoreAddOutlined />, [
              getItem(<IntegrationLink />, "myIntegrations"),
              getItem(
                  <Link to={"/add/integrations"}>Add Integrations</Link>,
                  "addIntegrations",
              ),
          ])
        : null,
    { type: "divider" },
    // support
    getItem(
        "Support",
        "support",
        null,
        [
            getItem(
                "Contact Support",
                "contactSupport",
                <QuestionCircleOutlined />,
            ),
        ],
        "group",
    ),

    { type: "divider" },
    // settings
    getItem("Settings", "settings", <SettingOutlined />, [
        isUserAdmin()
            ? getItem(
                  <Link to={"/settings"}>Company Settings</Link>,
                  "companySettings",
              )
            : null,
        getItem("Profile Settings", "profileSettings"),
        isUserAdmin() ? getItem("Subscription Plan", "subscriptionPlan") : null,
    ]),

    getItem("Logout", "logout", <LogoutOutlined />),
];

const rootSubmenuKeys = [
    "cars",
    "locations",
    "settings",
    "support",
    "integrations",
    "employees",
    "customers",
    "histories",
];
interface AntSidebarProp {
    isMenuOpen?: any;
}
const AntSidebar: React.FC<AntSidebarProp> = ({ isMenuOpen }) => {
    const { logout } = useAuth();
    const [openKeys, setOpenKeys] = useState([""]);

    const onOpenChange: MenuProps["onOpenChange"] = (keys) => {
        const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
        if (latestOpenKey && rootSubmenuKeys.indexOf(latestOpenKey!) === -1) {
            setOpenKeys(keys);
        } else {
            setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
        }
    };
    const onClick = async (e: any) => {
        if (e && isMenuOpen) {
            isMenuOpen(false);
        }
        switch (e.key) {
            case "logout":
                await logout();
                break;
            default:
                break;
        }
    };
    return (
        <>
            <Menu
                onClick={onClick}
                onOpenChange={onOpenChange}
                openKeys={openKeys}
                mode="inline"
                items={items}
                className={"h-screen w-[270px] mdh:w-full sm:border-t-0"}
            />
        </>
    );
};

export default AntSidebar;
