import React, { useState } from "react";
import { Button, Divider, Input, Modal, Tooltip } from "antd";
import { EditOutlined } from "@ant-design/icons";
import useIntegrationConfig from "Hooks/IntegrationHooks/IntegrationConfig/useIntegrationConfig";
import Label from "Components/Label";

interface CreateRuleModalPropTypes {
    update: () => void;
    data: {
        ruleId: string;
        duration_from: string;
        duration_to: string;
        price: string;
    };
}
const UpdateRuleModal = ({ update, data }: CreateRuleModalPropTypes) => {
    const { updatePeriodRule } = useIntegrationConfig();

    const initialFormData = data;
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [formData, setFormData] = useState(initialFormData);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = async () => {
        await updatePeriodRule(formData).then((res) => {
            setIsModalOpen(false);
            update();
        });
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    return (
        <>
            <Tooltip title={"Add rules for period"}>
                <Button icon={<EditOutlined />} onClick={showModal}></Button>
            </Tooltip>
            <Modal
                title="Update Rule for Period"
                open={isModalOpen}
                onOk={handleOk}
                okText={"Update"}
                onCancel={handleCancel}
            >
                <small>
                    Update duration ranges that will be handled in API and give
                    price depending on duration of reservation.
                </small>
                <Divider />
                <div className={"flex flex-col gap-3"}>
                    <div>
                        <Label title={"Duration Days From"} />
                        <Input
                            type={"number"}
                            placeholder={"Duration Days From"}
                            value={formData.duration_from || undefined}
                            onChange={(e) =>
                                setFormData({
                                    ...formData,
                                    duration_from: e.target.value,
                                })
                            }
                        />
                    </div>
                    <div>
                        <Label title={"Duration Day To"} />
                        <Input
                            type={"number"}
                            placeholder={"Duration Days To"}
                            value={formData.duration_to || undefined}
                            onChange={(e) =>
                                setFormData({
                                    ...formData,
                                    duration_to: e.target.value,
                                })
                            }
                        />
                    </div>

                    <div>
                        <Label title={"Price for Period"} />
                        <Input
                            type={"number"}
                            placeholder={"Price"}
                            value={formData.price || undefined}
                            onChange={(e) =>
                                setFormData({
                                    ...formData,
                                    price: e.target.value,
                                })
                            }
                        />
                    </div>
                </div>
                <Divider />
            </Modal>
        </>
    );
};

export default UpdateRuleModal;
