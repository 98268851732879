import React, { Dispatch, SetStateAction, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Modal, Upload } from "antd";
import type { RcFile } from "antd/es/upload";
import type { UploadFile } from "antd/es/upload/interface";
import { App } from "antd";
import Axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";

interface UploaderImageProps {
    image?: {
        uid: string;
        name: string;
        status: string;
        url: string;
    };
    setImage: Dispatch<SetStateAction<any>>;
    uploadText?: string;
    config?: {
        entity: "employee" | "car";
        entityId: string;
    };
    clearState?: boolean;
}

interface DeleteImageParams {
    entity: "employee" | "car";
    entityId: string;
}

const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = (error) => reject(error);
    });

const deleteImage = async (config: DeleteImageParams | undefined) => {
    return await Axios.delete(
        `/image/remove/${config?.entity}/${config?.entityId}`,
    );
};

const UploaderImage: React.FC<UploaderImageProps> = ({
    image = { uid: "1", name: "", status: "done", url: "" },
    setImage,
    uploadText,
    config,
    clearState = true,
}) => {
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const { notification } = App.useApp();

    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState("");
    const [previewTitle, setPreviewTitle] = useState("");
    const [fileList, setFileList] = useState([image]);
    const handleCancel = () => setPreviewOpen(false);

    const handlePreview = async (file: UploadFile) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj as RcFile);
        }
        setPreviewImage(file.url || (file.preview as string));
        setPreviewOpen(true);
        setPreviewTitle(
            file.name || file.url!.substring(file.url!.lastIndexOf("/") + 1),
        );
    };

    const beforeUpload = (file: RcFile) => {
        const isJpgOrPng =
            file.type === "image/jpeg" || file.type === "image/png";
        if (!isJpgOrPng) {
            notification.error({
                message: "Error",
                description: "You can only upload JPG/PNG files!",
            });
        }
        const isLt250Kb = file.size / 1024 < 250;
        if (!isLt250Kb) {
            notification.error({
                message: "Error",
                description: "Image must be smaller than 250KB!",
            });
        }
        return isJpgOrPng && isLt250Kb;
    };

    // @ts-ignore
    const handleChange: any = (e) => {
        if (e.file.status !== "removed") {
            setFileList([e.file]);
            setImage(e.file.originFileObj);
        }
    };

    const handleRemove = async (event: any) => {
        await deleteImage(config)
            .then((response) => {
                notification.success({
                    message: "Success",
                    description: "Removed image successfully.",
                });
                setFileList([]);
                if (clearState) {
                    navigate(pathname, { replace: true });
                }
            })
            .catch((error) => {
                notification.error({
                    message: "Error",
                    description:
                        "Something went wrong, please try again later.",
                });
            });
    };

    const uploadButton = (
        <div>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>{uploadText || "Upload"}</div>
        </div>
    );
    return (
        <>
            <Upload
                listType="picture-circle"
                // @ts-ignore
                fileList={fileList}
                showUploadList={{ showRemoveIcon: !!image.url }}
                onPreview={handlePreview}
                onRemove={handleRemove}
                onChange={handleChange}
                beforeUpload={beforeUpload}
            >
                {uploadButton}
            </Upload>
            <Modal
                open={previewOpen}
                title={previewTitle}
                footer={null}
                onCancel={handleCancel}
            >
                <img
                    alt="example"
                    style={{ width: "100%" }}
                    src={previewImage}
                />
            </Modal>
        </>
    );
};

export default UploaderImage;
