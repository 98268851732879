// @ts-nocheck
import React, { useContext, useState } from "react";
import { Button, Divider, Drawer, Image, Input, Select } from "antd";
import { ActivateIntegrationContext } from "Context/Integrations/ActivateIntegrationContext";
import RentelonImage from "Assets/Images/rentelon.png";
import WoocommerceImage from "Assets/Images/Integrations/woocommerce.png";
import HeadingTitle from "Components/Headings/HeadingTitle";
import { isMobile } from "Utils/DeviceUtils";
import useIntegrations from "Hooks/IntegrationHooks/useIntegrations";
import IntegrationImage from "../../../Components/Integrations/IntegrationImage";

const IntegrationDrawer: React.FC = () => {
    const {
        isDrawerOpen,
        closeIntegrationDrawer,
        integration,
        locations,
        activationData,
        setActivationData,
    } = useContext(ActivateIntegrationContext);

    const { addIntegration } = useIntegrations();
    const [loading, setLoading] = useState<boolean>(false);

    const handleActivation = async () => {
        setLoading(true);
        const data = activationData;
        data.integration_type = integration?.integrationType;
        data.integration_service = integration?.serviceType;
        // Rentelon API can go without location like global API
        switch (integration?.integrationType) {
            case "RENTELON_API":
                data.location_id = 0;
                break;
        }
        await addIntegration(data).finally(() => {
            setLoading(false);
        });
    };

    return (
        <Drawer
            placement="right"
            closable={false}
            onClose={closeIntegrationDrawer}
            open={isDrawerOpen}
            getContainer={!isMobile() ? false : undefined}
        >
            <div className={"flex gap-3"}>
                <div
                    className={
                        "w-[110px] h-[110px] bg-blue-100 rounded-md flex items-center justify-center"
                    }
                >
                    <IntegrationImage
                        integrationType={integration?.integrationType}
                        size={85}
                    />
                </div>
                <div>
                    <h3 className={"text-2xl font-bold"}>
                        {integration?.integrationTitle}
                    </h3>
                    <small className={"uppercase font-bold"}>Service: </small>
                    <small className={"font-bold text-blue-700 text-[12px]"}>
                        {integration?.serviceType}
                    </small>
                    <p className={"w-[200px]"}>
                        <small>{integration?.description}</small>
                    </p>
                </div>
            </div>
            <HeadingTitle title={"Description"} />
            <p className={"!text-gray-500"}>{integration?.longDescription}</p>
            <HeadingTitle title={"Activation"} />
            <div className={"flex flex-col gap-3"}>
                <Input
                    value={activationData?.integration_name || undefined}
                    onChange={(e) =>
                        setActivationData({
                            ...activationData,
                            integration_name: e.target.value,
                        })
                    }
                    type={"text"}
                    placeholder={"Integration name"}
                />
                {integration?.integrationType !== "RENTELON_API" && (
                    <Select
                        onChange={(uuid) =>
                            setActivationData({
                                ...activationData,
                                location_id: uuid,
                            })
                        }
                        placeholder={"Select location..."}
                        value={activationData?.location_id || undefined}
                        className={"w-full"}
                        // @ts-ignore
                        disabled={!locations?.length > 0}
                        // @ts-ignore
                        options={locations?.map((location) => {
                            const { name, address, uuid } = location;
                            return {
                                value: uuid,
                                label: `${name} - ${address}`,
                            };
                        })}
                    />
                )}
                <Button
                    onClick={handleActivation}
                    loading={loading}
                    type={"primary"}
                >
                    Activate
                </Button>
            </div>
        </Drawer>
    );
};

export default IntegrationDrawer;
