import React, { useEffect, useState } from "react";
import HeadingTitle from "Components/Headings/HeadingTitle";
import useLocations from "Hooks/LocationsHooks/useLocations";
import useCar from "Hooks/RentCarHooks/useCar";
import Colors from "Utils/partials/basic_color_list.json";
import UploaderImage from "Components/UploaderImage";
import Button from "Components/Button";
import HorizontalDivider from "Components/HorizontalDivider";
import { ColorPicker, Input, InputNumber, Radio, Select } from "antd";
import { LocationTypes } from "Types/LocationTypes";
import Label from "Components/Label";

type DoorOptionsTypes = "2 Doors" | "4 Doors";

interface RentCarFormTypes {
    car_name: null | string;
    car_price: undefined | string;
    location_uuid: null | string;
    category_uuid: null | string;
    car_production_year: null | string;
    car_transmission_type: null | string;
    car_fuel_type: null | string;
    car_engine_type: null | string;
    car_image_path: null | string;
    car_doors: null | string;
    car_licence_number: null | string;
    car_color: null | string;
    is_active: 1 | 0;
    carKilometers: null | string;
    car_sipp_code_id: null | string;
}

const AddRentCarForm = () => {
    const { getLocations } = useLocations();
    // @ts-ignore
    const { getCarCategories, setCar, getCarSippCodes } = useCar();

    const [locations, setLocations] = useState<LocationTypes | null>(null);
    const [categories, setCategories] = useState(null);
    const [carSippCodes, setCarSippCodes] = useState(null);
    const [image, setImage] = useState(null);
    const [loading, setLoading] = useState(false);

    const [formData, setFormData] = useState<RentCarFormTypes>({
        car_name: null,
        car_price: undefined,
        location_uuid: null,
        category_uuid: null,
        car_production_year: null,
        car_transmission_type: null,
        car_fuel_type: null,
        car_engine_type: null,
        car_image_path: null,
        car_doors: null,
        car_licence_number: null,
        car_color: "",
        is_active: 1,
        carKilometers: null,
        car_sipp_code_id: null,
    });

    const doorsOptionsRadio: DoorOptionsTypes[] = ["2 Doors", "4 Doors"];
    const transmissionOptions = ["Automatic", "Manual"];
    const fuelOptions = ["Diesel", "Gasoline"];

    const fetchLocationsData = async () => {
        const locations = await getLocations();
        setLocations(locations);
    };

    const fetchCarCategories = async () => {
        const categories = await getCarCategories();
        setCategories(categories);
    };

    const fetchCarSippCodes = async () => {
        const carSippCodes = await getCarSippCodes();
        setCarSippCodes(carSippCodes);
    };

    const addCar = (e: { preventDefault: () => void }) => {
        e.preventDefault();
        setLoading(true);
        Object.assign(formData, { car_image_path: image });
        setCar(formData, setLoading);
    };

    useEffect(() => {
        fetchLocationsData();
        fetchCarCategories();
        fetchCarSippCodes();
    }, []);

    return (
        <div className="add-rent-cars">
            <form id="add-rent-car-form ">
                <HeadingTitle title="Car Specification" />
                <div>
                    <Label title={"Car Photo"} />
                    <UploaderImage setImage={setImage} />
                </div>
                <div className="flex justify-center sm:flex-wrap lg:flex-wrap gap-5">
                    <div className="md-min:basis-1/2 lg:w-full gap-3 flex flex-col md-min:mr-8">
                        <div>
                            <Label title={"Car Brand"} />
                            <Input
                                // @ts-ignore
                                onChange={(e) =>
                                    setFormData({
                                        ...formData,
                                        car_name: e.target.value,
                                    })
                                }
                                type="span"
                                placeholder="Enter Car Brand..."
                                className="input  w-full "
                            />
                        </div>
                        <div>
                            <Label title={"Year of Production"} />
                            <Input
                                // @ts-ignore
                                onChange={({ target: { value } }) =>
                                    setFormData({
                                        ...formData,
                                        car_production_year: value,
                                    })
                                }
                                placeholder="Enter year"
                                className="input w-full "
                            />
                        </div>

                        <div>
                            <Label title={"Licence Number"} />
                            <Input
                                onChange={(e: { target: { value: any } }) =>
                                    setFormData({
                                        ...formData,
                                        car_licence_number: e.target.value,
                                    })
                                }
                                type="text"
                                placeholder="Enter licence number..."
                                className="input  w-full "
                            />
                        </div>
                        <div>
                            <Label title={"Engine Type"} />
                            <Input
                                onChange={(e: { target: { value: any } }) =>
                                    setFormData({
                                        ...formData,
                                        car_engine_type: e.target.value,
                                    })
                                }
                                type="text"
                                placeholder="e.g 1.0 TSI"
                                className="input w-full "
                            />
                        </div>
                        <div>
                            <Label title={"Car Kilometers"} />
                            <Input
                                onChange={(e) =>
                                    setFormData({
                                        ...formData,
                                        carKilometers: e.target.value,
                                    })
                                }
                                placeholder="Enter kilomenters..."
                                type="text"
                                className="input w-full "
                            />
                        </div>
                    </div>
                    <div className="md-min:basis-1/2 lg:w-full flex flex-col gap-3">
                        <div>
                            <Label title={"Price Per Day"} />
                            <InputNumber
                                onChange={(price) =>
                                    setFormData({
                                        ...formData,
                                        car_price: price?.toString(),
                                    })
                                }
                                placeholder="Enter Price..."
                                className="input w-full "
                            />
                        </div>
                        <div>
                            <Label title={"Assign Location"} />
                            <Select
                                onChange={(uuid) =>
                                    setFormData({
                                        ...formData,
                                        location_uuid: uuid,
                                    })
                                }
                                placeholder={"Select location..."}
                                className="input w-full "
                                // @ts-ignore
                                disabled={!locations?.length > 0}
                                // @ts-ignore
                                options={locations?.map((location) => {
                                    const { name, address, uuid } = location;
                                    return {
                                        value: uuid,
                                        label: `${name} - ${address}`,
                                    };
                                })}
                            />
                        </div>
                        <div>
                            <Label title={"Category"} />
                            <Select
                                onChange={(uuid) =>
                                    setFormData({
                                        ...formData,
                                        category_uuid: uuid,
                                    })
                                }
                                placeholder={"Select category..."}
                                className="w-full"
                                // @ts-ignore
                                disabled={!categories?.length > 0}
                                // @ts-ignore
                                options={categories?.map((category) => {
                                    const { category_name, uuid } = category;
                                    return {
                                        value: uuid,
                                        label: category_name,
                                    };
                                })}
                            />
                        </div>
                        <div>
                            <Label title={"Car Sipp Code"} />
                            <Select
                                onChange={(uuid) =>
                                    setFormData({
                                        ...formData,
                                        car_sipp_code_id: uuid,
                                    })
                                }
                                placeholder={"Select code..."}
                                className="w-full"
                                // @ts-ignore
                                disabled={!carSippCodes?.length > 0}
                                // @ts-ignore
                                options={carSippCodes?.map((sippCode) => {
                                    const { code, description, uuid } =
                                        sippCode;
                                    return {
                                        value: uuid,
                                        label: `${code} - (${description})`,
                                    };
                                })}
                            />
                        </div>
                        <div className="input w-[100%] flex justify-between items-center">
                            <div className={"w-[94%]"}>
                                <Label title={"Color"} />
                                <Select
                                    onChange={(color) =>
                                        setFormData({
                                            ...formData,
                                            car_color: color,
                                        })
                                    }
                                    placeholder={"Select car color..."}
                                    className="w-[94%]"
                                    // @ts-ignore
                                    disabled={!Colors?.length > 0}
                                    // @ts-ignore
                                    options={Colors?.map((color) => {
                                        const { name, value } = color;
                                        return {
                                            value: value,
                                            label: name,
                                        };
                                    })}
                                />
                            </div>
                            <ColorPicker
                                className={"relative top-[12px]"}
                                value={formData.car_color}
                                onChange={(color) => {
                                    setFormData({
                                        ...formData,
                                        car_color: color.toHexString(),
                                    });
                                }}
                                format="hex"
                                disabled={true}
                            />
                        </div>
                    </div>
                </div>
                <HorizontalDivider classes="mt-5" />
                <div className="w-full flex md:flex-wrap mt-5 gap-3">
                    <Radio.Group
                        size="large"
                        onChange={(e) =>
                            setFormData({
                                ...formData,
                                car_transmission_type: e.target.value,
                            })
                        }
                        options={transmissionOptions}
                        optionType="button"
                        buttonStyle="solid"
                        className="mr-3 sm:w-full"
                    />
                    <Radio.Group
                        size="large"
                        onChange={(e) =>
                            setFormData({
                                ...formData,
                                car_fuel_type: e.target.value,
                            })
                        }
                        options={fuelOptions}
                        optionType="button"
                        buttonStyle="solid"
                        className="mr-3"
                    />
                    <Radio.Group
                        size="large"
                        onChange={(e) =>
                            setFormData({
                                ...formData,
                                car_doors: e.target.value,
                            })
                        }
                        options={doorsOptionsRadio}
                        optionType="button"
                        buttonStyle="solid"
                        className="mr-3"
                    />
                </div>
                <HeadingTitle title="Actions" />
                <div className="w-full text-end mt-5">
                    <Button
                        onClick={addCar}
                        buttonType="primary"
                        className="w-28"
                        isLoading={loading}
                    >
                        <span>Save Car</span>
                    </Button>
                </div>
            </form>
        </div>
    );
};

export default AddRentCarForm;
