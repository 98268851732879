const English = {
    en: {
        translation: {
            rentalAgreement: "Rental Agreement",
            billNo: "Bill No",
            rentalAgreementNumber: "Rental Agreement Number",
            company: "Company",
            address: "Address",
            city: "City",
            operatorName: "Operator Name",
            phone: "Phone",
            email: "Email",
            customer: "Customer",
            name: "Name",
            country: "Country",
            passport: "Passport",
            drivingLicence: "D. Licence",
            vehicle: "Vehicle",
            category: "Category",
            registrationNumber: "Reg No.",
            color: "Color",
            pickUp: "Pick Up",
            date: "Date",
            time: "Time",
            return: "Return",
            crossingBorders: "Crossing Borders",
            additionalDrivers: "Additional Drivers",
            termsConditions:
                "By signing this rental agreement customer agrees that he read and accepted the terms & conditions written on back side of the agreement.",
            agencySignature: "Agency Signature",
            customerSignature: "Customer Signature",
            damageExcess: "Damage Excess",
            additionalDriversTotal: "Additional Drivers Total",
            crossingBordersTotal: "Crossing Borders Total",
            babySeatTotal: "Baby Seat Total",
            gpsTotal: "GPS Total",
            insurancePremiumCoverage: "(Premium CDW)",
            insuranceSuperCoverage: "(Standard CDW)",
            insurance: "Coverage",
            rentTotal: "Rent Total",
            deposit: "Deposit",
            total: "Total",
            vehicleDetails:
                "SKODA OCTAVIA J03-A-458 FROM: 11/06/2024 TO: 21/06/2024",
            vehicleWashed: "Vehicle Washed",
            firstAid: "First Aid",
            spareTyre: "Spare Tyre",
            wheelCaps: "Wheel Caps",
            chains: "Chains",
            fireExtinguisher: "Fire Extinguisher",
            carJack: "Car Jack",
            lightBulbs: "Light Bulbs",
            pickUpVehicleCondition: "PICK UP VEHICLE CONDITION",
            fuel: "FUEL",
            returnVehicleCondition: "RETURN VEHICLE CONDITION",
            secretKey: "SECRET KEY",
            from: "From",
            to: "To",
            cardNumber: "Card Number",
        },
    },
};

export default English;
