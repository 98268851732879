import React, { useEffect, useState } from "react";
import HeadingTitle from "Components/Headings/HeadingTitle";
import useLocations from "Hooks/LocationsHooks/useLocations";
import useCar from "Hooks/RentCarHooks/useCar";
import Colors from "Utils/partials/basic_color_list.json";
import { useLocation } from "react-router-dom";
import UploaderImage from "Components/UploaderImage";
import { EditCarFormTypes } from "Types/CarTypes";
import HorizontalDivider from "Components/HorizontalDivider";
import Button from "Components/Button";
import {
    Modal,
    ColorPicker,
    InputNumber,
    Radio,
    Select,
    Switch,
    Input,
    Alert,
} from "antd";
import Label from "Components/Label";

const EditRentCarForm = () => {
    const { getLocations } = useLocations();
    // @ts-ignore
    const { getCarCategories, editCar, removeCar, getCarSippCodes } = useCar();
    const { state } = useLocation();

    const {
        uuid,
        car_name,
        car_price,
        company_location,
        category,
        car_production_year,
        car_transmission_type,
        car_fuel_type,
        car_engine_type,
        car_image_path,
        car_doors,
        car_licence_number,
        car_color,
        is_active,
        carKilometers,
        car_code,
    } = state;

    const [formData, setFormData] = useState<EditCarFormTypes>({
        uuid: uuid,
        car_name: car_name,
        car_price: car_price,
        location_uuid: company_location[0].uuid,
        category_uuid: category[0].uuid,
        car_production_year: car_production_year,
        car_transmission_type: car_transmission_type,
        car_fuel_type: car_fuel_type,
        car_engine_type: car_engine_type,
        car_image_path: null,
        car_doors: car_doors,
        car_licence_number: car_licence_number,
        car_color: car_color,
        is_active: is_active,
        carKilometers: carKilometers,
        car_sipp_code_id: car_code?.uuid || null,
    });
    const [locations, setLocations] = useState(null);
    const [categories, setCategories] = useState(null);
    const [carSippCodes, setCarSippCodes] = useState(null);
    const [image, setImage] = useState(null);
    const [loading, setLoading] = useState(false);
    const [isActive, setIsActive] = useState<boolean>(
        is_active === 1 ? true : false,
    );

    const doorsOptionsRadio = ["2 Doors", "4 Doors"];
    const transmissionOptions = ["Automatic", "Manual"];
    const fuelOptions = ["Diesel", "Gasoline"];

    const fetchLocationsData = async () => {
        const locations = await getLocations();
        setLocations(locations);
    };

    const fetchCarCategories = async () => {
        const categories = await getCarCategories();
        setCategories(categories);
    };

    const fetchCarSippCodes = async () => {
        const carSippCodes = await getCarSippCodes();
        setCarSippCodes(carSippCodes);
    };

    const updateCar = (e: { preventDefault: () => void }) => {
        e.preventDefault();
        setLoading(true);
        Object.assign(formData, { car_image_path: image });
        editCar(formData, setLoading);
    };

    // Car status switching
    const carStatusSwitch = (checked: boolean) => {
        const active = checked ? 1 : 0;
        setFormData({ ...formData, is_active: active });
        setIsActive(checked);
    };

    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = async () => {
        await removeCar(uuid);
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    useEffect(() => {
        fetchLocationsData();
        fetchCarCategories();
        fetchCarSippCodes();
    }, []);

    console.log(car_image_path);

    return (
        <div className="add-rent-cars relative">
            <div className="flex items-center gap-3">
                <HeadingTitle title="Update Car Specification" />
                <Switch
                    onChange={carStatusSwitch}
                    defaultChecked={isActive}
                    title="Active / Inactive"
                />
            </div>

            <form id="add-rent-car-form ">
                {!isActive && (
                    <Alert
                        showIcon
                        description={
                            "This car is currently set as not active and it can not be rented."
                        }
                        type={"warning"}
                    />
                )}
                <div>
                    <Label title={"Car Photo"} />
                    <UploaderImage
                        image={{
                            uid: uuid,
                            name: "Vehicle Photo",
                            status: "done",
                            // @ts-ignore
                            url: car_image_path
                                ? `${process.env.REACT_APP_DOMAIN}${car_image_path}`
                                : "",
                        }}
                        setImage={setImage}
                        config={{
                            entity: "car",
                            entityId: uuid,
                        }}
                        clearState={false}
                    />
                </div>
                <div className="flex sm:flex-wrap lg:flex-wrap lg:justify-center">
                    <div className="md-min:basis-1/2  md-min:mr-8 flex flex-col gap-3 lg:w-full sm:mt-5">
                        <div>
                            <Label title={"Car Brand"} />
                            <Input
                                onChange={(e) =>
                                    setFormData({
                                        ...formData,
                                        car_name: e.target.value,
                                    })
                                }
                                type="text"
                                placeholder="Enter brand..."
                                className="w-full "
                                defaultValue={formData.car_name}
                            />
                        </div>
                        <div>
                            <Label title={"Year of Production"} />
                            <Input
                                // @ts-ignore
                                onChange={({ target: { value } }) =>
                                    setFormData({
                                        ...formData,
                                        car_production_year: value,
                                    })
                                }
                                placeholder="Production Year"
                                className=" w-full"
                                defaultValue={formData.car_production_year}
                            />
                        </div>
                        <div>
                            <Label title={"Licence Number"} />
                            <Input
                                onChange={(e) =>
                                    setFormData({
                                        ...formData,
                                        car_licence_number: e.target.value,
                                    })
                                }
                                type="text"
                                placeholder="Enter Licence Number..."
                                className=" w-full"
                                defaultValue={formData.car_licence_number}
                            />
                        </div>
                        <div>
                            <Label title={"Engine Type"} />
                            <Input
                                onChange={(e) =>
                                    setFormData({
                                        ...formData,
                                        car_engine_type: e.target.value,
                                    })
                                }
                                type="text"
                                placeholder="e.g 1.0 TSI"
                                className="w-full"
                                defaultValue={formData.car_engine_type}
                            />
                        </div>
                        <div>
                            <Label title={"Car Kilometers"} />
                            <Input
                                onChange={(e) =>
                                    setFormData({
                                        ...formData,
                                        carKilometers: e.target.value,
                                    })
                                }
                                type="text"
                                placeholder="Enter Kilometers"
                                className=" w-full"
                                defaultValue={formData.carKilometers}
                            />
                        </div>
                    </div>
                    <div className="md-min:basis-1/2 lg:w-full flex flex-col gap-3">
                        <div>
                            <Label title={"Price"} />
                            <InputNumber
                                onChange={(price) =>
                                    setFormData({
                                        ...formData,
                                        // @ts-ignore
                                        car_price: price,
                                    })
                                }
                                placeholder="Enter Price..."
                                className=" w-full "
                                defaultValue={formData.car_price}
                            />
                        </div>
                        <div>
                            <Label title={"Location"} />
                            <Select
                                onChange={(uuid) =>
                                    setFormData({
                                        ...formData,
                                        location_uuid: uuid,
                                    })
                                }
                                placeholder={"Select location..."}
                                className=" w-full "
                                defaultValue={formData.location_uuid}
                                // @ts-ignore
                                disabled={!locations?.length > 0}
                                // @ts-ignore
                                options={locations?.map((location) => {
                                    const { name, address, uuid } = location;
                                    return {
                                        value: uuid,
                                        label: `${name} - ${address}`,
                                    };
                                })}
                            />
                        </div>
                        <div>
                            <Label title={"Category"} />
                            <Select
                                onChange={(uuid) =>
                                    setFormData({
                                        ...formData,
                                        category_uuid: uuid,
                                    })
                                }
                                placeholder={"Select category..."}
                                className="w-full"
                                defaultValue={formData.category_uuid}
                                // @ts-ignore
                                disabled={!categories?.length > 0}
                                // @ts-ignore
                                options={categories?.map((category) => {
                                    const { category_name, uuid } = category;
                                    return {
                                        value: uuid,
                                        label: category_name,
                                    };
                                })}
                            />
                        </div>
                        <div>
                            <Label title={"Car Sipp Code"} />
                            <Select
                                defaultValue={formData.car_sipp_code_id}
                                onChange={(uuid) =>
                                    setFormData({
                                        ...formData,
                                        car_sipp_code_id: uuid,
                                    })
                                }
                                placeholder={"Select code..."}
                                className="w-full"
                                // @ts-ignore
                                disabled={!carSippCodes?.length > 0}
                                // @ts-ignore
                                options={carSippCodes?.map((sippCode) => {
                                    const { code, description, uuid } =
                                        sippCode;
                                    return {
                                        value: uuid,
                                        label: `${code} - (${description})`,
                                    };
                                })}
                            />
                        </div>
                        <div className="input w-[100%] flex justify-between">
                            <div className={"w-[94%]"}>
                                <Label title={"Color"} />
                                <Select
                                    defaultValue={formData.car_color}
                                    onChange={(color) =>
                                        setFormData({
                                            ...formData,
                                            car_color: color,
                                        })
                                    }
                                    placeholder={"Select car color..."}
                                    className="w-[94%]"
                                    // @ts-ignore
                                    disabled={!Colors?.length > 0}
                                    // @ts-ignore
                                    options={Colors?.map((color) => {
                                        const { name, value } = color;
                                        return {
                                            value: value,
                                            label: name,
                                        };
                                    })}
                                />
                            </div>
                            <ColorPicker
                                className={"relative top-[12px]"}
                                value={formData.car_color}
                                onChange={(color) => {
                                    setFormData({
                                        ...formData,
                                        car_color: color.toHexString(),
                                    });
                                }}
                                format="hex"
                                disabled={true}
                            />
                        </div>
                    </div>
                </div>
                <HorizontalDivider classes={"mt-5 mb-5"} />
                <div className="w-full flex sm:flex-wrap mt-5 gap-5">
                    <Radio.Group
                        size="large"
                        defaultValue={() =>
                            formData.car_transmission_type.replace(
                                /^./,
                                formData.car_transmission_type[0].toUpperCase(),
                            )
                        }
                        onChange={(e) =>
                            setFormData({
                                ...formData,
                                car_transmission_type: e.target.value,
                            })
                        }
                        options={transmissionOptions}
                        optionType="button"
                        buttonStyle="solid"
                        className="mr-3"
                    />
                    <Radio.Group
                        size="large"
                        defaultValue={formData.car_fuel_type}
                        onChange={(e) =>
                            setFormData({
                                ...formData,
                                car_fuel_type: e.target.value,
                            })
                        }
                        options={fuelOptions}
                        optionType="button"
                        buttonStyle="solid"
                        className="mr-3"
                    />
                    <Radio.Group
                        defaultValue={formData.car_doors}
                        size="large"
                        onChange={(e) =>
                            setFormData({
                                ...formData,
                                car_doors: e.target.value,
                            })
                        }
                        options={doorsOptionsRadio}
                        optionType="button"
                        buttonStyle="solid"
                        className="mr-3"
                    />
                </div>
                <HeadingTitle title="Actions" />
                <div className="w-full text-end mt-10">
                    <Button
                        onClick={showModal}
                        danger={true}
                        className={"w-28 mr-3"}
                    >
                        Delete
                    </Button>
                    <Button
                        buttonType={"primary"}
                        onClick={updateCar}
                        isLoading={loading}
                        className={"w-28"}
                    >
                        Update
                    </Button>
                </div>
            </form>
            <Modal
                title="You are about to delete car?"
                okText={"Confirm"}
                okType={"primary"}
                okButtonProps={{ danger: true }}
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
            >
                <p className={"mb-10 mt-5"}>
                    After car is deleted all data related to car such as rented
                    cars periods, customers will be available in rental history
                    for 6 months after car is deleted!
                </p>
            </Modal>
        </div>
    );
};

export default EditRentCarForm;
