import React from "react";

const LoginLayout = ({ children }) => {
    return (
        <div className="login-layout flex md:flex-wrap-reverse ">
            {children}
        </div>
    );
};
export default LoginLayout;
