import React from "react";
import { Tag } from "antd";

interface ReservationRejectedInterface {
    rejected: "REJECTED";
}
const ReservationRejected = ({ rejected }: ReservationRejectedInterface) => {
    return (
        <Tag className={"px-2 py-1 font-semibold border-[2px]"} color={"red"}>
            {rejected}
        </Tag>
    );
};

export default ReservationRejected;
