import React from "react";
import CustomerAgreement from "Components/PDFDocuments/CustomerAgreement";
import { PdfProvider } from "Context/PdfContext";
import PdfOptions from "Components/PDFDocuments/PdfOptions";
import "./pdf-documents.css";
import { I18nextProvider } from "react-i18next";
import i18n from "i18next";
import English from "../../i18next/english";
import Bosnian from "../../i18next/bosnian";
import Macedonia from "../../i18next/macedonia";

i18n.init({
    interpolation: { escapeValue: false },
    lng: "en",
    resources: {
        ...English, // en
        ...Bosnian, // bs
        ...Macedonia, // mk
    },
});

const AgreementPage = () => {
    return (
        <div className={"agreement-page"}>
            <PdfProvider>
                <I18nextProvider i18n={i18n}>
                    <CustomerAgreement />
                    <PdfOptions />
                </I18nextProvider>
            </PdfProvider>
        </div>
    );
};

export default AgreementPage;
