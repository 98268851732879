import React from "react";
import { useSession } from "Hooks/SessionHooks/useSession";
import { Navigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import { hasAllPermissions } from "Helpers/UserHelper";

const withRole = (WrappedComponent, allowedRoles, allowedPermissions) => {
    return (props) => {
        const session = useSession();
        const cache = session.getUserCache();
        if (!cache) {
            secureLocalStorage.clear();
            return <Navigate to="/register" />;
        }
        const role = cache.roles[0].name;
        const permissions = cache.permissions;

        if (allowedRoles.includes(role)) {
            return <WrappedComponent {...props} />;
        } else if (hasAllPermissions(permissions, allowedPermissions)) {
            return <WrappedComponent {...props} />;
        }
        return null;
    };
};

export default withRole;
