import React from "react";
import SingleCarContainer from "../../../Containers/RentCars/SingleCarContainer/SingleCarContainer";

const SingleCarPage = () => {
    return (
        <div className="single-car-page">
            <SingleCarContainer />
        </div>
    );
};

export default SingleCarPage;
