interface BuildMediaUrlInterface {
    /**
     * Builds Media URL
     */
    imagePath: string;
}

const UrlBuilderUtils = {
    buildMediaUrl: (imagePath: BuildMediaUrlInterface): string | null => {
        if (!imagePath) {
            return null;
        }
        return `${process.env.REACT_APP_DOMAIN}${imagePath}`;
    },
    getBaseUrl: () => {
        return;
    },
};

export default UrlBuilderUtils;
