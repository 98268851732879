import React from "react";
import { Link } from "react-router-dom";
import HorizontalDivider from "Components/HorizontalDivider/HorizontalDivider";
import { FaGasPump, FaCarAlt } from "react-icons/fa";
import { GiGearStickPattern, GiCarDoor } from "react-icons/gi";
import { TbEngine } from "react-icons/tb";
import ReactCountryFlag from "react-country-flag";
import { formatDate } from "Utils/DateUtils";
import { Alert, Avatar, Button, ColorPicker, Image } from "antd";
import {
    SwapRightOutlined,
    UserOutlined,
    CalendarOutlined,
    FieldTimeOutlined,
} from "@ant-design/icons";
import CustomerStatusHandler from "Components/Status/CustomerStatus/CustomerStatusHandler";
import {
    getCustomerReservationType,
    handleCustomerRentalStatus,
} from "Helpers/CustomerHelper";
import TypeReservationHandler from "../../../../Components/TypeReservation/TypeReservationHandler";
import HeadingTitle from "../../../../Components/Headings/HeadingTitle";

const SingleCarInformation = ({ data }) => {
    const {
        car_color,
        car_doors,
        car_engine_type,
        car_fuel_type,
        car_image_path,
        car_licence_number,
        car_name,
        car_price,
        car_production_year,
        car_transmission_type,
        category,
        rented_car,
        company_location,
        carKilometers,
        car_code,
    } = data;

    const { address, city, country, country_code, currency, name } =
        company_location[0];

    return (
        <div className="flex sm:flex-wrap lg:flex-wrap items-center">
            <div className="md-min:basis-2/5 sm:w-full lg:w-full">
                <figure className="px-1 flex sm:flex-wrap justify-center">
                    <Image
                        width={400}
                        className={"rounded-md"}
                        src={`${process.env.REACT_APP_DOMAIN}${car_image_path}`}
                        fallback={process.env.REACT_APP_IMAGE_FALLBACK}
                    />
                </figure>
            </div>
            <div className="md-min:basis-3/5 md-min:pl-10 lg:w-full">
                <div className="flex sm:flex-wrap lg:flex-wrap justify-between">
                    <h2 className="title flex items-center">
                        <ColorPicker
                            size="small"
                            value={car_color}
                            disabled={true}
                            rootClassName={"cursor-none"}
                        />
                        <span className="ml-3 text-2xl font-bold">
                            {car_name}
                        </span>
                        <span
                            className={
                                "text-[#bbbbbb] text-xl font-semibold ml-2"
                            }
                        >
                            ({car_code?.code} -{" "}
                            <small>{car_code?.description}</small>)
                        </span>
                    </h2>
                    <h2 className="text-2xl font-bold text-blue-500">
                        {car_price} {currency}
                    </h2>
                </div>
                <HorizontalDivider />
                <div className="flex justify-between pt-3 pb-3">
                    <div className={"text-[16px]"}>
                        Licence Plate: {car_licence_number}
                    </div>
                    <div className={"text-[16px]"}>
                        Production Year: {car_production_year}
                    </div>
                    <div className={"text-[16px]"}>
                        Car kilometers: {carKilometers}
                    </div>
                </div>
                <HorizontalDivider />
                <div className="flex gap-5 sm:flex-wrap lg:flex-wrap lg:flex-row sm:justify-start justify-between pt-3 pb-3">
                    <div className="flex items-center capitalize text[16px]">
                        <GiGearStickPattern />{" "}
                        <span className="ml-2">{car_transmission_type}</span>
                    </div>
                    <div className="flex items-center text-[16px]">
                        <FaGasPump />{" "}
                        <span className="ml-2">{car_fuel_type}</span>
                    </div>
                    <div className="flex items-center text-[16px]">
                        <GiCarDoor /> <span className="ml-2">{car_doors}</span>
                    </div>
                    <div className="flex items-center text-[16px]">
                        <FaCarAlt />{" "}
                        <span className="ml-2">
                            {category[0].category_name}
                        </span>
                    </div>
                    <div className="flex items-center text-[16px]">
                        <TbEngine />{" "}
                        <span className="ml-2">{car_engine_type}</span>
                    </div>
                </div>
                <HorizontalDivider classes={"mb-7"} />
                {rented_car.length > 0 && (
                    <div>
                        <HeadingTitle title={"Current Reservation"} />
                        <Alert
                            className={"sm:flex sm:flex-col sm:gap-3"}
                            type="info  "
                            description={
                                <div>
                                    <div className={"uppercase font-medium"}>
                                        {" "}
                                        <UserOutlined />{" "}
                                        {
                                            rented_car[0].customer[0]
                                                .customer_name
                                        }{" "}
                                    </div>
                                    <div className={"font-medium"}>
                                        {" "}
                                        <CalendarOutlined />{" "}
                                        {formatDate(
                                            rented_car[0].from,
                                            "/",
                                        )}{" "}
                                        <SwapRightOutlined /> TO:{" "}
                                        {formatDate(rented_car[0].to, "/")}{" "}
                                    </div>
                                    <div className={"font-medium"}>
                                        {" "}
                                        <FieldTimeOutlined />{" "}
                                        {rented_car[0].check_out}{" "}
                                    </div>
                                </div>
                            }
                            action={
                                <div className={"flex items-center gap-2"}>
                                    <TypeReservationHandler
                                        type={getCustomerReservationType(
                                            rented_car[0]?.customer,
                                        )}
                                        size={100}
                                    />
                                    <CustomerStatusHandler
                                        status={handleCustomerRentalStatus(
                                            rented_car[0].from,
                                            rented_car[0].to,
                                        )}
                                        date={rented_car[0].from}
                                    />
                                    <Button size="small" type="dashed">
                                        <Link
                                            to={`/customer/${rented_car[0].customer[0].uuid}`}
                                            className={""}
                                        >
                                            Show Customer
                                        </Link>
                                    </Button>
                                </div>
                            }
                        />
                    </div>
                )}
                {/* Assigned location of car */}
                <div className="flex gap-4 items-center mt-7">
                    <Avatar
                        size={70}
                        icon={
                            <ReactCountryFlag
                                countryCode={country_code}
                                svg
                                style={{ width: "90px", height: "90px" }}
                            />
                        }
                    />
                    <div className="flex sm:flex-wrap lg:flex-wrap">
                        <div>
                            <p className={"font-bold uppercase"}>{country}</p>
                            <p>
                                {" "}
                                {address}, {city}{" "}
                            </p>
                            <p> {name} </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SingleCarInformation;
