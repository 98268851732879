import React from "react";
import SingleCustomerContainer from "Containers/Customers/SingleCustomerContainer";

const SingleCustomerPage = () => {
    return (
        <div className="single-customer-page">
            <SingleCustomerContainer />
        </div>
    );
};
export default SingleCustomerPage;
