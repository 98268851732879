import React from "react";
import withRole from "Providers/AuthProvider/WithRole";
import HeadingTitle from "Components/Headings/HeadingTitle";
import AddIntegrationsContainer from "Containers/Integrations/AddIntegrationsContainer";
import { ActivateIntegrationProvider } from "Context/Integrations/ActivateIntegrationContext";
const AddIntegrationPage = () => {
    return (
        <div className={"h-full"}>
            <ActivateIntegrationProvider>
                <HeadingTitle title={"Available Integrations"} />
                <AddIntegrationsContainer />
            </ActivateIntegrationProvider>
        </div>
    );
};

export default withRole(AddIntegrationPage, ["admin"]);
