import dayjs from "dayjs";

/**
 * @param from
 * @param to
 * @returns {number}
 */
export function getDaysBetweenDates(from, to) {
    if (!from || !to) return null;
    const startDate = dayjs(from);
    const endDate = dayjs(to);
    return endDate.diff(startDate, "day");
}

export const handleDisabledDates = (from, to) => {
    if (!from || !to) return null;
    let fromDate = dayjs(from);
    let toDate = dayjs(to);
    let days = [];

    // Loop through each day between from and to
    while (fromDate.isBefore(toDate) || fromDate.isSame(toDate, "day")) {
        days.push(fromDate.format("YYYY-MM-DD"));
        fromDate = fromDate.add(1, "day");
    }

    return days;
};

/**
 * @param data contains datetime from database
 * @returns {string} formated only date
 */
export function formatDate(data, divider = ".") {
    const date = new Date(data);
    const month =
        date.getMonth() + 1 < 10
            ? "0" + (date.getMonth() + 1)
            : date.getMonth() + 1;
    return `${date.getDate()}${divider}${month}${divider}${date.getFullYear()}`;
}

/**
 * @param data
 * @param divider
 * @returns {string}
 */
export function formatObjectDate(data = {}, divider = "/") {
    return `${data.year}${divider}${data.month}${divider}${data.day}`;
}

/**
 * @param date
 * @returns {{month: number, year: number, day: number}}
 */
export function toObjectDate(date) {
    const [year, month, day] = date.split("-");
    return {
        year: parseInt(year),
        month: parseInt(month),
        day: parseInt(day),
    };
}

/**
 * @param daysToAdd
 * @returns {string}
 */
export function getDateAfterDays(daysToAdd = undefined) {
    if (daysToAdd === undefined) {
        // Return today's date if no number is provided
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, "0");
        const day = String(today.getDate()).padStart(2, "0");
        return `${year}-${month}-${day}`;
    }
    const today = new Date();
    const futureDate = new Date(today);
    futureDate.setDate(today.getDate() + daysToAdd);

    const year = futureDate.getFullYear();
    const month = String(futureDate.getMonth() + 1).padStart(2, "0");
    const day = String(futureDate.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
}

/**
 * @returns {`${string}-${string}-${string} ${string}:${string}:${string}`}
 */
export const getCurrentDateTime = () => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");
    const hours = String(currentDate.getHours()).padStart(2, "0");
    const minutes = String(currentDate.getMinutes()).padStart(2, "0");
    const seconds = String(currentDate.getSeconds()).padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};

/**
 *
 * @param date
 * @return {number}
 */
export const getCurrentDateDifference = (date) => {
    const currentDate = new Date();
    const dateTo = new Date(date);
    if (currentDate < dateTo) {
        const timeDifference = dateTo - currentDate;
        return Math.ceil(timeDifference / (1000 * 60 * 60 * 24)); // Calculate days
    }
    return undefined;
};
