import React from "react";
import AddEmployeForm from "./components/AddEmployeForm";

const AddEmployee = () => {
    return (
        <div className="add-employee-container">
            <AddEmployeForm />
        </div>
    );
};

export default AddEmployee;
