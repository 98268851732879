import React from "react";
import QrCodeReservationContainer from "Containers/Public/QrCodeReservationContainer";

const QrCodeReservationPage = () => {
    return (
        <div>
            <QrCodeReservationContainer />
        </div>
    );
};

export default QrCodeReservationPage;
