import React from "react";
import CustomersContainer from "Containers/Customers/Customers/CustomersContainer";
import withRole from "Providers/AuthProvider/WithRole";

const CustomersPage = () => {
    return (
        <div className={"customers-page"}>
            <CustomersContainer />
        </div>
    );
};
export default withRole(CustomersPage, ["admin", "employee"]);
