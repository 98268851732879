// @ts-nocheck
import React, { useContext, useState } from "react";
import { Button, Divider, Input, Modal, Select } from "antd";
import { EditOutlined } from "@ant-design/icons";
import useLocations from "Hooks/LocationsHooks/useLocations";
import useCar from "Hooks/RentCarHooks/useCar";
import { SingleIntegrationContext } from "Context/Integrations/SingleIntegrationContext";
import useIntegrationConfig from "Hooks/IntegrationHooks/IntegrationConfig/useIntegrationConfig";
import { IntegrationConfigStatus } from "Types/IntegrationTypes";
import Label from "Components/Label";

interface UpdateConfigPropTypes {
    data: {
        car_sipp_code_id: string;
        location_id: string;
        config_id: string;
        description: string;
        default_price: number;
        insurance_price: number | null;
        deposit: number;
        availability: string | number;
        status: IntegrationConfigStatus;
    };
}
const UpdateConfigModal = ({ data }: UpdateConfigPropTypes) => {
    const formDataInitialValues = data;
    const { refresh } = useContext(SingleIntegrationContext);

    const { updateConfig } = useIntegrationConfig();
    const { getLocations } = useLocations();
    const { getCarSippCodes } = useCar();

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [okLoading, setOkLoading] = useState(false);

    const [locations, setLocations] = useState(null);
    const [sipp, setSipp] = useState(null);

    const [formData, setFormData] = useState(formDataInitialValues);

    const showModal = async () => {
        setIsModalOpen(true);
        setLoading(true);
        const [locations, sippCodes]: any = await Promise.all([
            getLocations(),
            getCarSippCodes(),
        ]).finally(() => {
            setLoading(false);
        });
        setLocations(locations);
        setSipp(sippCodes);
    };

    const handleOk = async () => {
        setOkLoading(true);
        await updateConfig(formData)
            .then((res) => {
                if (res) {
                    setIsModalOpen(false);
                    setFormData(formDataInitialValues);
                    refresh();
                }
            })
            .finally(() => {
                setOkLoading(false);
            });
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    return (
        <>
            <Button
                onClick={showModal}
                className={"h-[30px] w-[30px] flex items-center justify-center"}
                size={"middle"}
                title={"Edit"}
                type={"default"}
            >
                <EditOutlined />
            </Button>
            <Modal
                title="Update Rentelon API Configuration"
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                okText={"Update"}
                confirmLoading={okLoading}
            >
                <div className={"flex flex-col gap-3 mt-5 mb-5"}>
                    <div>
                        <Label title={"Select location"} />
                        <Select
                            value={formData?.location_id || undefined}
                            onChange={(value) =>
                                setFormData({ ...formData, location_id: value })
                            }
                            placeholder={"Select location ..."}
                            className={"w-full"}
                            // @ts-ignore
                            disabled={!locations?.length > 0}
                            loading={loading}
                            // @ts-ignore
                            options={locations?.map((location) => {
                                const { name, address, uuid } = location;
                                return {
                                    value: uuid,
                                    label: `${name} - ${address}`,
                                };
                            })}
                        />
                    </div>
                    <div>
                        <Label title={"Select Category"} />
                        <Select
                            value={formData?.car_sipp_code_id || undefined}
                            onChange={(value) =>
                                setFormData({
                                    ...formData,
                                    car_sipp_code_id: value,
                                })
                            }
                            placeholder={"Select category..."}
                            className={"w-full"}
                            // @ts-ignore
                            disabled={!sipp?.length > 0}
                            loading={loading}
                            // @ts-ignore
                            options={sipp?.map((item) => {
                                const { code, uuid, description } = item;
                                return {
                                    value: uuid,
                                    label: `${code} - ${description}`,
                                };
                            })}
                        />
                    </div>

                    <div>
                        <Label title={"Change description"} />
                        <Input
                            value={formData?.description || undefined}
                            onChange={(e) =>
                                setFormData({
                                    ...formData,
                                    description: e.target.value,
                                })
                            }
                            type={"text"}
                            placeholder={
                                "car description e.g passat or similiar"
                            }
                        />
                    </div>
                    <div>
                        <Label title={"Default price per day"} />
                        <Input
                            value={
                                parseInt(formData?.default_price) || undefined
                            }
                            onChange={(e) =>
                                setFormData({
                                    ...formData,
                                    default_price: e.target.value,
                                })
                            }
                            type={"number"}
                            placeholder={"Default price per day"}
                        />
                    </div>
                    <div>
                        <Label title={"Insurance price per day"} />
                        <Input
                            value={
                                parseInt(formData?.insurance_price) || undefined
                            }
                            onChange={(e) =>
                                setFormData({
                                    ...formData,
                                    insurance_price: e.target.value,
                                })
                            }
                            type={"text"}
                            placeholder={"Insurance Price per day"}
                        />
                    </div>
                    <div>
                        <Label title={"Deposit"} />
                        <Input
                            value={parseInt(formData?.deposit) || undefined}
                            onChange={(e) =>
                                setFormData({
                                    ...formData,
                                    deposit: e.target.value,
                                })
                            }
                            type={"number"}
                            placeholder={"Deposit"}
                        />
                    </div>
                    <div>
                        <Label title={"Availability"} />
                        <Input
                            value={
                                parseInt(formData?.availability) || undefined
                            }
                            onChange={(e) =>
                                setFormData({
                                    ...formData,
                                    availability: e.target.value,
                                })
                            }
                            type={"number"}
                            placeholder={"Availability"}
                        />
                    </div>
                    <div>
                        <Label title={"Status"} />
                        <Select
                            value={formData?.status || undefined}
                            onChange={(value) =>
                                setFormData({ ...formData, status: value })
                            }
                            placeholder={"Select status..."}
                            className={"w-full"}
                            options={[
                                {
                                    value: "ENABLED",
                                    label: "ENABLED",
                                },
                                {
                                    value: "DISABLED",
                                    label: "DISABLED",
                                },
                            ]}
                        />
                    </div>
                </div>
                <Divider />
            </Modal>
        </>
    );
};

export default UpdateConfigModal;
