import React from "react";
import AddRentCarForm from "./partials/AddRentCarForm";

const AddRentCar = () => {
    return (
        <div className="rent-cars-container p-1">
            <AddRentCarForm />
        </div>
    );
};

export default AddRentCar;
