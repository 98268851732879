import React from "react";
import { RentCarStatusTypes } from "Types/CarTypes";
import AvailableStatus from "./AvailableStatus";
import RentedStatus from "./RentedStatus";
import FinishedStatus from "./FinishedStatus";
import TodayStatus from "./TodayStatus";
import TomorrowStatus from "./TomorrowStatus";
import CustomerPickUpStatus from "../CustomerStatus/CustomerPickUpStatus";
import { getCurrentDateDifference } from "Utils/DateUtils";

interface RentalStatusHandlerProps {
    rentalStatus: RentCarStatusTypes;
    date?: string;
}
const RentalStatusHandler = ({
    rentalStatus,
    date,
}: RentalStatusHandlerProps) => {
    const handleStatus = () => {
        switch (rentalStatus) {
            case "AVAILABLE":
                return <AvailableStatus />;
            case "FINISHED":
                return <FinishedStatus />;
            case "RENTED":
                return <RentedStatus />;
            case "TODAY":
                return <TodayStatus />;
            case "TOMORROW":
                return <TomorrowStatus />;
            case "PICK_UP":
                const untilPickupDays = getCurrentDateDifference(date);
                return <CustomerPickUpStatus days={untilPickupDays} />;
            default:
                return null;
        }
    };
    return <>{handleStatus()}</>;
};

export default RentalStatusHandler;
