import React, { useContext } from "react";
import RentelonApiConfig from "./RentelonApiConfigurations/RentelonApiConfig";
import { SingleIntegrationContext } from "../../Context/Integrations/SingleIntegrationContext";

const IntegrationConfig = () => {
    const {
        integration: { integration_type, configs, uuid },
    } = useContext(SingleIntegrationContext);
    const IntegrationConfigHandler = () => {
        switch (integration_type) {
            case "RENTELON_API":
                return <RentelonApiConfig />;
            case "WOOCOMMERCE":
                return <></>;
            case "RENTELON_WEBHOOK":
                return <></>;
            default:
                return <></>;
        }
    };
    return <IntegrationConfigHandler />;
};

export default IntegrationConfig;
