import secureLocalStorage from "react-secure-storage";

const useCustomerAggrement = () => {
    const CUSTOMER_AGGREMENT_KEY = "customer_aggrement_settings";

    const initCustomerAggrementSettings = () => {
        const options = {
            showCreditCardInfo: true,
            showVehicleCondition: true,
            showTermsConditions: true,
            showQrCodeReservation: true,
        };
        if (!secureLocalStorage.getItem(CUSTOMER_AGGREMENT_KEY)) {
            secureLocalStorage.setItem(
                CUSTOMER_AGGREMENT_KEY,
                JSON.stringify(options),
            );
        }
    };

    const isCreditCardEnabled = () => {
        const value = JSON.parse(
            secureLocalStorage.getItem(CUSTOMER_AGGREMENT_KEY),
        );
        return value.showCreditCardInfo;
    };

    const isQrCodeReservationEnabled = () => {
        const value = JSON.parse(
            secureLocalStorage.getItem(CUSTOMER_AGGREMENT_KEY),
        );
        return value.showQrCodeReservation;
    };

    const isVehicleConditionEnabled = () => {
        const value = JSON.parse(
            secureLocalStorage.getItem(CUSTOMER_AGGREMENT_KEY),
        );
        return value.showVehicleCondition;
    };

    const isTermsConditionsEnabled = () => {
        const value = JSON.parse(
            secureLocalStorage.getItem(CUSTOMER_AGGREMENT_KEY),
        );
        return value.showTermsConditions;
    };

    const setCreditCardValue = (value) => {
        const currentValues = JSON.parse(
            secureLocalStorage.getItem(CUSTOMER_AGGREMENT_KEY),
        );
        currentValues.showCreditCardInfo = value;
        secureLocalStorage.setItem(
            CUSTOMER_AGGREMENT_KEY,
            JSON.stringify(currentValues),
        );
    };

    const setVehicleConditionValue = (value) => {
        const currentValues = JSON.parse(
            secureLocalStorage.getItem(CUSTOMER_AGGREMENT_KEY),
        );
        currentValues.showVehicleCondition = value;
        secureLocalStorage.setItem(
            CUSTOMER_AGGREMENT_KEY,
            JSON.stringify(currentValues),
        );
    };

    const setTermsConditionValue = (value) => {
        const currentValues = JSON.parse(
            secureLocalStorage.getItem(CUSTOMER_AGGREMENT_KEY),
        );
        currentValues.showTermsConditions = value;
        secureLocalStorage.setItem(
            CUSTOMER_AGGREMENT_KEY,
            JSON.stringify(currentValues),
        );
    };

    const setQrCodeFinishValue = (value) => {
        const currentValues = JSON.parse(
            secureLocalStorage.getItem(CUSTOMER_AGGREMENT_KEY),
        );
        currentValues.showQrCodeReservation = value;
        secureLocalStorage.setItem(
            CUSTOMER_AGGREMENT_KEY,
            JSON.stringify(currentValues),
        );
    };

    return {
        initCustomerAggrementSettings,
        isCreditCardEnabled,
        isVehicleConditionEnabled,
        isTermsConditionsEnabled,
        setCreditCardValue,
        setVehicleConditionValue,
        setTermsConditionValue,
        isQrCodeReservationEnabled,
        setQrCodeFinishValue,
    };
};

export default useCustomerAggrement;
