import React, { useState } from "react";
import { Button, Input, Modal, App } from "antd";

interface AdditionalDriverTypes {
    onAction?: React.Dispatch<React.SetStateAction<DriverStateTypes>>;
}
interface DriverStateTypes {
    driverName: string | null;
    driverLicence: string | null;
    price: number;
}
const AdditionalDriverOption = ({ onAction }: AdditionalDriverTypes) => {
    const { message } = App.useApp();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [driver, setDriver] = useState<DriverStateTypes>({
        driverName: null,
        driverLicence: null,
        price: 0,
    });

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        const { driverName, driverLicence, price } = driver;
        if (!driverName || !driverLicence || !price) {
            message.error("All fields are required.");
        } else {
            setIsModalOpen(false);
            if (onAction) {
                onAction(driver);
            }
            message.success("Added additional driver.");
        }
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    return (
        <div className={"sm:w-full"}>
            <Button className={"sm:w-full"} onClick={showModal}>
                Add Additional Driver
            </Button>
            <Modal
                title="Additional Driver"
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
            >
                <div className="flex flex-col gap-3">
                    <Input
                        placeholder="Drivers Name"
                        onChange={(name) =>
                            setDriver({
                                ...driver,
                                driverName: name.target.value,
                            })
                        }
                    />
                    <Input
                        placeholder="Drivers Licence"
                        onChange={(licence) =>
                            setDriver({
                                ...driver,
                                driverLicence: licence.target.value,
                            })
                        }
                    />
                    <Input
                        type={"number"}
                        placeholder="Price per/day"
                        onChange={(price) =>
                            setDriver({
                                ...driver,
                                price: parseInt(price.target.value),
                            })
                        }
                    />
                </div>
            </Modal>
        </div>
    );
};

export default AdditionalDriverOption;
