import React from "react";
import EditEmployeForm from "./components/EditEmployeForm";

const EditEmployee = () => {
    return (
        <div className="add-employee-container">
            <EditEmployeForm />
        </div>
    );
};

export default EditEmployee;
