import React, { useState } from "react";
import { Button, DatePicker, Divider, Input, Modal } from "antd";
import { EditOutlined } from "@ant-design/icons";
import useIntegrationConfig from "Hooks/IntegrationHooks/IntegrationConfig/useIntegrationConfig";
import dayjs from "dayjs";
import Label from "Components/Label";

interface CreatePeriodModalPropTypes {
    data: {
        period_id: string;
        date_from: string;
        date_to: string;
        price: string;
    };
    update: () => void;
}
const UpdatePeriodModal = ({ data, update }: CreatePeriodModalPropTypes) => {
    const { updatePeriod } = useIntegrationConfig();
    const initialFormData = data;
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [formData, setFormData] = useState(initialFormData);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = async () => {
        await updatePeriod(formData).then((res) => {
            if (res) {
                setIsModalOpen(false);
                update();
            }
        });
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    return (
        <>
            <Button icon={<EditOutlined />} onClick={showModal}></Button>
            <Modal
                title="Change Period"
                open={isModalOpen}
                onOk={handleOk}
                okText={"Update"}
                onCancel={handleCancel}
            >
                <small>
                    Update date ranges that will be handled in API and give
                    price depending on months.
                </small>
                <Divider />
                <div className={"flex flex-col gap-3"}>
                    <div className={"flex sm:flex-wrap gap-3"}>
                        <div className={"w-full"}>
                            <Label title={"From Date"} />
                            <DatePicker
                                className={"w-full"}
                                defaultValue={dayjs(initialFormData.date_from)}
                                placeholder={"From"}
                                onChange={(_, timestring) =>
                                    setFormData({
                                        ...formData,
                                        date_from: `${timestring} 00:00`,
                                    })
                                }
                            />
                        </div>
                        <div className={"w-full"}>
                            <Label title={"To Date"} />
                            <DatePicker
                                className={"w-full"}
                                defaultValue={dayjs(initialFormData.date_to)}
                                placeholder={"To"}
                                onChange={(_, timestring) =>
                                    setFormData({
                                        ...formData,
                                        date_to: `${timestring} 00:00`,
                                    })
                                }
                            />
                        </div>
                    </div>
                    <div>
                        <Label title={"Price"} />
                        <Input
                            placeholder={"Price"}
                            value={formData.price || undefined}
                            type={"text"}
                            onChange={(e) =>
                                setFormData({
                                    ...formData,
                                    price: e.target.value,
                                })
                            }
                        />
                    </div>
                </div>
                <Divider />
            </Modal>
        </>
    );
};

export default UpdatePeriodModal;
