import React, { useEffect, useState } from "react";
import RentCarCard from "Components/Card/RentCarCard";
import HeadingTitle from "Components/Headings/HeadingTitle";
import useCar from "Hooks/RentCarHooks/useCar";
import { useAuth } from "Hooks/AuthHooks/useAuth";
import LoadingPlaceholder from "Components/Placeholders/LoadingPlaceholder/LoadingPlaceholder";
import HorizontalDivider from "Components/HorizontalDivider/HorizontalDivider";
import FilterRentCars from "./components/FilterRentCars";
import { Divider, Modal, Pagination, Result, Button, Alert } from "antd";
import useCustomer from "../../../Hooks/CustomerHooks/useCustomer";
import { addPageQueryParam, getPageParam } from "../../../Helpers/PageHelper";
import { useNavigate } from "react-router-dom";
import MobileRentCarCard from "../../../Components/Card/MobileRentCarCard";
import { isMobile } from "../../../Utils/DeviceUtils";

const ManageRentCarContainer = () => {
    const { getCompanyCars, filterCars } = useCar();
    const { finishReservation } = useCustomer();
    const { isUserAdmin } = useAuth();
    const navigate = useNavigate();

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [companyCars, setCompanyCars] = useState(null);
    const [currentPage, setCurrentPage] = useState(getPageParam());
    const [lastPage, setLastPage] = useState(null);
    const [isSearch, setIsSearch] = useState(false);
    const [searchPage, setSearchPage] = useState(null);
    const [totalCars, setTotalCars] = useState(0);

    const [filterQuery, setFilterQuery] = useState("");

    const [loading, setLoading] = useState(false);
    const [customerId, setCustomerId] = useState(null);

    const fetchCars = async (page) => {
        const companyCars = await getCompanyCars(page);
        setCompanyCars(companyCars?.data);
        setTotalCars(companyCars?.total);
    };
    const showModal = (customerId) => {
        setIsModalOpen(true);
        setCustomerId(customerId);
    };
    const handleOk = async () => {
        setLoading(true);
        setIsModalOpen(false);
        // @ts-ignore
        await finishReservation(customerId, setLoading);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const handlePagination = (pageNo) => {
        setCurrentPage(pageNo);
    };

    useEffect(() => {
        if (!isSearch) {
            fetchCars(currentPage);
        } else {
            filterCars(filterQuery, currentPage)
                .then((cars) => {
                    setCurrentPage(cars?.current_page);
                    setCompanyCars(cars.data);
                })
                .catch((err) => console.log(err));
        }
        addPageQueryParam(currentPage);
    }, [loading, currentPage]);

    return (
        <div className="manage-car-container ">
            <FilterRentCars
                setQuery={setFilterQuery}
                actionSetCars={setCompanyCars}
                actionSearch={setIsSearch}
                actionSetCurrentPage={setCurrentPage}
                actionSetLastPage={setLastPage}
                actionPage={searchPage}
                actionTotalCars={setTotalCars}
                loading={setLoading}
                clearance={fetchCars}
            />
            <HeadingTitle title={"Cars List"} />
            {isSearch && (
                <div>
                    <div className={"pl-1 font-bold"}>
                        <p> {totalCars} CAR/S FOUND </p>
                    </div>
                    <HorizontalDivider classes={"mt-4 mb-5"} />
                </div>
            )}
            <div className="flex flex-col flex-wrap mb-6 gap-4 md:overflow-scroll">
                {!companyCars || loading ? (
                    <LoadingPlaceholder />
                ) : companyCars?.length > 0 ? (
                    companyCars.map((car) => {
                        return (
                            <div
                                className={"md:flex md:justify-center md:gap-2"}
                            >
                                {!isMobile() ? (
                                    <RentCarCard
                                        key={car.uuid}
                                        triggerModal={showModal}
                                        carData={car}
                                    />
                                ) : (
                                    <MobileRentCarCard
                                        carData={car}
                                        triggerModal={showModal}
                                        key={car.uuid}
                                    />
                                )}
                            </div>
                        );
                    })
                ) : !isSearch ? (
                    <Result
                        className={""}
                        title={"You do not have any cars yet added."}
                        subTitle={"Add cars to manage them."}
                        extra={
                            <Button
                                onClick={() => navigate("/add/car")}
                                type="primary"
                                key="console"
                            >
                                Add Vehicle
                            </Button>
                        }
                    />
                ) : (
                    <Alert
                        message={"There is no matching cars."}
                        description={
                            "We could not find any cars related to your search."
                        }
                        type="info"
                        showIcon
                    />
                )}
                ,
                <Divider />
                {companyCars?.length > 0 && (
                    <div className={"text-end"}>
                        <Pagination
                            onChange={handlePagination}
                            defaultPageSize={8}
                            defaultCurrent={getPageParam()}
                            total={totalCars}
                            showSizeChanger={false}
                        />
                    </div>
                )}
            </div>
            <Modal
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                title={"You are about to finish reservation?"}
                confirmLoading={loading}
                okText={"Yes"}
            >
                <p>
                    Are you sure you want to finish reservation for current
                    customer?
                </p>
            </Modal>
        </div>
    );
};

export default ManageRentCarContainer;
