import React from "react";
import { Descriptions, Tag } from "antd";
import type { DescriptionsProps } from "antd";
import { formatDate } from "Utils/DateUtils";

// @ts-ignore
const CustomerDateTime = ({ checkIn, checkOut, rentFrom, rentTo }) => {
    const dateTimeItems: DescriptionsProps["items"] = [
        {
            label: "Rented From",
            span: 2,
            children: (
                <Tag className={"font-bold"} color={"blue"}>
                    {formatDate(rentFrom, "/")}
                </Tag>
            ),
        },
        {
            label: "Rented To",
            span: 2,
            children: (
                <Tag className={"font-bold"} color={"blue"}>
                    {formatDate(rentTo, "/")}
                </Tag>
            ),
        },
        {
            label: "Pick Up",
            span: 2,
            children: (
                <Tag className={"font-bold"} color={"blue"}>
                    {checkIn}
                </Tag>
            ),
        },
        {
            label: "Return",
            span: 2,
            children: (
                <Tag className={"font-bold"} color={"blue"}>
                    {checkOut}
                </Tag>
            ),
        },
    ];
    return (
        <Descriptions
            className={"sm:overflow-scroll"}
            size={"small"}
            column={4}
            items={dateTimeItems}
            bordered
        />
    );
};

export default CustomerDateTime;
