import React from "react";
import { Tag, Tooltip } from "antd";

const CustomerFinishedStatus = () => {
    return (
        <Tag className={"pl-3 pr-3 pt-1 pb-1 font-bold"} color={"red"}>
            <Tooltip title={"Customer Status"}>FINISHED</Tooltip>
        </Tag>
    );
};
export default CustomerFinishedStatus;
