import { React, useContext } from "react";
import HorizontalDivider from "../HorizontalDivider/HorizontalDivider";
import { useLocation } from "react-router-dom";
import useCompany from "../../Hooks/CompanyHooks/useCompany";
import { getCarColor } from "../../Utils/CarUtils";
import { formatDate, getCurrentDateTime } from "../../Utils/DateUtils";
import PdfContext from "../../Context/PdfContext";
import PdfVehicleConditions from "./PdfVehicleConditions";
import PdfTermsConditions from "./PdfTermsConditions";
import CreditCardAggrement from "./CreditCardAggrement";
import QrCodeReservationPdf from "./QrCodeReservationPdf";
import { Avatar, QRCode, Tag } from "antd";
import TypeReservationHandler from "../TypeReservation/TypeReservationHandler";
import { useTranslation } from "react-i18next";

const CustomerAgreement = () => {
    const { drawer, pdfOptions, companyContactInput } = useContext(PdfContext);
    const { t } = useTranslation();
    const {
        vehicleCondition,
        creditCard,
        termsConditions,
        pricesOptions,
        aggrementInfo,
        qrCodeFinishReservation,
        legalDocuments,
    } = pdfOptions;

    const {
        addDriverPrice,
        crossingBorderPrice,
        babySeatPrice,
        damageExcess,
        gpsPrice,
        depositPrice,
        totalRentPrice,
        insurancePrice,
        totalPrice,
    } = pricesOptions?.data;

    const { billNumber } = aggrementInfo;

    const { state } = useLocation();
    const { getCompanySettings } = useCompany();
    const company = getCompanySettings();

    const {
        check_in,
        check_out,
        from,
        to,
        deposit_price,
        insurance_price,
        insurance_type,
        total_price,
        rent_price,
        damage_excess,
        total_days,
        crossing_border_list,
        crossing_border_price,
        additional_driver_price,
        gps_price,
        baby_seat_price,
        additional_drivers_list,
        car,
        customer,
        id,
        qr_code_reservation,
    } = state.data;

    const companyLocation = car.company_location[0];

    const { car_color, car_name, category, car_licence_number } = car;

    const {
        customer_name,
        customer_passport,
        customer_driving_licence,
        customer_country,
        reservationType,
        reservationTypeReference,
    } = customer[0];

    const calcInsurance =
        parseFloat(insurance_price.replace(",", "")) * parseInt(total_days);
    const totalInsurance = calcInsurance.toFixed(2);
    return (
        <div className={"customer-agreement"}>
            <div className={"main-document-agreement p-6"}>
                <div className={"flex justify-between"}>
                    <h1 className={"text-[14px]"}>
                        <span className={"font-bold uppercase"}>
                            {t("rentalAgreement")}
                        </span>{" "}
                        | {formatDate(getCurrentDateTime(), "/")}
                    </h1>
                    <h1 className={"text-[11px]"}>
                        <span className={"font-bold uppercase mr-5"}>
                            {t("billNo")}: {billNumber}
                        </span>{" "}
                        <span className={"font-bold uppercase"}>
                            {t("rentalAgreementNumber")}: {id}
                        </span>
                    </h1>
                </div>
                <HorizontalDivider classes={"my-2"} />
                {/*CUSTOMER INFO*/}
                <div className={"grid grid-cols-2"}>
                    <div className={"alda"}>
                        <div>
                            <div
                                className={
                                    "flex items-center justify-start mt-2"
                                }
                            >
                                <div className={"mr-5"}>
                                    {/* @ts-ignore */}
                                    <Avatar
                                        size={135}
                                        icon={
                                            <img
                                                src={
                                                    company.company_image
                                                        ? process.env
                                                              .REACT_APP_DOMAIN +
                                                          company.company_image
                                                        : process.env
                                                              .REACT_APP_IMAGE_FALLBACK
                                                }
                                            />
                                        }
                                        shape={"square"}
                                    />
                                </div>
                                <div
                                    className={
                                        "w-[555px] h-[135px] flex justify-between border rounded-md border-[#9b9b9b] p-2"
                                    }
                                >
                                    <div>
                                        <div className={"flex"}>
                                            <p className={"sm:w-24 w-36"}>
                                                {" "}
                                                {t("company")}:{" "}
                                            </p>
                                            <p>{company.name}</p>
                                        </div>
                                        <div className={"flex"}>
                                            <p className={"sm:w-24 w-36"}>
                                                {" "}
                                                {t("address")}:{" "}
                                            </p>
                                            <p>{companyLocation.address}</p>
                                        </div>
                                        <div className={"flex"}>
                                            <p className={"sm:w-24 w-36"}>
                                                {" "}
                                                {t("city")}:{" "}
                                            </p>
                                            <p>{companyLocation.city}</p>
                                        </div>
                                        <div className={"flex"}>
                                            <p className={"sm:w-24 w-36"}>
                                                {t("operatorName")}:{" "}
                                            </p>
                                            <p>
                                                {
                                                    companyContactInput
                                                        .selectedContact?.name
                                                }
                                            </p>
                                        </div>
                                        <div className={"flex"}>
                                            <p className={"sm:w-24 w-36"}>
                                                {t("phone")}:{" "}
                                            </p>
                                            <p>
                                                {
                                                    companyContactInput
                                                        .selectedContact?.phone
                                                }
                                            </p>
                                        </div>
                                        <div className={"flex"}>
                                            <p className={"sm:w-24 w-36"}>
                                                {t("email")}:{" "}
                                            </p>
                                            <p>
                                                {
                                                    companyContactInput
                                                        .selectedContact?.email
                                                }
                                            </p>
                                        </div>
                                    </div>
                                    <div>
                                        <QRCode
                                            bordered={false}
                                            size={120}
                                            type="svg"
                                            value={companyLocation.qrcode_value}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className={
                                "cv-container flex items-center justify-between mt-3"
                            }
                        >
                            <div>
                                <h3
                                    className={
                                        "font-bold uppercase text-[12px]"
                                    }
                                >
                                    {t("customer")}
                                </h3>
                                <div
                                    className={
                                        "w-[310px] ] border rounded-md border-[#9b9b9b] p-2"
                                    }
                                >
                                    <div className={"flex"}>
                                        <p className={"w-32"}>{t("name")}: </p>
                                        <p>{customer_name}</p>
                                    </div>
                                    <div className={"flex"}>
                                        <p className={"w-32"}>
                                            {t("country")}:{" "}
                                        </p>
                                        <p className={"uppercase"}>
                                            {customer_country}
                                        </p>
                                    </div>
                                    <div className={"flex"}>
                                        <p className={"w-32"}>
                                            {t("passport")}:{" "}
                                        </p>
                                        <p>{customer_passport}</p>
                                    </div>
                                    <div className={"flex"}>
                                        <p className={"w-32"}>
                                            {t("drivingLicence")}:{" "}
                                        </p>
                                        <p>{customer_driving_licence}</p>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <h3
                                    className={
                                        "font-bold uppercase text-[12px]"
                                    }
                                >
                                    {t("vehicle")}
                                </h3>
                                <div
                                    className={
                                        "w-[310px] ] border rounded-md border-[#9b9b9b] p-2"
                                    }
                                >
                                    <div className={"flex"}>
                                        <p className={"w-32"}>
                                            {t("vehicle")}:{" "}
                                        </p>
                                        <p>{car_name}</p>
                                    </div>
                                    <div className={"flex"}>
                                        <p className={"w-32"}>
                                            {t("category")}:{" "}
                                        </p>
                                        <p>{category[0].category_name}</p>
                                    </div>
                                    <div className={"flex"}>
                                        <p className={"w-32"}>
                                            {t("registrationNumber")}:{" "}
                                        </p>
                                        <p>{car_licence_number}</p>
                                    </div>
                                    <div className={"flex"}>
                                        <p className={"w-32"}>{t("color")}: </p>
                                        <p>{getCarColor(car_color)}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className={
                                "cv-container flex items-center justify-between mt-3"
                            }
                        >
                            <div>
                                <h3
                                    className={
                                        "font-bold uppercase text-[12px]"
                                    }
                                >
                                    {t("pickUp")}
                                </h3>
                                <div
                                    className={
                                        "w-[310px] ] border rounded-md border-[#9b9b9b] p-2"
                                    }
                                >
                                    <div className={"flex"}>
                                        <p className={"w-32"}>{t("date")}: </p>
                                        <p>{formatDate(from, "/")}</p>
                                    </div>
                                    <div className={"flex"}>
                                        <p className={"w-32"}>{t("time")}: </p>
                                        <p>{check_in}</p>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <h3
                                    className={
                                        "font-bold uppercase text-[12px]"
                                    }
                                >
                                    {t("return")}
                                </h3>
                                <div
                                    className={
                                        "w-[310px] border rounded-md border-[#9b9b9b] p-2"
                                    }
                                >
                                    <div className={"flex"}>
                                        <p className={"w-32"}>{t("date")}: </p>
                                        <p>{formatDate(to, "/")}</p>
                                    </div>
                                    <div className={"flex"}>
                                        <p className={"w-32"}>{t("time")}: </p>
                                        <p>{check_out}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {reservationType !== "DEFAULT" && (
                            <div
                                className={
                                    "w-full mt-4 flex gap-5 items-center"
                                }
                            >
                                <TypeReservationHandler
                                    type={reservationType}
                                    size={150}
                                />
                                <Tag className={"font-bold"} color={"gray"}>
                                    {" "}
                                    {reservationTypeReference}
                                </Tag>
                            </div>
                        )}
                        {crossing_border_list?.length > 0 && (
                            <div>
                                <HorizontalDivider classes={"mt-3 mb-3"} />
                                <p className={"text-[12px]"}>
                                    {t("crossingBorders")}
                                    {crossing_border_list.map((border) => {
                                        return (
                                            <span
                                                style={{ borderColor: "#000" }}
                                                className={"ml-2 mr-2 border-b"}
                                            >
                                                {border},
                                            </span>
                                        );
                                    })}
                                </p>
                            </div>
                        )}
                        {additional_drivers_list?.length > 0 && (
                            <div className={"mb-3"}>
                                <HorizontalDivider classes={"mt-3 mb-3"} />
                                <div
                                    className={
                                        "flex items-center flex-wrap gap-2"
                                    }
                                >
                                    <p className={"text-[12px]"}>
                                        {t("additionalDrivers")}{" "}
                                    </p>
                                    {additional_drivers_list.map((driver) => {
                                        const { driverName, driverLicence } =
                                            driver;
                                        return (
                                            <p
                                                style={{ borderColor: "#000" }}
                                                className={
                                                    "text-[12px] border-b pl-1 pr-1"
                                                }
                                            >
                                                {driverName} | {driverLicence}
                                            </p>
                                        );
                                    })}
                                </div>
                            </div>
                        )}
                        <HorizontalDivider classes={"mt-3 mb-3"} />
                        <div
                            className={
                                "flex flex-col basis-4/12 relative  pt-2"
                            }
                        >
                            {addDriverPrice && (
                                <div
                                    className={
                                        "flex justify-between w-full border-b py-1"
                                    }
                                >
                                    <p
                                        className={
                                            "text-[10px] font-bold uppercase w-[150px] text-start pl-1"
                                        }
                                    >
                                        {t("additionalDriversTotal")}
                                    </p>
                                    <p
                                        className={
                                            "text-[10px] font-bold uppercase"
                                        }
                                    >
                                        {parseFloat(additional_driver_price) *
                                            total_days *
                                            (additional_drivers_list?.length > 0
                                                ? additional_drivers_list?.length
                                                : 1) || "0.0"}{" "}
                                        {companyLocation.currency}
                                    </p>
                                </div>
                            )}
                            {crossingBorderPrice && (
                                <div
                                    className={
                                        "flex justify-between  w-full border-b py-1"
                                    }
                                >
                                    <p
                                        className={
                                            "text-[10px] font-bold uppercase w-[150px] text-start pl-1"
                                        }
                                    >
                                        {t("crossingBordersTotal")}
                                    </p>
                                    <p
                                        className={
                                            "text-[10px] font-bold uppercase"
                                        }
                                    >
                                        {parseInt(crossing_border_price) ||
                                            "0.0"}{" "}
                                        {companyLocation.currency}
                                    </p>
                                </div>
                            )}
                            {babySeatPrice && (
                                <div
                                    className={
                                        "flex justify-between w-full border-b py-1"
                                    }
                                >
                                    <p
                                        className={
                                            "text-[10px] font-bold uppercase w-[150px] text-start pl-1"
                                        }
                                    >
                                        {t("babySeatTotal")}
                                    </p>
                                    <p
                                        className={
                                            "text-[10px] font-bold uppercase "
                                        }
                                    >
                                        {parseFloat(baby_seat_price) *
                                            parseInt(total_days) || "0.0"}{" "}
                                        {companyLocation.currency}
                                    </p>
                                </div>
                            )}
                            {gpsPrice && (
                                <div
                                    className={
                                        "flex justify-between w-full border-b py-1"
                                    }
                                >
                                    <p
                                        className={
                                            "text-[10px] font-bold uppercase w-[150px] text-start pl-1"
                                        }
                                    >
                                        {t("gpsTotal")}
                                    </p>
                                    <p
                                        className={
                                            "text-[10px] font-bold uppercase"
                                        }
                                    >
                                        {parseFloat(gps_price) *
                                            parseInt(total_days) || "0.0"}{" "}
                                        {companyLocation.currency}
                                    </p>
                                </div>
                            )}
                            {insurancePrice && (
                                <div
                                    className={
                                        "flex justify-between w-full border-b py-1"
                                    }
                                >
                                    <p
                                        className={
                                            "text-[10px] font-bold uppercase w-[150px] text-start pl-1"
                                        }
                                    >
                                        {t("insurance")} <br></br>
                                        {insurance_type === "PREMIUM"
                                            ? t("insurancePremiumCoverage")
                                            : insurance_type === "SCDW" &&
                                              t("insuranceSuperCoverage")}
                                    </p>
                                    <p
                                        className={
                                            "text-[10px] font-bold uppercase"
                                        }
                                    >
                                        {totalInsurance || "0.0"}{" "}
                                        {companyLocation.currency}
                                    </p>
                                </div>
                            )}
                            {totalRentPrice && (
                                <div
                                    className={
                                        "flex justify-between w-full pt-1"
                                    }
                                >
                                    <p
                                        className={
                                            "text-[10px] font-bold uppercase w-[150px] text-start pl-1"
                                        }
                                    >
                                        {t("rentTotal")}
                                    </p>
                                    <p
                                        className={
                                            "text-[10px] font-bold uppercase"
                                        }
                                    >
                                        {(
                                            parseFloat(rent_price) *
                                            parseInt(total_days)
                                        ).toFixed(2) || "0.0"}{" "}
                                        {companyLocation.currency}
                                    </p>
                                </div>
                            )}

                            {totalPrice && (
                                <>
                                    <HorizontalDivider classes={"mt-2 mb-2"} />
                                    <div
                                        className={
                                            "flex justify-between w-full"
                                        }
                                    >
                                        <p
                                            className={
                                                "text-[14px] font-bold uppercase text-start pl-1 pl-1 pb-1"
                                            }
                                        >
                                            {t("total")}
                                        </p>
                                        <p
                                            className={
                                                "text-[14px] font-bold uppercase"
                                            }
                                        >
                                            {total_price}{" "}
                                            {companyLocation.currency}
                                        </p>
                                    </div>
                                </>
                            )}
                            {damageExcess && (
                                <>
                                    <HorizontalDivider classes={"mt-2 mb-2"} />
                                    <div
                                        className={
                                            "flex justify-between w-full border-b py-1"
                                        }
                                    >
                                        <p
                                            className={
                                                "text-[10px] font-bold uppercase w-[150px] text-start pl-1"
                                            }
                                        >
                                            {t("damageExcess")}
                                        </p>
                                        <p
                                            className={
                                                "text-[10px] font-bold uppercase"
                                            }
                                        >
                                            {damage_excess || "0.0"}{" "}
                                            {companyLocation.currency}
                                        </p>
                                    </div>
                                </>
                            )}
                            {depositPrice && (
                                <>
                                    <div
                                        className={
                                            "flex justify-between w-full p-0 pt-1"
                                        }
                                    >
                                        <p
                                            className={
                                                "text-[10px] font-bold uppercase text-start pl-1 py-1"
                                            }
                                        >
                                            {t("deposit")}
                                        </p>
                                        <p
                                            className={
                                                "text-[10px] font-bold uppercase"
                                            }
                                        >
                                            {deposit_price || "0.0"}{" "}
                                            {companyLocation.currency}
                                        </p>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>

                    {/*TEKST*/}
                    <div className={"px-8"}>
                        <div className={"p-2 basis-8/12 pl-0"}>
                            <p
                                className={"text-[10px] ql-editor legal p-0 "}
                                dangerouslySetInnerHTML={{
                                    __html:
                                        legalDocuments?.selected
                                            ?.legal_information || "",
                                }}
                            ></p>
                            {creditCard.enabled && legalDocuments.selected && (
                                <>
                                    <CreditCardAggrement value={""} />
                                </>
                            )}
                            <p className={"text-[10px]"}>
                                {t("termsConditions")}
                            </p>
                        </div>
                    </div>
                </div>

                <HorizontalDivider classes={"mb-3"} />
                <div className={"flex justify-between"}>
                    <div>
                        <p>{t("agencySignature")}</p>
                        <HorizontalDivider classes={"mt-7"} />
                    </div>
                    <div>
                        <p>{t("customerSignature")}</p>
                        <HorizontalDivider classes={"mt-7"} />
                    </div>
                </div>
            </div>
            {vehicleCondition.enabled && (
                <PdfVehicleConditions
                    carName={car_name}
                    licencePlate={car_licence_number}
                    from={formatDate(from, "/")}
                    to={formatDate(to, "/")}
                />
            )}
            {termsConditions.enabled && legalDocuments.selected && (
                <PdfTermsConditions
                    value={legalDocuments?.selected?.terms_conditions}
                />
            )}
            {qrCodeFinishReservation.enabled && (
                <QrCodeReservationPdf
                    link={qr_code_reservation?.link}
                    secretKey={qr_code_reservation?.secretKey}
                />
            )}
        </div>
    );
};

export default CustomerAgreement;
