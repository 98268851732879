import React from "react";
import { Tag, Tooltip } from "antd";

const CustomerRentingStatus = () => {
    return (
        <Tag className={"pl-3 pr-3 pt-1 pb-1 font-bold"} color={"orange"}>
            <Tooltip title={"Customer Status"}>RENTING</Tooltip>
        </Tag>
    );
};
export default CustomerRentingStatus;
