import React, { Dispatch, SetStateAction, useState, useEffect } from "react";
import { formatObjectDate } from "Utils/DateUtils";
import useCustomer from "Hooks/CustomerHooks/useCustomer";
import Button from "Components/Button";
import HeadingTitle from "Components/Headings/HeadingTitle";
import { isUserAdmin } from "Utils/UserUtils";
import { SearchOutlined } from "@ant-design/icons";
import { Input, DatePicker, Select } from "antd";
import useLocations from "Hooks/LocationsHooks/useLocations";

interface CustomerFilterProps {
    setCustomerData: Dispatch<SetStateAction<any>>;
    currentPage: number;
    filtersActive: any;
    setFiltersActive: any;
}

interface SelectedDateTypes {
    day: number;
    month: number;
    year: number;
}

const CustomerFilter: React.FC<CustomerFilterProps> = ({
    setCustomerData,
    currentPage,
    filtersActive,
    setFiltersActive,
}) => {
    const { searchCustomer, getCustomers } = useCustomer();
    const { getLocations } = useLocations();

    const [selectedDate, setSelectedDate] = useState<SelectedDateTypes>();
    const [defaultSelectedDate, setDefaultSelectedDate] = useState(null);
    const [carName, setCarName] = useState<any>();
    const [licencePlate, setLicencePlate] = useState<string>();
    const [customerName, setCustomerName] = useState<string>();
    const [locations, setLocations] = useState(null);
    const [searchLocation, setSearchLocation] = useState(null);
    const [rentalAggrementNo, setRentalAggrementNo] = useState<string>();

    // Create a search query string
    const buildSearchQuery = async (locationId: string | null = null) => {
        setFiltersActive(true);
        setCustomerData(null);
        const query = new URLSearchParams();

        if (locationId) {
            query.append("locationId", locationId);
        }

        if (!locationId && searchLocation) {
            query.append("locationId", searchLocation);
        }

        if (rentalAggrementNo) {
            query.append("rentalId", rentalAggrementNo);
        }

        if (carName) {
            query.append("carName", carName);
        }

        if (customerName) {
            query.append("customerName", customerName);
        }

        if (selectedDate) {
            const formatedDate: string = formatObjectDate(selectedDate, "-");
            query.append("selectedDate", formatedDate);
        }

        if (licencePlate) {
            query.append("licencePlate", licencePlate);
        }
        const customers = await searchCustomer(query.toString(), currentPage);
        setCustomerData(customers);
    };

    // Clear all filters
    const clearFilters = async () => {
        // reset all filters and customers search parameters
        setCustomerData(null);
        setFiltersActive(false);
        // get customers
        const customers = await getCustomers();
        setCustomerData(customers);
        setDefaultSelectedDate(null);
        setCarName(null);
        setSearchLocation(null);
        setRentalAggrementNo(undefined);
    };

    const fetchLocationsData = async () => {
        const locations = await getLocations();
        setLocations(locations);
    };

    const handleLocationChange = (uuid: string) => {
        // @ts-ignore
        setSearchLocation(uuid);
        buildSearchQuery(uuid);
    };

    useEffect(() => {
        if (isUserAdmin()) {
            fetchLocationsData();
        }
        if (filtersActive) {
            buildSearchQuery();
        }
    }, [filtersActive, currentPage]);

    const handleSelectedDate = (date: any) => {
        setSelectedDate({
            day: date.$D,
            month: date.$M + 1,
            year: date.$y,
        });
        setDefaultSelectedDate(date);
    };

    return (
        <div className={"customer-filter w-full"}>
            {isUserAdmin() && (
                <div className={"flex gap-2 items-center"}>
                    <HeadingTitle title={"LOCATION"} />
                    <Select
                        value={searchLocation}
                        onChange={handleLocationChange}
                        placeholder={"Select location..."}
                        className={"sm:w-full w-[250px]"}
                        // @ts-ignore
                        disabled={!locations?.length > 0}
                        // @ts-ignore
                        options={locations?.map((location) => {
                            const { name, address, uuid } = location;
                            return {
                                value: uuid,
                                label: `${name} - ${address}`,
                            };
                        })}
                    />
                </div>
            )}
            <div className={"flex flex-wrap gap-3 w-full"}>
                <HeadingTitle title={"Filters"} />
                <div className={"md-tablet:mr-2 sm:mb-2 sm:w-full"}>
                    <Input
                        value={rentalAggrementNo}
                        onChange={({ target: { value } }) =>
                            setRentalAggrementNo(value)
                        }
                        type="number"
                        placeholder="Rental Aggrement No."
                        className="input w-full "
                    />
                </div>
                <div className={"md-tablet:mr-2 sm:mb-2 sm:w-full"}>
                    <Input
                        value={customerName}
                        onChange={({ target: { value } }) =>
                            setCustomerName(value)
                        }
                        type="text"
                        placeholder="Customer name..."
                        className="input w-full "
                    />
                </div>
                <div className={"sm:w-full"}>
                    <Input
                        value={carName}
                        onChange={({ target: { value } }) => setCarName(value)}
                        type="text"
                        placeholder="Car name..."
                        className="input w-full "
                    />
                </div>
                <div className={"sm:w-full"}>
                    <Input
                        value={licencePlate}
                        onChange={({ target: { value } }) =>
                            setLicencePlate(value)
                        }
                        type="text"
                        placeholder="Licence plate number..."
                        className="input w-full"
                    />
                </div>
                <DatePicker
                    onChange={handleSelectedDate}
                    value={defaultSelectedDate}
                    className="cursor-pointer sm:w-full"
                />
                <Button
                    onClick={() => buildSearchQuery()}
                    buttonType="primary"
                    className="w-28 sm:w-full"
                >
                    Search <SearchOutlined />
                </Button>
                {filtersActive && (
                    <Button className="w-28" onClick={clearFilters}>
                        Clear Filters
                    </Button>
                )}
            </div>
        </div>
    );
};

export default CustomerFilter;
