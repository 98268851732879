import React from "react";
import CoomingSoon from "../../Components/CoomingSoon";

const DevelopersContainer = () => {
    return (
        <div className="developers-container">
            <CoomingSoon />
        </div>
    );
};

export default DevelopersContainer;
