import React, { useState } from "react";
import { IntegrationPeriod } from "Types/IntegrationTypes";
import { Button, Divider, Modal } from "antd";
import { FieldTimeOutlined } from "@ant-design/icons";
import PeriodList from "../../Listing/PeriodList";
import CreatePeriodModal from "./CreatePeriodModal";
import useIntegrationConfig from "Hooks/IntegrationHooks/IntegrationConfig/useIntegrationConfig";

interface PeriodListingModalPropTypes {
    periods: IntegrationPeriod[];
    configurationId: string;
    currency: string;
}
const PeriodListingModal = ({
    periods,
    configurationId,
    currency,
}: PeriodListingModalPropTypes) => {
    const { getPeriodByConfigId } = useIntegrationConfig();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [statePeriods, setStatePeriods] = useState(periods);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const updatePeriods = async () => {
        const { periods } = await getPeriodByConfigId(configurationId);
        setStatePeriods(periods);
    };

    return (
        <>
            <Button
                onClick={() => showModal()}
                className={"h-[30px] w-[30px] flex items-center justify-center"}
                title={"Add Period"}
                type={"primary"}
            >
                <FieldTimeOutlined />
            </Button>
            <Modal
                title="Price Periods"
                open={isModalOpen}
                okButtonProps={{ className: "hidden" }}
                onCancel={handleCancel}
                width={1000}
            >
                <div className={"text-end"}>
                    <CreatePeriodModal
                        update={updatePeriods}
                        configId={configurationId}
                    />
                </div>
                <Divider />
                <PeriodList
                    currency={currency}
                    update={updatePeriods}
                    periods={statePeriods}
                />
                <Divider />
            </Modal>
        </>
    );
};

export default PeriodListingModal;
