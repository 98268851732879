import React, { createContext, ReactNode, useEffect, useState } from "react";
import useReservation from "../../Hooks/ReservationHooks/useReservation";
import { ReservationTypes } from "../../Types/ReservationTypes";
import { addPageQueryParam, getPageParam } from "../../Helpers/PageHelper";

interface ReservationProviderInterface {
    children: ReactNode;
}

interface ReservationContextInterface {
    reservations: ReservationTypes[];
    curPage: number;
    totalPerPage: number;
    setCurPage: React.Dispatch<number>;
    setTotalPerPage: React.Dispatch<number>;
    loading: boolean;
    fetchReservations: () => void;
}

// @ts-ignore
const ReservationContext = createContext<ReservationContextInterface>();

const ReservationProvider = ({ children }: ReservationProviderInterface) => {
    const { getReservations } = useReservation();

    const [reservations, setReservations] = useState<ReservationTypes[]>([]);
    const [curPage, setCurPage] = useState(getPageParam());
    const [totalPerPage, setTotalPerPage] = useState(0);
    const [loading, setLoading] = useState(false);

    const fetchReservations = async () => {
        setLoading(true);
        const reservations = await getReservations(curPage).finally(() => {
            setLoading(false);
        });
        // @ts-ignore
        setReservations(reservations?.data);
        setTotalPerPage(reservations?.total);
    };
    return (
        <ReservationContext.Provider
            value={{
                reservations,
                curPage,
                setCurPage,
                totalPerPage,
                setTotalPerPage,
                loading,
                fetchReservations,
            }}
        >
            {children}
        </ReservationContext.Provider>
    );
};

export { ReservationProvider, ReservationContext };
