import React, { useContext } from "react";
import { CarHistoryType } from "Types/CarHistoryTypes";
import HeadingTitle from "Components/Headings/HeadingTitle";
import { Button, Pagination, Space, Table, Tag } from "antd";
import type { ColumnsType } from "antd/es/table";
import { getPageParam } from "Helpers/PageHelper";
import { CloudDownloadOutlined } from "@ant-design/icons";
import { CarHistoryContext } from "Context/History/CarHistoryContext";
import { formatDate } from "Utils/DateUtils";
import moment from "moment";
import useCarHistory from "../../../../Hooks/History/useCarHistory";

interface DataType {
    key: string;
    carName: string;
    carLicencePlate: string;
    permaDeletion: string;
    deletedAt: string;
    action: (carHistoryId: string, pdfName: string) => void;
}
interface CarHistoryDataTableProps {
    data: CarHistoryType[];
    pagination: {
        current: number;
        total: number;
    };
}

const columns: ColumnsType<DataType> = [
    {
        title: "Car Name",
        dataIndex: "carName",
        key: "carName",
    },
    {
        title: "Licence Plate",
        dataIndex: "carLicencePlate",
        key: "carLicencePlate",
    },
    {
        title: "Deleted At",
        dataIndex: "deletedAt",
        key: "deleted",
        render: (_, record) => (
            <Space size="middle">
                <Tag className={"font-bold"} color={"red"}>
                    {record.deletedAt}
                </Tag>
            </Space>
        ),
    },
    {
        title: "Permament Deletion",
        dataIndex: "permaDeletion",
        key: "permaDeletion",
    },
    {
        title: "Action",
        key: "action",
        className: "text-center",
        render: (_, record) => {
            return (
                <Space size="middle">
                    <Button
                        onClick={() =>
                            record.action(
                                record.key,
                                `${record.carName}_${record.carLicencePlate}`,
                            )
                        }
                        icon={<CloudDownloadOutlined />}
                        type={"primary"}
                    >
                        Download
                    </Button>
                </Space>
            );
        },
    },
];

const CarHistoryDataTable = () => {
    const {
        setPageConfig,
        carHistories,
        pageConfig: { total },
    } = useContext(CarHistoryContext);

    const { downloadCarHistory } = useCarHistory();
    const handlePagination = (pageNo: number) => {
        setPageConfig({
            total,
            current: pageNo,
        });
    };

    const handleDownloadCarHistory = async (
        carHistoryId: string,
        pdfName: string,
    ) => {
        return await downloadCarHistory(carHistoryId, pdfName);
    };

    return (
        <>
            <HeadingTitle title={"Car History List"} />
            <Table
                className={"md:overflow-auto"}
                columns={columns}
                pagination={false}
                dataSource={carHistories?.map((history) => {
                    const dayOfDeletion = moment(history.deleted).add(
                        "180",
                        "days",
                    );
                    return {
                        key: history.carHistoryId,
                        carName: history.carName,
                        carLicencePlate: history.carLicencePlate,
                        permaDeletion: `${dayOfDeletion
                            .diff(moment(), "days")
                            .toString()} day/s`,
                        deletedAt: formatDate(history?.deleted, "/"),
                        action: handleDownloadCarHistory,
                    };
                })}
            />
            <Pagination
                className={"mt-5 text-end"}
                onChange={handlePagination}
                defaultPageSize={8}
                defaultCurrent={getPageParam()}
                total={total}
                showSizeChanger={false}
            />
        </>
    );
};

export default CarHistoryDataTable;
