import React from "react";
import { Link } from "react-router-dom";
import { Button, Result } from "antd";

const NotFoundPage = () => {
    return (
        <div className="not-found-page center-full bg-white rounded-xl">
            <Result
                status="404"
                title="404"
                subTitle="Sorry, the page you visited does not exist."
                extra={
                    <Button type="primary">
                        <Link to={"/login"}>Back Home</Link>
                    </Button>
                }
            />
        </div>
    );
};

export default NotFoundPage;
