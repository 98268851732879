import React, { useState } from "react";
import { Avatar, Button, Drawer } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import AntSidebar from "../Sidebar/AntSidebar";
import { useSession } from "../../Hooks/SessionHooks/useSession";

const MobileMenu: React.FC = () => {
    const [open, setOpen] = useState(false);
    const { getUserCache } = useSession();

    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    return (
        <div
            className={"mdh-min:hidden m-3 rounded-md p-2 pt-3 bg-white w-full"}
        >
            <div className={"flex justify-between gap-2"}>
                <div className={"flex gap-3 items-center"}>
                    <Avatar
                        style={{
                            backgroundColor: "#1677ff",
                            verticalAlign: "middle",
                        }}
                    >
                        {getUserCache()?.firstname.substring(0, 1)}
                    </Avatar>
                    {`${getUserCache()?.firstname} ${getUserCache()?.lastname}`}
                </div>
                <MenuOutlined className={"text-[22px]"} onClick={showDrawer} />
            </div>
            <Drawer
                title="Menu"
                placement="left"
                bodyStyle={{ padding: "0px" }}
                onClose={onClose}
                width={285}
                open={open}
            >
                <AntSidebar isMenuOpen={setOpen} />
            </Drawer>
        </div>
    );
};

export default MobileMenu;
