import React, { useEffect, useState } from "react";
import { Divider, Button, Input, Image, Tag, Modal } from "antd";
import { FieldNumberOutlined } from "@ant-design/icons";
import useCustomer from "Hooks/CustomerHooks/useCustomer";
import { useParams } from "react-router-dom";
import LoadingPlaceholder from "Components/Placeholders/LoadingPlaceholder";
import TypeReservationHandler from "Components/TypeReservation/TypeReservationHandler";
import { TypeReservation } from "Types/CustomerTypes";
import { formatDate } from "../../Utils/DateUtils";

interface Car {
    name: string;
    image: string;
    carKilometers: string;
}

interface Customer {
    customerName: string;
    country: string;
}

interface Reservation {
    rentalId: string;
    pickUpDate: string;
    returnDate: string;
    pickupTime: string;
    returnTime: string;
    reservationType: TypeReservation;
    reservationTypeReference: string;
    price: string;
    currency: string;
}

interface QrCodeReservationTypes {
    car: Car;
    customer: Customer;
    reservation: Reservation;
}

interface CloseReservationData {
    secretKey: number | null;
    hash: string | undefined;
    carKilometer: string | null;
}

const QrCodeReservationContainer = () => {
    const { hash } = useParams();
    const { getQrCodeReservation, finishQrCodeReservation } = useCustomer();

    const [reservation, setReservation] =
        useState<QrCodeReservationTypes | null>(null);
    const [secretKey, setSecretKey] = useState<number | null>(null);
    const [isLoading, setLoading] = useState<boolean>(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [carKilometer, setCarKilometer] = useState<string | null>(null);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = async () => {
        setLoading(true);
        const data: CloseReservationData = {
            secretKey,
            hash,
            carKilometer,
        };
        await finishQrCodeReservation(data, setLoading);
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const fetchReservation = async () => {
        const QRreservation = await getQrCodeReservation(hash);
        setReservation(QRreservation);
    };

    useEffect(() => {
        fetchReservation();
    }, []);

    if (!reservation) return <LoadingPlaceholder />;

    const { name, carKilometers, image } = reservation.car;
    const { country, customerName } = reservation.customer;

    const {
        reservationType,
        reservationTypeReference,
        pickUpDate,
        pickupTime,
        returnDate,
        returnTime,
        currency,
        price,
    } = reservation.reservation;

    return (
        <div className="relative mx-[16.5px] font-inter">
            <div className="flex items-center justify-center">
                <Image
                    width={300}
                    src={`${process.env.REACT_APP_DOMAIN}${image}`}
                    alt="lambo"
                    fallback={process.env.REACT_APP_IMAGE_FALLBACK}
                />
            </div>
            <Divider className="bg-slate-300" />
            <div className="flex justify-between  items-center mt-5">
                <h1 className="text-[20px] font-bold">{name}</h1>
                <p className="text-[16px]">{carKilometers} kms</p>
            </div>
            <Divider className="bg-slate-300" />
            <div className="flex justify-between  items-center  ">
                <h2 className="text-[16px] ">{customerName}</h2>
                <p className="text-[16px] ">{country}</p>
            </div>
            <Divider className="bg-slate-300" />
            <div className="flex justify-between  items-center  ">
                <h2 className="text-[16px] ">{formatDate(pickUpDate, "/")}</h2>
                <p className="text-[16px] ">{pickupTime}</p>
            </div>
            <div className="flex justify-between  items-center">
                <h2 className="text-[16px] ">{formatDate(returnDate, "/")}</h2>
                <p className="text-[16px] ">{returnTime}</p>
            </div>
            <Divider className="bg-slate-300" />
            <div className="flex justify-between items-center">
                <TypeReservationHandler type={reservationType} size={130} />
                <Tag color={"gray"} className="text-[16px] font-bold">
                    {reservationTypeReference}
                </Tag>
            </div>
            <Divider className="bg-slate-300" />
            <div className="flex justify-end pt-[65px]">
                <p className="text-[16px] font-semibold">
                    Total Price: {price} {currency}
                </p>
            </div>
            <Divider className="bg-slate-300" />
            <div className="mt-[22px]">
                <Input
                    placeholder="enter kilometers"
                    defaultValue={carKilometers}
                    onChange={({ target: { value } }) => setCarKilometer(value)}
                    prefix={
                        <FieldNumberOutlined className="w-[19px] h-[12px] text-blue-600" />
                    }
                />
                <p className="text-[10px]">
                    This is used for easier update of kilometers for vehicle.
                </p>
            </div>
            <Button
                className="w-full text-[14px] mt-5 mb-5"
                type="primary"
                size={"middle"}
                onClick={showModal}
                loading={isLoading}
            >
                Finish reservation
            </Button>
            <Modal
                title="Finish Reservation"
                open={isModalOpen}
                okText={"Done"}
                onOk={handleOk}
                onCancel={handleCancel}
                okButtonProps={{ loading: isLoading }}
            >
                <div>
                    <Input
                        type={"number"}
                        placeholder={"Enter secret key"}
                        onChange={({ target: { value } }) =>
                            setSecretKey(Number(value))
                        }
                    />
                </div>
            </Modal>
        </div>
    );
};

export default QrCodeReservationContainer;
