import React from "react";
import { Tag } from "antd";

interface ReservationFinishedInterface {
    finished: "FINISHED";
}
const ReservationFinished = ({ finished }: ReservationFinishedInterface) => {
    return (
        <Tag className={"px-2 py-1 font-semibold border-[2px]"} color={"blue"}>
            {finished}
        </Tag>
    );
};

export default ReservationFinished;
