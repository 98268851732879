import React from "react";
import withRole from "../../../Providers/AuthProvider/WithRole";
import AddLocation from "../../../Containers/Location/AddLocation/AddLocation";

const AddLocationPage = (props) => {
    return (
        <div className="add-location-page">
            <AddLocation />
        </div>
    );
};

export default withRole(AddLocationPage, ["admin"]);
