import React, { useContext } from "react";
import { DashboardContextType } from "Types/AnalyticsTypes";
import StatisticBox from "Components/Dashboard/StatisticBox";
import DashboardContext from "Context/DashboardContext";
import LoadingPlaceholder from "Components/Placeholders/LoadingPlaceholder/LoadingPlaceholder";
import LocationFilter from "./partials/LocationFilter";
import PieChart from "Components/Charts/PieChart";
import LineChart from "Components/Charts/LineChart";
import HeadingTitle from "Components/Headings/HeadingTitle";
import BarChart from "Components/Charts/BarChart";
import withRole from "Providers/AuthProvider/WithRole";
import { Divider } from "antd";
import {
    CarTwoTone,
    CheckCircleTwoTone,
    DollarTwoTone,
    CloseCircleTwoTone,
} from "@ant-design/icons";

const DashboardContainer = () => {
    const { analytics, locations }: DashboardContextType =
        useContext(DashboardContext);

    if (!analytics || !locations) return <LoadingPlaceholder />;

    const { statistic_box_data } = analytics;

    return (
        <div className="dashboard-page">
            <div className={"flex items-center gap-3"}>
                <HeadingTitle title={"Location"} />
                <LocationFilter currentLocation={analytics.location_uuid} />
            </div>
            <Divider />
            <div className="flex flex-wrap gap-5 justify-between">
                <StatisticBox
                    title={"Total Cars"}
                    color={"#5470c6"}
                    data={statistic_box_data.total_cars}
                    iconComponent={<CarTwoTone />}
                    linkPath={"/manage/cars"}
                    linkTitle={"All Cars"}
                />
                <StatisticBox
                    title={"Active Reservation"}
                    color={"#91cc75"}
                    data={statistic_box_data.active_reservations}
                    iconComponent={<CheckCircleTwoTone />}
                    linkPath={"/check/cars"}
                    linkTitle={"Check In/Out"}
                />
                <StatisticBox
                    title={"Finished Reservation"}
                    color={"#fac858"}
                    data={statistic_box_data.finished_reservations}
                    iconComponent={<CloseCircleTwoTone />}
                    linkPath={"/customers"}
                    linkTitle={"Customers"}
                />
                <StatisticBox
                    title={"Earnings"}
                    color={"#e66"}
                    data={`${statistic_box_data.earnings} ${analytics.currency}`}
                    iconComponent={<DollarTwoTone />}
                    linkPath={"/manage/cars"}
                    linkTitle={"Reports"}
                />
            </div>
            <div
                className={"charts flex flex-wrap justify-between w-full mt-10"}
            >
                <div
                    className={
                        "w-[63%] sm:w-full bg-[#fafafa] p-3 rounded border-2 border-gray-300"
                    }
                >
                    <div className={"text-center"}>
                        <HeadingTitle
                            title={`Current Week Statistics - (${analytics.currency})`}
                        />
                    </div>
                    <BarChart />
                </div>
                <div
                    className={
                        "w-[35%] sm:w-full bg-[#fafafa] border-2 border-gray-300 p-3 rounded-md sm:mt-10"
                    }
                >
                    <div className={"text-center"}>
                        <HeadingTitle
                            title={`Top 10 Cars By Price - (${analytics.currency})`}
                        />
                    </div>
                    <PieChart />
                </div>
            </div>
            <div
                className={
                    "w-full text-center mt-10 bg-[#fafafa] border-2 border-gray-300 rounded-md p-3"
                }
            >
                <HeadingTitle
                    title={`Current Year Statistics - (${analytics.currency})`}
                />
                <LineChart />
            </div>
        </div>
    );
};

export default withRole(DashboardContainer, ["admin"]);
