import React from "react";
import withRole from "../../../Providers/AuthProvider/WithRole";
import CheckCarsContainer from "../../../Containers/RentCars/CheckCars/CheckCarsContainer";

const CheckPage = () => {
    return (
        <div className="add-car-page">
            <CheckCarsContainer />
        </div>
    );
};

export default withRole(CheckPage, ["admin", "employee"]);
