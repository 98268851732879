export function arraySum(array: []) {
    // @ts-ignore
    return array.reduce((accumulator: any, currentValue: any) => {
        if (currentValue !== null) {
            return parseFloat(accumulator) + parseFloat(currentValue);
        } else {
            return parseFloat(accumulator);
        }
    });
}
