import React, { useContext, useState } from "react";
import { Select } from "antd";
import { CarHistoryContext } from "Context/History/CarHistoryContext";
const CarHistoryLocationFilter = () => {
    const { locations, searchQuery, setSearchQuery } =
        useContext(CarHistoryContext);
    const handleLocationChange = (location: string) => {
        setSearchQuery({ ...searchQuery, location });
    };

    return (
        <Select
            onChange={handleLocationChange}
            value={searchQuery?.location}
            placeholder={"Select location..."}
            className={"sm:w-full w-[250px]"}
            // @ts-ignore
            disabled={!locations?.length > 0}
            // @ts-ignore
            options={locations?.map((location) => {
                const { name, address, uuid } = location;
                return {
                    value: uuid,
                    label: `${name} - ${address}`,
                };
            })}
        />
    );
};

export default CarHistoryLocationFilter;
