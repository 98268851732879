import React, { useEffect, useState } from "react";
import LocationFilter from "./partials/LocationsFilter";
import HeadingTitle from "Components/Headings/HeadingTitle";
import StatisticBox from "Components/StatisticBox/StatisticBox";
import useEmployee from "Hooks/EmployeeHooks/useEmployee";
import useLocations from "Hooks/LocationsHooks/useLocations";
import LoadingPlaceholder from "../../../Components/Placeholders/LoadingPlaceholder";
import EmployeeFilter from "./partials/EmployeeFilter";
import { Button, Divider } from "antd";
import DateFilter from "./partials/DateFilter";
import EmployeeList from "./partials/EmployeeList";
import { EmployeeDataStructureTypes } from "../../../Types/EmployeeTypes";

const EmployeAnalyticsContainer = () => {
    const { getEmployeeAnalytics } = useEmployee();
    const { getLocations } = useLocations();

    const [locations, setLocations] = useState();
    const [filterQuery, setFilterQuery] = useState(null);
    const [selectedEmployee, setSelectedEmployee] = useState<string>("");
    const [selectedDateRange, setSelectedDateRange] = useState<string | null>(
        null,
    );
    const [isLoading, setLoading] = useState(false);
    const [analyticsData, setAnalyticsData] =
        useState<EmployeeDataStructureTypes>({
            employees: [],
            totalPrice: 0,
            totalDays: 0,
            insuranceTotalPrice: 0,
            reservationsCount: 0,
            location: {
                locationId: "",
                locationName: "",
                currency: "",
                locationEmployees: null,
            },
        });

    const buildQueryString = (location?: string, employee?: string) => {
        const query = new URLSearchParams();

        if (location || analyticsData.location.locationId) {
            query.append(
                "locationId",
                location || analyticsData.location.locationId,
            );
        }

        if (employee || selectedEmployee) {
            query.append("employeId", employee || selectedEmployee);
        }

        if (selectedDateRange) {
            query.append("date", selectedDateRange);
        }
        console.log(window.location.href);
        return query.toString();
    };

    const fetchLocations = async () => {
        const data = await getLocations();
        setLocations(data);
    };
    const fetchAnalyticsData = async () => {
        setLoading(true);
        const data = await getEmployeeAnalytics(filterQuery, setLoading);
        setAnalyticsData(data);
    };

    const handleLocation = (location: string) => {
        const query = buildQueryString(location);
        // @ts-ignore
        setFilterQuery(query);
    };

    const handleEmployee = (employee: string) => {
        setSelectedEmployee(employee);
    };

    const handleDate = (date: any, formated: any) => {
        const dates = `${formated[0]},${formated[1]}`;
        setSelectedDateRange(dates);
    };

    const getAnalytics = () => {
        const query = buildQueryString();
        // @ts-ignore
        setFilterQuery(query);
    };

    useEffect(() => {
        !locations && fetchLocations();
        fetchAnalyticsData();
    }, [filterQuery]);

    if (!analyticsData || !locations) return <LoadingPlaceholder />;
    return (
        <>
            {analyticsData?.location?.locationName && (
                <LocationFilter
                    locations={locations}
                    onChange={handleLocation}
                    // @ts-ignore
                    defaultValue={analyticsData?.location?.locationName}
                />
            )}
            {analyticsData?.location?.locationEmployees && (
                <div>
                    <Divider />
                    <div className={"flex gap-3 md:flex-wrap"}>
                        <EmployeeFilter
                            employees={
                                analyticsData?.location?.locationEmployees
                            }
                            onChange={handleEmployee}
                        />
                        <DateFilter onChange={handleDate} />
                        <Button
                            className={"sm:w-full"}
                            loading={isLoading}
                            onClick={getAnalytics}
                            type={"primary"}
                        >
                            Get Analytics
                        </Button>
                    </div>
                </div>
            )}
            <HeadingTitle title={"Employee Analytics"} />
            <div className={"flex gap-3 md:flex-wrap"}>
                <StatisticBox
                    color={"#3f8600"}
                    title={"Total Price"}
                    value={analyticsData.totalPrice}
                    currency={analyticsData?.location?.currency}
                />
                <StatisticBox
                    color={"#3f8600"}
                    title={"Total Insurance"}
                    value={analyticsData.insuranceTotalPrice}
                    currency={analyticsData?.location?.currency}
                />
                <StatisticBox
                    round={0}
                    color={"#3f8600"}
                    title={"Total Days"}
                    value={analyticsData.totalDays}
                />
                <StatisticBox
                    color={"#3f8600"}
                    title={"Total Reservations"}
                    value={analyticsData.reservationsCount}
                />
            </div>
            <HeadingTitle title={"Employees List"} />
            <EmployeeList
                employees={analyticsData?.employees}
                currency={analyticsData?.location?.currency}
            />
        </>
    );
};

export default EmployeAnalyticsContainer;
