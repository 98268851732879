import React, { useContext } from "react";
import Integrations from "Utils/partials/integrations";
import IntegrationCard from "Components/Card/IntegrationCard";
import { ActivateIntegrationContext } from "../../Context/Integrations/ActivateIntegrationContext";
import IntegrationDrawer from "./partials/IntegrationDrawer";

const AddIntegrationsContainer = () => {
    // @ts-ignore
    const { showIntegrationDrawer } = useContext(ActivateIntegrationContext);
    return (
        <>
            <div className={"flex flex-wrap gap-5"}>
                {Integrations?.map((integration) => {
                    const {
                        integrationType,
                        serviceType,
                        description,
                        integrationTitle,
                    } = integration;
                    return (
                        <IntegrationCard
                            onClick={showIntegrationDrawer}
                            title={integrationTitle}
                            integrationType={integrationType}
                            serviceType={serviceType}
                            description={description}
                        />
                    );
                })}
                <IntegrationDrawer />
            </div>
        </>
    );
};

export default AddIntegrationsContainer;
