import React from "react";
import withRole from "../../../Providers/AuthProvider/WithRole";
import ManageEmployee from "../../../Containers/Employee/ManageEmployee";

const ManageEmployeesPage = () => {
    return (
        <div className="manage-employees-page">
            <ManageEmployee />
        </div>
    );
};

export default withRole(ManageEmployeesPage, ["admin"]);
