export const getPageParam = (): number => {
    const searchParams = new URLSearchParams(window.location.search);
    const page = searchParams.get("page");
    // @ts-ignore
    return parseInt(page) || 1;
};

export const addPageQueryParam = (currentPage: number): void => {
    const url = new URL(window.location.href); // Get the current URL
    url.searchParams.set("page", currentPage?.toString()); // Add or update the 'page' query parameter
    // Update the URL without a page refresh
    window.history.replaceState({}, "", url.toString());
};

export const updateURLParams = (queryParam: string): void => {
    // Update the browser's URL without reloading the page
    window.history.replaceState({}, "", queryParam);
};
