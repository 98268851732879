import React, { useContext } from "react";
import HeadingTitle from "../../../Components/Headings/HeadingTitle";
import ReservationList from "./partials/ReservationList";
import { Pagination } from "antd";
import { getPageParam } from "../../../Helpers/PageHelper";
import { ReservationContext } from "../../../Context/ReservationsContext/ReservationContext";

const ReservationsContainer = () => {
    const { curPage, totalPerPage, setCurPage } =
        useContext(ReservationContext);
    const handlePagination = (pageNo: number) => {
        setCurPage(pageNo);
    };
    return (
        <>
            <HeadingTitle title={"Imported Reservations"} />
            <ReservationList />
            <div className={"mt-5 text-end"}>
                <Pagination
                    onChange={handlePagination}
                    defaultPageSize={10}
                    defaultCurrent={getPageParam()}
                    total={totalPerPage}
                    showSizeChanger={false}
                />
            </div>
        </>
    );
};

export default ReservationsContainer;
