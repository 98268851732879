import React from "react";

interface HorizontalDividerTypes {
    classes?: string;
}

const HorizontalDivider = ({ classes }: HorizontalDividerTypes) => {
    return (
        <p
            style={{ borderBottom: "1px solid #eaeaea" }}
            className={`hz-divider ${classes}`}
        >
            {" "}
        </p>
    );
};

export default HorizontalDivider;
