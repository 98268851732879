import React from "react";
import { CloseCircleOutlined } from "@ant-design/icons";
import { Result, Typography } from "antd";

const { Paragraph, Text } = Typography;

const QrCodeReservationNotFound: React.FC = () => (
    <Result
        status="error"
        title="Submission Failed"
        subTitle="Please check and modify the following information before resubmitting."
        extra={[]}
    >
        <div className="desc">
            <Paragraph>
                <Text
                    strong
                    style={{
                        fontSize: 16,
                    }}
                >
                    The content you submitted has the following error:
                </Text>
            </Paragraph>
            <Paragraph>
                <CloseCircleOutlined className="site-result-demo-error-icon" />{" "}
                Reservation can't be found.
            </Paragraph>
            <Paragraph>
                <CloseCircleOutlined className="site-result-demo-error-icon" />{" "}
                Reservation is already finished.
            </Paragraph>
        </div>
    </Result>
);

export default QrCodeReservationNotFound;
