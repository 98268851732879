import React from "react";

const DisplayJsonPreview = ({ data }: { data: any }) => {
    // Recursive function to handle different data types
    const renderData = (value: any, key: any) => {
        // Check if the value is null or undefined
        if (value === null || value === undefined) {
            return <span className="text-red-500">No data available</span>;
        }

        if (typeof value === "object" && !Array.isArray(value)) {
            // Render object with a heading and key-value pairs
            return (
                <div className="ml-4 mb-4">
                    <h2 className="text-xl font-bold mb-2 capitalize">{key}</h2>
                    {Object.entries(value).map(([subKey, subVal]) => {
                        if (subKey === "icon") return;
                        return (
                            <div key={subKey} className="ml-2">
                                <strong className="font-medium capitalize">
                                    {subKey}:
                                </strong>{" "}
                                {renderData(subVal, subKey)}
                            </div>
                        );
                    })}
                </div>
            );
        } else if (Array.isArray(value)) {
            // Render arrays as lists
            return (
                <div className="ml-4 mb-4">
                    <h2 className="text-xl font-bold mb-2 capitalize">{key}</h2>
                    <ul className="list-disc ml-5">
                        {value.length > 0 ? (
                            value.map((item, index) => (
                                <li key={index}>{renderData(item, index)}</li>
                            ))
                        ) : (
                            <li>No items available</li>
                        )}
                    </ul>
                </div>
            );
        } else {
            // Render simple values
            return <span>{value.toString()}</span>;
        }
    };

    // Check if the top-level data is valid
    if (!data || typeof data !== "object") {
        return <div className="text-red-500">Invalid or empty</div>;
    }

    return (
        <div className="p-4 rounded-lg shadow-md grid grid-cols-4 gap-5">
            {Object.entries(data).map(([key, value]) => (
                <div key={key} className={"shadow-md"}>
                    {renderData(value, key)}
                </div>
            ))}
        </div>
    );
};

export default DisplayJsonPreview;
