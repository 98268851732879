import React, { useEffect, useState } from "react";
import { Button, Divider, Modal } from "antd";
import { useLocation, useNavigate } from "react-router-dom";

const IntegrationTokenModal = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleOk = () => {
        setIsModalOpen(false);
    };

    const resetState = () => {
        // Replace the current entry without state
        navigate(location.pathname, { replace: true });
    };
    const handleCancel = () => {
        setIsModalOpen(false);
        resetState();
    };
    useEffect(() => {
        if (location?.state?.token) {
            setIsModalOpen(true);
        }
    }, [location]);
    return (
        <Modal
            title="Access Token"
            open={isModalOpen}
            okButtonProps={{ className: "hidden" }}
            onCancel={handleCancel}
            cancelButtonProps={{ className: "hidden" }}
        >
            <small>
                Please copy this access token somewhere safe. This is used to
                communicate with integrations, you can check our documentation
                for more info.
            </small>
            <Divider className={"mt-2 mb-2"} />
            <p className={"p-3 bg-gray-200 rounded-md font-bold"}>
                <code>{location?.state?.token}</code>
            </p>
            <Divider className={"mt-2 mb-2"} />
        </Modal>
    );
};

export default IntegrationTokenModal;
