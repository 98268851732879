import React, { ReactNode } from "react";
import { Button } from "antd";

type ButtonType =
    | "primary"
    | "link"
    | "text"
    | "default"
    | "dashed"
    | undefined;
type ButtonSize = "small" | "middle" | "large";
type ButtonHtmlType = "submit" | "button" | "reset" | undefined;

interface AntButtonTypes {
    children: ReactNode;
    isLoading?: boolean;
    onClick?: (e: any) => void;
    ref?: React.Ref<HTMLElement>;
    className?: string;
    buttonType?: ButtonType;
    danger?: boolean;
    size?: ButtonSize;
    htmlType?: ButtonHtmlType;
    disabled?: boolean;
}

const AntButton = ({
    children,
    isLoading,
    onClick,
    ref,
    className,
    buttonType,
    danger,
    size,
    htmlType,
    disabled,
}: AntButtonTypes) => {
    return (
        <Button
            ref={ref}
            typeof={"submit"}
            onClick={onClick}
            className={`w-full ${className}`}
            type={buttonType ? buttonType : "default"}
            size={size ? size : "middle"}
            loading={isLoading}
            danger={danger}
            htmlType={htmlType}
            disabled={disabled}
        >
            {children}
        </Button>
    );
};

export default AntButton;
