import { CustomerRentalStatus, CustomerTypes } from "Types/CustomerTypes";

interface CrossingBorderTypes {
    crossing_border_list: [];
}
interface AdditionalDriverList {
    additional_drivers_list: [];
}
export const hasAdditionalDrivers = (
    customer: AdditionalDriverList,
): boolean => {
    return customer.additional_drivers_list?.length > 0;
};

export const hasCrossingBorders = (customer: CrossingBorderTypes): boolean => {
    return customer.crossing_border_list?.length > 0;
};

export const getCustomerReservationType = (customer: CustomerTypes[]) => {
    return customer[0]?.reservationType;
};

export const getCustomerReservationTypeReference = (
    customer: CustomerTypes[],
) => {
    return customer[0]?.reservationTypeReference;
};

export const handleCustomerRentalStatus = (
    from: string,
    to: string,
): CustomerRentalStatus => {
    const startDate = new Date(from);
    const endDate = new Date(to);
    const currentDate = new Date();

    if (currentDate >= startDate && currentDate <= endDate) {
        return "RENTING";
    } else if (currentDate < startDate) {
        return "PICK_UP";
    }
    return "FINISHED";
};
