import React from "react";
import { ReservationStatus } from "Types/ReservationTypes";
import ReservationPending from "./ReservationStatuses/ReservationPending";
import ReservationConfirmed from "./ReservationStatuses/ReservationConfirmed";
import ReservationRejected from "./ReservationStatuses/ReservationRejected";
import ReservationFinished from "./ReservationStatuses/ReservationFinished";
import ReservationCanceled from "./ReservationStatuses/ReservationCanceled";

interface ReservationStatusHandlerInterface {
    status: ReservationStatus;
}
const ReservationStatusHandler = ({
    status,
}: ReservationStatusHandlerInterface) => {
    const currentStatus = () => {
        switch (status) {
            case "PENDING":
                return <ReservationPending pending={status} />;
            case "CANCELED":
                return <ReservationCanceled canceled={status} />;
            case "CONFIRMED":
                return <ReservationConfirmed confirmed={status} />;
            case "FINISHED":
                return <ReservationFinished finished={status} />;
            case "REJECTED":
                return <ReservationRejected rejected={status} />;
            default:
                return "NOT ACTIVE";
        }
    };
    return <>{currentStatus()}</>;
};

export default ReservationStatusHandler;
