import React from "react";
import EmployeAnalyticsContainer from "Containers/Employee/EmployeeAnalytics/EmployeAnalyticsContainer";

const EmployeeAnalyticsPage = () => {
    return (
        <>
            <EmployeAnalyticsContainer />
        </>
    );
};

export default EmployeeAnalyticsPage;
