import React from "react";
import { Card, Statistic } from "antd";

interface StatisticBoxProps {
    title: string;
    value: string | number;
    color: string;
    round?: number;
    currency?: string;
}

const StatisticBox = ({
    title,
    value,
    color,
    round,
    currency,
}: StatisticBoxProps) => (
    <Card bordered={true} className={"w-full"}>
        <Statistic
            title={<p className={"font-bold"}> {title} </p>}
            value={value}
            precision={round}
            valueStyle={{ color: color }}
            suffix={currency}
        />
    </Card>
);

export default StatisticBox;
