import React from "react";
import { GetCarHistoryParamTypes } from "../../Types/CarHistoryTypes";
import axios from "axios";
import { App } from "antd";

const useCarHistory = () => {
    const { message, notification } = App.useApp();
    /**
     * @param page
     * @param query { carName, carLicencePlate, location }
     * @param loading
     */
    const getCarHistories = async ({
        page = 1,
        query = "",
    }: GetCarHistoryParamTypes) => {
        try {
            const url = `/history/cars?page=${page}&${query}`;
            const carHistories = await axios.get(url);
            return carHistories?.data;
        } catch (error: any) {
            message.error(error.message);
            return [];
        }
    };

    const downloadCarHistory = async (
        carHistoryId: string,
        pdfName: string = "car_history",
    ) => {
        try {
            const response = await axios.post(
                "/history/cars/download",
                { carHistoryId },
                { responseType: "blob" },
            );

            const blob = new Blob([response.data], { type: "application/pdf" });
            const url = window.URL.createObjectURL(blob);

            // Create an anchor element
            const link = document.createElement("a");
            link.href = url;
            link.download = `${pdfName}.pdf`;
            link.click();

            // Clean up resources after the download
            window.URL.revokeObjectURL(url);
        } catch (error: any) {
            notification.error({
                message: error.response.data.error,
            });
        }
    };

    return {
        getCarHistories,
        downloadCarHistory,
    };
};
export default useCarHistory;
