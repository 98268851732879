import React, { useEffect, useState } from "react";
import { App, Button, Input, Modal, Select } from "antd";
import { getCountries } from "Utils/CountryUtils";

interface CrossingBorderTypes {
    onAction?: (data: any) => void;
    currency: string;
    crossingBorderList: [];
    formPrice: number | string;
}

interface CrossingBorderStateTypes {
    bordersList: [];
    price: undefined | number | string;
}

const initialStateBorderValue: CrossingBorderStateTypes = {
    bordersList: [],
    price: 0,
};
const CrossingBorderOption = ({
    onAction,
    currency,
    crossingBorderList,
    formPrice,
}: CrossingBorderTypes) => {
    const { message } = App.useApp();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [crossingBorder, setCrossingBorder] =
        useState<CrossingBorderStateTypes>({
            bordersList: crossingBorderList,
            // @ts-ignore
            price: parseInt(formPrice) / crossingBorderList?.length,
        });
    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
        const data = {
            bordersList: crossingBorder.bordersList,
            price: crossingBorderTotalPrice(),
        };
        if (onAction) {
            onAction(data);
        }
        message.success("Added borders to list.");
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    // Filter `option.label` match the user type `input`
    const filterOption = (
        input: string,
        option?: { label: string; value: string },
    ) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

    const crossingBorderTotalPrice = () => {
        if (crossingBorder.bordersList?.length <= 0) {
            return 0;
        }
        const bordersNo = crossingBorder.bordersList?.length;
        // @ts-ignore
        return crossingBorder.price * bordersNo;
    };
    return (
        <>
            <Button className={"sm:w-full"} onClick={showModal}>
                Add Crossing Border
            </Button>
            <Modal
                title="Crossing Borders List"
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
            >
                <div className="flex flex-col gap-3">
                    <Input
                        type={"number"}
                        placeholder={"Price per/border"}
                        value={crossingBorder.price}
                        onChange={(price) =>
                            setCrossingBorder({
                                ...crossingBorder,
                                price: parseInt(price.target.value),
                            })
                        }
                    />
                    <Select
                        showSearch
                        mode={"tags"}
                        placeholder={"Select Country"}
                        optionFilterProp="children"
                        defaultValue={crossingBorderList}
                        filterOption={filterOption}
                        autoClearSearchValue={true}
                        onChange={(list) => {
                            setCrossingBorder({
                                ...crossingBorder,
                                bordersList: list,
                            });
                            crossingBorderTotalPrice();
                        }}
                        options={getCountries().map((country) => {
                            return {
                                value: country.name,
                                label: country.name,
                            };
                        })}
                    />
                    <p className={"font-bold text-end"}>
                        Total: {crossingBorderTotalPrice()} {currency}
                    </p>
                </div>
            </Modal>
        </>
    );
};

export default CrossingBorderOption;
