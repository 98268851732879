import React, { useContext, useEffect, useState } from "react";
import PdfContext from "../../Context/PdfContext";
import HorizontalDivider from "../HorizontalDivider/HorizontalDivider";
import {
    ArrowLeftOutlined,
    PrinterOutlined,
    SettingOutlined,
} from "@ant-design/icons";
import {
    Button,
    Select,
    Space,
    Drawer,
    FloatButton,
    Input,
    Checkbox,
} from "antd";
import { useNavigate } from "react-router-dom";
import useCompany from "../../Hooks/CompanyHooks/useCompany";
import { useTranslation } from "react-i18next";

const PdfOptions = () => {
    const { i18n } = useTranslation();
    const { pdfOptions, creditCardInput, companyContactInput } =
        useContext(PdfContext);

    const [contactList, setContactList] = useState([]);
    const [legalDocument, setLegalDocument] = useState([]);

    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const showDrawer = () => {
        setOpen(true);
    };
    const { getAllLegalDocuments, getLegalDocumentByUuid } = useCompany();

    const onClose = () => {
        setOpen(false);
    };

    const {
        vehicleCondition,
        creditCard,
        termsConditions,
        pricesOptions,
        aggrementInfo,
        qrCodeFinishReservation,
        legalDocuments,
    } = pdfOptions;

    const {
        addDriverPrice,
        crossingBorderPrice,
        babySeatPrice,
        gpsPrice,
        depositPrice,
        totalRentPrice,
        insurancePrice,
        totalPrice,
        damageExcess,
    } = pricesOptions?.data;

    const contactOptions = () => {
        const contacts = companyContactInput.contactList();
        return contacts.map((contact) => {
            return {
                value: contact.id,
                label: contact.name,
            };
        });
    };

    const fetchLegalDocuments = async () => {
        const docs = await getAllLegalDocuments();
        setLegalDocument(docs);
    };

    useEffect(() => {
        setContactList(contactOptions());
        fetchLegalDocuments();
    }, []);

    return (
        <>
            <div className={"customer-agreement"}>
                <FloatButton.Group shape="square" className="mr-5 float-button">
                    <FloatButton
                        icon={<PrinterOutlined />}
                        onClick={() => window.print()}
                        type="primary"
                    />
                    <FloatButton
                        icon={<SettingOutlined />}
                        onClick={showDrawer}
                    />
                    <FloatButton
                        icon={<ArrowLeftOutlined />}
                        onClick={() => navigate(-1)}
                    />
                </FloatButton.Group>
            </div>

            <Drawer
                title="Print Options"
                placement="left"
                onClose={onClose}
                open={open}
            >
                <div className="drawer-side">
                    <ul className="menu w-80 bg-base-100 text-base-content">
                        <h3 className={"uppercase font-bold"}>
                            Legal Documents
                        </h3>
                        <HorizontalDivider classes={"mt-2 mb-2"} />
                        <div className="form-control mt-2 mb-5">
                            <Select
                                onChange={(uuid) => {
                                    const selected = legalDocument?.find(
                                        (object) => object.uuid === uuid,
                                    );
                                    legalDocuments.action(selected);
                                }}
                                className={"w-full"}
                                defaultValue={"Select Legal Document..."}
                                options={legalDocument?.map((item) => {
                                    return {
                                        label: item.name,
                                        value: item.uuid,
                                    };
                                })}
                            />
                            <Select
                                onChange={(lng) => i18n.changeLanguage(lng)}
                                className={"w-full mt-5"}
                                defaultValue={"Select Language..."}
                            >
                                <Select.Option value={"en"}>
                                    English
                                </Select.Option>
                                <Select.Option value={"bs"}>
                                    Bosnian
                                </Select.Option>
                                <Select.Option value={"mk"}>
                                    Macedonia
                                </Select.Option>
                            </Select>
                        </div>
                        <h3 className={"uppercase font-bold"}>Sections</h3>
                        <HorizontalDivider classes={"mt-2 mb-2"} />
                        <div className="form-control">
                            <Checkbox
                                onClick={(e) =>
                                    creditCard.action(e.target.checked)
                                }
                                defaultChecked={creditCard.enabled}
                                autoFocus={true}
                                className="checkbox checkbox-success"
                            />
                            <span className="label-text text-secondary ml-1">
                                Show Credit Card Info
                            </span>
                        </div>
                        <div className="form-control">
                            <Checkbox
                                onClick={(e) =>
                                    vehicleCondition.action(e.target.checked)
                                }
                                defaultChecked={vehicleCondition.enabled}
                                autoFocus={true}
                                className="checkbox checkbox-success"
                            />
                            <span className="label-text text-secondary ml-1">
                                Show Vehicle Condition
                            </span>
                        </div>
                        <div className="form-control">
                            <Checkbox
                                onClick={(e) =>
                                    termsConditions.action(e.target.checked)
                                }
                                defaultChecked={termsConditions.enabled}
                                autoFocus={true}
                                className="checkbox checkbox-success"
                            />
                            <span className="label-text text-secondary ml-1">
                                Show Terms & Conditions
                            </span>
                        </div>
                        <div className="form-control">
                            <Checkbox
                                onClick={(e) =>
                                    qrCodeFinishReservation.action(
                                        e.target.checked,
                                    )
                                }
                                defaultChecked={qrCodeFinishReservation.enabled}
                                autoFocus={true}
                                className="checkbox checkbox-success"
                            />
                            <span className="label-text text-secondary ml-1">
                                Show QR Code Finish Reservation
                            </span>
                        </div>
                        <HorizontalDivider classes={"mt-2 mb-2"} />
                        <h3 className={"uppercase font-bold"}>Prices</h3>
                        <HorizontalDivider classes={"mt-2 mb-2"} />
                        <div className="form-control">
                            <Checkbox
                                onClick={(e) =>
                                    pricesOptions.action({
                                        ...pricesOptions.data,
                                        damageExcess: e.target.checked,
                                    })
                                }
                                defaultChecked={damageExcess}
                                autoFocus={true}
                                className="checkbox checkbox-success"
                            />
                            <span className="label-text text-secondary ml-1">
                                Show Damage Excess
                            </span>
                        </div>
                        <div className="form-control">
                            <Checkbox
                                onClick={(e) =>
                                    pricesOptions.action({
                                        ...pricesOptions.data,
                                        addDriverPrice: e.target.checked,
                                    })
                                }
                                defaultChecked={addDriverPrice}
                                autoFocus={true}
                                className="checkbox checkbox-success"
                            />
                            <span className="label-text text-secondary ml-1">
                                Show Add. Driver Price
                            </span>
                        </div>
                        <div className="form-control">
                            <Checkbox
                                onClick={(e) =>
                                    pricesOptions.action({
                                        ...pricesOptions.data,
                                        crossingBorderPrice: e.target.checked,
                                    })
                                }
                                defaultChecked={crossingBorderPrice}
                                autoFocus={true}
                                className="checkbox checkbox-success"
                            />
                            <span className="label-text text-secondary ml-1">
                                Show Crossing Border Price
                            </span>
                        </div>
                        <div className="form-control">
                            <Checkbox
                                onClick={(e) =>
                                    pricesOptions.action({
                                        ...pricesOptions.data,
                                        babySeatPrice: e.target.checked,
                                    })
                                }
                                defaultChecked={babySeatPrice}
                                autoFocus={true}
                                className="checkbox checkbox-success"
                            />
                            <span className="label-text text-secondary ml-1">
                                Show Baby Seat Price
                            </span>
                        </div>
                        <div className="form-control">
                            <Checkbox
                                onClick={(e) =>
                                    pricesOptions.action({
                                        ...pricesOptions.data,
                                        gpsPrice: e.target.checked,
                                    })
                                }
                                defaultChecked={gpsPrice}
                                autoFocus={true}
                                className="checkbox checkbox-success"
                            />
                            <span className="label-text text-secondary ml-1">
                                Show GPS Price
                            </span>
                        </div>
                        <div className="form-control">
                            <Checkbox
                                onClick={(e) =>
                                    pricesOptions.action({
                                        ...pricesOptions.data,
                                        depositPrice: e.target.checked,
                                    })
                                }
                                defaultChecked={depositPrice}
                                autoFocus={true}
                                className="checkbox checkbox-success"
                            />
                            <span className="label-text text-secondary ml-1">
                                Show Deposit
                            </span>
                        </div>
                        <div className="form-control">
                            <Checkbox
                                onClick={(e) =>
                                    pricesOptions.action({
                                        ...pricesOptions.data,
                                        insurancePrice: e.target.checked,
                                    })
                                }
                                defaultChecked={insurancePrice}
                                autoFocus={true}
                                className="checkbox checkbox-success"
                            />
                            <span className="label-text text-secondary ml-1">
                                Show Insurance Price
                            </span>
                        </div>
                        <div className="form-control">
                            <Checkbox
                                onClick={(e) =>
                                    pricesOptions.action({
                                        ...pricesOptions.data,
                                        totalRentPrice: e.target.checked,
                                    })
                                }
                                defaultChecked={totalRentPrice}
                                autoFocus={true}
                                className="checkbox checkbox-success"
                            />
                            <span className="label-text text-secondary ml-1">
                                Show Rent Price
                            </span>
                        </div>
                        <div className="form-control">
                            <Checkbox
                                onClick={(e) =>
                                    pricesOptions.action({
                                        ...pricesOptions.data,
                                        totalPrice: e.target.checked,
                                    })
                                }
                                defaultChecked={totalPrice}
                                autoFocus={true}
                                className="checkbox checkbox-success"
                            />
                            <span className="label-text text-secondary ml-1">
                                Show Total Price
                            </span>
                        </div>
                        {/*CONTACT INPUTS*/}
                        <HorizontalDivider classes={"mt-2 mb-2"} />
                        <h3 className={"uppercase font-bold"}>
                            Contact Inputs
                        </h3>
                        <HorizontalDivider classes={"mt-2 mb-2"} />
                        <div className={"flex justify-between flex-wrap"}>
                            <Input
                                onChange={(e) =>
                                    companyContactInput.nameAction(
                                        e.target.value,
                                    )
                                }
                                placeholder="Contact Name"
                                className="input input-bordered input-sm w-full max-w-xs mb-2"
                            />
                            <Input
                                onChange={(e) =>
                                    companyContactInput.emailAction(
                                        e.target.value,
                                    )
                                }
                                placeholder="Email"
                                className="input input-bordered input-sm w-full max-w-xs mb-2"
                            />
                            <Input
                                onChange={(e) =>
                                    companyContactInput.phoneAction(
                                        e.target.value,
                                    )
                                }
                                placeholder="Phone"
                                className="input input-bordered input-sm w-full max-w-xs mb-2"
                            />
                            <Space wrap>
                                <Select
                                    onChange={(e) =>
                                        companyContactInput.updateAction(e)
                                    }
                                    defaultValue={"Choose Contact"}
                                    style={{ width: 250 }}
                                    options={[
                                        {
                                            value: "",
                                            label: "Choose Contact",
                                            disabled: true,
                                        },
                                        ...contactList,
                                    ]}
                                />
                                <Button
                                    onClick={() => {
                                        companyContactInput.action();
                                        setContactList(contactOptions());
                                    }}
                                    className={"capitalize"}
                                    type={"primary"}
                                >
                                    Add
                                </Button>
                            </Space>
                        </div>
                        {/*CARD INPUTS*/}
                        <HorizontalDivider classes={"mt-2 mb-2"} />
                        <h3 className={"uppercase font-bold"}>Card Inputs</h3>
                        <HorizontalDivider classes={"mt-2 mb-2"} />
                        <div className={"flex justify-between flex-wrap"}>
                            <Input
                                onChange={(e) =>
                                    creditCardInput.action({
                                        ...creditCardInput.values,
                                        cardNumber: e.target.value,
                                    })
                                }
                                placeholder="Card Number"
                                className="input input-bordered input-sm w-full max-w-xs mb-2"
                            />
                            <Input
                                onChange={(e) =>
                                    creditCardInput.action({
                                        ...creditCardInput.values,
                                        cvv: e.target.value,
                                    })
                                }
                                type="text"
                                placeholder="CVV"
                                className="input input-bordered input-sm w-[49%] max-w-xs mb-2"
                            />
                            <Input
                                onChange={(e) =>
                                    creditCardInput.action({
                                        ...creditCardInput.values,
                                        expireDate: e.target.value,
                                    })
                                }
                                type="text"
                                placeholder="Expire Date"
                                className="input input-bordered input-sm w-[49%] max-w-xs mb-2"
                            />
                        </div>
                        <HorizontalDivider classes={"mt-2 mb-2"} />
                        Additional Options
                        <HorizontalDivider classes={"mt-2 mb-2"} />
                        <div className={"flex justify-between flex-wrap"}>
                            <Input
                                onChange={(e) =>
                                    aggrementInfo.actionBill(e.target.value)
                                }
                                type="text"
                                placeholder="Bill Number"
                                className="input input-bordered input-sm w-[49%] max-w-xs mb-2"
                            />
                        </div>
                        <small>
                            Data added here will only be represented on current
                            device.
                        </small>
                    </ul>
                </div>
            </Drawer>
        </>
    );
};

export default PdfOptions;
