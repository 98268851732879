import React from "react";
import { Card, Tag, Timeline } from "antd";
import { ArrowRightOutlined, ClockCircleOutlined } from "@ant-design/icons";

interface ReservationHistoryTimelineProps {
    histories: {};
}
const ReservationHistoryTimeline = ({
    histories,
}: ReservationHistoryTimelineProps) => {
    return (
        <>
            <Timeline
                mode={"right"}
                items={Object.entries(histories)?.map(([index, history]) => {
                    let editedBy = "";
                    return {
                        label: <p className={"font-bold"}> {index} </p>,
                        dot: (
                            <ClockCircleOutlined style={{ fontSize: "14px" }} />
                        ),
                        children: (
                            <Card>
                                {// @ts-ignore
                                history?.map(({ changes }) => {
                                    editedBy = changes.user.userName;
                                    return (
                                        <>
                                            <Card
                                                type="inner"
                                                className={
                                                    "mb-3 capitalize lg:overflow-scroll p-0"
                                                }
                                                title={changes.type}
                                            >
                                                {/* @ts-ignore */}
                                                <div className={"flex  gap-5"}>
                                                    <div
                                                        className={
                                                            "grid place-content-between"
                                                        }
                                                    >
                                                        {Object.entries(
                                                            changes.before,
                                                        )?.map(
                                                            ([key, change]) => {
                                                                return (
                                                                    <div
                                                                        className={
                                                                            "mt-2 flex items-center "
                                                                        }
                                                                    >
                                                                        {/*// @ts-ignore */}
                                                                        <Tag
                                                                            color={
                                                                                "red"
                                                                            }
                                                                            className={
                                                                                "font-bold capitalize mr-2 w-44 whitespace-normal"
                                                                            }
                                                                        >
                                                                            {key.replace(
                                                                                "_",
                                                                                " ",
                                                                            )}
                                                                            :{" "}
                                                                            {change ||
                                                                                "N/A"}
                                                                        </Tag>
                                                                        <ArrowRightOutlined />
                                                                    </div>
                                                                );
                                                            },
                                                        )}
                                                    </div>

                                                    <div
                                                        className={
                                                            "grid place-content-between"
                                                        }
                                                    >
                                                        {Object.entries(
                                                            changes.after,
                                                        )?.map(
                                                            ([key, change]) => {
                                                                return (
                                                                    <div
                                                                        className={
                                                                            "mt-2 grow-1"
                                                                        }
                                                                    >
                                                                        {/*// @ts-ignore */}
                                                                        <Tag
                                                                            color={
                                                                                "green"
                                                                            }
                                                                            className={
                                                                                "font-bold capitalize w-44 whitespace-normal"
                                                                            }
                                                                        >
                                                                            {key.replace(
                                                                                "_",
                                                                                " ",
                                                                            )}
                                                                            :{" "}
                                                                            {change ||
                                                                                "N/A"}
                                                                        </Tag>
                                                                    </div>
                                                                );
                                                            },
                                                        )}
                                                    </div>
                                                </div>
                                            </Card>
                                        </>
                                    );
                                })}
                                <p
                                    className={
                                        "pl-1 text-right !text-gray-300 font-bold"
                                    }
                                >
                                    Edited by: {editedBy}
                                </p>
                            </Card>
                        ),
                    };
                })}
            />
        </>
    );
};

export default ReservationHistoryTimeline;
