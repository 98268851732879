const Bosnian = {
    bs: {
        translation: {
            rentalAgreement: "Ugovor o najmu",
            billNo: "Broj računa",
            rentalAgreementNumber: "Broj ugovora o najmu",
            company: "Kompanija",
            address: "Adresa",
            city: "Grad",
            operatorName: "Ime operatera",
            phone: "Telefon",
            email: "Email",
            customer: "Kupac",
            name: "Ime",
            country: "Država",
            passport: "Pasoš",
            drivingLicence: "Vozačka dozvola",
            vehicle: "Vozilo",
            category: "Kategorija",
            registrationNumber: "Registarski broj",
            color: "Boja",
            pickUp: "Preuzimanje",
            date: "Datum",
            time: "Vrijeme",
            return: "Vraćanje",
            crossingBorders: "Prelazak granica",
            additionalDrivers: "Dodatni vozači",
            termsConditions:
                "Potpisivanjem ovog ugovora o najmu kupac se slaže da je pročitao i prihvatio uslove i odredbe napisane na poleđini ugovora.",
            agencySignature: "Potpis agencije",
            customerSignature: "Potpis kupca",
            damageExcess: "Učešće u šteti",
            additionalDriversTotal: "Ukupno dodatni vozači",
            crossingBordersTotal: "Ukupno prelazak granica",
            babySeatTotal: "Ukupno dječija sjedalica",
            gpsTotal: "Ukupno GPS",
            insurancePremiumCoverage: "(Premium CDW)",
            insuranceSuperCoverage: "(Standard CDW)",
            rentTotal: "Ukupno najam",
            deposit: "Depozit",
            total: "Ukupno",
            vehicleDetails:
                "ŠKODA OCTAVIA J03-A-458 OD: 11/06/2024 DO: 21/06/2024",
            vehicleWashed: "Vozilo oprano",
            firstAid: "Prva pomoć",
            spareTyre: "Rezervni točak",
            wheelCaps: "Poklopci točkova",
            chains: "Lanci",
            fireExtinguisher: "Aparat za gašenje požara",
            carJack: "Dizalica za auto",
            lightBulbs: "Sijalice",
            pickUpVehicleCondition: "STANJE VOZILA PRI PREUZIMANJU",
            fuel: "GORIVO",
            returnVehicleCondition: "STANJE VOZILA PRI VRAĆANJU",
            secretKey: "TAJNI KLJUČ",
            insurance: "Dodatno pokriće",
            from: "Od",
            to: "Do",
            cardNumber: "Broj Kartice",
        },
    },
};

export default Bosnian;
