import React, { useEffect, useState } from "react";
import HeadingTitle from "Components/Headings/HeadingTitle";
import SingleCarInformation from "./partials/SingleCarInformation";
import SingleCarRentList from "./partials/SingleCarRentList";
import SingleRentCar from "./partials/SingleRentCar";
import useCar from "Hooks/RentCarHooks/useCar";
import { useParams } from "react-router-dom";
import LoadingPlaceholder from "Components/Placeholders/LoadingPlaceholder/LoadingPlaceholder";
import SingleCarActions from "Containers/RentCars/SingleCarContainer/partials/SingeCarActions";

const SingleCarContainer = () => {
    // @ts-ignore
    const { getCarByUuid } = useCar();
    const { uuid } = useParams();

    const [carData, setCarData] = useState(null);

    const fetchCarByUuid = async () => {
        const car = await getCarByUuid(uuid);
        setCarData(car);
    };

    useEffect(() => {
        fetchCarByUuid();
    }, []);

    if (!carData) return <LoadingPlaceholder />;
    // @ts-ignore
    return (
        <div className="single-car-container">
            <div className=" flex items-center gap-3 relative">
                <HeadingTitle title={"Single Vehicle View"} />
                <SingleCarActions data={carData} />
            </div>
            <SingleCarInformation data={carData} />
            <SingleCarRentList
                currency={carData.company_location[0].currency}
                rentList={carData?.rented_car}
            />
            <SingleRentCar
                action={fetchCarByUuid}
                uuid={uuid}
                carPrice={carData.car_price}
                currency={carData.company_location[0].currency}
                rentedCars={carData.rented_car}
            />
        </div>
    );
};

export default SingleCarContainer;
