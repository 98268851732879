import React from "react";

interface LabelPropTypes {
    className?: string;
    title: string;
}
const Label = ({ className, title }: LabelPropTypes) => {
    return (
        <p
            className={`${className} uppercase !text-[#bbbbbb] font-semibold pb-1`}
        >
            <small>{title}</small>
        </p>
    );
};

export default Label;
