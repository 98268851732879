import React, { ReactNode } from "react";

interface ContentWrapperTypes {
    children: ReactNode;
}

const ContentWrapper = ({ children }: ContentWrapperTypes) => {
    return (
        <div className="rac-content-wrapper bg-white w-full h-fit p-3 pb-5 rounded-md lg:z-[120] h-full relative">
            {children}
        </div>
    );
};
export default ContentWrapper;
