import React, { useContext, useState } from "react";
import { Button, Modal } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { SingleIntegrationContext } from "../../../../Context/Integrations/SingleIntegrationContext";

interface DeleteConfigPropTypes {
    configId: string;
}
const DeleteConfigModal = ({ configId }: DeleteConfigPropTypes) => {
    const { deleteConfig, refresh } = useContext(SingleIntegrationContext);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = async () => {
        setLoading(true);
        await deleteConfig(configId).finally(() => {
            setLoading(false);
            setIsModalOpen(false);
            refresh();
        });
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    return (
        <>
            <Button
                onClick={() => showModal()}
                className={"h-[30px] w-[30px] flex items-center justify-center"}
                title={"Delete"}
                danger={true}
                type={"primary"}
            >
                <DeleteOutlined />
            </Button>
            <Modal
                title="Are you sure that you want to delete configuration?"
                open={isModalOpen}
                onOk={handleOk}
                okText={"Confirm"}
                okButtonProps={{ danger: true }}
                confirmLoading={loading}
                onCancel={handleCancel}
            >
                <p>
                    Deleting configuration will delete all periods and rules
                    related to it. This action can't be undone.
                </p>
            </Modal>
        </>
    );
};

export default DeleteConfigModal;
