import secureLocalStorage from "react-secure-storage";

export const useSession = () => {
    const SESSION_KEY = "session_cache_key";
    const USER_PERSIST_CACHE = "user_persist_cache";

    const getToken = () => {
        return JSON.parse(secureLocalStorage.getItem(SESSION_KEY));
    };

    const setToken = (token) => {
        secureLocalStorage.setItem(SESSION_KEY, JSON.stringify(token));
    };

    const clearToken = () => {
        secureLocalStorage.removeItem(SESSION_KEY);
    };

    const setUserCache = (data) => {
        secureLocalStorage.setItem(USER_PERSIST_CACHE, JSON.stringify(data));
    };

    const getUserCache = () => {
        return JSON.parse(secureLocalStorage?.getItem(USER_PERSIST_CACHE));
    };

    const getUserRole = () => {
        const cache = JSON.parse(
            secureLocalStorage.getItem(USER_PERSIST_CACHE),
        );
        return cache ? cache.roles[0].name : null;
    };

    const clearUserCache = () => {
        secureLocalStorage.clear();
    };

    return {
        getToken,
        setToken,
        clearToken,
        setUserCache,
        getUserCache,
        clearUserCache,
        getUserRole,
    };
};
