import React from "react";
import { useLocation } from "react-router-dom";
import QrCodeReservationNotFound from "Components/Status/QrCodeReservationStatus/QrCodeReservationNotFound";
import QrCodeReservationSuccess from "Components/Status/QrCodeReservationStatus/QrCodeReservationSuccess";

type QrCodeReservationStatusTypes = "NOT_FOUND" | "SUCCESS";
interface QrCodeReservationStateTypes {
    status: QrCodeReservationStatusTypes;
}
const QrCodeReservationStatusPage = () => {
    const location = useLocation();
    const { status }: QrCodeReservationStateTypes = location.state || {};
    const handleStatus = () => {
        switch (status) {
            case "NOT_FOUND":
                return <QrCodeReservationNotFound />;
            case "SUCCESS":
                return <QrCodeReservationSuccess />;
            default:
                return <QrCodeReservationNotFound />;
        }
    };

    return <>{handleStatus()}</>;
};

export default QrCodeReservationStatusPage;
