import React from "react";
import { CarHistoryProvider } from "../../../Context/History/CarHistoryContext";
import CarHistoryContainer from "../../../Containers/History/CarHistoryContainer";

const CarHistoryPage = () => {
    return (
        <CarHistoryProvider>
            <CarHistoryContainer />
        </CarHistoryProvider>
    );
};

export default CarHistoryPage;
