import React from "react";
import LogoDefault from "../../Assets/Images/rentelon.png";
import LogoWhite from "../../Assets/Images/rentelon-white-logo.svg";

type VersionType = "white";

interface LogoTypes {
    version?: VersionType;
}

const Logo = ({ version }: LogoTypes) => {
    const returnLogoVersion = () => {
        if (version === "white") {
            return <img width={"400"} src={LogoWhite} alt="Rac Logo White" />;
        } else {
            return <img width={"400"} src={LogoDefault} alt="Rac Logo" />;
        }
    };

    return returnLogoVersion();
};

export default Logo;
