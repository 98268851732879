import React from "react";
import { Link } from "react-router-dom";
import Logo from "Components/Logo/Logo";

const RegisterHeroComponent = () => {
    return (
        <div className="login-hero-component w-3/6 md:w-full h-screen bg-primary">
            <div className="relative w-full h-full">
                <div className="center-full">
                    <div className="flex flex-col justify-center">
                        <Logo version="white" />
                        <span className="text-center w-full mt-10 text-white">
                            {" "}
                            If you already have an account sign in!{" "}
                        </span>
                        <Link
                            className="text-center btn-outline rounded-xl hover-white text-white mt-6 w-50 rac-btn-lg border-white border-2"
                            to="/login"
                        >
                            <span className="font-medium ">Sign in</span>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RegisterHeroComponent;
