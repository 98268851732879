import React from "react";

const PdfTermsConditions = ({ value }) => {
    const maxCharsPerDiv = 10000; // Maximum characters per div

    // Function to split HTML content without breaking tags
    const splitHtmlIntoChunks = (htmlString, maxCharsPerDiv) => {
        // Parse the HTML string into a DOM Document
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlString, "text/html");
        const body = doc.body;

        // Collect all child nodes
        const nodes = Array.from(body.childNodes);

        // Serialize nodes and collect their lengths
        const nodeHtmls = nodes.map((node) => {
            if (node.nodeType === Node.ELEMENT_NODE) {
                return node.outerHTML;
            } else if (node.nodeType === Node.TEXT_NODE) {
                return node.textContent;
            } else if (node.nodeType === Node.COMMENT_NODE) {
                return `<!--${node.textContent}-->`;
            } else {
                return "";
            }
        });

        const nodeLengths = nodeHtmls.map((nodeHtml) => nodeHtml.length);
        const totalChars = nodeLengths.reduce((sum, len) => sum + len, 0);

        // Determine the number of chunks needed
        const numChunks = totalChars > maxCharsPerDiv ? 2 : 1;

        const chunks = [];

        if (numChunks === 1) {
            // Just one chunk
            chunks.push(nodeHtmls.join(""));
        } else {
            // Need to split into exactly 2 chunks
            const cumulativeLengths = [];
            let cumulativeLength = 0;
            for (let len of nodeLengths) {
                cumulativeLength += len;
                cumulativeLengths.push(cumulativeLength);
            }

            // Find the split index where cumulativeLengths >= totalChars / 2
            const halfTotal = totalChars / 2;
            let splitIndex = 0;

            for (let i = 0; i < cumulativeLengths.length; i++) {
                if (cumulativeLengths[i] >= halfTotal) {
                    splitIndex = i;
                    break;
                }
            }

            // Build first chunk
            const firstChunk = nodeHtmls.slice(0, splitIndex + 1).join("");
            // Build second chunk
            const secondChunk = nodeHtmls.slice(splitIndex + 1).join("");
            chunks.push(firstChunk);
            chunks.push(secondChunk);
        }

        return chunks;
    };

    const chunks = splitHtmlIntoChunks(value, maxCharsPerDiv);

    return (
        <div
            className={
                "terms-conditions p-6 relative md:z-[-1] ql-container ql-snow grid grid-cols-2"
            }
        >
            {chunks.map((chunk, index) => (
                <div
                    key={index}
                    className={"ql-editor"}
                    dangerouslySetInnerHTML={{
                        __html: chunk,
                    }}
                ></div>
            ))}
        </div>
    );
};

export default PdfTermsConditions;
