import React from "react";
import RentelonImage from "Assets/Images/rentelon.png";
import WoocommerceImage from "Assets/Images/Integrations/woocommerce.png";
import { Image } from "antd";
import {
    IntegrationServiceType,
    IntegrationTypes,
} from "../../Types/IntegrationTypes";
import integrationDrawer from "../../Containers/Integrations/partials/IntegrationDrawer";
import IntegrationImage from "../Integrations/IntegrationImage";

interface IntegrationCardPropTypes {
    title: string;
    integrationType: IntegrationTypes;
    description: string;
    serviceType: IntegrationServiceType;
    onClick: (integrationType: IntegrationTypes) => void;
}
const IntegrationCard = ({
    integrationType,
    serviceType,
    title,
    description,
    onClick,
}: IntegrationCardPropTypes) => {
    const handleOnClick = () => {
        onClick(integrationType);
    };
    return (
        <>
            <div
                onClick={handleOnClick}
                className={
                    "p-3 border-2 border-gray-300 sm:w-full w-[450px] rounded-md hover:border-blue-500 cursor-pointer transition-all duration-300 ease-in-out delay-0 flex gap-3"
                }
            >
                <div
                    className={
                        "w-[90px] h-[90px] bg-blue-100 rounded-md flex items-center justify-center"
                    }
                >
                    <IntegrationImage integrationType={integrationType} />
                </div>
                <div>
                    <h3 className={"text-2xl font-bold"}>{title}</h3>
                    <small className={"font-bold text-gray-400 text-[12px]"}>
                        {serviceType}
                    </small>
                    <p>
                        <small>{description}</small>
                    </p>
                </div>
            </div>
        </>
    );
};

export default IntegrationCard;
