import React from "react";
import DevelopersContainer from "../../../Containers/Developers";

const DevelopersPage = () => {
    return (
        <div className="integration-page">
            <DevelopersContainer />
        </div>
    );
};

export default DevelopersPage;
