import React, { createContext, useEffect, useState } from "react";
import {
    CarHistoryContextTypes,
    CarHistoryProviderTypes,
    CarHistoryType,
} from "Types/CarHistoryTypes";
import useCarHistory from "Hooks/History/useCarHistory";
import useLocations from "Hooks/LocationsHooks/useLocations";
import { LocationTypes } from "Types/LocationTypes";
import QueryBuilder from "Utils/QueryBuilderUtil";
import { addPageQueryParam, getPageParam } from "Helpers/PageHelper";

const CarHistoryContext = createContext<CarHistoryContextTypes>({
    setSearchQuery: () => {},
    setPageConfig: () => {},
    searchQuery: "",
    pageLoading: false,
    carHistories: [],
    locations: [],
    pageConfig: {
        current: 1,
        total: 1,
    },
});

const CarHistoryProvider = ({ children }: CarHistoryProviderTypes) => {
    const { getCarHistories } = useCarHistory();
    const { getLocations } = useLocations();

    const [pageLoading, setPageLoading] = useState<boolean>(false);
    const [carHistories, setCarHistories] = useState<CarHistoryType[]>([]);
    const [locations, setLocations] = useState<LocationTypes[]>([]);
    const [searchQuery, setSearchQuery] = useState<any>({});
    const [pageConfig, setPageConfig] = useState<any>({
        current: getPageParam(),
        total: 1,
        lastPage: 1,
    });
    const loadDataForCarHistories = async () => {
        setPageLoading(true);
        try {
            const query = QueryBuilder.createQuery(searchQuery);
            const [carHistory, locations]: any = await Promise.all([
                getCarHistories({ page: pageConfig.current, query }),
                getLocations(),
            ]);
            setCarHistories(carHistory?.data);
            setLocations(locations);
            setPageConfig({
                current: carHistory?.current_page,
                total: carHistory?.total,
                lastPage: carHistory?.last_page,
            });
        } catch (error: any) {
        } finally {
            setPageLoading(false);
        }
    };

    useEffect(() => {
        loadDataForCarHistories();
        addPageQueryParam(pageConfig.current);
        if (getPageParam() > pageConfig.lastPage) {
            addPageQueryParam(1);
            window.location.reload();
        }
    }, [searchQuery, pageConfig.current]);

    return (
        <CarHistoryContext.Provider
            value={{
                setSearchQuery,
                setPageConfig,
                searchQuery,
                pageLoading,
                pageConfig,
                carHistories: carHistories,
                locations: locations,
            }}
        >
            {children}
        </CarHistoryContext.Provider>
    );
};

export { CarHistoryProvider, CarHistoryContext };
