import { AnalyticsTypes } from "Types/AnalyticsTypes";

export const DefaultAnalyticsDashboardContextObject: AnalyticsTypes = {
    location_uuid: "",
    currency: "",
    statistic_box_data: {
        total_cars: 0,
        active_reservations: 0,
        finished_reservations: 0,
        earnings: "",
    },
    weeklyStatistic: {
        Monday: {
            reservations: 0,
            totalPrice: 0,
        },
        Tuesday: {
            reservations: 0,
            totalPrice: 0,
        },
        Wednesday: {
            reservations: 0,
            totalPrice: 0,
        },
        Thursday: {
            reservations: 0,
            totalPrice: 0,
        },
        Friday: {
            reservations: 0,
            totalPrice: 0,
        },
        Saturday: {
            reservations: 0,
            totalPrice: 0,
        },
        Sunday: {
            reservations: 0,
            totalPrice: 0,
        },
    },
    rentedCarsByPrice: [],
    yearlyStatistic: [],
};
