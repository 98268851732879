import React from "react";
import { Tooltip, Divider } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";

interface HeadingTitleTypes {
    title: string;
    className?: string;
    tooltip?: string;
}

const HeadingTitle = ({ title, className, tooltip }: HeadingTitleTypes) => {
    return (
        <div className={`${className} w-full`}>
            <Divider
                style={{ borderColor: "#BBBBBB" }}
                orientation="left"
                orientationMargin="0"
                className="uppercase mt-5"
            >
                <div className={"flex gap-2 items-center"}>
                    <span className="text-[12px]">{title}</span>
                    {tooltip && (
                        <Tooltip title={tooltip}>
                            <QuestionCircleOutlined className="text-[14px]" />
                        </Tooltip>
                    )}
                </div>
            </Divider>
        </div>
    );
};

export default HeadingTitle;
