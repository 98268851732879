import React, { useEffect, useState } from "react";
import HeadingTitle from "Components/Headings/HeadingTitle";
import { getDaysBetweenDates, handleDisabledDates } from "Utils/DateUtils";
import useCar from "Hooks/RentCarHooks/useCar";
import { arraySum } from "Utils/ArrayUtils";
import { Input, InputNumber, Tabs, Select } from "antd";
import Button from "Components/Button";
import { TimePicker, DatePicker } from "antd";
import TextArea from "antd/es/input/TextArea";
import AdditionalDriverOption from "./AdditionalRentOptions/AdditionalDriverOption";
import HorizontalDivider from "../../../../Components/HorizontalDivider";
import CrossingBorderOption from "./AdditionalRentOptions/CrossingBorderOption";
import SummaryDescriptions from "./SummaryDescriptions";
import AdditinalDriverTableDetails from "./AdditionalRentOptions/AdditinalDriverTableDetails";
import TypeReservationHandler from "Components/TypeReservation/TypeReservationHandler";

const { RangePicker } = DatePicker;
const SingleRentCar = ({ uuid, carPrice, currency, action, rentedCars }) => {
    const { rentACar } = useCar();

    const [totalPrice, setTotalPrice] = useState(true);
    const [totalDays, setTotalDays] = useState(0);
    const [disabledDays, setDisabledDays] = useState([]);
    const [loading, setLoading] = useState(false);
    const [additionalDrivers, setAdditionalDrivers] = useState([]);
    const [crossingBorderList, setCrossingBordersList] = useState([]);
    const [defaultDateValue, setDefaultDateValue] = useState(null);

    const [formData, setFormData] = useState({
        uuid: uuid,
        rent_price: carPrice,
        insurance_price: null,
        insurance_type: null,
        damage_excess: null,
        deposit_price: null,
        additional_driver_price: 0,
        baby_seat_price: null,
        crossing_border_price: 0,
        gps_price: null,
        total_price: null,
        note: null,
        check_in: null,
        check_out: null,
        from: null,
        to: null,
        customer: {
            customer_name: null,
            customer_email: null,
            customer_phone: null,
            customer_country: null,
            customer_passport: null,
            customer_driving_licence: null,
            reservationType: "DEFAULT",
            reservationTypeReference: null,
        },
    });

    const setRentCar = async (e) => {
        setLoading(true);
        e.preventDefault();
        const data = {
            ...formData,
            total_price: totalPrice,
            total_days: totalDays?.toString(),
            crossing_border_list: crossingBorderList,
            additional_drivers_list: additionalDrivers,
        };
        rentACar(data, setLoading, action);
    };

    const crossingBordersAction = (data) => {
        const { bordersList, price } = data;
        setFormData({ ...formData, crossing_border_price: price });
        setCrossingBordersList(bordersList);
    };

    const additionalDriversAction = (data) => {
        const { driverName, driverLicence, price } = data;
        setAdditionalDrivers([
            ...additionalDrivers,
            {
                driverName: driverName,
                driverLicence: driverLicence,
                price: price,
            },
        ]);
        setFormData({
            ...formData,
            additional_driver_price: formData.additional_driver_price + price,
        });
    };

    const handleSelectedDayRange = (date, timestring) => {
        setFormData({
            ...formData,
            from: timestring[0],
            to: timestring[1],
        });
        setDefaultDateValue({
            from: timestring[0],
            to: timestring[1],
        });
    };

    const isDateDisabled = (current) => {
        const formattedDate = current.format("YYYY-MM-DD");
        return disabledDays?.includes(formattedDate);
    };

    useEffect(() => {
        const {
            rent_price,
            insurance_price,
            additional_driver_price,
            baby_seat_price,
            gps_price,
            crossing_border_price,
        } = formData;

        const prices = [
            parseFloat(rent_price),
            parseFloat(insurance_price || 0),
            parseFloat(additional_driver_price || 0),
            parseFloat(baby_seat_price || 0),
            parseFloat(gps_price || 0),
        ];

        const days = getDaysBetweenDates(formData.from, formData.to);
        const getReservationDays = () => {
            let days = getDaysBetweenDates(formData.from, formData.to);
            if (days === 0 && formData.from && formData.to) {
                return days + 1;
            }
            if (days === 1 && formData.check_in < formData.check_out) {
                return days + 1;
            }
            if (
                days !== 1 &&
                days !== 0 &&
                formData.check_in < formData.check_out
            ) {
                return days + 1;
            }
            return days;
        };

        let total = days
            ? arraySum(prices) * getReservationDays()
            : arraySum(prices);

        if (crossing_border_price) {
            total = parseFloat(total) + parseInt(crossing_border_price);
        }
        setTotalPrice(total.toFixed(2));
        setTotalDays(getReservationDays());

        const daysToDisable = [];
        rentedCars.map((reservation) => {
            const days = handleDisabledDates(reservation.from, reservation.to);
            daysToDisable.push(...days);
            return null;
        });
        setDisabledDays(daysToDisable);
    }, [formData, rentedCars, defaultDateValue, additionalDrivers]);
    // Additional drivers table items
    const items = [
        {
            key: "1",
            label: "Additional Driver",
            disabled: !additionalDrivers.length > 0,
            children: (
                <AdditinalDriverTableDetails
                    setDrivers={setAdditionalDrivers}
                    drivers={additionalDrivers}
                    setPrice={setFormData}
                    formData={formData}
                />
            ),
        },
    ];

    return (
        <div className="single-rent-car">
            <HeadingTitle title={"Add Customer for Rent"} />
            <div className="flex gap-3">
                <div className={"flex flex-col gap-3 w-full"}>
                    <Input
                        placeholder="Full Name"
                        className="w-full"
                        onChange={(e) =>
                            setFormData({
                                ...formData,
                                customer: {
                                    ...formData.customer,
                                    customer_name: e.target.value,
                                },
                            })
                        }
                    />
                    <Input
                        placeholder="Email Address"
                        className="w-full"
                        onChange={(e) =>
                            setFormData({
                                ...formData,
                                customer: {
                                    ...formData.customer,
                                    customer_email: e.target.value,
                                },
                            })
                        }
                    />
                    <InputNumber
                        placeholder="Phone"
                        className="w-full"
                        onChange={(phone) =>
                            setFormData({
                                ...formData,
                                customer: {
                                    ...formData.customer,
                                    customer_phone: phone,
                                },
                            })
                        }
                    />
                    <Input
                        placeholder="Country"
                        className="w-full"
                        onChange={(e) =>
                            setFormData({
                                ...formData,
                                customer: {
                                    ...formData.customer,
                                    customer_country: e.target.value,
                                },
                            })
                        }
                    />
                    <Input
                        placeholder="Passport Number"
                        className="w-full"
                        onChange={(e) =>
                            setFormData({
                                ...formData,
                                customer: {
                                    ...formData.customer,
                                    customer_passport: e.target.value,
                                },
                            })
                        }
                    />
                    <Input
                        placeholder="Driving Licence"
                        className="w-full"
                        onChange={(e) =>
                            setFormData({
                                ...formData,
                                customer: {
                                    ...formData.customer,
                                    customer_driving_licence: e.target.value,
                                },
                            })
                        }
                    />
                </div>
                <div className="flex flex-col gap-3 w-full">
                    <InputNumber
                        type="number"
                        placeholder="Deposit price"
                        className="input w-full"
                        onChange={(deposit) =>
                            setFormData({
                                ...formData,
                                deposit_price: deposit === "" ? 0 : deposit,
                            })
                        }
                    />
                    <div className={"flex items-center gap-3"}>
                        <InputNumber
                            placeholder="Insurance price per/day"
                            className="basis-6/12"
                            onChange={(insurance) =>
                                setFormData({
                                    ...formData,
                                    insurance_price:
                                        insurance === "" ? 0 : insurance,
                                })
                            }
                        />
                        <Select
                            onChange={(value) =>
                                setFormData({
                                    ...formData,
                                    insurance_type: value,
                                })
                            }
                            placeholder={"Select insurance type"}
                            className={"basis-6/12"}
                        >
                            <Select.Option value={"PREMIUM"}>
                                Premium Coverage
                            </Select.Option>
                            <Select.Option value={"SCDW"}>
                                SCDW (Super Colision Damage Waiver)
                            </Select.Option>
                        </Select>
                    </div>
                    <Input
                        placeholder="Rent price per/day"
                        className="input w-full"
                        onChange={(rentPrice) =>
                            setFormData({
                                ...formData,
                                rent_price: rentPrice.target.value
                                    ? rentPrice.target.value
                                    : null,
                            })
                        }
                    />
                    <div className={"flex w-full md:flex-wrap gap-3"}>
                        <TimePicker
                            className={"w-full"}
                            placeholder={"Pick Up"}
                            format={"HH:mm"}
                            onChange={(time, timeString) => {
                                setFormData({
                                    ...formData,
                                    check_in: timeString,
                                });
                            }}
                        />
                        <TimePicker
                            className={"w-full"}
                            placeholder={"Return"}
                            format={"HH:mm"}
                            onChange={(time, timeString) => {
                                setFormData({
                                    ...formData,
                                    check_out: timeString,
                                });
                            }}
                        />
                    </div>
                    <RangePicker
                        onChange={handleSelectedDayRange}
                        disabledDate={isDateDisabled}
                    />
                    <TextArea
                        rows={1}
                        placeholder="Leave a note for customer..."
                        onChange={(e) =>
                            setFormData({
                                ...formData,
                                note:
                                    e.target.value === ""
                                        ? null
                                        : e.target.value,
                            })
                        }
                    />
                </div>
            </div>
            <HorizontalDivider classes={"mt-5 mb-5"} />
            <div className="flex flex-wrap gap-3">
                <AdditionalDriverOption onAction={additionalDriversAction} />
                <CrossingBorderOption
                    crossingBorderList={crossingBorderList}
                    formPrice={formData.crossing_border_price}
                    currency={currency}
                    onAction={crossingBordersAction}
                />
                <Input
                    placeholder="GPS per/day"
                    className="w-36 sm:w-full"
                    onChange={(e) =>
                        setFormData({
                            ...formData,
                            gps_price: e.target.value || null,
                        })
                    }
                />
                <Input
                    placeholder="Baby Seat per/day"
                    className="w-36 sm:w-full"
                    onChange={(e) =>
                        setFormData({
                            ...formData,
                            baby_seat_price: e.target.value || null,
                        })
                    }
                />
                <Input
                    placeholder="Damage/Theft Excess"
                    className="w-40 sm:w-full"
                    title={"Damage/Theft Excess"}
                    onChange={(e) =>
                        setFormData({
                            ...formData,
                            damage_excess: e.target.value || null,
                        })
                    }
                />
            </div>
            {additionalDrivers.length > 0 && (
                <Tabs
                    className={"bg-[#fafafa] mt-3 rounded-xl"}
                    tabPosition={"left"}
                    items={items}
                    size={"small"}
                />
            )}

            <HeadingTitle title={"Reservation Type"} />
            <div className="flex items-center gap-3">
                <div>
                    <Select
                        defaultValue="DEFAULT"
                        placeholder="Select Reservation Type"
                        style={{ width: 200 }}
                        onChange={(type) =>
                            setFormData({
                                ...formData,
                                customer: {
                                    ...formData.customer,
                                    reservationType: type,
                                },
                            })
                        }
                        options={[
                            { value: "BOOKING", label: "Booking" },
                            { value: "CAR_FLEXI", label: "Car Flexi" },
                            { value: "DISCOVER_CARS", label: "Discover Car" },
                            { value: "ECONOMY_CARS", label: "Economy Cars" },
                            { value: "WEB_SHOP", label: "Web Shop" },
                            { value: "WALK_IN", label: "Walk In" },
                            { value: "DEFAULT", label: "Default" },
                        ]}
                    />
                </div>
                <Input
                    placeholder="Reference"
                    className="w-36 sm:w-full"
                    onChange={(e) =>
                        setFormData({
                            ...formData,
                            customer: {
                                ...formData.customer,
                                reservationTypeReference: e.target.value,
                            },
                        })
                    }
                />

                <TypeReservationHandler
                    type={formData.customer.reservationType}
                    size={200}
                />
            </div>

            <HeadingTitle title={"Rental Summary"} />
            <SummaryDescriptions
                dateRange={defaultDateValue}
                additionalDriversList={additionalDrivers}
                crossingBorderList={crossingBorderList}
                totalDays={totalDays}
                totalPrice={totalPrice}
                rentalData={formData}
                currency={currency}
                setFormData={setFormData}
            />

            <HeadingTitle title={"Actions"} />
            <div className="w-full text-end">
                <Button
                    isLoading={loading}
                    onClick={setRentCar}
                    buttonType={"primary"}
                    className={"w-44"}
                >
                    Create Reservation
                </Button>
            </div>
        </div>
    );
};
export default SingleRentCar;
