import { IntegrationTypes } from "Types/IntegrationTypes";
import Integrations from "./partials/integrations";

const IntegrationUtils = {
    getIntegrationLongDescription: (integrationType: IntegrationTypes) => {
        const integration = Integrations.find(
            (integration) => integration.integrationType === integrationType,
        );
        return integration?.longDescription;
    },
    getIntegrationShortDescrption: (integrationType: IntegrationTypes) => {
        const integration = Integrations.find(
            (integration) => integration.integrationType === integrationType,
        );
        return integration?.description;
    },
};
// @ts-ignore
export default IntegrationUtils;
