import React from "react";
import RegisterForm from "Components/AuthComponents/RegisterComponents/RegisterForm";
import RegisterHeroComponent from "Components/AuthComponents/RegisterComponents/RegisterHeroComponent";
import RegisterLayout from "Layouts/AuthLayouts/RegisterLayout";

const RegisterPage = () => {
    return (
        <RegisterLayout>
            <RegisterForm />
            <RegisterHeroComponent />
        </RegisterLayout>
    );
};
export default RegisterPage;
