import React from "react";
import { Tag } from "antd";

interface ReservationCanceledInterface {
    canceled: "CANCELED";
}
const ReservationCanceled = ({ canceled }: ReservationCanceledInterface) => {
    return (
        <Tag
            className={"px-2 py-1 font-semibold border-[2px]"}
            color={"purple"}
        >
            {canceled}
        </Tag>
    );
};

export default ReservationCanceled;
