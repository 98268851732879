import React from "react";
import { Tag, Tooltip } from "antd";

const TodayStatus = () => {
    return (
        <Tag className={"pl-3 pr-3 pt-1 pb-1 font-bold"} color={"blue"}>
            <Tooltip title={"Return status"}>TODAY</Tooltip>
        </Tag>
    );
};
export default TodayStatus;
