import React, { useContext, useState } from "react";
import { Button, Divider, Input, Modal, Select } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { IntegrationStatuses } from "Types/IntegrationTypes";
import useIntegrations from "Hooks/IntegrationHooks/useIntegrations";
import { SingleIntegrationContext } from "Context/Integrations/SingleIntegrationContext";
import Label from "../../../Components/Label";

interface UpdateIntegrationModalProps {
    data: {
        integration_name: string;
        status: IntegrationStatuses;
        integrationId: string;
    };
}
const UpdateIntegrationModal = ({ data }: UpdateIntegrationModalProps) => {
    const { updateIntegration } = useIntegrations();
    const { refresh } = useContext(SingleIntegrationContext);
    const formInitializeData = data;

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [formData, setFormData] = useState(formInitializeData);
    const [isLoading, setIsLoading] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = async () => {
        setIsModalOpen(false);
        setIsLoading(true);
        await updateIntegration(formData)
            .then((res) => {
                if (res) {
                    setIsModalOpen(false);
                    refresh();
                }
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    return (
        <>
            <Button
                onClick={showModal}
                className={"h-[30px] w-[30px] flex items-center justify-center"}
                size={"middle"}
                title={"Edit"}
                type={"default"}
            >
                <EditOutlined />
            </Button>
            <Modal
                title="Update Integration"
                open={isModalOpen}
                okText={"Update"}
                onOk={handleOk}
                confirmLoading={isLoading}
                onCancel={handleCancel}
            >
                <div className={"flex gap-3 flex-col"}>
                    <div>
                        <Label title={"Integration Name"} />
                        <Input
                            value={formData.integration_name}
                            onChange={(e) =>
                                setFormData({
                                    ...formData,
                                    integration_name: e.target.value,
                                })
                            }
                            type={"text"}
                            placeholder={"Integration name..."}
                        />
                    </div>
                    <div>
                        <Label title={"Select status"} />
                        <Select
                            className={"w-full"}
                            value={formData.status}
                            onChange={(value) =>
                                setFormData({ ...formData, status: value })
                            }
                            options={[
                                { value: "ACTIVE", label: "ACTIVE" },
                                { value: "DISABLED", label: "DISABLED" },
                            ]}
                        />
                    </div>
                </div>
                <Divider />
            </Modal>
        </>
    );
};

export default UpdateIntegrationModal;
