import React from "react";
import { Image, Tag, Tooltip } from "antd";
import { IntegrationStatuses } from "Types/IntegrationTypes";

interface IntegrationStatusPropsTypes {
    integrationStatus: IntegrationStatuses;
    className?: string;
}
const IntegrationStatus = ({
    integrationStatus,
    className,
}: IntegrationStatusPropsTypes) => {
    const IntegrationStatusHandler = () => {
        let integrationColor = "";
        switch (integrationStatus) {
            case "ACTIVE":
                integrationColor = "green";
                break;
            case "DISABLED":
                integrationColor = "red";
                break;
            case "EXPIRED":
                integrationColor = "orange";
                break;
            default:
                integrationColor = "";
                break;
        }
        return (
            <Tooltip showArrow={true} title={"Integration Status"}>
                <Tag
                    className={`font-bold ${className}`}
                    color={integrationColor}
                >
                    {" "}
                    {integrationStatus}{" "}
                </Tag>
            </Tooltip>
        );
    };
    return <IntegrationStatusHandler />;
};

export default IntegrationStatus;
