import React from "react";
import LoginLayout from "Layouts/AuthLayouts/LoginLayout";
import LoginForm from "Components/AuthComponents/LoginComponents/LoginForm";

const LoginPage = () => {
    return (
        <LoginLayout>
            <LoginForm />
        </LoginLayout>
    );
};

export default LoginPage;
