import { useState } from "react";
import { useAuth } from "Hooks/AuthHooks/useAuth";
import { Link } from "react-router-dom";
import HorizontalDivider from "Components/HorizontalDivider/HorizontalDivider";
import Logo from "Components/Logo/Logo";
import Button from "Components/Button";
import { Input } from "antd";

interface LoginDataTpyes {
    email: string | null;
    password: string | null;
}

const LoginForm = () => {
    const [email, setEmail] = useState<string | null>(null);
    const [password, setPassword] = useState<string | null>(null);
    const [loader, setLoader] = useState<boolean>(false);

    const { login } = useAuth();

    const loginUser = () => {
        setLoader(true);
        const data: LoginDataTpyes = {
            email: email,
            password: password,
        };
        login(data, setLoader);
    };
    return (
        <div className="flex h-full w-full sm:mt-10 sm:m-2">
            <div className="relative w-full max-w-[460px] center-full">
                <div className="w-[200px] mx-auto mb-5">
                    <Logo />
                </div>
                <div className={"flex w-full justify-center"}>
                    <span className="font-medium w-full md-min:text-3xl sm:text-xl text-secondary text-center">
                        Login to your account
                    </span>
                </div>
                <div className="p-7 bg-white max-w-[460px] rounded-lg rac-shadow mt-7">
                    <div className="flex flex-col justify-center w-full">
                        <div className={"flex flex-col"}>
                            <label className={"text-sm"}>Email</label>
                            <Input
                                onChange={(e) => setEmail(e.target.value)}
                                className=" mb-5"
                                name="email"
                                type="email"
                                placeholder="Enter your email"
                            />
                        </div>
                        <div className={"flex flex-col"}>
                            <label className={"text-sm"}>Password</label>
                            <Input
                                onChange={(e) => setPassword(e.target.value)}
                                className=" mb-5"
                                name="password"
                                type="password"
                                placeholder="Enter password"
                            />
                        </div>
                        {/*@ts-ignore*/}
                        <Link
                            to={"/forgot-password"}
                            className={"text-end mb-5"}
                        >
                            <span className="text-primary text-sm">
                                Forgot password?
                            </span>
                        </Link>
                        <Button
                            className={"mb-5"}
                            buttonType={"primary"}
                            onClick={loginUser}
                            isLoading={loader}
                        >
                            Login
                        </Button>
                        {/*@ts-ignore*/}
                        <span className={"text-[12px] text-secondary"}>
                            By logging in you agree to our user terms and
                            integrity policy.
                        </span>
                        <HorizontalDivider classes={"mt-3 mb-5"} />
                        <div className={"flex justify-evenly"}>
                            <Link
                                className={
                                    "text-xs text-gray font-bold no-underline transition-all hover:underline"
                                }
                                to={"/"}
                            >
                                User Terms
                            </Link>
                            <Link
                                className={
                                    "text-xs text-gray font-bold no-underline transition-all hover:underline"
                                }
                                to={"/"}
                            >
                                Integrity Policy
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LoginForm;
