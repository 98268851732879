import React from "react";
import { useLocation } from "react-router-dom";
import HeadingTitle from "Components/Headings/HeadingTitle";
import AddLocationForm from "./partials/AddLocationForm";

const AddLocation = () => {
    const location = useLocation();

    return (
        <div className="add-car-container">
            {!location?.state ? (
                <HeadingTitle title="Add Location" />
            ) : (
                <HeadingTitle title="Update Location" />
            )}
            <AddLocationForm />
        </div>
    );
};
export default AddLocation;
