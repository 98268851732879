import React from "react";
import withRole from "../../../Providers/AuthProvider/WithRole";
import EditRentCar from "../../../Containers/RentCars/EditRentCar/EditRentCar";

const EditCarPage = () => {
    return (
        <div className="add-car-page">
            <EditRentCar />
        </div>
    );
};

export default withRole(EditCarPage, ["admin"]);
