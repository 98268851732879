// @ts-nocheck
import { App } from "antd";
import { ActivationDataTypes } from "Types/IntegrationTypes";
import axios from "axios";
import { useNavigate } from "react-router-dom";
const useIntegrations = () => {
    const { message, notification } = App.useApp();
    const navigate = useNavigate();
    const getActivatedIntegrations = async () => {
        try {
            const { data } = await axios.get("/get/integrations");
            return data;
        } catch (error) {
            message.error("Failed to load integration");
        }
    };

    const getIntegrationByUuid = async (uuid: string | undefined) => {
        try {
            const { data } = await axios.get(`/get/integration/${uuid}`);
            return data;
        } catch (error) {
            notification.error({
                // @ts-ignore
                message:
                    error?.response?.data?.errors ||
                    error?.response?.data?.error ||
                    error.message,
            });
            navigate("/integrations");
        }
    };

    const addIntegration = async (data: ActivationDataTypes) => {
        try {
            const response = await axios.post("/add/integration", data);

            const {
                data: { token, integrationId },
            } = response;

            notification.success({
                message: "Successfully added integration.",
            });
            navigate(`/integration/${integrationId}`, { state: { token } });
        } catch (error) {
            notification.error({
                // @ts-ignore
                message: error?.response?.data?.errors,
            });
        }
    };

    const updateIntegration = async (data: any) => {
        try {
            const response = await axios.patch(
                `/edit/integration/${data.integrationId}`,
                data,
            );
            notification.success({
                message: "Successfully updated integration.",
            });
            return true;
        } catch (error) {
            notification.error({
                // @ts-ignore
                message:
                    error?.response?.data?.errors ||
                    error?.response?.data?.error ||
                    error.message,
            });
            return false;
        }
    };

    const deleteIntegration = async (integrationId: string) => {
        try {
            await axios.delete(`/remove/integration/${integrationId}`);
            notification.success({
                // @ts-ignore
                message: "Successfully deleted integration.",
            });
            navigate("/integrations");
        } catch (error) {
            notification.error({
                // @ts-ignore
                message:
                    error?.response?.data?.errors ||
                    error?.response?.data?.error ||
                    error.message,
            });
        }
    };

    return {
        addIntegration,
        deleteIntegration,
        updateIntegration,
        getActivatedIntegrations,
        getIntegrationByUuid,
    };
};

export default useIntegrations;
