import React from "react";
import { formatDate } from "Utils/DateUtils";
import { useNavigate } from "react-router-dom";
import { StarFilled } from "@ant-design/icons";
import UserAvatar from "Assets/Images/user.png";
import { EmployeeAnalyticsTypes } from "../../../../Types/EmployeeTypes";
import {
    Avatar,
    Badge,
    Button,
    Collapse,
    CollapseProps,
    Divider,
    List,
    Tag,
} from "antd";

interface EmployeeListProps {
    employees: EmployeeAnalyticsTypes[];
    currency: string;
}

const EmployeeList = ({ employees, currency }: EmployeeListProps) => {
    const navigate = useNavigate();
    const handleCar = (uuid: string) => {
        navigate(`/car/${uuid}`);
    };
    const handleCustomer = (uuid: string) => {
        navigate(`/customer/${uuid}`);
    };
    return (
        <List
            className={"bg-gray-100 p-3 rounded-md"}
            itemLayout="vertical"
            size="small"
            dataSource={employees?.map((employee) => {
                const items: CollapseProps["items"] = [
                    {
                        key: "1",
                        label: "Show Reservations",
                        children: employee?.reservations.map((reservation) => {
                            return (
                                <div
                                    className={
                                        "w-full p-3 bg-white border mt-2 mb-2 rounded-md flex items-center justify-between flex-wrap"
                                    }
                                >
                                    <div
                                        className={
                                            "flex items-center flex-wrap gap-3"
                                        }
                                    >
                                        <p className={"font-bold w-[150px]"}>
                                            {reservation.car_name}
                                        </p>
                                        <p className={"w-[230px]"}>
                                            <Tag color={"gray"}>
                                                PICK UP:{" "}
                                                {formatDate(
                                                    reservation.from,
                                                    "/",
                                                )}{" "}
                                                - {reservation.check_in}
                                            </Tag>
                                        </p>
                                        <p className={"w-[230px]"}>
                                            <Tag color={"gray"}>
                                                RETURN:{" "}
                                                {formatDate(
                                                    reservation.to,
                                                    "/",
                                                )}{" "}
                                                - {reservation.check_out}{" "}
                                            </Tag>
                                        </p>
                                        <p className={"w-[130px] uppercase"}>
                                            Total Days: {reservation.total_days}{" "}
                                        </p>
                                        <p className={"w-[200px] uppercase"}>
                                            Total Price:{" "}
                                            {reservation.total_price} {currency}{" "}
                                        </p>
                                        <p className={"w-[200px] uppercase"}>
                                            Insurance Price:{" "}
                                            {reservation.insurance_price}{" "}
                                            {currency}{" "}
                                        </p>
                                    </div>
                                    <div className={"flex gap-3 flex-wrap"}>
                                        <Button
                                            className={"sm:w-full"}
                                            onClick={() =>
                                                handleCar(reservation.uuid)
                                            }
                                        >
                                            Car
                                        </Button>
                                        <Button
                                            className={"sm:w-full"}
                                            onClick={() =>
                                                handleCustomer(
                                                    reservation.customer[0]
                                                        .uuid,
                                                )
                                            }
                                        >
                                            Reservation
                                        </Button>
                                    </div>
                                </div>
                            );
                        }),
                    },
                ];
                return {
                    title: employee.fullName,
                    avatar: employee.profileImage
                        ? `${process.env.REACT_APP_DOMAIN}${employee.profileImage}`
                        : UserAvatar,
                    totalDays: employee.totalDays,
                    totalInsurance: employee.insuranceTotalPrice,
                    totalReservations: employee.totalReservations,
                    totalPrice: employee.totalPrice,
                    averagerPrice: employee.averagePrice,
                    content: (
                        <Collapse
                            items={items}
                            bordered={false}
                            className={"shadow"}
                        />
                    ),
                };
            })}
            renderItem={(item, index) => (
                <Badge.Ribbon
                    className={index !== 0 ? "hidden" : ""}
                    color={"yellow"}
                    text={<StarFilled />}
                >
                    <List.Item
                        className={"bg-white shadow mt-2 rounded-md"}
                        key={item.title}
                    >
                        <List.Item.Meta
                            className={"mt-2"}
                            avatar={<Avatar size={50} src={item.avatar} />}
                            title={
                                <h3 className={"font-medium uppercase"}>
                                    {item.title}
                                </h3>
                            }
                        />
                        <div
                            className={
                                "flex gap-3 flex-wrap border-[1px] rounded-xl  border-gray-200 p-3"
                            }
                        >
                            <small className={"font-medium uppercase"}>
                                Total Days:{" "}
                                <Tag color={"blue"}>
                                    {" "}
                                    <span className={"font-bold"}>
                                        {item.totalDays}{" "}
                                    </span>{" "}
                                </Tag>
                            </small>
                            <small className={"font-medium uppercase"}>
                                Total Reservations:{" "}
                                <Tag color={"blue"}>
                                    {" "}
                                    <span className={"font-bold"}>
                                        {item.totalReservations}{" "}
                                    </span>{" "}
                                </Tag>{" "}
                            </small>
                            <small className={"font-medium uppercase"}>
                                Total Insurance:{" "}
                                <Tag color={"purple"}>
                                    {" "}
                                    <span className={"font-bold"}>
                                        {item.totalInsurance} {currency}{" "}
                                    </span>{" "}
                                </Tag>{" "}
                            </small>
                            <small className={"font-medium uppercase"}>
                                Total Price:{" "}
                                <Tag color={"green"}>
                                    {" "}
                                    <span className={"font-bold"}>
                                        {item.totalPrice} {currency}{" "}
                                    </span>{" "}
                                </Tag>{" "}
                            </small>
                            <small className={"font-medium uppercase"}>
                                Average Total:{" "}
                                <Tag color={"orange"}>
                                    {" "}
                                    <span className={"font-bold"}>
                                        {item.averagerPrice} {currency}{" "}
                                    </span>{" "}
                                </Tag>{" "}
                            </small>
                        </div>
                        <Divider />
                        <div className={"mt-3 mb-3"}>{item.content}</div>
                    </List.Item>
                </Badge.Ribbon>
            )}
        />
    );
};
export default EmployeeList;
