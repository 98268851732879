import React, { useContext, useState } from "react";
import { Button, Divider, Input, Modal, Select } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import useLocations from "Hooks/LocationsHooks/useLocations";
import useCar from "Hooks/RentCarHooks/useCar";
import { SingleIntegrationContext } from "Context/Integrations/SingleIntegrationContext";
import useIntegrationConfig from "Hooks/IntegrationHooks/IntegrationConfig/useIntegrationConfig";

interface RentelonApiCreatePropTypes {
    integrationId: string;
}
const CreateConfigModal = ({ integrationId }: RentelonApiCreatePropTypes) => {
    const formDataInitialValues = {
        car_sipp_code_id: "",
        location_id: "",
        integration_id: integrationId,
        description: "",
        default_price: "",
        insurance_price: "",
        deposit: "",
        availability: "",
        status: "",
    };

    const { refresh } = useContext(SingleIntegrationContext);

    const { createConfig } = useIntegrationConfig();
    const { getLocations } = useLocations();
    const { getCarSippCodes } = useCar();

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [okLoading, setOkLoading] = useState(false);

    const [locations, setLocations] = useState(null);
    const [sipp, setSipp] = useState(null);

    const [formData, setFormData] = useState(formDataInitialValues);

    const showModal = async () => {
        setIsModalOpen(true);
        setLoading(true);
        const [locations, sippCodes]: any = await Promise.all([
            getLocations(),
            getCarSippCodes(),
        ]).finally(() => {
            setLoading(false);
        });
        setLocations(locations);
        setSipp(sippCodes);
    };

    const handleOk = async () => {
        setOkLoading(true);
        await createConfig(formData)
            .then((res) => {
                if (res) {
                    setIsModalOpen(false);
                    setFormData(formDataInitialValues);
                    refresh();
                }
            })
            .finally(() => {
                setOkLoading(false);
            });
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    return (
        <>
            <Button
                onClick={() => showModal()}
                className={"h-[30px] w-[30px] flex items-center justify-center"}
                title={"Add Configuration"}
                type={"primary"}
            >
                <PlusOutlined />
            </Button>
            <Modal
                title="Create Rentelon API Configuration"
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                okText={"Create"}
                confirmLoading={okLoading}
            >
                <div className={"flex flex-col gap-3 mt-5 mb-5"}>
                    <Select
                        value={formData?.location_id || undefined}
                        onChange={(value) =>
                            setFormData({ ...formData, location_id: value })
                        }
                        placeholder={"Select location ..."}
                        className={"w-full"}
                        // @ts-ignore
                        disabled={!locations?.length > 0}
                        loading={loading}
                        // @ts-ignore
                        options={locations?.map((location) => {
                            const { name, address, uuid } = location;
                            return {
                                value: uuid,
                                label: `${name} - ${address}`,
                            };
                        })}
                    />
                    <Select
                        value={formData?.car_sipp_code_id || undefined}
                        onChange={(value) =>
                            setFormData({
                                ...formData,
                                car_sipp_code_id: value,
                            })
                        }
                        placeholder={"Select category..."}
                        className={"w-full"}
                        // @ts-ignore
                        disabled={!sipp?.length > 0}
                        loading={loading}
                        // @ts-ignore
                        options={sipp?.map((item) => {
                            const { code, uuid, description } = item;
                            return {
                                value: uuid,
                                label: `${code} - ${description}`,
                            };
                        })}
                    />
                    <Input
                        value={formData?.description || undefined}
                        onChange={(e) =>
                            setFormData({
                                ...formData,
                                description: e.target.value,
                            })
                        }
                        type={"text"}
                        placeholder={"car description e.g passat or similiar"}
                    />
                    <Input
                        value={formData?.default_price || undefined}
                        onChange={(e) =>
                            setFormData({
                                ...formData,
                                default_price: e.target.value,
                            })
                        }
                        type={"text"}
                        placeholder={"Default price per day"}
                    />
                    <Input
                        value={formData?.insurance_price || undefined}
                        onChange={(e) =>
                            setFormData({
                                ...formData,
                                insurance_price: e.target.value,
                            })
                        }
                        type={"text"}
                        placeholder={"Insurance Price per day"}
                    />
                    <Input
                        value={formData?.deposit || undefined}
                        onChange={(e) =>
                            setFormData({
                                ...formData,
                                deposit: e.target.value,
                            })
                        }
                        type={"text"}
                        placeholder={"Deposit"}
                    />
                    <Input
                        value={formData?.availability || undefined}
                        onChange={(e) =>
                            setFormData({
                                ...formData,
                                availability: e.target.value,
                            })
                        }
                        type={"text"}
                        placeholder={"Availability"}
                    />
                    <Select
                        value={formData?.status || undefined}
                        onChange={(value) =>
                            setFormData({ ...formData, status: value })
                        }
                        placeholder={"Select status..."}
                        className={"w-full"}
                        options={[
                            {
                                value: "ENABLED",
                                label: "ENABLED",
                            },
                            {
                                value: "DISABLED",
                                label: "DISABLED",
                            },
                        ]}
                    />
                </div>
                <Divider />
            </Modal>
        </>
    );
};

export default CreateConfigModal;
