import React, { useContext, useRef, useState } from "react";
import { Button, Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import HeadingTitle from "Components/Headings/HeadingTitle";
import { CarHistoryContext } from "Context/History/CarHistoryContext";
import ObjectUtils from "Utils/ObjectUtils";

const CarHistoryFilters = () => {
    const { searchQuery, setSearchQuery, pageLoading } =
        useContext(CarHistoryContext);
    const [inputValues, setInputValues] = useState({});

    const filterRef = useRef();

    const handleSearch = () => {
        const validateInputs = ObjectUtils.removeEmptyProperty(inputValues);
        if (!ObjectUtils.isEmptyObject(validateInputs)) {
            setSearchQuery({ ...searchQuery, ...inputValues });
        }
    };

    const clearSearch = () => {
        setSearchQuery({});
        setInputValues({});
    };

    return (
        <>
            <HeadingTitle title={"Filters"} />
            <div className={"flex gap-3 flex-wrap"}>
                <Input
                    onChange={(event) =>
                        setInputValues({
                            ...inputValues,
                            carName: event.target.value,
                        })
                    }
                    // @ts-ignore
                    value={inputValues?.carName}
                    className={"w-44 sm:w-full"}
                    type={"text"}
                    placeholder={"Car name..."}
                />
                <Input
                    onChange={(event) =>
                        setInputValues({
                            ...inputValues,
                            carLicencePlate: event.target.value,
                        })
                    }
                    className={"w-44 sm:w-full"}
                    // @ts-ignore
                    value={inputValues?.carLicencePlate}
                    type={"text"}
                    placeholder={"Licence plate..."}
                />
                <Button
                    loading={pageLoading}
                    onClick={handleSearch}
                    className={"sm:w-full"}
                    type={"primary"}
                    icon={<SearchOutlined />}
                >
                    Search
                </Button>
                {!ObjectUtils.isEmptyObject(searchQuery) && (
                    <Button onClick={clearSearch}>Clear Filters</Button>
                )}
            </div>
        </>
    );
};

export default CarHistoryFilters;
