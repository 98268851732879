import React, { useContext } from "react";
import { CarHistoryContext } from "Context/History/CarHistoryContext";
import LoadingPlaceholder from "Components/Placeholders/LoadingPlaceholder";
import HeadingTitle from "Components/Headings/HeadingTitle";
import CarHistoryFilters from "./partials/CarHistory/CarHistoryFilters";
import CarHistoryLocationFilter from "./partials/CarHistory/CarHistoryLocationFilter";
import CarHistoryDataTable from "./partials/CarHistory/CarHistoryDataTable";

const CarHistoryContainer = () => {
    const { pageLoading, carHistories, pageConfig } =
        useContext(CarHistoryContext);

    return (
        <>
            <div className={"flex gap-2 items-center"}>
                <HeadingTitle title={"Locations"} />
                <CarHistoryLocationFilter />
            </div>
            <CarHistoryFilters />
            {pageLoading ? <LoadingPlaceholder /> : <CarHistoryDataTable />}
        </>
    );
};

export default CarHistoryContainer;
