import Axios from "axios";
import { App } from "antd";
import { ReservationStatus } from "Types/ReservationTypes";

interface UpdateReservationStatusData {
    uuid: string;
    status: ReservationStatus;
}

const useReservation = () => {
    const { message, notification } = App.useApp();
    const getReservations = async (page: number) => {
        const reservations = await Axios.get(`/get/reservations?page=${page}`);
        return reservations?.data;
    };

    const updateReservationStatus = async (
        data: UpdateReservationStatusData,
    ) => {
        try {
            await Axios.patch("/edit/reservations/status", data);
            notification.success({
                description: "Succesfully updated reservation status.",
                message: "Success",
            });
        } catch (e) {
            notification.error({
                message: "Error",
                description: "Something went wrong, try again later.",
            });
        }
    };

    return {
        getReservations,
        updateReservationStatus,
    };
};

export default useReservation;
