import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Input, Modal, Select } from "antd";
import { getCountries, getCountryByCode } from "Utils/CountryUtils";
import HeadingTitle from "Components/Headings/HeadingTitle";
import Button from "Components/Button";
import useLocations from "Hooks/LocationsHooks/useLocations";
import Currency from "Utils/partials/currency_list.json";
import Label from "Components/Label";

interface LocationFormData {
    name: null | string;
    country_code: null | string;
    country: null | string;
    currency: null | string;
    qrcode_value: null | string;
    city: null | string;
    address: null | string;
}

const AddLocationForm = () => {
    const location = useLocation();
    const navigate = useNavigate();
    // @ts-ignore
    const { setLocation, updateLocation, removeLocation } = useLocations();

    const [loading, setLoading] = useState<boolean>(false);
    const [editData, setEditData] = useState(location?.state);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = async () => {
        setIsModalOpen(false);
        await removeLocation(location.state.data.uuid);
        navigate("/locations");
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const [formData, setFormData] = useState<LocationFormData>({
        name: null,
        country_code: null,
        country: null,
        currency: null,
        qrcode_value: null,
        city: null,
        address: null,
    });

    function addLocation(e: { preventDefault: () => void }) {
        setLoading(true);
        e.preventDefault();
        if (!location.state) {
            setLocation(formData, setLoading);
        } else {
            updateLocation(formData, setLoading);
        }
    }

    useEffect(() => {
        if (editData) {
            setFormData(editData.data);
        }
    }, []);

    return (
        <div>
            {/* @ts-ignore*/}
            <span>Register location of rent a cars for your company.</span>
            <form onSubmit={addLocation} className="mt-5 mb-3">
                <div className="flex justify-start  flex-wrap lg:justify-between w-full mb-10 gap-2">
                    <div className="xl-min:w-[32%] lg:w-[49%] md-min:w-[31%] sm:w-full">
                        <Label title={"Name of location"} />
                        <Input
                            // @ts-ignore
                            onChange={(e) =>
                                setFormData({
                                    ...formData,
                                    name: e.target.value,
                                })
                            }
                            name="name"
                            className="w-full sm:w-full"
                            type="text"
                            placeholder="Enter location name..."
                            defaultValue={editData?.data.name}
                        />
                    </div>
                    <div className="xl-min:w-[32%] lg:w-[50%] md-min:w-[31%] sm:w-full">
                        <Label title={"Select country"} />
                        <Select
                            showSearch
                            placeholder={"Select Country"}
                            onChange={(countryCode) =>
                                setFormData({
                                    ...formData,
                                    country_code: countryCode,
                                    country: getCountryByCode(countryCode),
                                })
                            }
                            style={{ width: "100%" }}
                            defaultValue={editData?.data.country_code}
                            options={getCountries().map((country) => {
                                return {
                                    value: country.cca2,
                                    label: country.name,
                                };
                            })}
                        />
                    </div>

                    <div className="xl-min:w-[32%] md-min:w-[31%] relative sm:w-full">
                        <Label title={"Select currency"} />
                        <Select
                            showSearch
                            onChange={(currency) =>
                                setFormData({ ...formData, currency: currency })
                            }
                            defaultValue={editData?.data.currency}
                            placeholder={"Choose currency"}
                            style={{ width: "100%" }}
                            options={Currency.map((item) => {
                                return {
                                    value: item.currencySymbol,
                                    label: item.currencyName,
                                };
                            })}
                        />
                    </div>

                    <div className="xl-min:w-[32%] md-min:w-[31%] sm:w-full">
                        <Label title={"city"} />
                        <Input
                            // @ts-ignore
                            onChange={(e) =>
                                setFormData({
                                    ...formData,
                                    city: e.target.value,
                                })
                            }
                            className="w-full sm:w-full"
                            type="text"
                            placeholder="Enter City..."
                            defaultValue={editData?.data.city}
                        />
                    </div>

                    <div className="xl-min:w-[32%]  md-min:w-[31%] sm:w-full">
                        <Label title={"address"} />
                        <Input
                            // @ts-ignore
                            onChange={(e) =>
                                setFormData({
                                    ...formData,
                                    address: e.target.value,
                                })
                            }
                            className="w-full sm:w-full"
                            type="text"
                            placeholder="Enter Address..."
                            defaultValue={editData?.data.address}
                        />
                    </div>
                    <div className="xl-min:w-[32%]  md-min:w-[31%] sm:w-full">
                        <Label title={"QR Code Link"} />
                        <Input
                            // @ts-ignore
                            onChange={(e) =>
                                setFormData({
                                    ...formData,
                                    qrcode_value: e.target.value,
                                })
                            }
                            className="w-full sm:w-full"
                            type="text"
                            placeholder="Enter link"
                            defaultValue={editData?.data.qrcode_value}
                        />
                    </div>
                </div>

                <HeadingTitle title="Actions"></HeadingTitle>

                <div className="w-full text-end flex gap-3 justify-end">
                    {location.state?.data && (
                        <div>
                            <Button
                                onClick={showModal}
                                className={"w-32"}
                                danger={true}
                            >
                                Delete
                            </Button>
                            <Modal
                                title="You are about to remove location?"
                                open={isModalOpen}
                                okText={"Confirm"}
                                okButtonProps={{ danger: true }}
                                onOk={handleOk}
                                onCancel={handleCancel}
                            >
                                <p>
                                    This action can't be undone, if you delete
                                    location you will delete all cars related to
                                    it.
                                </p>
                            </Modal>
                        </div>
                    )}
                    <Button
                        isLoading={loading}
                        className={"w-40"}
                        htmlType={"submit"}
                        buttonType={"primary"}
                    >
                        {!location.state ? "Add Location" : "Update Location"}
                    </Button>
                </div>
            </form>
        </div>
    );
};

export default AddLocationForm;
