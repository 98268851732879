import React from "react";
import {
    LeftOutlined,
    MailOutlined,
    InfoCircleOutlined,
} from "@ant-design/icons";
import { Input, Button } from "antd";
import { Link } from "react-router-dom";

const ForgotPassword = () => {
    return (
        <section>
            <div
                className={
                    "max-w-[460px] flex flex-col items-center justify-center px-6 py-16 mx-auto md:h-screen lg:py-0"
                }
            >
                <div
                    className={
                        "p-6 bg-white rounded-lg shadow-xl md:mt-0 sm:max-w-md sm:p-8"
                    }
                >
                    <InfoCircleOutlined className="flex justify-center text-[100px] text-[rgb(22,119,255)]" />
                    <h2
                        className={
                            "mb-1 text-xl font-bold leading-tight tracking-tight  md:text-2xl text-center mt-5"
                        }
                    >
                        Forgot Password
                    </h2>
                    <p className={"font-light mt-5 text-center mb-10"}>
                        Enter your email and we'll send you link to reset your
                        password.
                    </p>
                    <div className={"mt-4 space-y-4 lg:mt-5 md:space-y-5"}>
                        <div>
                            <Input
                                type="email"
                                className={
                                    "border flex border-gray-300 font-semibold rounded-lg focus:ring-primary-600 focus:border-primary-600 w-full p-2.5"
                                }
                                placeholder="example@example.com"
                                prefix={<MailOutlined />}
                            />
                        </div>
                        <div className={"flex items-start"}>
                            <div className={"flex items-center h-5"}></div>
                            <div className={"text-sm"}>
                                <p className={"font-normal mb-3"}>
                                    We cannot find your email.
                                </p>
                            </div>
                        </div>
                        <Button
                            className={
                                "w-full text-white font-bold hover:bg-blue-700 focus:outline-none mt-10"
                            }
                            type="primary"
                        >
                            Submit
                        </Button>
                        <p className={"mt-4 text-center"}>
                            <Link to={"/login"} className={"text-gray-500"}>
                                {" "}
                                <LeftOutlined /> Back to Login
                            </Link>
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ForgotPassword;
