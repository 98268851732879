import { IntegrationListTypes } from "Types/IntegrationTypes";

const Integrations: IntegrationListTypes[] = [
    {
        integrationTitle: "Rentelon Webhook",
        integrationType: "RENTELON_WEBHOOK",
        serviceType: "WEBHOOK",
        description: "Integrate rentelon to import orders from your system.",
        longDescription:
            "Elevate your rental management system with the bespoke integration of Rentelon using custom webhooks. Our solution offers a tailored approach, enabling seamless amalgamation of Rentelon's robust reservation infrastructure with your existing platform. Through custom webhook integration, effortlessly import reservations made across your channels directly into Rentelon. This personalized integration empowers your rental business, ensuring a cohesive and efficient reservation management process while delivering an exceptional experience for both your team and clientele.\"",
    },
    {
        integrationTitle: "Rentelon API",
        integrationType: "RENTELON_API",
        serviceType: "API",
        description:
            "Integrate rentelon to your system to import and manage reservations",
        longDescription:
            "Rentelon API integration empowers businesses to streamline their reservation management processes effortlessly. With this powerful tool, you can easily handle bookings, track availability, and efficiently manage reservations, enhancing customer satisfaction and optimizing your operations. Unlock the potential of Rentelon API integration to revolutionize the way you manage your rental business.",
    },
    // {
    //     integrationTitle: "Woocommerce",
    //     integrationType: "WOOCOMMERCE",
    //     serviceType: "WEBHOOK",
    //     description: "Woocommerce import order to rentelon.",
    //     longDescription:
    //         "Enhance the functionality of your e-commerce platform with the seamless integration of Rentelon and WooCommerce through webhooks. Our innovative solution allows you to effortlessly incorporate Rentelon's powerful reservation system into your WooCommerce setup. By integrating Rentelon's webhook, you can effortlessly synchronize and import reservations made on your WooCommerce store directly into Rentelon. Streamline your rental business operations, ensuring a unified and efficient process for managing reservations while providing a superior user experience for your customers.",
    // },
];

export default Integrations;
