import React from "react";
import { QRCode, Tag } from "antd";

interface QrCodeReservationPdfProps {
    link: string;
    secretKey: string;
}

const QrCodeReservationPdf: React.FC<QrCodeReservationPdfProps> = ({
    link,
    secretKey,
}) => {
    return (
        <div className={"terms-conditions p-1 md:z-[-1] ql-container ql-snow"}>
            <div className="flex p-3 flex-col border rounded-md">
                <div>
                    <QRCode
                        bordered={false}
                        size={180}
                        type="svg"
                        value={link}
                    />
                    <div className={"p-3"}>
                        <Tag
                            color={"black"}
                            className="text-sm mt-2 uppercase font-bold"
                        >
                            Secret Key: {secretKey}
                        </Tag>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default QrCodeReservationPdf;
