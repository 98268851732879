import React, { useEffect, useState } from "react";
import IntegrationsList from "./partials/IntegrationsList";
import HeadingTitle from "Components/Headings/HeadingTitle";
import useIntegrations from "Hooks/IntegrationHooks/useIntegrations";
import LoadingPlaceholder from "Components/Placeholders/LoadingPlaceholder";

const ManageIntegrationsContainer = () => {
    const { getActivatedIntegrations } = useIntegrations();
    const [integrations, setIntegrations] = useState();
    const [loading, setLoading] = useState(false);
    const fetchIntegrations = async () => {
        setLoading(true);
        const integrations = await getActivatedIntegrations().finally(() => {
            setLoading(false);
        });
        setIntegrations(integrations);
    };

    useEffect(() => {
        fetchIntegrations();
    }, []);
    return (
        <div className="integrations">
            <HeadingTitle title={"Integrations List"} />
            {loading ? (
                <LoadingPlaceholder />
            ) : (
                <IntegrationsList integrations={integrations} />
            )}
        </div>
    );
};

export default ManageIntegrationsContainer;
