import React from "react";
import HeadingTitle from "Components/Headings/HeadingTitle";
import { Select } from "antd";

interface LocationsFilterProps {
    employees: [] | null;
    onChange?: (employee: string) => void;
}
const LocationsFilter = ({ employees, onChange }: LocationsFilterProps) => {
    const options = employees?.map(({ firstname, lastname, uuid }) => {
        return {
            value: uuid,
            label: `${firstname} ${lastname}`,
        };
    });
    // @ts-ignore
    return (
        <>
            <Select
                className={"w-[300px] md:w-full"}
                defaultValue={"Select employee"}
                onChange={onChange}
                options={[
                    { value: "", label: `All` },
                    // @ts-ignore
                    ...options,
                ]}
            />
        </>
    );
};

export default LocationsFilter;
