import React, { useContext } from "react";
import { IntegrationConfigs } from "Types/IntegrationTypes";
import ConfigList from "./Listing/ConfigList";
import { SingleIntegrationContext } from "../../../Context/Integrations/SingleIntegrationContext";

const RentelonApiConfig = () => {
    const {
        integration: { configs, uuid },
    } = useContext(SingleIntegrationContext);

    return <ConfigList configs={configs} integrationId={uuid} />;
};

export default RentelonApiConfig;
