import {
    CarCategoryTypes,
    CarTextStatusTypes,
    RentCarStatusTypes,
    RentedCarTypes,
    CheckParamTypes,
} from "Types/CarTypes";

export const isCarRented = (data: RentedCarTypes[]): boolean => {
    return data.length > 0;
};

export const isCarActive = (value: number): boolean => {
    return value === 1;
};

export const getRentalCount = (data: RentedCarTypes[]): number => {
    return data.length;
};

export const getCarStatusText = (value: number): CarTextStatusTypes => {
    switch (value) {
        case 1:
            return "ACTIVE";
        case 0:
            return "NOT ACTIVE";
        default:
            return "STATUS NOT AVAILABLE";
    }
};

export const getCarCategory = (category: CarCategoryTypes[]) => {
    return category[0].category_name;
};

export const handleCurrentRentalStatus = (
    data: RentedCarTypes[],
    checkType?: CheckParamTypes,
    onCheckSection?: boolean,
): RentCarStatusTypes => {
    if (data.length > 0) {
        const { from, to } = data[0];
        const startDate = new Date(from);
        const endDate = new Date(to);
        const currentDate = new Date();
        const tommorow = new Date();
        tommorow.setDate(tommorow.getDate() + 1);

        if (isCarRented(data) && !onCheckSection) {
            if (currentDate >= startDate && currentDate <= endDate) {
                return "RENTED";
            } else if (currentDate < startDate) {
                return "PICK_UP";
            } else {
                return "FINISHED";
            }
        } else if (checkType === "IN") {
            if (
                currentDate.toLocaleDateString() ===
                startDate.toLocaleDateString()
            ) {
                return "TODAY";
            } else if (tommorow.getDate() === startDate.getDate()) {
                return "TOMORROW";
            }
        } else if (checkType === "OUT") {
            if (
                currentDate.toLocaleDateString() ===
                endDate.toLocaleDateString()
            ) {
                return "TODAY";
            } else if (tommorow.getDate() === endDate.getDate()) {
                return "TOMORROW";
            }
        }
    }
    return !onCheckSection ? "AVAILABLE" : null;
};
