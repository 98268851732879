import React, { useState } from "react";
import useCompany from "Hooks/CompanyHooks/useCompany";
import LoadingPlaceholder from "Components/Placeholders/LoadingPlaceholder/LoadingPlaceholder";
import { useSession } from "Hooks/SessionHooks/useSession";
import HeadingTitle from "Components/Headings/HeadingTitle";
import Editor from "Components/TextEditor";
import UploaderImage from "Components/UploaderImage";
import { Button, Divider } from "antd";
import { ShopOutlined } from "@ant-design/icons";
import "react-quill/dist/quill.snow.css";
import CompanyLegalDocuments from "./partials/CompanyLegalDocuments";
import LegalDocumentsList from "./partials/LegalDocumentsList";

// TODO: This should be deprecated
const CompanySettingsContainer = () => {
    const { getCompanySettings, updateCompanySettings } = useCompany();
    const { getUserCache, setUserCache } = useSession();
    const { name, company_image } = getCompanySettings();
    const [companyName] = useState(name);
    const [imagePath, setImagePath] = useState(null);
    const [termsConditions, setTermsConditions] = useState(
        getCompanySettings().terms_conditions,
    );
    const [loading, setLoading] = useState(false);

    const handleCompanyUpdate = async () => {
        setLoading(true);
        const data = {
            name: companyName,
            company_image: imagePath,
            terms_conditions: termsConditions,
        };
        const companyData = await updateCompanySettings(data, setLoading);
        const userCache = getUserCache();
        if (companyData) {
            userCache.company[0].name = companyData?.name;
            userCache.company[0].company_image = companyData?.company_image;
        }
        setUserCache(userCache);
    };
    if (!name) return <LoadingPlaceholder />;
    return (
        <div className={"company-settings-container"}>
            <div className={"company-profile "}>
                <div className={"flex items-center"}>
                    <div className={"flex items-center"}>
                        <UploaderImage
                            image={{
                                name: "Company Logo",
                                status: "done",
                                // @ts-ignore
                                url: company_image
                                    ? `${process.env.REACT_APP_DOMAIN}${company_image}`
                                    : process.env.REACT_APP_IMAGE_FALLBACK,
                            }}
                            setImage={setImagePath}
                            uploadText={"Upload Logo"}
                        />
                        <div className={"w-56"}>
                            <p
                                className={
                                    "font-medium flex items-center gap-2"
                                }
                            >
                                {" "}
                                <ShopOutlined className={"text-[20px]"} />{" "}
                                {name}
                            </p>
                            <p></p>
                        </div>
                    </div>
                </div>
            </div>
            <Divider />
            <CompanyLegalDocuments />
            <LegalDocumentsList />

            {/*<HeadingTitle title={"Terms & Conditions"} />*/}
            {/*<div className={"flex justify-between flex-wrap"}>*/}
            {/*    <div className={"md:w-full md-tablet:w-[48%]"}>*/}
            {/*        <Editor*/}
            {/*            actualValue={termsConditions}*/}
            {/*            returnValues={setTermsConditions}*/}
            {/*        />*/}
            {/*    </div>*/}
            {/*    <div*/}
            {/*        className={*/}
            {/*            "md:w-full border-[#cacaca] p-7 pb-4 md-tablet:w-[48%] border ql-container ql-snow"*/}
            {/*        }*/}
            {/*    >*/}
            {/*        <div*/}
            {/*            className={"ql-editor"}*/}
            {/*            dangerouslySetInnerHTML={{ __html: termsConditions }}*/}
            {/*        ></div>*/}
            {/*    </div>*/}
            {/*</div>*/}

            <HeadingTitle title={"Actions"} />
            <div className="modal-action text-end">
                <Button
                    type={"primary"}
                    loading={loading}
                    onClick={handleCompanyUpdate}
                >
                    Update
                </Button>
            </div>
        </div>
    );
};

export default CompanySettingsContainer;
