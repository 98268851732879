import React from "react";
import { Tag, Tooltip } from "antd";

const TomorrowStatus = () => {
    return (
        <Tag className={"pl-3 pr-3 pt-1 pb-1 font-bold"} color={"pink"}>
            <Tooltip title={"Return status"}>TOMORROW</Tooltip>
        </Tag>
    );
};
export default TomorrowStatus;
