import React, { useContext, useState } from "react";
import { Button, DatePicker, Divider, Input, Modal, Tooltip } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import useIntegrationConfig from "Hooks/IntegrationHooks/IntegrationConfig/useIntegrationConfig";

interface CreateRuleModalPropTypes {
    update: () => void;
    periodId: string;
}
const CreateRuleModal = ({ update, periodId }: CreateRuleModalPropTypes) => {
    const { createRule } = useIntegrationConfig();

    const initialFormData = {
        integration_config_period_id: periodId,
        duration_from: "",
        duration_to: "",
        price: "",
    };
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [formData, setFormData] = useState(initialFormData);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = async () => {
        await createRule(formData).then((res) => {
            setIsModalOpen(false);
            update();
        });
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    return (
        <>
            <Tooltip title={"Add rules for period"}>
                <Button
                    type="primary"
                    icon={<PlusOutlined />}
                    onClick={showModal}
                ></Button>
            </Tooltip>
            <Modal
                title="Create Rule for Period"
                open={isModalOpen}
                onOk={handleOk}
                okText={"Save"}
                onCancel={handleCancel}
            >
                <small>
                    Add duration ranges that will be handled in API and give
                    price depending on duration of reservation.
                </small>
                <Divider />
                <div className={"flex flex-col gap-3"}>
                    <Input
                        type={"number"}
                        placeholder={"Duration Days From"}
                        value={formData.duration_from || undefined}
                        onChange={(e) =>
                            setFormData({
                                ...formData,
                                duration_from: e.target.value,
                            })
                        }
                    />
                    <Input
                        type={"number"}
                        placeholder={"Duration Days To"}
                        value={formData.duration_to || undefined}
                        onChange={(e) =>
                            setFormData({
                                ...formData,
                                duration_to: e.target.value,
                            })
                        }
                    />
                    <Input
                        type={"number"}
                        placeholder={"Price"}
                        value={formData.price || undefined}
                        onChange={(e) =>
                            setFormData({ ...formData, price: e.target.value })
                        }
                    />
                </div>
                <Divider />
            </Modal>
        </>
    );
};

export default CreateRuleModal;
