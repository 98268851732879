import React from "react";
import { Tag } from "antd";

interface ReservationPendingInterface {
    pending: "PENDING";
}
const ReservationPending = ({ pending }: ReservationPendingInterface) => {
    return (
        <Tag
            className={"px-2 py-1 font-semibold border-[2px]"}
            color={"orange"}
        >
            {pending}
        </Tag>
    );
};

export default ReservationPending;
