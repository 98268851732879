import React from "react";
import App from "./App";
import ReactDOM from "react-dom/client";
import Axios from "axios";
import "./Assets/Styles/tailwind.css";
import "./Assets/Styles/global.css";
import * as Sentry from "@sentry/react";

if (process.env.REACT_APP_ENVIROMENT !== "DEV") {
    Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DNS_URL,
        integrations: [
            new Sentry.BrowserTracing({
                // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
                tracePropagationTargets: [
                    "localhost",
                    /^https:\/\/app\.rentelon\.com/,
                ],
            }),
            new Sentry.Replay(),
        ],
        // Performance Monitoring
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
        // Session Replay
        replaysSessionSampleRate: 0.2, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
}

Axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
Axios.defaults.headers.post["Content-Type"] = "application/json";
Axios.defaults.withCredentials = true;

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement,
);

root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
);
