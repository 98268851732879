import Axios from "axios";
import { useNavigate } from "react-router-dom";
import { App } from "antd";

/**
 *
 * @returns {{getLocations: (function(): Promise<axios.AxiosResponse<any>>)}}
 */
const useLocations = () => {
    const navigate = useNavigate();
    const { message, notification } = App.useApp();

    const setLocation = (data, loader) => {
        Axios.post("/add/location", data)
            .then((response) => {
                notification.success({
                    description: "Successfully added location.",
                });
                navigate("/locations");
            })
            .catch((error) => {
                notification.error({
                    description: error.response.data.errors,
                });
            })
            .finally(() => {
                loader(false);
            });
    };

    const getLocations = async () => {
        let data;
        try {
            const response = await Axios.get("/get/locations");
            data = response.data;
        } catch (error) {
            notification.error({
                description: "Failed to load locations",
            });
        }
        return data;
    };

    const updateLocation = (data, loader) => {
        Axios.post("/edit/location", data)
            .then((response) => {
                message.success("Successfully updated location.");
                loader(false);
                navigate("/locations");
            })
            .catch((error) => {
                loader(false);
                console.error(error);
                message.error(error.response.data.errors);
            });
    };

    const removeLocation = async (uuid) => {
        await Axios.post("/remove/location", { uuid: uuid })
            .then((response) => {
                message.success(response.data.msg);
            })
            .catch((error) => {
                message.error(error.response.data.errors);
            });
    };

    return {
        getLocations,
        setLocation,
        removeLocation,
        updateLocation,
    };
};

export default useLocations;
