import React from "react";
import RentelonImage from "Assets/Images/rentelon.png";
import WoocommerceImage from "Assets/Images/Integrations/woocommerce.png";
import { Image } from "antd";
import { IntegrationTypes } from "Types/IntegrationTypes";

interface IntegrationImageHandlerProps {
    integrationType: IntegrationTypes;
    size?: number;
}
const IntegrationImage = ({
    integrationType,
    size = 75,
}: IntegrationImageHandlerProps) => {
    const IntegrationImageHandler = () => {
        let integrationImage = "";
        switch (integrationType) {
            case "RENTELON_API":
                integrationImage = RentelonImage;
                break;
            case "RENTELON_WEBHOOK":
                integrationImage = RentelonImage;
                break;
            case "WOOCOMMERCE":
                integrationImage = WoocommerceImage;
                break;
            default:
                integrationImage = "";
                break;
        }
        return (
            <Image
                preview={false}
                width={size}
                className={"sm:w-full"}
                src={integrationImage}
            />
        );
    };
    return <IntegrationImageHandler />;
};

export default IntegrationImage;
