import React from "react";
import { DashboardProvider } from "Context/DashboardContext/DashboardContext";
import DashboardContainer from "Containers/Dashobard/DashboardContainer";
import DashboardEmployeeContainer from "Containers/Dashobard/DashboardEmployeeContainer";

const Dashboard = () => {
    return (
        <DashboardProvider>
            <DashboardContainer />
            <DashboardEmployeeContainer />
        </DashboardProvider>
    );
};
export default Dashboard;
