import React, { useEffect, useState } from "react";
import HeadingTitle from "Components/Headings/HeadingTitle";
import LoadingPlaceholder from "Components/Placeholders/LoadingPlaceholder/LoadingPlaceholder";
import useCar from "Hooks/RentCarHooks/useCar";
import { formatObjectDate } from "Utils/DateUtils";
import {
    Input,
    Select,
    DatePicker,
    Divider,
    Pagination,
    Result,
    Modal,
} from "antd";
import Button from "Components/Button";
import { SearchOutlined } from "@ant-design/icons";
import RentCarCard from "Components/Card/RentCarCard";
import { CheckParamTypes } from "Types/CarTypes";
import { addPageQueryParam, getPageParam } from "Helpers/PageHelper";
import { isMobile } from "Utils/DeviceUtils";
import MobileRentCarCard from "Components/Card/MobileRentCarCard";
import useLocations from "Hooks/LocationsHooks/useLocations";
import { isUserAdmin } from "Utils/UserUtils";

const CheckCarsContainer = () => {
    const { getLocations } = useLocations();

    // @ts-ignore
    const { getRentCars } = useCar();
    const [cars, setCars] = useState(null);
    const [selectedDay, setSelectedDay] = useState(null);
    const [defaultSelectedDay, setDefaultSelectedDay] = useState(null);
    const [carName, setCarName] = useState(null);
    const [checkOut, setCheckOut] = useState<boolean | null>(true);
    const [filterActive, setFilterActive] = useState(false);
    const [labelStatus, setStatusLabel] = useState<CheckParamTypes>("OUT");
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(getPageParam());
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [customerId, setCustomerId] = useState<null | string>(null);
    const [loading, setLoading] = useState(false);
    const [locations, setLocations] = useState(null);
    const [searchLocation, setSearchLocation] = useState<string | null>(null);

    // options for check in and check out selection partials
    const checkInOptions = [
        {
            value: "checkIn",
            label: "Pick Up",
        },
        {
            value: "checkOut",
            label: "Returns",
        },
    ];
    // Create search query for check in cars
    const buildQuery = (locationId: string | null = null) => {
        setLoading(true);
        setFilterActive(true);
        const query = new URLSearchParams();
        if (locationId) {
            query.append("locationId", locationId);
        }
        if (selectedDay && checkOut) {
            query.append("by_date_out", formatObjectDate(selectedDay));
        } else if (selectedDay) {
            query.append("by_date_in", formatObjectDate(selectedDay));
        }
        if (carName) {
            query.append("car_name", carName);
        }
        if (checkOut && !selectedDay) {
            query.append("list_check_out", "true");
        } else if (!selectedDay) {
            query.append("list_check_in", "true");
        }
        if (checkOut) {
            setStatusLabel("OUT");
        } else {
            setStatusLabel("IN");
        }
        fetchCars(query.toString());
    };
    const clearFilters = () => {
        setCheckOut(true);
        setCarName(null);
        setSelectedDay(null);
        setFilterActive(false);
        setDefaultSelectedDay(null);
        setSearchLocation(null);
        fetchCars();
    };
    const handleCheckSelection = (selected: "checkIn" | "checkOut") => {
        switch (selected) {
            case "checkIn":
                setCheckOut(false);
                break;
            case "checkOut":
                setCheckOut(true);
                break;
            default:
                break;
        }
    };
    // @ts-ignore
    const handleSelectedDate = (date) => {
        setSelectedDay({
            // @ts-ignore
            day: date.$D,
            month: date.$M + 1,
            year: date.$y,
        });
        setDefaultSelectedDay(date);
    };

    const showModal = (customerId: string) => {
        setIsModalOpen(true);
        setCustomerId(customerId);
    };
    const handleOk = async () => {
        setLoading(true);
        setIsModalOpen(false);
        // @ts-ignore
        await finishReservation(customerId, setLoading);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const fetchCars = async (query: string = "") => {
        const cars = await getRentCars(query, currentPage, setLoading);
        setCars(cars?.data);
        setCurrentPage(cars?.current_page);
        setTotalPages(cars?.total);
    };
    const handlePagination = (pageNo: number) => {
        setCurrentPage(pageNo);
    };
    const fetchLocationsData = async () => {
        const locations = await getLocations();
        setLocations(locations);
    };

    const handleLocationChange = (uuid: string) => {
        setSearchLocation(uuid);
        buildQuery(uuid);
    };

    useEffect(() => {
        fetchCars();
        setStatusLabel("OUT");
        if (isUserAdmin()) fetchLocationsData();
    }, [selectedDay, currentPage]);

    return (
        <div className="check-in-car-container">
            <div>
                {isUserAdmin() && (
                    <div className={"flex gap-2 items-center"}>
                        <HeadingTitle title={"LOCATION"} />
                        <Select
                            value={searchLocation}
                            onChange={handleLocationChange}
                            placeholder={"Select location..."}
                            className={"sm:w-full w-[250px]"}
                            // @ts-ignore
                            disabled={!locations?.length > 0}
                            // @ts-ignore
                            options={locations?.map((location) => {
                                const { name, address, uuid } = location;
                                return {
                                    value: uuid,
                                    label: `${name} - ${address}`,
                                };
                            })}
                        />
                    </div>
                )}
            </div>
            <HeadingTitle title={"Filter Vehicles"} />
            <div className="sm:w-full flex sm:flex-wrap gap-3 lg:flex-wrap">
                <div className="mdh-min:mr-3 sm:w-full">
                    <Input
                        // @ts-ignore
                        onChange={(e) => setCarName(e.target.value)}
                        // @ts-ignore
                        value={carName}
                        className={"w-36 sm:w-full"}
                        type={"text"}
                        name={"car_name"}
                        placeholder="Search by name..."
                    />
                </div>
                <DatePicker
                    onChange={handleSelectedDate}
                    placeholder="Select date..."
                    className="mdh-min:mr-3 w-36 sm:w-full"
                    value={defaultSelectedDay}
                />
                <Select
                    defaultValue={"checkOut"}
                    value={checkOut ? "checkOut" : "checkIn"}
                    onChange={handleCheckSelection}
                    placeholder="Select pick up / return..."
                    options={checkInOptions}
                    className="mdh-min:mr-3 w-36 sm:w-full"
                />
                <Button
                    buttonType="primary"
                    onClick={() => buildQuery(searchLocation)}
                    className="w-28 lg:w-full sm:w-full"
                >
                    Search <SearchOutlined />
                </Button>
                {filterActive && (
                    <Button onClick={clearFilters} className="sm:w-full w-28">
                        Clear Filters
                    </Button>
                )}
            </div>

            <div className="flex">
                <HeadingTitle
                    title={"Pick Up / Return vehicles"}
                    tooltip={
                        "Cars that are listed here are today and tommorow pick up / return, depending on your filter options."
                    }
                />
            </div>
            {filterActive && (
                <div>
                    <Divider />
                    <div className={"pl-2 font-bold"}>
                        {/* @ts-ignore */}
                        {filterActive && <p> {cars?.length} CAR/S FOUND </p>}
                    </div>
                    <Divider />
                </div>
            )}
            {!cars || loading ? (
                <LoadingPlaceholder />
            ) : // @ts-ignore
            cars?.length > 0 ? (
                // @ts-ignore
                cars?.map((car, index) => {
                    return (
                        <div className={"md:flex md:justify-center md:gap-3"}>
                            {!isMobile() ? (
                                <RentCarCard
                                    key={index}
                                    carData={car}
                                    onCheckSection={true}
                                    checkType={labelStatus}
                                    showReservationType={true}
                                />
                            ) : (
                                <MobileRentCarCard
                                    onCheckSection={true}
                                    checkType={labelStatus}
                                    carData={car}
                                    triggerModal={showModal}
                                    showReservationType={true}
                                />
                            )}
                        </div>
                    );
                })
            ) : (
                <Result title={"There is no records found for cars!"} />
            )}

            <Modal
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                title={"You are about to finish reservation?"}
                confirmLoading={loading}
                okText={"Yes"}
            >
                <p>
                    Are you sure you want to finish reservation for current
                    customer?
                </p>
            </Modal>
        </div>
    );
};

export default CheckCarsContainer;
