import secureLocalStorage from "react-secure-storage";
import { USER_PERSIST_CACHE } from "../Constants/rentelon";
import { UserPermissions } from "../Types/AuthTypes";

export const isUserAdmin = () => {
    const cache = JSON.parse(
        <string>secureLocalStorage.getItem(USER_PERSIST_CACHE),
    );
    return cache?.roles[0]?.name === "admin";
};

export const hasPermissionTo = (userPermission: string): boolean => {
    const cache = JSON.parse(
        <string>secureLocalStorage.getItem(USER_PERSIST_CACHE),
    );
    return (
        cache?.permissions?.some(
            (permission: { name: UserPermissions }) =>
                permission.name === userPermission,
        ) || false
    );
};
