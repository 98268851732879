import React, { createContext, useState } from "react";
import useCustomerAggrement from "Hooks/CustomerHooks/useCustomerAggrement";
import secureLocalStorage from "react-secure-storage";
import { App } from "antd";
import useCompany from "../../Hooks/CompanyHooks/useCompany";

const PdfContext = createContext();

export const PdfProvider = ({ children }) => {
    const { message } = App.useApp();

    const {
        initCustomerAggrementSettings,
        isCreditCardEnabled,
        isVehicleConditionEnabled,
        isTermsConditionsEnabled,
        setCreditCardValue,
        setVehicleConditionValue,
        setTermsConditionValue,
        isQrCodeReservationEnabled,
        setQrCodeFinishValue,
    } = useCustomerAggrement();

    initCustomerAggrementSettings(); // TODO: This has to be moved to database in future

    const [drawerZindex, setDrawerZindex] = useState("z-[-1]");

    const [vehicleConditionEnabled, setVehicleConditionEnabled] = useState(
        isVehicleConditionEnabled(),
    );
    const [creditCardEnabled, setCreditCardEnabled] = useState(
        isCreditCardEnabled(),
    );
    const [termsConditionsEnabled, setTermsConditionEnabled] = useState(
        isTermsConditionsEnabled(),
    );
    const [finishQrCodeReservation, setFinishQrCodeReseration] = useState(
        isQrCodeReservationEnabled(),
    );

    const [selectedDocument, setSelectedDocument] = useState(null);

    const [contactEmail, setContactEmail] = useState(null);
    const [contactPhone, setContactPhone] = useState(null);
    const [contactName, setContactName] = useState(null);
    const [billNumber, setBillNumber] = useState(null);
    const [selectedContact, setSelectedContact] = useState(null);
    const [creditCardDetails, setCreditCardDetails] = useState({
        cardNumber: null,
        cvv: null,
        expireDate: null,
    });

    const [pricesOptions, setPricesOptions] = useState({
        addDriverPrice: true,
        crossingBorderPrice: true,
        babySeatPrice: true,
        gpsPrice: true,
        depositPrice: true,
        totalRentPrice: true,
        insurancePrice: true,
        totalPrice: true,
        damageExcess: true,
    });
    const creditCardAction = (value) => {
        setCreditCardEnabled(value);
        setCreditCardValue(value);
    };
    const vehicleConditionAction = (value) => {
        setVehicleConditionEnabled(value);
        setVehicleConditionValue(value);
    };
    const termsConditionsAction = (value) => {
        setTermsConditionEnabled(value);
        setTermsConditionValue(value);
    };

    const qrCodeFinishReservationAction = (value) => {
        setFinishQrCodeReseration(value);
        setQrCodeFinishValue(value);
    };
    const saveContactToLocalStorage = () => {
        if (contactEmail && contactPhone && contactName) {
            const contactData = {
                name: contactName,
                email: contactEmail,
                phone: contactPhone,
            };
            if (secureLocalStorage.getItem("pdf")) {
                const pdfContacts = JSON.parse(
                    secureLocalStorage.getItem("pdf"),
                );
                const newPdfContacts = [...pdfContacts, contactData];
                secureLocalStorage.setItem(
                    "pdf",
                    JSON.stringify(newPdfContacts),
                );
            } else {
                secureLocalStorage.setItem(
                    "pdf",
                    JSON.stringify([contactData]),
                );
            }
        } else {
            message.error("Email & Phone are required.");
        }
    };
    const listContacts = () => {
        if (secureLocalStorage.getItem("pdf")) {
            return JSON.parse(secureLocalStorage.getItem("pdf")).map(
                (contact, index) => {
                    const { name, email, phone } = contact;
                    return {
                        id: index,
                        name: name,
                        email: email,
                        phone: phone,
                    };
                },
            );
        }
        return [];
    };
    const updateSelectedContact = (contactIndex) => {
        const contacts = listContacts();
        if (contacts.length > 0) {
            setSelectedContact(contacts[contactIndex]);
        }
    };

    return (
        <PdfContext.Provider
            value={{
                drawer: {
                    zIndex: drawerZindex,
                    setIndex: setDrawerZindex,
                },
                pdfOptions: {
                    vehicleCondition: {
                        enabled: vehicleConditionEnabled,
                        action: vehicleConditionAction,
                    },
                    creditCard: {
                        enabled: creditCardEnabled,
                        action: creditCardAction,
                    },
                    termsConditions: {
                        enabled: termsConditionsEnabled,
                        action: termsConditionsAction,
                    },
                    pricesOptions: {
                        data: pricesOptions,
                        action: setPricesOptions,
                    },
                    aggrementInfo: {
                        billNumber: billNumber,
                        actionBill: setBillNumber,
                    },
                    qrCodeFinishReservation: {
                        enabled: finishQrCodeReservation,
                        action: qrCodeFinishReservationAction,
                    },
                    legalDocuments: {
                        selected: selectedDocument,
                        action: setSelectedDocument,
                    },
                },
                creditCardInput: {
                    values: creditCardDetails,
                    action: setCreditCardDetails,
                },
                companyContactInput: {
                    contactList: listContacts,
                    nameAction: setContactName,
                    emailAction: setContactEmail,
                    phoneAction: setContactPhone,
                    selectedContact: selectedContact,
                    updateAction: updateSelectedContact,
                    action: saveContactToLocalStorage,
                },
            }}
        >
            {children}
        </PdfContext.Provider>
    );
};

export default PdfContext;
