import React from "react";
import { Descriptions, List, Tag } from "antd";
import type { DescriptionsProps } from "antd";
import {
    hasAdditionalDrivers,
    hasCrossingBorders,
} from "../../../../Helpers/CustomerHelper";
import HeadingTitle from "../../../../Components/Headings/HeadingTitle";

// @ts-ignore
const CustomerInfo = ({ data }) => {
    const {
        additional_driver_price,
        baby_seat_price,
        gps_price,
        crossing_border_price,
        insurance_price,
        insurance_type,
        damage_excess,
        deposit_price,
        rent_price,
        total_price,
        total_days,
        car,
        crossing_border_list,
        additional_drivers_list,
    } = data;

    const {
        customer_country,
        customer_driving_licence,
        customer_email,
        customer_name,
        customer_passport,
        customer_phone,
    } = data.customer[0];

    const currency = car?.company_location[0]?.currency;

    //@ts-ignore
    const additionalDriversData = [
        //@ts-ignore
        additional_drivers_list?.map((item) => {
            return `${item.driverName} - ${item.driverLicence}`;
        }),
    ];

    const customerInformations: DescriptionsProps["items"] = [
        {
            label: "Name",
            span: 5,
            children: customer_name,
        },
        {
            label: "Country",
            span: 4,
            children: customer_country,
        },
        {
            label: "Email",
            span: 4,
            children: customer_email || "N/A",
        },
        {
            label: "Driving Licence",
            span: 4,
            children: customer_driving_licence,
        },
        {
            label: "Passport No.",
            span: 4,
            children: customer_passport,
        },
        {
            label: "Phone No.",
            span: 4,
            children: customer_phone || "N/A",
        },
    ];

    const additionalOptions: DescriptionsProps["items"] = [
        {
            label: "Additional Driver Price",
            span: 4,
            children: (
                <Tag
                    className={"font-bold"}
                    color={
                        additional_drivers_list?.length > 0 ? "green" : "red"
                    }
                >
                    {additional_drivers_list?.length > 0
                        ? additional_driver_price
                        : "0.0"}{" "}
                    {currency}
                </Tag>
            ),
        },
        {
            label: "Crossing Borders Price",
            span: 4,
            children: (
                <Tag
                    className={"font-bold"}
                    color={crossing_border_list?.length > 0 ? "green" : "red"}
                >
                    {crossing_border_list?.length > 0
                        ? crossing_border_price
                        : "0.0"}{" "}
                    {currency}
                </Tag>
            ),
        },
        {
            label: "Baby Seat Price",
            span: 4,
            children: (
                <Tag
                    className={"font-bold"}
                    color={baby_seat_price ? "green" : "red"}
                >
                    {baby_seat_price ? baby_seat_price : "0.0"} {currency}
                </Tag>
            ),
        },
        {
            label: "GPS Price ",
            span: 4,
            children: (
                <Tag
                    className={"font-bold"}
                    color={gps_price ? "green" : "red"}
                >
                    {gps_price ? gps_price : "0.0"} {currency}
                </Tag>
            ),
        },
        {
            label: "Insurance Price per day",
            span: 4,
            children: (
                <div className={"flex gap-3"}>
                    <Tag
                        className={"font-bold"}
                        color={insurance_price ? "green" : "red"}
                    >
                        {insurance_price ? insurance_price : "0.0"} {currency}
                    </Tag>
                    <p>
                        {insurance_type === "PREMIUM"
                            ? "Premium Coverage"
                            : insurance_type === "SCDW" &&
                              "Super Collision Damage Waiver"}
                    </p>
                </div>
            ),
        },
        {
            label: "Deposit",
            span: 4,
            children: (
                <Tag
                    className={"font-bold"}
                    color={
                        deposit_price && deposit_price !== "0" ? "green" : "red"
                    }
                >
                    {deposit_price ? deposit_price : "0.00"} {currency}
                </Tag>
            ),
        },
    ];

    const calcInsurance =
        parseFloat(insurance_price.replace(",", "")) * parseInt(total_days);
    const totalInsurance = calcInsurance.toFixed(2);

    return (
        <div className="customer-info ">
            <div className="flex sm:flex-wrap gap-7 sm:w-full lg:w-[100%] lg:flex-wrap">
                <div className=" sm:w-full w-[50%] sm:mt-3 sm:mr-0">
                    <Descriptions
                        items={customerInformations}
                        size={"small"}
                        column={2}
                        bordered
                    />
                </div>
                <div className="info-1 w-[50%] sm:w-full">
                    <Descriptions
                        items={additionalOptions}
                        size={"small"}
                        column={2}
                        bordered
                    />
                </div>
            </div>

            {hasAdditionalDrivers(data) && (
                <List
                    className={"mt-3 mb-4"}
                    size="small"
                    header={
                        <div className="font-bold uppercase">
                            Additional drivers
                        </div>
                    }
                    bordered
                    dataSource={additionalDriversData}
                    renderItem={(item: string) => <List.Item>{item}</List.Item>}
                />
            )}
            {hasCrossingBorders(data) && (
                <List
                    className={"mb-5 mt-3"}
                    size="small"
                    header={
                        <div className="font-bold uppercase">Borders list</div>
                    }
                    bordered
                    dataSource={crossing_border_list}
                    renderItem={(item: string) => <List.Item>{item}</List.Item>}
                />
            )}
            <HeadingTitle title={"Total Summary"} />
            <div className={"mt-5 flex justify-end"}>
                <div>
                    {damage_excess && (
                        <p className="font-bold uppercase text-[13px]">
                            Damage Excess:
                        </p>
                    )}
                    <p className="font-bold uppercase text-[13px]">
                        Total Days:
                    </p>
                    <p className="font-bold uppercase text-[13px]">
                        Total Rent Price:
                    </p>
                    <p className="font-bold uppercase text-[13px]">
                        Total Insurance Price:
                    </p>
                    <p className="font-bold !text-blue-500 uppercase mt-5 text-[13px]">
                        Total Price:
                    </p>
                </div>
                <div className={"pl-5"}>
                    {damage_excess && (
                        <p className="font-bold uppercase text-[13px]">
                            {damage_excess} {car.company_location[0].currency}
                        </p>
                    )}
                    <p className="font-bold uppercase text-[13px]">
                        {total_days ?? 0}
                    </p>
                    <p className="font-bold uppercase text-[13px]">
                        {rent_price} {car.company_location[0].currency}
                    </p>
                    <p className="font-bold uppercase text-[13px]">
                        {totalInsurance} {car.company_location[0].currency}
                    </p>
                    <p className="font-bold !text-blue-500 uppercase mt-5 text-[13px]">
                        {total_price} {car.company_location[0].currency}
                    </p>
                </div>
            </div>
        </div>
    );
};

export default CustomerInfo;
