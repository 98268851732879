import { TypeReservation } from "Types/CustomerTypes";
import React from "react";
import booking from "Assets/Images/ReservationType/booking.png";
import carFlexi from "Assets/Images/ReservationType/carflexiicon.png";
import economyCars from "Assets/Images/ReservationType/economy.png";
import imgDefault from "Assets/Images/ReservationType/default.png";
import discoverCars from "Assets/Images/ReservationType/discover_cars.png";
import walkIn from "Assets/Images/ReservationType/walk_in.png";
import webShop from "Assets/Images/ReservationType/web_shop.png";
import { Image } from "antd";

interface TypeReservationHandlerProps {
    type: TypeReservation;
    size?: number;
}

const TypeReservationHandler = ({
    type,
    size,
}: TypeReservationHandlerProps) => {
    const handleType = () => {
        const imageFallback: string | undefined =
            process.env.REACT_APP_IMAGE_FALLBACK;
        switch (type) {
            case "BOOKING":
                return (
                    <Image
                        preview={false}
                        fallback={imageFallback}
                        src={booking}
                        width={size || 100}
                    />
                );
            case "CAR_FLEXI":
                return (
                    <Image
                        preview={false}
                        fallback={imageFallback}
                        src={carFlexi}
                        width={size || 100}
                    />
                );
            case "DISCOVER_CARS":
                return (
                    <Image
                        preview={false}
                        fallback={imageFallback}
                        src={discoverCars}
                        width={size || 100}
                    />
                );
            case "ECONOMY_CARS":
                return (
                    <Image
                        preview={false}
                        fallback={imageFallback}
                        src={economyCars}
                        width={size || 100}
                    />
                );
            case "WEB_SHOP":
                return (
                    <Image
                        preview={false}
                        fallback={imageFallback}
                        src={webShop}
                        width={size || 100}
                    />
                );
            case "WALK_IN":
                return (
                    <Image
                        preview={false}
                        fallback={imageFallback}
                        src={walkIn}
                        width={size || 100}
                    />
                );
            case "DEFAULT":
                return (
                    <Image
                        preview={false}
                        fallback={imageFallback}
                        src={imgDefault}
                        width={size || 50}
                    />
                );
            default:
                return (
                    <Image
                        preview={false}
                        fallback={imageFallback}
                        src={imgDefault}
                        width={size || 50}
                    />
                );
        }
    };

    return <>{handleType()}</>;
};

export default TypeReservationHandler;
