import React from "react";
import HeadingTitle from "Components/Headings/HeadingTitle";
import withRole from "Providers/AuthProvider/WithRole";

const SettingsLayout = ({ children, title }) => {
    return (
        <div className={"settings-layout"}>
            <HeadingTitle title={title} />
            {children}
        </div>
    );
};

export default withRole(SettingsLayout, ["admin"]);
