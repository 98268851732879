import React from "react";
import withRole from "Providers/AuthProvider/WithRole";
import ReservationsContainer from "Containers/Reservations/ManageReservations/ReservationsContainer";
import { ReservationProvider } from "Context/ReservationsContext/ReservationContext";

const ManageReservationsPage = () => {
    return (
        <>
            <ReservationProvider>
                <ReservationsContainer />
            </ReservationProvider>
        </>
    );
};

export default withRole(
    ManageReservationsPage,
    ["admin"],
    ["manage_imported_reservations"],
);
