import React from "react";
import { Result } from "antd";

interface CoomingSoonTypes {
    customText?: string;
}
const CoomingSoon = ({ customText }: CoomingSoonTypes) => {
    return (
        <Result
            className={"h-screen"}
            status="404"
            title="404"
            subTitle="This page is cooming soon."
        />
    );
};

export default CoomingSoon;
