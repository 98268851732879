import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const TextEditor = ({ returnValues, actualValue }) => {
    return (
        <ReactQuill
            placeholder={"Add your terms and conditions..."}
            theme="snow"
            value={actualValue ? actualValue : ""}
            onChange={returnValues}
            modules={{
                toolbar: [
                    [{ header: [1, 2, 3, 4, 5, 6, true] }],
                    ["bold", "italic", "underline"],
                    [
                        { align: "" },
                        { align: "center" },
                        { align: "right" },
                        { align: "justify" },
                    ],
                    [
                        { list: "ordered" },
                        { list: "bullet" },
                        { indent: "-1" },
                        { indent: "+1" },
                    ],
                    ["link"],
                ],
            }}
        />
    );
};

export default TextEditor;
