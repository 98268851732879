import React from "react";
import { Button, Result } from "antd";

const QrCodeReservationSuccess: React.FC = () => (
    <Result
        status="success"
        title="Successfully finished reservation"
        extra={[]}
    />
);

export default QrCodeReservationSuccess;
