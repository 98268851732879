import React from "react";
import withRole from "../../../Providers/AuthProvider/WithRole";
import ManageLocations from "../../../Containers/Location/ManageLocations/ManageLocations";

const ManageLocationPage = () => {
    return (
        <div className="add-car-page">
            <ManageLocations />
        </div>
    );
};

export default withRole(ManageLocationPage, ["admin"]);
