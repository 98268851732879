// @ts-nocheck
import Axios from "axios";
import { App } from "antd";

const useIntegrationConfig = () => {
    const { notification } = App.useApp();
    const createConfig = async (data: any) => {
        try {
            await Axios.post("/add/integration/config", data);
            notification.success({
                message: "Successfully created integration configuration.",
            });
            return true;
        } catch (error) {
            notification.error({
                // @ts-ignore
                message:
                    error?.response?.data?.errors ||
                    error?.response?.data?.error ||
                    error.message,
            });
            return false;
        }
    };

    const updateConfig = async (data: any) => {
        console.log(data);
        try {
            await Axios.patch("/update/integration/config", data);
            notification.success({
                message: "Successfully updated integration configuration.",
            });
            return true;
        } catch (error) {
            notification.error({
                // @ts-ignore
                message:
                    error?.response?.data?.errors ||
                    error?.response?.data?.error ||
                    error.message,
            });
            return false;
        }
    };

    const deleteConfig = async (configId: string) => {
        try {
            await Axios.delete(`/remove/integration/config/${configId}`);
            notification.success({
                message: "Successfully removed integration configuration.",
            });
        } catch (error) {
            notification.error({
                // @ts-ignore
                message: error?.response?.data.error || error.message,
            });
        }
    };

    const createPeriod = async (data: any) => {
        try {
            await Axios.post("/add/integration/config/period", data);
            notification.success({
                message: "Successfully created period.",
            });
            return true;
        } catch (error) {
            console.log(error);

            notification.error({
                // @ts-ignore
                message:
                    error?.response?.data?.error ||
                    error?.response?.data?.errors ||
                    error.message,
            });
            return false;
        }
    };

    const getPeriodByConfigId = async ($configId: string) => {
        try {
            const { data } = await Axios.get(`/get/config/${$configId}/period`);
            return data;
        } catch (error) {
            notification.error({
                // @ts-ignore
                message: error?.response?.data?.error,
            });
        }
    };

    const createRule = async (data: any) => {
        try {
            await Axios.post("/add/integration/config/period/rule", data);
            notification.success({
                message: "Successfully created rule.",
            });
            return true;
        } catch (error) {
            notification.error({
                // @ts-ignore
                message: error?.response?.data?.error,
            });
            return false;
        }
    };

    const updatePeriod = async (data: any) => {
        try {
            await Axios.patch("/edit/integration/config/period/", data);
            notification.success({
                message: "Successfully updated period.",
            });
            return true;
        } catch (error) {
            console.log(error);
            notification.error({
                message:
                    error?.response?.data?.errors ||
                    error?.response?.data?.error,
            });
            return false;
        }
    };

    const deleteConfigPeriod = async (periodId: string) => {
        try {
            await Axios.delete(`/remove/integration/config/period/${periodId}`);
            notification.success({
                message:
                    "Successfully removed integration configuration period.",
            });
        } catch (error) {
            notification.error({
                // @ts-ignore
                message: error?.response?.data.error || error.message,
            });
        }
    };

    const updatePeriodRule = async (data: any) => {
        try {
            await Axios.patch("/edit/integration/config/period/rule", data);
            notification.success({
                message: "Successfully updated period rule.",
            });
            return true;
        } catch (error) {
            console.log(error);
            notification.error({
                message:
                    error?.response?.data?.errors ||
                    error?.response?.data?.error,
            });
            return false;
        }
    };

    const deleteConfigPeriodRule = async (ruleId: string) => {
        try {
            await Axios.delete(
                `/remove/integration/config/period/rule/${ruleId}`,
            );
            notification.success({
                message:
                    "Successfully removed integration configuration period rule.",
            });
        } catch (error) {
            notification.error({
                // @ts-ignore
                message: error?.response?.data.error || error.message,
            });
        }
    };

    return {
        createConfig,
        createPeriod,
        createRule,
        updateConfig,
        updatePeriod,
        updatePeriodRule,
        deleteConfig,
        deleteConfigPeriod,
        deleteConfigPeriodRule,
        getPeriodByConfigId,
    };
};

export default useIntegrationConfig;
