// @ts-nocheck
import React, { useEffect, useState } from "react";
import withRole from "Providers/AuthProvider/WithRole";
import {
    Button,
    DatePicker,
    Divider,
    Image,
    Input,
    InputNumber,
    Modal,
    Select,
    Tabs,
    Tag,
    TimePicker,
} from "antd";
import { CustomerTypes } from "Types/CustomerTypes";
import dayjs from "dayjs";
import HeadingTitle from "Components/Headings/HeadingTitle";
import Label from "Components/Label";
import AdditionalDriverOption from "../../../RentCars/SingleCarContainer/partials/AdditionalRentOptions/AdditionalDriverOption";
import CrossingBorderOption from "../../../RentCars/SingleCarContainer/partials/AdditionalRentOptions/CrossingBorderOption";
import TextArea from "antd/es/input/TextArea";
import { getDaysBetweenDates, handleDisabledDates } from "Utils/DateUtils";
import { arraySum } from "Utils/ArrayUtils";
import { CarTypes, RentedCarTypes } from "Types/CarTypes";
import AdditinalDriverTableDetails from "../../../RentCars/SingleCarContainer/partials/AdditionalRentOptions/AdditinalDriverTableDetails";
import useCar from "Hooks/RentCarHooks/useCar";
import { getLocationCurrency } from "Helpers/LocationHelper";
import { RetweetOutlined } from "@ant-design/icons";
import useCustomer from "Hooks/CustomerHooks/useCustomer";
import { Spin } from "antd/lib";

const { RangePicker } = DatePicker;
interface UpdateCustomerReservationProps {
    reservation: {
        uuid: string;
        rent_price: string;
        insurance_price: string;
        insurance_type: "PREMIUM" | "SCDW" | null | undefined;
        damage_excess: string | null;
        check_in: string;
        additional_driver_price: string;
        additional_drivers_list: [];
        crossing_border_list: [];
        check_out: string;
        deposit_price: string;
        gps_price: string;
        total_price: string;
        crossing_border_price: string;
        baby_seat_price: string;
        car: {
            car_name: string;
            car_licence_number: string;
            company_location: [];
            rented_car: RentedCarTypes[];
            car_image_path: string;
        };
        from: string;
        to: string;
        customer: CustomerTypes[];
        note: string;
    };
    customerUuid: string;
    isUpdated: React.Dispatch<React.SetStateAction<boolean>>;
}

const UpdateCustomerReservation = ({
    reservation,
    customerUuid,
    isUpdated,
}: UpdateCustomerReservationProps) => {
    // @ts-ignore
    const { getCarsByLocation } = useCar();
    // @ts-ignore
    const { updateReservation } = useCustomer();

    const {
        check_in,
        check_out,
        rent_price,
        insurance_price,
        insurance_type,
        damage_excess,
        deposit_price,
        additional_driver_price,
        additional_drivers_list,
        crossing_border_list,
        baby_seat_price,
        crossing_border_price,
        gps_price,
        car,
        uuid,
        note,
        from,
        to,
    } = reservation;

    const [open, setOpen] = useState<boolean>(false);
    const [reservationCar, setReservationCar] = useState(car.rented_car);
    const [additionalDrivers, setAdditionalDrivers] = useState(
        additional_drivers_list,
    );
    const [crossingBorderList, setCrossingBordersList] =
        useState(crossing_border_list);
    const [disabledDays, setDisabledDays] = useState([]);
    const [defaultDateValue, setDefaultDateValue] = useState(null);
    const [totalPrice, setTotalPrice] = useState(true);
    const [totalDays, setTotalDays] = useState(0);
    const [isLoading, setLoading] = useState(false);
    const [exchangeCar, setExchangeCar] = useState<CarTypes | null>(null);
    const [locationCars, setLocationCars] = useState([]);
    const [rangePickerValue, setRangePickerValue] = useState([
        dayjs(from),
        dayjs(to),
    ]);
    const [selectedDayRange, setSelectedDayRange] = useState({
        from: null,
        to: null,
    });

    const {
        customer_name,
        customer_email,
        customer_phone,
        customer_country,
        customer_driving_licence,
        customer_passport,
    } = reservation.customer[0];

    const fetchCars = async () => {
        setLoading(true);
        const locationCars = await getCarsByLocation(
            car.company_location[0].uuid,
            setLoading,
        );
        setLocationCars(locationCars);
    };

    const [formData, setFormData] = useState({
        uuid: uuid,
        rent_price: rent_price,
        insurance_price: insurance_price,
        insurance_type,
        deposit_price: deposit_price,
        additional_driver_price: additional_driver_price,
        baby_seat_price: baby_seat_price,
        crossing_border_price: crossing_border_price,
        gps_price: gps_price,
        total_price: "",
        note: note,
        check_in: check_in,
        check_out: check_out,
        from: from,
        to: to,
        customer: {
            //@ts-ignore
            customer_name: customer_name,
            customer_email: customer_email,
            customer_phone: customer_phone,
            customer_country: customer_country,
            customer_passport: customer_passport,
            customer_driving_licence: customer_driving_licence,
        },
    });

    const crossingBordersAction = (data: any) => {
        const { bordersList, price } = data;
        setFormData({ ...formData, crossing_border_price: price });
        setCrossingBordersList(bordersList);
    };

    const additionalDriversAction = (data: any) => {
        const { driverName, driverLicence, price } = data;
        // @ts-ignore
        setAdditionalDrivers([
            ...additionalDrivers,
            {
                driverName: driverName,
                driverLicence: driverLicence,
                price: price,
            },
        ]);
        // @ts-ignore
        setFormData({
            ...formData,
            additional_driver_price:
                parseInt(formData.additional_driver_price) + parseInt(price),
        });
    };

    const handleCarExchange = (carUuid: string) => {
        const car: CarTypes | undefined = locationCars?.find(
            (car: CarTypes) => {
                return car.uuid === carUuid;
            },
        );
        // @ts-ignore
        setExchangeCar(car);
        setDisabledDays([]);
        // @ts-ignore
        setReservationCar(car?.rented_car);
        // @ts-ignore
        setRangePickerValue(null);
        setFormData({
            ...formData,
            from: "",
            to: "",
        });
    };

    // @ts-ignore
    const handleSelectedDayRange = (date, timestring) => {
        setFormData({
            ...formData,
            from: timestring[0],
            to: timestring[1],
        });
        setDefaultDateValue({
            // @ts-ignore
            from: timestring[0],
            to: timestring[1],
        });
        setRangePickerValue([dayjs(timestring[0]), dayjs(timestring[1])]);
    };

    // @ts-ignore
    const isDateDisabled = (current) => {
        const formattedDate = current.format("YYYY-MM-DD");
        // @ts-ignore
        return disabledDays?.includes(formattedDate);
    };

    const handeUpdateReservation = async () => {
        setLoading(true);
        const data = {
            ...formData,
            total_price: totalPrice,
            total_days: totalDays?.toString(),
            crossing_border_list: crossingBorderList,
            additional_drivers_list: additionalDrivers,
            exchangeCar: exchangeCar?.uuid,
        };
        // @ts-ignore
        await updateReservation(data, {
            loading: setLoading,
            closeModal: setOpen,
            resetExchangeCar: setExchangeCar,
            isUpdated: isUpdated,
        });
    };

    useEffect(() => {
        const prices = [
            parseFloat(formData.rent_price),
            // @ts-ignore
            parseFloat(formData.insurance_price || 0),
            // @ts-ignore
            parseFloat(formData.additional_driver_price || 0),
            // @ts-ignore
            parseFloat(formData.baby_seat_price || 0),
            // @ts-ignore
            parseFloat(formData.gps_price || 0),
        ];

        const days = getDaysBetweenDates(formData.from, formData.to);
        const getReservationDays = () => {
            let days = getDaysBetweenDates(formData.from, formData.to);
            if (days === 0 && formData.from && formData.to) {
                return days + 1;
            }
            if (days === 1 && formData.check_in < formData.check_out) {
                return days + 1;
            }
            if (
                days !== 1 &&
                days !== 0 &&
                formData.check_in < formData.check_out
            ) {
                return days + 1;
            }
            return days;
        };
        // @ts-ignore
        let total = days
            ? arraySum(prices) * getReservationDays()
            : arraySum(prices);
        // @ts-ignore
        if (formData.crossing_border_price) {
            // @ts-ignore
            total =
                parseFloat(total) + parseInt(formData.crossing_border_price);
        }
        // @ts-ignore
        setTotalPrice(total.toFixed(2));
        // @ts-ignore
        setTotalDays(getReservationDays());
        // @ts-ignore
        const daysToDisable = [];
        reservationCar?.map((reservation) => {
            const days = handleDisabledDates(reservation.from, reservation.to);
            // @ts-ignore
            reservation.customer[0].uuid !== customerUuid &&
                daysToDisable.push(...days);
            return null;
        });
        // @ts-ignore
        setDisabledDays(daysToDisable);
        // @ts-ignore
        if (open) {
            fetchCars();
        }
    }, [
        formData,
        selectedDayRange,
        defaultDateValue,
        additionalDrivers,
        open,
        exchangeCar,
    ]);

    // Additional drivers table items
    const items = [
        {
            key: "1",
            label: "Additional Driver",
            disabled: !(additionalDrivers?.length > 0),
            children: (
                <AdditinalDriverTableDetails
                    setDrivers={setAdditionalDrivers}
                    drivers={additionalDrivers}
                    setPrice={setFormData}
                    formData={formData}
                />
            ),
        },
    ];

    // @ts-ignore
    return (
        <>
            <Button
                className={"sm:w-full"}
                style={{ backgroundColor: "gray" }}
                type="primary"
                onClick={() => setOpen(true)}
            >
                Edit Reservation
            </Button>
            <Modal
                title="Edit Customer Reservation"
                centered
                okText={"Update"}
                okButtonProps={{ loading: isLoading, disabled: isLoading }}
                open={open}
                onOk={handeUpdateReservation}
                onCancel={() => setOpen(false)}
                width={1200}
            >
                <Spin spinning={isLoading}>
                    <Divider />
                    <div
                        className={
                            "flex gap-3 items-center sm:justify-between sm:mb-3"
                        }
                    >
                        <HeadingTitle
                            title={"Selected Car"}
                            className={"sm:hidden"}
                        />
                        <Select
                            showSearch
                            style={{ width: 350 }}
                            onChange={handleCarExchange}
                            loading={isLoading}
                            disabled={isLoading}
                            placeholder="Change Car..."
                            optionFilterProp="children"
                            // @ts-ignore
                            filterOption={(input, option) =>
                                (option?.label.toLowerCase() ?? "").includes(
                                    input,
                                )
                            }
                            filterSort={(optionA, optionB) =>
                                // @ts-ignore
                                (optionA?.label ?? "")
                                    .toLowerCase()
                                    .localeCompare(
                                        (optionB?.label ?? "").toLowerCase(),
                                    )
                            }
                            options={// @ts-ignore
                            locationCars?.map((car: CarTypes) => {
                                return {
                                    value: car.uuid,
                                    label: `${car.car_name} - ${car.car_licence_number}`,
                                };
                            })}
                        />
                    </div>
                    <div
                        className={`${
                            exchangeCar ? "bg-red-100" : "bg-green-100"
                        } p-1 pt-2 rounded-md flex gap-3 items-center sm:justify-between`}
                    >
                        <div>
                            <Image
                                fallback={process.env.REACT_APP_IMAGE_FALLBACK}
                                className={"border"}
                                width={40}
                                src={`${process.env.REACT_APP_DOMAIN}${car.car_image_path}`}
                            />
                        </div>
                        <div className={"flex gap-3"}>
                            <p>{car.car_name}</p>
                            <div>
                                <Tag color={"blue-inverse"}>
                                    {car.car_licence_number}
                                </Tag>
                            </div>
                        </div>
                    </div>
                    {exchangeCar && (
                        <div className={"flex justify-center mt-2 mb-2"}>
                            <RetweetOutlined className={"text-[20px]"} />
                        </div>
                    )}
                    {exchangeCar && (
                        <>
                            <div
                                className={
                                    "bg-green-100  p-1 pt-2 rounded-md flex gap-3 items-center sm:justify-between"
                                }
                            >
                                <div>
                                    <Image
                                        className={"border"}
                                        width={40}
                                        fallback={
                                            process.env.REACT_APP_IMAGE_FALLBACK
                                        }
                                        //@ts-ignore
                                        src={`${process.env.REACT_APP_DOMAIN}${exchangeCar?.car_image_path}`}
                                    />
                                </div>
                                <div className={"flex gap-3"}>
                                    <p>{exchangeCar?.car_name}</p>
                                    <div>
                                        <Tag color={"blue-inverse"}>
                                            {exchangeCar?.car_licence_number}
                                        </Tag>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                    <div className={"flex gap-3 sm:flex-wrap"}>
                        <div className={"flex flex-col gap-3 w-full"}>
                            <HeadingTitle title={"Customer Info"} />
                            <div>
                                <Label
                                    className={"text-blue-500"}
                                    title={"Name"}
                                />
                                <Input
                                    placeholder="Full Name"
                                    defaultValue={
                                        formData.customer.customer_name
                                    }
                                    className="w-full"
                                    onChange={(e) =>
                                        setFormData({
                                            ...formData,
                                            customer: {
                                                ...formData.customer,
                                                customer_name: e.target.value,
                                            },
                                        })
                                    }
                                />
                            </div>
                            <div>
                                <Label
                                    className={"text-blue-500"}
                                    title={"Email"}
                                />
                                <Input
                                    placeholder="Email Address"
                                    defaultValue={
                                        formData.customer.customer_email
                                    }
                                    className="w-full"
                                    onChange={(e) =>
                                        setFormData({
                                            ...formData,
                                            customer: {
                                                ...formData.customer,
                                                customer_email: e.target.value,
                                            },
                                        })
                                    }
                                />
                            </div>
                            <div>
                                <Label
                                    className={"text-blue-500"}
                                    title={"Phone No."}
                                />
                                <InputNumber
                                    placeholder="Phone"
                                    defaultValue={
                                        formData.customer.customer_phone
                                    }
                                    className="w-full"
                                    // @ts-ignore
                                    onChange={(phone) =>
                                        setFormData({
                                            ...formData,
                                            customer: {
                                                ...formData.customer,
                                                customer_phone: phone,
                                            },
                                        })
                                    }
                                />
                            </div>
                            <div>
                                <Label
                                    className={"text-blue-500"}
                                    title={"Country"}
                                />
                                <Input
                                    defaultValue={
                                        formData.customer.customer_country
                                    }
                                    placeholder="Country"
                                    className="w-full"
                                    onChange={(e) =>
                                        setFormData({
                                            ...formData,
                                            customer: {
                                                ...formData.customer,
                                                customer_country:
                                                    e.target.value,
                                            },
                                        })
                                    }
                                />
                            </div>
                            <div>
                                <Label
                                    className={"text-blue-500"}
                                    title={"Passport No."}
                                />
                                <Input
                                    defaultValue={
                                        formData.customer.customer_passport
                                    }
                                    placeholder="Passport Number"
                                    className="w-full"
                                    onChange={(e) =>
                                        setFormData({
                                            ...formData,
                                            customer: {
                                                ...formData.customer,
                                                customer_passport:
                                                    e.target.value,
                                            },
                                        })
                                    }
                                />
                            </div>
                            <div>
                                <Label
                                    className={"text-blue-500"}
                                    title={"Drivers Licence"}
                                />
                                <Input
                                    defaultValue={
                                        formData.customer
                                            .customer_driving_licence
                                    }
                                    placeholder="Driving Licence"
                                    className="w-full"
                                    onChange={(e) =>
                                        setFormData({
                                            ...formData,
                                            customer: {
                                                ...formData.customer,
                                                customer_driving_licence:
                                                    e.target.value,
                                            },
                                        })
                                    }
                                />
                            </div>
                        </div>
                        <div className="flex flex-col gap-3 w-full">
                            <HeadingTitle title={"Price Info"} />
                            <div>
                                <Label
                                    className={"text-blue-500"}
                                    title={"Deposit Price"}
                                />
                                <InputNumber
                                    type="number"
                                    placeholder="Deposit price"
                                    defaultValue={formData.deposit_price}
                                    className="input w-full"
                                    // @ts-ignore
                                    onChange={(deposit) =>
                                        setFormData({
                                            ...formData,
                                            deposit_price:
                                                deposit === "" ? 0 : deposit,
                                        })
                                    }
                                />
                            </div>
                            <div
                                className={
                                    "flex items-center gap-3 sm:flex-wrap"
                                }
                            >
                                <div className={"basis-6/12"}>
                                    <Label
                                        className={"text-blue-500"}
                                        title={"Insurance Price"}
                                    />
                                    <InputNumber
                                        defaultValue={formData.insurance_price}
                                        placeholder="Insurance price per/day"
                                        className="w-full"
                                        // @ts-ignore
                                        onChange={(insurance) =>
                                            setFormData({
                                                ...formData,
                                                insurance_price:
                                                    insurance === ""
                                                        ? 0
                                                        : insurance,
                                            })
                                        }
                                    />
                                </div>
                                <div className={"basis-6/12"}>
                                    <Label
                                        className={"text-blue-500"}
                                        title={"Insurance Type"}
                                    />
                                    <Select
                                        className={"w-full"}
                                        defaultValue={formData.insurance_type}
                                        onChange={(value) =>
                                            setFormData({
                                                ...formData,
                                                insurance_type: value,
                                            })
                                        }
                                        placeholder={"Select insurance type"}
                                    >
                                        <Select.Option value={"PREMIUM"}>
                                            Premium Coverage
                                        </Select.Option>
                                        <Select.Option value={"SCDW"}>
                                            SCDW (Super Colision Damage Waiver)
                                        </Select.Option>
                                    </Select>
                                </div>
                            </div>

                            <div>
                                <Label
                                    className={"text-blue-500"}
                                    title={"Rent Price"}
                                />
                                <Input
                                    placeholder="Rent price per/day"
                                    defaultValue={formData.rent_price}
                                    className="input w-full"
                                    // @ts-ignore
                                    onChange={(rentPrice) =>
                                        setFormData({
                                            ...formData,
                                            rent_price: rentPrice.target.value
                                                ? rentPrice.target.value
                                                : null,
                                        })
                                    }
                                />
                            </div>
                            <div className={"flex w-full md:flex-wrap gap-3"}>
                                <div className={"w-full"}>
                                    <Label
                                        className={"text-blue-500"}
                                        title={"Pick Up"}
                                    />
                                    <TimePicker
                                        className={"w-full"}
                                        placeholder={"Pick Up"}
                                        defaultValue={dayjs(
                                            formData.check_in,
                                            "HH:mm",
                                        )}
                                        format={"HH:mm"}
                                        onChange={(time, timeString) => {
                                            setFormData({
                                                ...formData,
                                                check_in: timeString,
                                            });
                                        }}
                                    />
                                </div>
                                <div className={"w-full"}>
                                    <Label
                                        className={"text-blue-500"}
                                        title={"Returns"}
                                    />
                                    <TimePicker
                                        className={"w-full"}
                                        placeholder={"Return"}
                                        defaultValue={dayjs(
                                            formData.check_out,
                                            "HH:mm",
                                        )}
                                        format={"HH:mm"}
                                        onChange={(time, timeString) => {
                                            setFormData({
                                                ...formData,
                                                check_out: timeString,
                                            });
                                        }}
                                    />
                                </div>
                            </div>
                            <div className={"w-full"}>
                                <Label
                                    className={"text-blue-500"}
                                    title={"Select Dates"}
                                />
                                <div className={"w-full"}>
                                    <RangePicker
                                        allowClear={false}
                                        className={"w-full"}
                                        // @ts-ignore
                                        value={rangePickerValue}
                                        onChange={handleSelectedDayRange}
                                        disabledDate={isDateDisabled}
                                    />
                                </div>
                            </div>
                            <div>
                                <Label
                                    className={"text-blue-500"}
                                    title={"Customer Notes"}
                                />
                                <TextArea
                                    rows={1}
                                    defaultValue={note}
                                    placeholder="Leave a note for customer..."
                                    // @ts-ignore
                                    onChange={(e) =>
                                        setFormData({
                                            ...formData,
                                            note:
                                                e.target.value === ""
                                                    ? null
                                                    : e.target.value,
                                        })
                                    }
                                />
                            </div>
                        </div>
                    </div>
                    <div>
                        <HeadingTitle title={"Additional Options"} />
                        <div className="flex flex-wrap gap-3">
                            <AdditionalDriverOption
                                onAction={additionalDriversAction}
                            />
                            <CrossingBorderOption
                                // @ts-ignore
                                crossingBorderList={crossingBorderList}
                                formPrice={formData.crossing_border_price}
                                currency={"BAM"}
                                onAction={crossingBordersAction}
                            />
                            <Input
                                placeholder="GPS per/day"
                                className="w-36 sm:w-full"
                                defaultValue={gps_price}
                                // @ts-ignore
                                onChange={(e) =>
                                    setFormData({
                                        ...formData,
                                        gps_price: e.target.value || null,
                                    })
                                }
                            />
                            <Input
                                placeholder="Baby Seat per/day"
                                className="w-36 sm:w-full"
                                defaultValue={baby_seat_price}
                                // @ts-ignore
                                onChange={(e) =>
                                    setFormData({
                                        ...formData,
                                        baby_seat_price: e.target.value || null,
                                    })
                                }
                            />
                            <Input
                                defaultValue={damage_excess}
                                placeholder="Damage/Theft Excess"
                                className="w-40 sm:w-full"
                                title={"Damage/Theft Excess"}
                                onChange={(e) =>
                                    setFormData({
                                        ...formData,
                                        damage_excess: e.target.value || null,
                                    })
                                }
                            />
                        </div>
                        <div>
                            {additionalDrivers?.length > 0 && (
                                <Tabs
                                    className={"bg-[#fafafa] mt-3 rounded-xl"}
                                    tabPosition={"left"}
                                    items={items}
                                    size={"small"}
                                />
                            )}
                        </div>
                    </div>
                    <div>
                        <HeadingTitle title={"Summary"} />
                        <div className={"flex flex-col"}>
                            <div className={"flex gap-3 justify-end"}>
                                <p className={"font-bold uppercase"}>
                                    Total Days:{" "}
                                </p>
                                <p className={"font-bold uppercase"}>
                                    {totalDays}
                                </p>
                            </div>
                            <div className={"flex gap-3 justify-end"}>
                                <p className={"font-bold uppercase"}>
                                    Total Price:{" "}
                                </p>
                                <p className={"font-bold uppercase"}>
                                    {totalPrice}{" "}
                                    {getLocationCurrency(car.company_location)}
                                </p>
                            </div>
                        </div>
                        <Divider />
                    </div>
                </Spin>
            </Modal>
        </>
    );
};

export default withRole(
    UpdateCustomerReservation,
    ["admin"],
    ["manage_reservation"],
);
