import React, { useEffect, useState } from "react";
import useCompany from "Hooks/CompanyHooks/useCompany";
import { Button, Input, Modal, Table, TableProps } from "antd";
import Label from "Components/Label";
import Editor from "Components/TextEditor/TextEditor";

interface DataType {
    key: string;
    name: string;
}

interface LegalDocumentsTypes {
    uuid?: string;
    name: string;
    terms_conditions: string;
    legal_information: string;
}

const LegalDocumentsList = () => {
    const {
        getAllLegalDocuments,
        getLegalDocumentByUuid,
        updateLegalDocument,
        deleteLegalDocument,
    } = useCompany();

    const [open, setOpen] = useState(false);
    const [termsConditions, setTermsConditions] = useState<string>();
    const [legalInformations, setLegalInformations] = useState<string>();
    const [documentName, setDocumentName] = useState<string>();
    const [documents, setDocuments] = useState<LegalDocumentsTypes[] | null>();
    const [loading, setLoading] = useState(false);
    const [uuid, setUuid] = useState<string>();

    const fetchLegalDocuments = async () => {
        const docs = await getAllLegalDocuments();
        setDocuments(docs);
    };

    const fetchSingleLegalDocument = async (uuid: string) => {
        setOpen(true);
        const doc = (await getLegalDocumentByUuid(uuid)) as LegalDocumentsTypes;
        if (doc) {
            setLegalInformations(doc.legal_information);
            setTermsConditions(doc.terms_conditions);
            setDocumentName(doc.name);
            setUuid(doc.uuid);
        }
    };

    const patchLegalDocument = async (uuid: string) => {
        setLoading(true);
        const data = {
            name: documentName,
            terms_conditions: termsConditions,
            legal_information: legalInformations,
        } as LegalDocumentsTypes;
        await updateLegalDocument(uuid, data);
        setLoading(false);
        fetchLegalDocuments();
    };

    useEffect(() => {
        fetchLegalDocuments();
    }, [uuid]);

    const columns: TableProps<DataType>["columns"] = [
        {
            title: "Document Name",
            dataIndex: "name",
            key: "name",
            className: "w-[36]",
        },
        {
            title: "Actions",
            width: 80,
            dataIndex: "actions",
            key: "actions",
            render: (_, record) => (
                <div className={"flex gap-3"}>
                    <Button
                        type={"primary"}
                        onClick={() => fetchSingleLegalDocument(record.key)}
                    >
                        Edit
                    </Button>
                    <Button
                        type={"primary"}
                        danger
                        onClick={async () => {
                            await deleteLegalDocument(record.key);
                            await fetchLegalDocuments();
                        }}
                    >
                        Delete
                    </Button>
                </div>
            ),
        },
    ];

    return (
        <>
            <Table
                className={"mt-5"}
                bordered
                dataSource={documents?.map((document, index) => {
                    return {
                        key: document.uuid,
                        name: document.name,
                    };
                })}
                // @ts-ignore
                columns={columns}
            />
            <Modal
                title="Update Legal Document"
                okText={"Update"}
                open={open}
                confirmLoading={loading}
                onOk={() => patchLegalDocument(uuid as string)}
                onCancel={() => setOpen(false)}
                width={1000}
            >
                <div className={"flex flex-col gap-3"}>
                    <div>
                        <Label title={"Document Name"} />
                        <Input
                            value={documentName}
                            onChange={(e) => setDocumentName(e.target.value)}
                            type={"text"}
                            placeholder={"Enter document name..."}
                        />
                    </div>
                    <div>
                        <Label title={"Legal Informations"} />
                        <Editor
                            actualValue={legalInformations}
                            returnValues={setLegalInformations}
                        />
                    </div>
                    <div>
                        <Label title={"Terms & Conditions"} />
                        <Editor
                            actualValue={termsConditions}
                            returnValues={setTermsConditions}
                        />
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default LegalDocumentsList;
