import { useSession } from "../../Hooks/SessionHooks/useSession";
import Axios from "axios";

const RequireAuth = ({ children }) => {
    const session = useSession();

    const token = session.getToken();

    if (!token) {
        window.location.href = "/login";
    } else {
        Axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
        Axios.get("/user")
            .then((res) => {
                session.setUserCache(res.data);
            })
            .catch((err) => {
                session.clearToken();
                session.clearUserCache();
                window.location.reload();
            });
    }
    return children;
};

export default RequireAuth;
