import React from "react";
import withRole from "../../../Providers/AuthProvider/WithRole";
import AddEmployee from "../../../Containers/Employee/AddEmploye";

const AddEmployeePage = () => {
    return (
        <div className="add-employee-page">
            <AddEmployee />
        </div>
    );
};

export default withRole(AddEmployeePage, ["admin"]);
