import React from "react";
import EditRentCarForm from "./components/EditRentCarForm";

const EditRentCar = () => {
    return (
        <div className="rent-cars-container p-1">
            <EditRentCarForm />
        </div>
    );
};

export default EditRentCar;
