import React, { useState } from "react";
import { Button, DatePicker, Divider, Input, Modal } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import useIntegrationConfig from "Hooks/IntegrationHooks/IntegrationConfig/useIntegrationConfig";
import Label from "Components/Label";

interface CreatePeriodModalPropTypes {
    configId: string;
    update: () => void;
}
const CreatePeriodModal = ({
    update,
    configId,
}: CreatePeriodModalPropTypes) => {
    const { createPeriod } = useIntegrationConfig();

    const initialFormData = {
        integration_config_id: configId,
        date_from: "",
        date_to: "",
        price: "",
    };
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [formData, setFormData] = useState(initialFormData);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = async () => {
        await createPeriod(formData).then((res) => {
            if (res) {
                setIsModalOpen(false);
                update();
            }
        });
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    return (
        <>
            <Button
                type="primary"
                icon={<PlusCircleOutlined />}
                onClick={showModal}
            >
                Add Period
            </Button>
            <Modal
                title="Create Period"
                open={isModalOpen}
                onOk={handleOk}
                okText={"Save"}
                onCancel={handleCancel}
            >
                <small>
                    Add date ranges that will be handled in API and give price
                    depending on months.
                </small>
                <Divider />
                <div className={"flex flex-col gap-3"}>
                    <div className={"flex gap-3"}>
                        <div className={"w-full"}>
                            <Label title={"From Date"} />
                            <DatePicker
                                className={"w-full"}
                                placeholder={"From"}
                                onChange={(_, timestring) =>
                                    setFormData({
                                        ...formData,
                                        date_from: `${timestring} 00:00`,
                                    })
                                }
                            />
                        </div>
                        <div className={"w-full"}>
                            <Label title={"To Date"} />
                            <DatePicker
                                className={"w-full"}
                                placeholder={"To"}
                                onChange={(_, timestring) =>
                                    setFormData({
                                        ...formData,
                                        date_to: `${timestring} 00:00`,
                                    })
                                }
                            />
                        </div>
                    </div>

                    <div>
                        <Label title={"Price"} />
                        <Input
                            placeholder={"Price"}
                            value={formData.price || undefined}
                            type={"text"}
                            onChange={(e) =>
                                setFormData({
                                    ...formData,
                                    price: e.target.value,
                                })
                            }
                        />
                    </div>
                </div>
                <Divider />
            </Modal>
        </>
    );
};

export default CreatePeriodModal;
