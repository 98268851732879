import React from "react";
import { Link } from "react-router-dom";
import {
    Alert,
    Avatar,
    Badge,
    Divider,
    Image,
    Popover,
    Tag,
    Tooltip,
} from "antd";
import { RentCarCardProps } from "Types/CarTypes";
import Title from "antd/es/typography/Title";
import { getLocationCurrency } from "Helpers/LocationHelper";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { FaGasPump } from "react-icons/fa";
import { GiCarDoor, GiGearStickPattern } from "react-icons/gi";
import RentalStatusHandler from "../Status/CarStatus/RentalStatusHandler";
import { formatDate } from "Utils/DateUtils";
import CarOptions from "./partials/CarOptions";
import {
    getCarCategory,
    getCarStatusText,
    getRentalCount,
    handleCurrentRentalStatus,
    isCarActive,
    isCarRented,
} from "Helpers/CarHelper";
import TypeReservationHandler from "../TypeReservation/TypeReservationHandler";
import { getCustomerReservationType } from "../../Helpers/CustomerHelper";

const RentCarCard = ({
    carData,
    triggerModal,
    onCheckSection = false,
    checkType,
    showReservationType = false,
}: RentCarCardProps) => {
    const {
        uuid,
        car_name,
        car_price,
        car_transmission_type,
        car_fuel_type,
        car_image_path,
        car_doors,
        car_engine_type,
        car_licence_number,
        company_location,
        rented_car,
        category,
        car_production_year,
        is_active,
    } = carData;

    return (
        <div className="w-full mt-3 mb-3 h-32 flex justify-between gap-5 rounded-xl items-center bg-white border">
            <Link to={`/car/${uuid}`}>
                <Badge count={getRentalCount(rented_car)}>
                    <Avatar
                        shape={"square"}
                        style={{ border: "1px solid #e7e7e7" }}
                        className={"bg-[#fff] ml-5"}
                        size={90}
                        icon={
                            <Image
                                preview={false}
                                src={`${process.env.REACT_APP_DOMAIN}${car_image_path}`}
                                fallback={process.env.REACT_APP_IMAGE_FALLBACK}
                            />
                        }
                    />
                </Badge>
            </Link>
            <div className={"w-32"}>
                <Link to={`/car/${uuid}`}>
                    <Title className={"font-medium uppercase"} level={5}>
                        {" "}
                        {car_name}
                    </Title>
                    <Title className={"italic"} level={5}>
                        {car_production_year}
                    </Title>
                </Link>
            </div>
            <div className={"w-32"}>
                <Tag
                    className={"font-bold w-28 text-center uppercase"}
                    color={"#9e9e9e"}
                >
                    <Tooltip
                        title={"Category"}
                        placement={"top"}
                        className={"font-normal"}
                    >
                        {getCarCategory(category)}
                    </Tooltip>
                </Tag>
            </div>
            <div className={"w-24"}>
                <Title level={5}>
                    <Tooltip
                        title={"Licence Plate"}
                        placement={"top"}
                        className={"font-normal"}
                    >
                        {car_licence_number}
                    </Tooltip>
                </Title>
            </div>
            <div className={"w-20 relative z-50"}>
                <Title level={5} className={"!text-[#8b8b8b] !font-medium"}>
                    Specs
                    <Popover
                        title={
                            <div>
                                <div className="flex items-center">
                                    <FaGasPump />
                                    <span className="ml-2 capitalize">
                                        {car_fuel_type}
                                    </span>
                                </div>
                                <div className="flex items-center">
                                    <GiGearStickPattern />
                                    <span className="ml-2 uppercase">
                                        {car_transmission_type}
                                    </span>
                                </div>
                                <div className="flex items-center">
                                    <FaGasPump />
                                    <span className="ml-2 uppercasee">
                                        {car_engine_type}
                                    </span>
                                </div>
                                <div className="flex items-center">
                                    <GiCarDoor className={"text-[15px]"} />
                                    <span className="ml-2 uppercase">
                                        {car_doors}
                                    </span>
                                </div>
                            </div>
                        }
                        placement="top"
                        trigger={"click"}
                    >
                        <QuestionCircleOutlined className={"ml-2"} />
                    </Popover>
                </Title>
            </div>
            <div className={"w-20 relative z-50"}>
                <Title level={5} className={"!text-[#8b8b8b] !font-medium"}>
                    Info
                    <Popover
                        title={
                            <div>
                                {isCarRented(rented_car) ? (
                                    <div>
                                        <p className={"flex justify-between"}>
                                            <span className={"text-blue-500"}>
                                                FROM:
                                            </span>
                                            <span>
                                                {formatDate(
                                                    rented_car[0].from,
                                                    "/",
                                                )}{" "}
                                                - {rented_car[0].check_in}{" "}
                                            </span>
                                        </p>
                                        <p className={"flex justify-between"}>
                                            <span className={"text-blue-500"}>
                                                TO:
                                            </span>
                                            <span>
                                                {formatDate(
                                                    rented_car[0].to,
                                                    "/",
                                                )}{" "}
                                                - {rented_car[0].check_out}
                                            </span>
                                        </p>
                                        <Divider className={"mt-1 mb-1"} />
                                        <p>
                                            {
                                                rented_car[0].customer[0]
                                                    .customer_name
                                            }
                                        </p>
                                    </div>
                                ) : (
                                    <Alert
                                        showIcon
                                        className={"p-2 font-normal"}
                                        description={"Info not available."}
                                        type={"warning"}
                                    />
                                )}
                            </div>
                        }
                        placement="top"
                        trigger={"click"}
                    >
                        <QuestionCircleOutlined className={"ml-2"} />
                    </Popover>
                </Title>
            </div>
            <div className={"w-18"}>
                <Title level={4}>
                    {" "}
                    {car_price} {getLocationCurrency(company_location)}
                </Title>
            </div>
            {showReservationType && (
                <div className={"w-18"}>
                    <TypeReservationHandler
                        type={getCustomerReservationType(
                            rented_car[0].customer,
                        )}
                        size={70}
                    />
                </div>
            )}
            <div className={"w-32"}>
                <RentalStatusHandler
                    rentalStatus={handleCurrentRentalStatus(
                        rented_car,
                        checkType,
                        onCheckSection,
                    )}
                    date={
                        isCarRented(rented_car) ? rented_car[0].from : undefined
                    }
                />
            </div>
            <div className={"w-12"}>
                <CarOptions triggerModal={triggerModal} carData={carData} />
            </div>
        </div>
    );
};
export default RentCarCard;
