import React from "react";
import ReactCountryFlag from "react-country-flag";
import { formatDate } from "Utils/DateUtils";
import HorizontalDivider from "Components/HorizontalDivider";
import { useNavigate } from "react-router-dom";
import { Avatar, Tag } from "antd";
// @ts-ignore
const LocationCard = ({ locationData, action }) => {
    const navigate = useNavigate();
    const handleEdit = () => {
        navigate("/edit/location", { state: { data: locationData } });
    };

    const { name, city, address, country, country_code, currency, created_at } =
        locationData;

    return (
        <div
            onClick={handleEdit}
            className="cursor-pointer location-card sm:w-full w-96 xl-min:flex-1 2xl:flex-2 border-2 flex flex-wrap justify-between p-3 rounded items-center hover:border-blue-500 transition-all duration-300 ease-in-out delay-0"
        >
            <div className="location-flag text-start basis-1/4">
                <Avatar
                    size={64}
                    icon={
                        <ReactCountryFlag
                            countryCode={country_code}
                            svg
                            style={{ width: "70px", height: "90px" }}
                        />
                    }
                />
            </div>
            <div className="location-info basis-3/4 relative">
                <div className="uppercase flex justify-between font-bold">
                    {" "}
                    <span>{country}</span>{" "}
                    <span>
                        {" "}
                        <Tag className={"font-bold"} color={"gray"}>
                            {currency}
                        </Tag>{" "}
                    </span>
                </div>
                <br />
                <div className="mt-4 address">
                    <span>
                        {city} - {address}
                    </span>
                </div>
                <HorizontalDivider />
                <div className="company-info flex justify-between relative mt-5 sm:top-3 text-sm text-gray">
                    <p>{formatDate(created_at)}</p>
                    <p>{name}</p>
                </div>
            </div>
        </div>
    );
};

export default LocationCard;
