import { useState } from "react";
import { Link } from "react-router-dom";
import { App, Input } from "antd";
import { useAuth } from "Hooks/AuthHooks/useAuth";
import HorizontalDivider from "Components/HorizontalDivider/HorizontalDivider";

type StrNull = string | null;
const RegisterForm = () => {
    const { register } = useAuth();
    const { message } = App.useApp();
    const [email, setEmail] = useState<StrNull>(null);
    const [firstname, setFirstname] = useState<StrNull>(null);
    const [lastname, setLastname] = useState<StrNull>(null);
    const [company, setCompany] = useState<StrNull>(null);
    const [password, setPassword] = useState<StrNull>(null);
    const [confirmPassword, setConfirmPassword] = useState<StrNull>(null);
    const [loader, setLoader] = useState(false);

    const registerUser = () => {
        setLoader(true);
        const data = {
            email: email,
            company: company,
            password: password,
            firstname: firstname,
            lastname: lastname,
        };
        if (password !== confirmPassword) {
            message.error("The password you entered does not match.");
        } else {
            register(data, setLoader);
        }
    };

    return (
        <div className="w-2/3 md:w-full h-screen">
            <div className="relative w-full h-full bg-white">
                <div className="center-full w-1/2 p-8 rac-shadow rounded-xl">
                    <div className="flex flex-col justify-center w-full">
                        <span className="font-semibold span-2xl span-secondary mb-5">
                            Create an account
                        </span>
                        <span className=" span-secondary mb-5">
                            Enter your data to create an account.
                        </span>
                        {/*@ts-ignore*/}
                        <Input
                            onChange={({ target: { value } }) =>
                                setEmail(value)
                            }
                            className="p-3 mb-5"
                            name="email"
                            type="email"
                            placeholder="Enter your email"
                        />
                        {/*@ts-ignore*/}
                        <Input
                            onChange={(e) => setFirstname(e.target.value)}
                            className="p-3 mb-5"
                            name="firstname"
                            type="span"
                            placeholder="Enter firstname"
                        />
                        {/*@ts-ignore*/}
                        <Input
                            onChange={(e) => setLastname(e.target.value)}
                            className="p-3 mb-5"
                            name="lastname"
                            type="span"
                            placeholder="Enter lastname"
                        />
                        {/*@ts-ignore*/}
                        <Input
                            onChange={(e) => setCompany(e.target.value)}
                            className="p-3 mb-5"
                            name="company_name"
                            type="span"
                            placeholder="Enter your company name"
                        />
                        {/*@ts-ignore*/}
                        <Input
                            onChange={(e) => setPassword(e.target.value)}
                            className="p-3 mb-5"
                            name="password"
                            type="password"
                            placeholder="Enter password"
                        />
                        {/*@ts-ignore*/}
                        <Input
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            className="p-3 mb-5"
                            name="confirm_password"
                            type="password"
                            placeholder="Confirm password"
                        />

                        <button
                            onClick={registerUser}
                            className="mb-5 rac-btn-lg btn btn-primary bg-primary span-white"
                        >
                            {/*@ts-ignore*/}
                        </button>
                        <HorizontalDivider classes={"mt-3 mb-3"} />
                        {/*@ts-ignore*/}
                        <Link>
                            <span className="span-secondary">
                                Forgot password?
                            </span>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RegisterForm;
