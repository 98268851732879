import React from "react";
import withRole from "Providers/AuthProvider/WithRole";
import { useNavigate } from "react-router-dom";
import { EditOutlined } from "@ant-design/icons";

const SingeCarActions = ({ data }) => {
    const navigate = useNavigate();

    const handleEdit = () => {
        navigate("/edit/car", { state: { ...data } });
    };
    return (
        <div className="right-0 top-0">
            <EditOutlined
                className={"cursor-pointer text-xl"}
                onClick={handleEdit}
            />
        </div>
    );
};

export default withRole(SingeCarActions, ["admin"]);
