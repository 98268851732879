import React from "react";
import withRole from "../../../Providers/AuthProvider/WithRole";
import ManageRentCarContainer from "../../../Containers/RentCars/ManageRentCars/ManageRentCarContainer";

const ManageCarsPage = () => {
    return (
        <div className="manage-cars-page">
            <ManageRentCarContainer />
        </div>
    );
};

export default withRole(ManageCarsPage, ["admin", "employee"]);
