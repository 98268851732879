import React, { useEffect, useState } from "react";
import { App, Input, Select } from "antd";
import HeadingTitle from "Components/Headings/HeadingTitle";
import useLocations from "Hooks/LocationsHooks/useLocations";
import LoadingPlaceholder from "Components/Placeholders/LoadingPlaceholder/LoadingPlaceholder";
import useEmployee from "Hooks/EmployeeHooks/useEmployee";
import UploaderImage from "Components/UploaderImage";
import Button from "Components/Button";
import Label from "Components/Label";

interface EmployeFormData {
    firstname: string | null;
    lastname: string | null;
    password: string | null;
    confirmPassword: string | null;
    location_uuid: string | null;
    email: string | null;
}
const AddEmployeForm = () => {
    const { getLocations } = useLocations();
    const { setEmployee } = useEmployee();
    const { message } = App.useApp();

    const [locations, setLocations] = useState(null);
    const [imagePath, setImagePath] = useState(null);
    const [loading, setLoading] = useState(false);

    const [formData, setFormData] = useState<EmployeFormData>({
        firstname: null,
        lastname: null,
        password: null,
        confirmPassword: null,
        location_uuid: null,
        email: null,
    });

    async function fetchLocationData() {
        const data = await getLocations();
        setLocations(data);
    }

    const addEmployee = (e: { preventDefault: () => void }) => {
        e.preventDefault();
        if (formData.password !== formData.confirmPassword) {
            message.error(
                "Passwords do not match, please set correct password.",
            );
        } else {
            setLoading(true);
            Object.assign(formData, { image: imagePath });
            setEmployee(formData, setLoading);
        }
    };

    useEffect(() => {
        fetchLocationData();
    }, []);

    if (!locations) return <LoadingPlaceholder />;

    return (
        <div>
            <HeadingTitle title="Add Employee" />

            <form>
                <div className="w-full flex flex-col flex-wrap mb-10 ">
                    <div className="flex flex-col mb-3">
                        <Label className={"mb-3"} title={"Profile photo"} />
                        <UploaderImage setImage={setImagePath} />
                    </div>
                    <div className=" flex justify-between flex-row flex-wrap gap-3">
                        <div className="md-min:w-[48%] lg:w-[49%] sm:w-full sm:mb-3">
                            <Label title={"Firstname"} />
                            <Input
                                // @ts-ignore
                                onChange={(e) =>
                                    setFormData({
                                        ...formData,
                                        firstname: e.target.value,
                                    })
                                }
                                className="w-full sm:w-full"
                                type="text"
                                placeholder="Firstname..."
                            />
                        </div>
                        <div className=" w-[48%] sm:w-full sm:mb-3">
                            <Label title={"Lastname"} />
                            <Input
                                // @ts-ignore
                                onChange={(e) =>
                                    setFormData({
                                        ...formData,
                                        lastname: e.target.value,
                                    })
                                }
                                className="w-full sm:w-full"
                                type="text"
                                placeholder="Lastname..."
                            />
                        </div>
                        <div className=" w-[48%] sm:w-full sm:mb-3">
                            <Label title={"Email"} />
                            <Input
                                // @ts-ignore
                                onChange={(e) =>
                                    setFormData({
                                        ...formData,
                                        email: e.target.value,
                                    })
                                }
                                className="w-full sm:w-full"
                                type="text"
                                placeholder="Email..."
                            />
                        </div>
                        <div className="w-[48%] sm:w-full sm:mb-3">
                            <Label title={"Select Location"} />
                            <Select
                                onChange={(uuid) =>
                                    setFormData({
                                        ...formData,
                                        location_uuid: uuid,
                                    })
                                }
                                placeholder={"Select location..."}
                                style={{ width: "100%" }}
                                // @ts-ignore
                                disabled={!locations?.length > 0}
                                // @ts-ignore
                                options={locations?.map((location) => {
                                    const { name, address, uuid } = location;
                                    return {
                                        value: uuid,
                                        label: `${name} - ${address}`,
                                    };
                                })}
                            />
                        </div>
                        <div className=" w-[48%] sm:w-full sm:mb-3">
                            <Label title={"Password"} />
                            <Input
                                onChange={(e) =>
                                    setFormData({
                                        ...formData,
                                        password: e.target.value,
                                    })
                                }
                                className="w-full sm:w-full"
                                type="password"
                                placeholder="Password.."
                            />
                        </div>
                        <div className="w-[48%] sm:w-full">
                            <Label title={"Confirm Password"} />
                            <Input
                                onChange={(e) =>
                                    setFormData({
                                        ...formData,
                                        confirmPassword: e.target.value,
                                    })
                                }
                                className="w-full sm:w-full"
                                type="password"
                                placeholder="Confirm Password..."
                            />
                        </div>
                    </div>
                </div>
                <HeadingTitle title="ACTIONS" />
                <div className="w-full text-end mt-9">
                    <Button
                        className={"w-36"}
                        isLoading={loading}
                        onClick={addEmployee}
                        buttonType={"primary"}
                    >
                        Add Employee
                    </Button>
                </div>
            </form>
        </div>
    );
};

export default AddEmployeForm;
