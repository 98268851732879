import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { DeleteFilled } from "@ant-design/icons";

interface AdditinalDriverTableProps {
    drivers: Driver[];
    setDrivers: any;
    setPrice: any;
    formData: any;
}
interface Driver {
    driverName: string;
    driverLicence: string;
    price: number;
}

interface DataType {
    key: string | number;
    name: string;
    licence: number | string;
    price: number;
    delete: string | number;
}

const AdditinalDriverTableDetails = ({
    drivers,
    setDrivers,
    setPrice,
    formData,
}: AdditinalDriverTableProps) => {
    const [tableSource, setTableSource] = useState<DataType[]>([]);

    const columns: ColumnsType<DataType> = [
        {
            title: "Name",
            dataIndex: "name",
            key: "1",
        },
        {
            title: "Licence",
            dataIndex: "licence",
            key: "2",
        },
        {
            title: "Price",
            dataIndex: "price",
            key: "3",
        },
        {
            title: "Action",
            className: "!text-center",
            dataIndex: "delete",
            key: "4",
            render: (_record) => (
                <>
                    <DeleteFilled
                        onClick={() => updateTableSource(_record)}
                        className={"cursor-pointer"}
                    />
                </>
            ),
        },
    ];

    const updateTableSource = (_index: string | number) => {
        let newArr = tableSource?.filter((driver, index) => index !== _index);
        setTableSource(newArr);
        let priceSum = 0;
        setDrivers(
            newArr.map((driver) => {
                priceSum = priceSum + driver.price;
                return {
                    driverName: driver.name,
                    driverLicence: driver.licence,
                    price: driver.price,
                };
            }),
        );
        setPrice({ ...formData, additional_driver_price: priceSum });
    };

    useEffect(() => {
        const dataSource = drivers?.map(
            ({ driverLicence, driverName, price }, index) => {
                return {
                    key: index,
                    name: driverName,
                    licence: driverLicence,
                    price: price,
                    delete: index,
                };
            },
        );
        setTableSource(dataSource);
    }, [drivers]);

    return (
        <Table
            pagination={false}
            size={"small"}
            columns={columns}
            dataSource={tableSource}
        />
    );
};

export default AdditinalDriverTableDetails;
