import React from "react";
import withRole from "../../../Providers/AuthProvider/WithRole";
import AddRentCar from "../../../Containers/RentCars/AddRentCar/AddRentCar";

const AddCarPage = () => {
    return (
        <div className="add-car-page">
            <AddRentCar />
        </div>
    );
};

export default withRole(AddCarPage, ["admin"]);
