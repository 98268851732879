import Axios from "axios";
import { useNavigate } from "react-router-dom";
import { App } from "antd";

/**
 *
 * @returns {{setCar: ((function(*, *): Promise<void>)|*), getCarCategories: (function(): Promise<any>), filterCars: ((function(string=, number=, function()=): Promise<*>)|*), getRentCars: ((function(string=, *, function()=): Promise<*>)|*), getCompanyCars: ((function(*, function()=): Promise<*>)|*), getCarsByLocation: ((function(*, function()=): Promise<*>)|*), getCarSippCodes: (function(): Promise<any>), rentACar: ((function(*, *, *): Promise<void>)|*), editCar: ((function(*, *): Promise<void>)|*), getCarByUuid: (function(*): Promise<any>), removeCar: ((function(*): Promise<void>)|*)}}
 */
const useCar = () => {
    const navigate = useNavigate();
    const { message, notification } = App.useApp();
    /**
     * Car Categories
     * @returns {Promise<any>}
     */
    const getCarCategories = async () => {
        const categories = await Axios.get("/get/categories");
        return categories?.data;
    };

    /**
     *
     * @returns {Promise<any>}
     */
    const getCarSippCodes = async () => {
        const sippCodes = await Axios.get("/get/sippcodes");
        return sippCodes?.data;
    };

    /**
     * @param data
     * @param loader
     * @param action
     * @returns {Promise<void>}
     */
    const rentACar = async (data, loader, action) => {
        Axios.post("/rent/car", data)
            .then((response) => {
                notification.success({
                    description: "Successfully created reservation for car.",
                });
                loader(false);
                navigate(`/customer/${response.data.uuid}`, { replace: true });
            })
            .catch((error) => {
                notification.error({
                    description: error.response.data.errors,
                });
                loader(false);
            });
    };

    /**
     * @param data
     * @param loader
     * @returns {Promise<void>}
     */
    const setCar = async (data, loader) => {
        const config = {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        };
        Axios.post("/add/car", data, config)
            .then((response) => {
                notification.success({
                    description: "Successfully added car.",
                });
                navigate(`/car/${response.data.uuid}`);
            })
            .catch((error) => {
                notification.error({
                    description: error.response.data.errors,
                });
                loader(false);
            });
    };

    /**
     * @param data
     * @param loader
     * @returns {Promise<void>}
     */
    const editCar = async (data, loader) => {
        const uuid = data.uuid;
        const config = {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        };
        Axios.post("/edit/car", data, config)
            .then((response) => {
                message.success("Successfully updated car.");
                loader(false);
                navigate(`/car/${uuid}`);
            })
            .catch((error) => {
                message.error(error.response.data.errors);
                loader(false);
            });
    };

    /**
     * @param {*} uuid
     */
    const removeCar = async (uuid) => {
        await Axios.post("/remove/car", { uuid: uuid })
            .then((response) => {
                message.success("Successfully removed car.");
                navigate("/manage/cars");
            })
            .catch((error) => {
                console.log(error);
                message.error(error.response.data.errors);
            });
    };

    /**
     *
     * @param page
     * @param loading
     * @returns {Promise<any>}
     */
    const getCompanyCars = async (page, loading = () => {}) => {
        try {
            const cars = await Axios.get(`/get/cars?page=${page}`);
            return cars.data;
        } catch (error) {
            message.error(error.response.data.errors);
        } finally {
            loading(false);
        }
    };

    /**
     *
     * @param locationUuid
     * @param loading
     * @returns {Promise<any>}
     */
    const getCarsByLocation = async (locationUuid, loading = () => {}) => {
        try {
            const locationCars = await Axios.get(
                `/get/location-cars/${locationUuid}`,
            );
            return locationCars.data;
        } catch (error) {
            console.error(error);
            message.error(error.response.data.errors);
        } finally {
            loading(false);
        }
    };

    /**
     *
     * @param uuid
     * @returns {Promise<any>}
     */
    const getCarByUuid = async (uuid) => {
        const car = await Axios.get(`/get/car/${uuid}`);
        return car.data;
    };

    /**
     * Search of cars in manage vehicle section on fronted
     * @param query
     * @param page
     * @param loading
     * @returns {Promise<any>}
     */
    const filterCars = async (query = "", page = 1, loading = () => {}) => {
        try {
            const filteredCars = await Axios.get(
                `/search/cars?page=${page}&${query}`,
            );
            return filteredCars.data;
        } catch (error) {
            message.error("Something went wrong, try again later");
            console.error(error);
        } finally {
            loading(false);
        }
    };

    /**
     *
     * @param query
     * @param page
     * @returns {Promise<any>}
     */
    const getRentCars = async (query = "", page, loading = () => {}) => {
        try {
            const rentCars = await Axios.get(
                `/get/rent/cars?${query ? query : "list_check_out=true"}`,
            );
            return rentCars;
        } catch (error) {
            message.error(error.message);
            return [];
        } finally {
            loading(false);
        }
    };

    return {
        rentACar,
        getCarCategories,
        getCarSippCodes,
        getCompanyCars,
        getCarsByLocation,
        getRentCars,
        getCarByUuid,
        setCar,
        editCar,
        removeCar,
        filterCars,
    };
};

export default useCar;
