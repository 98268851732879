import React, { createContext, ReactNode, useEffect, useState } from "react";
import {
    ActivationDataTypes,
    IntegrationListTypes,
    IntegrationTypes,
} from "../../Types/IntegrationTypes";
import Integrations from "Utils/partials/integrations";
import { LocationTypes } from "../../Types/LocationTypes";
import useLocations from "../../Hooks/LocationsHooks/useLocations";
import LoadingPlaceholder from "../../Components/Placeholders/LoadingPlaceholder";
interface ActivateIntegrationProviderTypes {
    children: ReactNode;
}

// @ts-ignore
const ActivateIntegrationContext = createContext();

const ActivationDataInitialState: ActivationDataTypes = {
    integration_name: "",
    integration_type: undefined,
    location_id: "",
};

const ActivateIntegrationProvider = ({
    children,
}: ActivateIntegrationProviderTypes) => {
    const { getLocations } = useLocations();

    const [openIntegration, setOpenIntegration] = useState<boolean>(false);
    const [integration, setIntegration] = useState<
        undefined | IntegrationListTypes
    >(undefined);
    const [locations, setLocations] = useState<LocationTypes[] | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [activationData, setActivationData] = useState<ActivationDataTypes>(
        ActivationDataInitialState,
    );

    const showIntegrationDrawer = (integrationType: IntegrationTypes) => {
        setActivationData(ActivationDataInitialState);
        const selectedIntegration = Integrations?.find(
            (integration) => integration.integrationType === integrationType,
        );
        setIntegration(selectedIntegration);
        setOpenIntegration(true);
    };
    const closeIntegrationDrawer = () => {
        setOpenIntegration(false);
        setIntegration(undefined);
    };

    const activateIntegration = () => {};

    const fetchLocations = async () => {
        setLoading(true);
        // @ts-ignore
        const locations: LocationTypes[] = await getLocations().finally(() => {
            setLoading(false);
        });
        setLocations(locations);
    };

    useEffect(() => {
        fetchLocations();
    }, []);

    return (
        <ActivateIntegrationContext.Provider
            value={{
                showIntegrationDrawer,
                closeIntegrationDrawer,
                integration,
                locations,
                setActivationData,
                activationData,
                activateIntegration,
                isDrawerOpen: openIntegration,
            }}
        >
            {loading ? <LoadingPlaceholder /> : children}
        </ActivateIntegrationContext.Provider>
    );
};

export { ActivateIntegrationProvider, ActivateIntegrationContext };
