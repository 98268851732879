import Axios from "axios";
import { App } from "antd";
import { useNavigate } from "react-router-dom";

const useEmployee = () => {
    const { message, notification } = App.useApp();
    const navigate = useNavigate();
    const setEmployee = (data, loader) => {
        Axios.post("/add/employee", data, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        })
            .then((response) => {
                message.success("Successfully added employee.");
                loader(false);
            })
            .catch((error) => {
                message.error(error.response.data.errors);
                loader(false);
            });
    };

    const updateEmployee = (data, loader) => {
        Axios.post("/edit/employee", data, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        })
            .then((response) => {
                notification.success({
                    message: "Success",
                    description: "Successfully updated employee.",
                });
                loader(false);
            })
            .catch((error) => {
                console.log(error);
                message.error(error.response.data.errors);
                loader(false);
            });
    };

    /**
     *
     * @param uuid
     */
    const removeEmployee = (uuid) => {
        Axios.post("/remove/employee", { uuid: uuid })
            .then((response) => {
                message.success("Successfully removed employee.");
            })
            .catch((error) => {
                message.error(error.response.data.errors);
            });
    };

    /**
     *
     * @param page
     * @param query
     * @returns {Promise<axios.AxiosResponse<any>>}
     */
    const getEmployees = async (page, query) => {
        const employees = await Axios.get(
            `/get/employees?page=${page}&${query}`,
        );
        return employees?.data;
    };

    const getEmployeeAnalytics = async (queryString, loading) => {
        try {
            const analytics = await Axios.get(
                `/employee/analytics?${queryString}`,
            );
            return analytics?.data;
        } catch (error) {
            console.log(error);
        } finally {
            loading(false);
        }
    };

    /**
     *
     * @param employeId
     * @returns {Promise<void>}
     */
    const getEmployeeByUuid = async (employeId) => {
        const employee = await Axios.get("/get/employee/" + employeId);
        return employee?.data;
    };

    const getEmployeesByLocation = async (locationId, loading) => {};

    return {
        setEmployee,
        getEmployees,
        getEmployeesByLocation,
        getEmployeeAnalytics,
        updateEmployee,
        removeEmployee,
        getEmployeeByUuid,
    };
};

export default useEmployee;
