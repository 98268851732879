import Axios from "axios";
import { useSession } from "../SessionHooks/useSession";
import { App } from "antd";

const useCompany = () => {
    const { getUserCache } = useSession();
    const { message, notification } = App.useApp();
    /**
     * GET COMPANY SETTINGS
     * @returns {*}
     */
    const getCompanySettings = () => {
        const userCache = getUserCache();
        const { company } = userCache;
        return company[0];
    };

    /**
     * UPDATE COMPANY SETTINGS
     * @param data
     * @param loading
     */
    const updateCompanySettings = async (data, loading) => {
        const config = {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        };
        const company = await Axios.post("/edit/company", data, config).catch(
            (error) => {
                message.error(error.response.data.errors);
            },
        );
        if (company) {
            message.success(
                "Succesfully updated company, refresh page to see changes.",
            );
        }
        loading(false);
        return company?.data;
    };

    /**
     * @param data
     * @returns {Promise<void>}
     */
    const createLegalDocument = async (data) => {
        const company = await Axios.post(
            "/company/documents/create",
            data,
        ).catch((error) => {
            console.log(error);

            notification.error({
                message: error.response.data.errors,
            });
        });
        if (company) {
            notification.success({
                message: "Successfully created legal document!",
            });
        }
    };

    /**
     * @returns {Promise<any>}
     */
    const getAllLegalDocuments = async () => {
        const documents = await Axios.get("/company/documents/get");
        return documents?.data;
    };

    const getLegalDocumentByUuid = async (uuid) => {
        const document = await Axios.get(`/company/documents/${uuid}/get`);
        console.log(document);
        return document?.data;
    };

    /**
     * @param uuid
     * @param data
     * @returns {Promise<void>}
     */
    const updateLegalDocument = async (uuid, data) => {
        const doc = await Axios.patch(
            `/company/documents/${uuid}/update`,
            data,
        ).catch((error) => {
            notification.error({
                message: error.response.data.errors,
            });
        });
        if (doc) {
            notification.success({
                message: "Successfully updated legal document!",
            });
        }
    };

    /**
     * @param uuid
     * @returns {Promise<void>}
     */
    const deleteLegalDocument = async (uuid) => {
        const doc = await Axios.delete(
            `/company/documents/${uuid}/delete`,
        ).catch((error) => {
            console.log(error);

            notification.error({
                message: error.response.data.errors,
            });
        });
        if (doc) {
            notification.success({
                message: "Successfully removed legal document!",
            });
        }
    };

    return {
        getCompanySettings,
        updateCompanySettings,
        createLegalDocument,
        getAllLegalDocuments,
        getLegalDocumentByUuid,
        updateLegalDocument,
        deleteLegalDocument,
    };
};

export default useCompany;
