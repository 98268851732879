import React, { useContext } from "react";
import { useAnalytics } from "Hooks/AnalyticsHooks/useAnalytics";
import DashboardContext from "Context/DashboardContext";
import withRole from "Providers/AuthProvider/WithRole";
import { Select, Space } from "antd";

const LocationFilter = ({ currentLocation }) => {
    const { getDashboardAnalytics } = useAnalytics();
    const { locations, setAnalytics } = useContext(DashboardContext);

    const handleAnalyticsLocation = async (e) => {
        const analytics = await getDashboardAnalytics(e);
        setAnalytics(analytics[0]);
    };

    const listOptions = () => {
        return locations?.length > 0
            ? locations.map((location) => {
                  const { uuid, name, address } = location;
                  return { value: uuid, label: name + " " + address };
              })
            : [];
    };

    return (
        <div className={"location-filter-dashboard"}>
            <Space wrap>
                <Select
                    defaultValue={currentLocation}
                    style={{ width: 200 }}
                    onChange={handleAnalyticsLocation}
                    options={listOptions()}
                />
            </Space>
        </div>
    );
};

export default withRole(LocationFilter, ["admin"]);
