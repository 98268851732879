import React from "react";
import { Link } from "react-router-dom";
import { Button } from "antd";
import { CarOutlined } from "@ant-design/icons";

// @ts-ignore
interface CustomerCarTypes {
    data: {
        uuid: string;
        car_name: string;
        car_price: string;
        car_transmission_type: string;
        car_fuel_type: string;
        car_licence_number: string;
    };
}
const CustomerCar = ({ data }: CustomerCarTypes) => {
    const {
        uuid,
        car_name,
        car_transmission_type,
        car_fuel_type,
        car_licence_number,
    } = data;

    return (
        <Link to={`/car/${uuid}`} className="lg:w-full">
            <div
                className={
                    "flex justify-between items-center md:flex-wrap bg-[#fafafa] border pt-4 pb-4 pl-2 pr-2 rounded-xl"
                }
            >
                <div className={"flex gap-5 md:flex-wrap  w-[50%]"}>
                    <p>
                        {" "}
                        <CarOutlined className={"text-2xl"} />{" "}
                    </p>
                    <p className={"sm:w-32"}> {car_name} </p>
                    <p className={"sm:w-full sm:hidden"}>
                        {" "}
                        {car_licence_number}{" "}
                    </p>
                    <p className={"sm:w-full sm:hidden"}> {car_fuel_type} </p>
                    <p className={"uppercase sm:w-full sm:hidden"}>
                        {" "}
                        {car_transmission_type}{" "}
                    </p>
                </div>
                <Button type={"dashed"}> Open Vehicle </Button>
            </div>
        </Link>
    );
};

export default CustomerCar;
