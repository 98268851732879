import React from "react";
import { Descriptions, Tag } from "antd";

interface SummaryDescriptionsPropTypes {
    dateRange: any;
    currency: string;
    additionalDriversList: [];
    crossingBorderList: [];
    rentalData: {
        check_in: string | null;
        check_out: string | null;
        rent_price: string | null;
        insurance_price: string | null;
        deposit_price: string | null;
        additional_driver_price: number;
        crossing_border_price: string | null;
        note: string | null;
        gps_price: string | null;
        baby_seat_price: string | null;
    };
    totalDays: string | number;
    totalPrice: string | number;
}

const SummaryDescriptions = ({
    currency,
    rentalData,
    totalDays,
    totalPrice,
    dateRange,
}: SummaryDescriptionsPropTypes) => {
    const {
        check_in,
        check_out,
        rent_price,
        insurance_price,
        deposit_price,
        gps_price,
        baby_seat_price,
        crossing_border_price,
        additional_driver_price,
        note,
    } = rentalData;

    const items = [
        {
            key: "1",
            label: "Additional Drivers Price",
            children: (
                <div className={"flex justify-between"}>
                    <Tag color={additional_driver_price ? "green" : "red"}>
                        <strong>
                            {additional_driver_price ?? "0"} {currency}
                        </strong>
                    </Tag>
                </div>
            ),
        },
        {
            key: "2",
            label: "Border Crossing Info",
            children: (
                <>
                    <Tag color={crossing_border_price ? "green" : "red"}>
                        <strong>
                            {crossing_border_price ?? "0"} {currency}
                        </strong>
                    </Tag>
                </>
            ),
        },
        {
            key: "3",
            label: "GPS Price per/day",
            children: (
                <>
                    <Tag color={gps_price ? "green" : "red"}>
                        <strong>
                            {gps_price ?? "0"} {currency}
                        </strong>
                    </Tag>
                </>
            ),
        },
        {
            key: "4",
            label: "Baby Seat Price per/day",
            children: (
                <>
                    <Tag color={baby_seat_price ? "green" : "red"}>
                        <strong>
                            {baby_seat_price ?? "0"} {currency}
                        </strong>
                    </Tag>
                </>
            ),
        },
        {
            key: "8",
            span: 2,
            label: "Start Time / End Time",
            children: (
                <>
                    <Tag color={check_in && check_out ? "green" : "gray"}>
                        <strong className={"uppercase"}>
                            {check_in && check_out
                                ? `${check_in} - ${check_out}`
                                : "Not Selected"}
                        </strong>
                    </Tag>
                </>
            ),
        },
        {
            key: "7",
            span: 2,
            label: "Start Date / End Date",
            children: (
                <>
                    <Tag color={dateRange ? "green" : "gray"}>
                        <strong className={"uppercase"}>
                            {dateRange
                                ? `${dateRange.from} - ${dateRange.to}`
                                : "Not Selected"}
                        </strong>
                    </Tag>
                </>
            ),
        },
        {
            key: "6",
            label: "Deposit",
            children: (
                <>
                    <Tag color={deposit_price ? "green" : "red"}>
                        <strong>
                            {" "}
                            {deposit_price ?? "0"} {currency}{" "}
                        </strong>
                    </Tag>
                </>
            ),
        },
        {
            key: "5",
            label: "Insurance Price",
            children: (
                <>
                    <Tag color={insurance_price ? "green" : "red"}>
                        <strong>
                            {" "}
                            {insurance_price ?? "0"} {currency}{" "}
                        </strong>
                    </Tag>
                </>
            ),
        },
        {
            key: "99",
            span: 3,
            label: "Rental Price",
            children: (
                <>
                    <Tag color={rent_price ? "green" : "red"}>
                        <strong>
                            {" "}
                            {rent_price ?? "0"} {currency}{" "}
                        </strong>
                    </Tag>
                </>
            ),
        },
        {
            key: "9",
            span: 5,
            label: "Customer Note",
            children: (
                <>
                    <Tag color={note ? "orange" : "gray"}>
                        <strong className={"uppercase"}>
                            {note ?? "Not Added"}
                        </strong>
                    </Tag>
                </>
            ),
        },
        {
            key: "10",
            span: 3,
            label: "Total Days",
            children: (
                <>
                    <Tag color={"blue"}>
                        <strong className={"text-xl text-end"}>
                            {totalDays ?? "0"}
                        </strong>
                    </Tag>
                </>
            ),
        },
        {
            key: "11",
            span: 3,
            label: "Total Summary",
            children: (
                <>
                    <Tag color={"blue"}>
                        <strong className={"text-xl text-end"}>{`${
                            totalDays ? totalPrice : "0.0"
                        } ${currency}`}</strong>
                    </Tag>
                </>
            ),
        },
    ];

    // @ts-ignore
    return (
        <Descriptions
            size={"small"}
            className={"h-full md:overflow-scroll"}
            column={4}
            bordered
            items={items}
        />
    );
};

export default SummaryDescriptions;
