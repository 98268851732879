import React from "react";
import CompanySettingsContainer from "Containers/Settings/Company/CompanySettingsContainer";

const CompanySettings = () => {
    return (
        <div className={"company-settings"}>
            <CompanySettingsContainer />
        </div>
    );
};

export default CompanySettings;
