// @ts-nocheck
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import HeadingTitle from "Components/Headings/HeadingTitle";
import useCustomer from "Hooks/CustomerHooks/useCustomer";
import LoadingPlaceholder from "Components/Placeholders/LoadingPlaceholder/LoadingPlaceholder";
import CustomerCar from "./partials/CustomerCar";
import CustomerInfo from "./partials/CustomerInfo";
import Button from "Components/Button";
import { Alert, Divider, Tag } from "antd";
import CustomerDateTime from "./partials/CustomerDateTime";
import UpdateCustomerReservation from "./partials/UpdateCustomerReservation";
import { isUserAdmin } from "Utils/UserUtils";
import TypeReservationHandler from "Components/TypeReservation/TypeReservationHandler";
import {
    getCustomerReservationType,
    getCustomerReservationTypeReference,
} from "Helpers/CustomerHelper";
import ReservationHistoryTimeline from "./partials/ReservationHistoryTimeline";
import ObjectUtils from "Utils/ObjectUtils";

const SingleCustomerContainer = () => {
    const { uuid } = useParams();
    const { getCustomerByUuid, finishReservation } = useCustomer();

    const [customer, setCustomer] = useState(null);
    const [loading, setLoading] = useState(false);
    const [reservationFinished, setReservationFinished] = useState(false);
    const [isUpdated, setIsUpdated] = useState(false);

    const fetchCustomer = async () => {
        const customer = await getCustomerByUuid(uuid);
        if (customer) {
            setCustomer(customer?.data);
        }
    };
    const handleReservation = () => {
        setLoading(true);
        // @ts-ignore
        finishReservation(customer?.uuid, setLoading);
        setReservationFinished(true);
    };
    useEffect(() => {
        fetchCustomer();
        if (isUpdated) {
            setIsUpdated(false);
        }
        if (reservationFinished) {
            setReservationFinished(false);
        }
    }, [isUpdated, reservationFinished]);

    if (!customer) return <LoadingPlaceholder />;

    const { check_in, check_out, from, to } = customer;

    return (
        <div className="single-customer-container">
            <HeadingTitle title={"Date / Time Information"} />

            <CustomerDateTime
                checkIn={check_in}
                checkOut={check_out}
                rentFrom={from}
                rentTo={to}
            />

            <HeadingTitle title={"Vehicle Information"} />
            {
                // @ts-ignore
                customer?.car && (
                    // @ts-ignore
                    <CustomerCar data={customer?.car} />
                )
            }
            <HeadingTitle title={"Reservation Type"} />
            <div className={"flex gap-5 items-center"}>
                <TypeReservationHandler
                    size={150}
                    type={getCustomerReservationType(customer?.customer)}
                />
                <Tag className={"font-bold"} color={"gray"}>
                    {getCustomerReservationTypeReference(customer?.customer)}
                </Tag>
            </div>

            <HeadingTitle title={"Customer Summary"} />
            <CustomerInfo data={customer} />
            {!ObjectUtils.isEmptyObject(customer?.reservation_history) &&
                isUserAdmin() && (
                    <>
                        <HeadingTitle title={"Edit History"} />
                        <ReservationHistoryTimeline
                            histories={customer?.reservation_history}
                        />
                    </>
                )}
            <HeadingTitle title={"Customer Note"} />
            <div className="md-min:w-full mx-auto">
                {
                    // @ts-ignore
                    customer.note ? (
                        <Alert
                            // @ts-ignore
                            description={customer.note}
                            type={"info"}
                            showIcon={true}
                        />
                    ) : (
                        <Alert
                            type={"warning"}
                            showIcon
                            message={"There is no notes for this customer."}
                        />
                    )
                }
            </div>
            <HeadingTitle title={"Actions"} />
            <div className="flex gap-2 justify-end items-center sm:justify-center sm:flex-wrap">
                {(!customer.deleted_at ||
                    (customer.deleted_at && isUserAdmin())) && (
                    <>
                        <div className="dropdown dropdown-top sm:w-full">
                            <Button className={"sm:w-full"}>
                                <Link
                                    to={"/pdf/agreement"}
                                    state={{ data: customer }}
                                >
                                    Create Agreement
                                </Link>
                            </Button>
                        </div>
                        <UpdateCustomerReservation
                            reservation={customer}
                            customerUuid={uuid}
                            isUpdated={setIsUpdated}
                        />
                    </>
                )}
                {
                    // @ts-ignore
                    !customer.deleted_at && !reservationFinished && (
                        <div className="text-end center sm:w-full">
                            <Button
                                isLoading={loading}
                                onClick={handleReservation}
                                buttonType={"primary"}
                                className="w-44 sm:w-full"
                            >
                                <span> Finish Reservation </span>
                            </Button>
                        </div>
                    )
                }
            </div>
        </div>
    );
};

export default SingleCustomerContainer;
